import * as React from "react";
import theme from "../../../../../../common/styles/base.module.scss";
import {observer} from 'mobx-react';
import {ScheduleReportCreateModel} from '../../../create/model/ScheduleReportCreateModel.mdl';
import {ScheduleReportEditModel} from '../../../edit/model/ScheduleReportEditModel.mdl';
import IReportingFilter from '../../../../../reporting/common/interfaces/IReportingFilter';
import IReportingConstraint from '../../../../../reporting/common/interfaces/IReportingConstraint';
import {Chips, HLine, Label, LinkButton} from '@vidazoo/ui-kit';
import ReportingConstraints from '../../../../../reporting/components/reportingConstraints/ReportingConstraints';
import ReportingFilters from '../../../../../reporting/components/reportingFilters/ReportingFilters';
import useReportingMetaDataManager from '../../../../../../common/hooks/useReportingMetaDataManager';
import ReportingSubDimensions from "../../../../../reporting/components/reportingSubDimensions/ReportingSubDimensions";

export interface IReportFormProps {
    model: ScheduleReportCreateModel | ScheduleReportEditModel;
}

export default observer(function ReportForm(props: IReportFormProps) {

    const reportingMetaDataManager = useReportingMetaDataManager();
    const {model} = props;
    const item = model.item.data

    const onAddFilter = () => {
        model.addFilter();
    };

    const onRemoveFilter = (filter: IReportingFilter) => {
        model.item.removeFromArrayByProp("filters", filter.id, "id");
    };

    const onAddConstraint = () => {
        model.addConstraint();
    };

    const onRemoveConstraint = (constraint: IReportingConstraint) => {
        model.item.removeFromArrayByProp("constraints", constraint.id, "id");
    };

    const onDeleteDimension = (value: string) => {
        model.item.removeFromArrayByProp("groups", value);
    };

    const onOrderDimensions = (dimensions: any[]) => {
        model.item.updateParam("groups", dimensions);
    };

    const onAddDimension = (value: string, label: string) => {
        model.item.addToParamArray("groups", {value, label});
    };

    const onDeleteMetric = (value: string) => {
        model.item.removeFromArrayByProp("fields", value);
    };

    const onOrderMetrics = (metrics: any[]) => {
        model.item.updateParam("fields", metrics);
    };

    const onAddMetric = (value: string, label: string) => {
        model.item.addToParamArray("fields", {value, label});
    };

    return (
        <div className={theme.form}>
            <div className="flex justify-space-between align-center" style={{marginBottom: 30}}>
                <Label spacing={1.25} size={13} monserrat weight={600}>Dimensions</Label>
                <LinkButton type="button" iconName="icon-plus-small" deepPurple small uppercase spaced bold
                            onClick={onAddFilter}>Add Filter</LinkButton>
                <LinkButton type="button" className={theme.link} iconName="icon-plus-small"
                            deepPurple small uppercase spaced bold
                            onClick={model.addSubDimension}>Add
                    SubDimension</LinkButton>
            </div>
            <Chips
                allowOrder
                required
                chips={item.groups}
                maxItems={5}
                leaveOpenAfterSelect
                onDelete={onDeleteDimension}
                onOrder={onOrderDimensions}
                onSelect={onAddDimension}
                itemValueKey="value"
                itemLabelKey="label"
                dataSource={reportingMetaDataManager.metaDataByVertical[item.verticalType].groups.entries}
                searchPlaceholder="Add Dimension"
                emptyPlaceholder="Add Dimension"
            />
            <ReportingSubDimensions
                subGroups={item.subGroups}
                onChangeSubGroup={model.setSubGroupParam}
                onDeleteSubGroup={model.removeSubGroup}
                onAddSubGroupValue={model.pushSubGroupValue}
                onRemoveSubGroupValue={model.removeSubGroupValue}
                onAddTargetingType={model.pushSubGroupTargetingType}
                onDeleteTargetingType={model.removeSubGroupTargetingType}
                onAddTargetingOperator={model.pushSubGroupTargetingOperator}
                onDeleteTargetingOperator={model.removeSubGroupTargetingOperator}
                onClearAllParams={model.onClearAllParams}
            />
            <ReportingFilters
                filters={item.filters}
                onClearAllParams={model.clearAllFilters}
                setFilterKey={model.setFilterKey}
                setFilterParam={model.setFilterParam}
                removeFilter={onRemoveFilter}
                pushFilterValue={model.pushFilterValue}
                removeFilterValue={model.removeFilterValue}
            />
            <HLine space={3.2}/>
            <div className="flex justify-space-between align-center" style={{marginBottom: 30}}>
                <Label spacing={1.25} size={13} monserrat weight={600}>Metrics</Label>
                <LinkButton type="button" iconName="icon-plus-small" deepPurple small uppercase spaced bold
                            onClick={onAddConstraint}>Add Constraint</LinkButton>
            </div>
            <Chips
                allowOrder
                required
                chips={item.fields}
                maxItems={5}
                leaveOpenAfterSelect
                onDelete={onDeleteMetric}
                onOrder={onOrderMetrics}
                itemValueKey="value"
                itemLabelKey="label"
                onSelect={onAddMetric}
                dataSource={reportingMetaDataManager.metaDataByVertical[item.verticalType].fields.entries}
                searchPlaceholder="Add Metric"
                emptyPlaceholder="Add Metric"
            />
            <ReportingConstraints
                setConstraintParam={model.setConstraintParam}
                removeConstraint={onRemoveConstraint}
                onClearAllParams={model.clearAllConstraint}
                constraints={item.constraints}
            />
        </div>
    );
})
