import {Objects} from '@vidazoo/ui-kit';
import Hook from '../utils/Hook';

export type LocalStorageHooks = {
    onStorageReady: Hook;
};

export default class BaseLocalStorageManager {

    protected storageKey: string;
    private storage: any;
    private flushAsyncId: any;
    public hooks: LocalStorageHooks;

    constructor() {
        this.storage = {};
        this.storageKey = null;
        this.flushAsyncId = null;

        this.hooks = {
            onStorageReady: new Hook(),
        };
    }

    public initialize = (storageKey: string) => {
        this.storage = this.getJson(storageKey);
        this.storageKey = storageKey;
        this.hooks.onStorageReady.call(this.storage)
    }

    protected get(path: string, defaultValue: any): any {
        return Objects.get(this.storage, path, defaultValue);
    }

    protected set(path: string, value: any) {
        Objects.set(this.storage, path, value);
        this.flush();
    }

    private flush = () => {
        window.clearTimeout(this.flushAsyncId);

        this.flushAsyncId = setTimeout(() => {
            this.setJson(this.storageKey, this.storage);
        });
    }

    private getJson(key: string): any {
        const value = localStorage.getItem(key);

        try {
            if (value) {
                return JSON.parse(value);
            }
        } catch (e) {
            //
        }

        return {};
    }

    private setJson(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    }
}
