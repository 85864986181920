import ScheduleReportsList from '../components/ScheduleReportsList';
import ScheduleReportsHeader from '../components/ScheduleReportsHeader';
import {ScheduleReportsListWithContext} from '../context/ScheduleReportsListContext';

export default function ScheduleReports() {

    return (
        <ScheduleReportsListWithContext>
            <ScheduleReportsHeader/>
            <ScheduleReportsList/>
        </ScheduleReportsListWithContext>
    );
}
