import {init, inject} from '@injex/core';
import * as socket from 'socket.io-client';
import {Injex} from '@injex/webpack';
import {SessionManager} from '../../modules/session/managers/sessionManager.mdl';
import IDisposable from '../interfaces/IDisposable';
import IEnv from '../interfaces/IEnv';
import Hook from '../utils/Hook';
import {guid} from '@vidazoo/ui-kit';

export type SocketHooks = {
    socketConnected: Hook;
};

export abstract class BaseSocket implements IDisposable {

    @inject() private sessionManager: SessionManager;
    @inject() public env: IEnv;
    @inject() private $injex: Injex;

    public hooks: SocketHooks;
    public name: string;

    protected abstract get getUri(): string;

    private _socket: socket.Socket;
    private _requests: { [index: string]: { resolve: (...args: any[]) => void; reject: (error: Error) => void }; };

    constructor() {
        this._requests = {};

        this.hooks = {
            socketConnected: new Hook(),
        };
    }

    @init()
    public initialize() {
        this.sessionManager.hooks.loginSuccess.tapAsync(this._onLogin, null, this);
    }

    private _onLogin() {
        if (this._socket?.connected) {
            return;
        }

        return new Promise((resolve, reject) => {
            this.$injex.logger.info(`${this.name} Connecting to socket...`);
            const {accessToken} = this.sessionManager.getRequestContext();

            this._socket = socket.io(this.getUri, {
                secure: process.env.NODE_ENV === 'production',
                transports: ['websocket'],
                reconnectionAttempts: 100,
                auth: {
                    token: accessToken
                }
            });

            this._socket.on('connect', this._onConnect.bind(this, resolve));
            this._socket.on('connect_error', this._onConnectError.bind(this, reject));
            this._socket.on('connect_timeout', this._onConnectTimeout.bind(this, reject));
            this._socket.on('error', this._onError.bind(this));
            this._socket.on('disconnect', this._onDisconnect.bind(this));
        });
    }

    public get socket() {
        return this._socket;
    }

    private async _onConnect(resolve) {
        this.$injex.logger.info(`${this.name} Socket connected.`);
        await this.hooks.socketConnected.call()
        resolve();
    }

    private _onDisconnect(reason: string) {
        this.$injex.logger.warn(`${this.name} Socket disconnected.`);
    }

    private _onConnectError(reject) {
        this.$injex.logger.error(`${this.name} Socket connection error.`);
        reject();
    }

    private _onConnectTimeout(reject) {
        this.$injex.logger.error(`${this.name} Socket connection timed out.`);
        reject();
    }

    private _onError(e) {
        this.$injex.logger.error(`${this.name} Socket error.`, e);
    }

    public request<T>(command: string, data: any, expire: number = 5 * 60 * 1000): Promise<T> {
        return new Promise((resolve, reject) => {
            if (!this._socket.connected) {
                return reject({message: 'service_unavailable', code: 503});
            }

            const id = guid();

            this._requests[id] = {resolve, reject};

            const timeout = window.setTimeout(() => {
                delete this._requests[id];
                reject({message: 'request_timeout', code: 408});
            }, expire);

            this._socket.emit(command, data, (reply: { success: boolean, data: T }) => {
                delete this._requests[id];
                window.clearTimeout(timeout);
                reply.success ? resolve(reply.data) : reject(reply);
            });
        });
    }

    public dispose(): void {
        if (this._socket?.connected) {
            this._socket.disconnect();
        }
    }

    public get isConnected(): boolean {
        return this._socket?.connected;
    }

    public on(event: string, callback: (...args: any[]) => void) {
        this._socket.on(event, callback);
    }

    public off(event: string) {
        this._socket.off(event)
    }
}
