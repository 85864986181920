import {define, singleton} from '@injex/core';
import EntityRequestAdapter from '../../../common/utils/EntityRequestAdapter';
import IDashboard from '../interfaces/IDashboard';

@define()
@singleton()
export class DashboardsApi extends EntityRequestAdapter<IDashboard> {
    protected basePathName: string = 'dashboards';

    protected get apiEndpoint(): string {
        return this.env.biDashboardsAPI;
    }
}
