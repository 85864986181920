import {createContext, useContext, useMemo} from "react";
import {ScheduleReportsListModel} from '../model/ScheduleReportsListModel.mdl';
import {useModuleFactory} from '@injex/react-plugin';
import {useTableRowSelection, TableRowSelectionHook} from '@vidazoo/ui-kit';

export interface IScheduleReportsListContext {
    model: ScheduleReportsListModel;
    tableRowSelectionHook: TableRowSelectionHook;
    allItemIds: string[];
}

export interface IScheduleReportsListWithContext {
    children: any,
}

export const ScheduleReportsListContext = createContext<IScheduleReportsListContext>({
    model: null,
    tableRowSelectionHook: null,
    allItemIds: null
})

export function ScheduleReportsListWithContext({children}: IScheduleReportsListWithContext) {

    const model = useModuleFactory<ScheduleReportsListModel>('scheduleReportsListModel');
    const allItemIds = useMemo<string[]>(() => model.maskItems.map((item) => item._id), [model.maskItems]);
    const tableRowSelectionHook = useTableRowSelection(allItemIds, false);

    const contextValue: IScheduleReportsListContext = useMemo(() => ({
        model,
        tableRowSelectionHook,
        allItemIds
    }), [model, tableRowSelectionHook, allItemIds]);

    return (
        <ScheduleReportsListContext.Provider value={contextValue}>
            {children}
        </ScheduleReportsListContext.Provider>
    )
}

export function useScheduleReportsListContext(): IScheduleReportsListContext {
    return useContext<IScheduleReportsListContext>(ScheduleReportsListContext);
}
