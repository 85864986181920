import React from 'react';
import {Column, HeaderColumn, Row, TableRowSelectionHook} from '@vidazoo/ui-kit';
import {observer} from 'mobx-react';
import {ScheduleReportsListModel} from '../model/ScheduleReportsListModel.mdl';
import {useDashboardsListContext} from '../../../dashboards/list/context/DashboardsListContext';
import {useScheduleReportsListContext} from '../context/ScheduleReportsListContext';

interface ITableHeaderProps {
}

function ScheduleReportsTableHeader(props: ITableHeaderProps) {
    const {model, tableRowSelectionHook} = useScheduleReportsListContext();
    const {isAllSelected, toggleAllItems} = tableRowSelectionHook

    return (
        <Row>
            <Column colKey="checkbox" width={40} className='flex items-center justify-center'>
                <input className="flex w-8 h-8 cursor-pointer" disabled={model.isLoading} type="checkbox"
                       checked={isAllSelected}
                       onChange={toggleAllItems}/>
            </Column>
            <HeaderColumn colKey="name" />
            <HeaderColumn colKey="schedule" />
            <HeaderColumn colKey="dateRange" />

            <HeaderColumn colKey="isActive" />
            <HeaderColumn colKey="created" />
        </Row>
    )
}

export default observer(ScheduleReportsTableHeader);
