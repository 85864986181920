import theme from '../reportingHeader/theme.module.scss';
import {ContextMenu} from '@vidazoo/ui-kit';
import ReportingFilterRow from './ReportingFilterRow';
import {ReportingContextMenuType} from '../../common/enums/ReportingContextMenuType';
import {useCallback} from 'react';
import {observer} from 'mobx-react';
import MemoryClipboard from '../../../../common/utils/MemoryClipboard';
import IReportingFilter from '../../common/interfaces/IReportingFilter';

export interface IReportingFiltersProps {
    filters?: IReportingFilter[];
    onClearAllParams: (key: ReportingContextMenuType) => void;
    pasteFilter?: (filter: IReportingFilter) => void;
    setFilterKey: (filter: IReportingFilter, key: string) => Promise<any>;
    setFilterParam: (filter: IReportingFilter, key: string, value: any) => void;
    removeFilter: (filter: IReportingFilter) => void;
    pushFilterValue: (filter: IReportingFilter, value: string, label: string) => void;
    removeFilterValue: (filter: IReportingFilter, value: string) => void;
}

function ReportingFilters(props: IReportingFiltersProps) {
    const {removeFilterValue, pushFilterValue, removeFilter, setFilterParam, setFilterKey, filters, pasteFilter, onClearAllParams} = props;

    const copyFilter = useCallback((filter: IReportingFilter) => {
        const filterValues = filter.values.map((item) => {
            if (typeof item === "string") {
                return item;
            } else if (typeof item === "object") {
                return item[filter.filterLabelKey];
            }
            return "";
        });
        MemoryClipboard.copy(filterValues.join(", "));
    }, []);

    const getContextMenuItems = useCallback((pasteMultiple?: () => void, customCopy?: () => void) => {
        const contextMenuItems = [
            {label: "Clear All Filters", onClick: () => onClearAllParams(ReportingContextMenuType.filters)},
        ];

        if (customCopy) {
            contextMenuItems.push({label: "Copy", onClick: customCopy});
        }

        if (pasteMultiple) {
            contextMenuItems.push({label: "Paste Multiple", onClick: pasteMultiple});
        }

        return contextMenuItems;
    }, []);

    return (
        <div className={theme.filters}>
            {filters.map((filter, idx) => {
                const pasteFn = (filter.key && pasteFilter) ? () => pasteFilter(filter) : null;
                const copyFn = () => copyFilter(filter);
                return (
                    <ContextMenu
                        items={getContextMenuItems(pasteFn, copyFn)}
                        key={filter.id || idx}>
                        <div>
                            <ReportingFilterRow
                                key={filter.id || idx}
                                index={idx}
                                filter={filter}
                                setFilterKey={setFilterKey}
                                setFilterParam={setFilterParam}
                                removeFilter={removeFilter}
                                pushFilterValue={pushFilterValue}
                                removeFilterValue={removeFilterValue}
                            />
                        </div>
                    </ContextMenu>);
            })}
        </div>
    )
}

export default observer(ReportingFilters);
