import {IScheduleReport} from '../../interfaces/IScheduleReport';
import {TableColumn} from '@vidazoo/ui-kit';
import {DateRanges, ReportSchedules} from '../../common/scheduleReportEnums';

export const ScheduleReportsTableColumns: TableColumn<IScheduleReport>[] = [
    {
        title: 'Checkbox',
        key: 'checkbox',
    },
    {
        title: 'Name',
        key: 'name',
        defaultWidth: 300,
        options: {search: {searchKey: 'name'}, freeze: true, sort: {sortKey: 'name'}}
    },
    {
        title: "Schedule",
        key: "schedule",
        defaultWidth: 200,
        options: {filter: {filterKey: 'schedule', options: ReportSchedules}, freeze: true, sort: {sortKey: 'schedule'}}
    }, {
        title: "Date Range",
        key: "dateRange",
        defaultWidth: 200,
        options: {filter: {filterKey: 'dateRange', options: DateRanges}, freeze: true, sort: {sortKey: 'dateRange'}}
    }, {
        title: "Last Schedule",
        key: "lastSchedule",
        defaultWidth: 200,
        options: {search: {searchKey: 'lastSchedule'}, freeze: true, sort: {sortKey: 'lastSchedule'}}
    }, {
        title: "Next Schedule",
        key: "nextSchedule",
        defaultWidth: 200,
        options: {search: {searchKey: 'nextSchedule'}, freeze: true, sort: {sortKey: 'nextSchedule'}}
    }, {
        title: "Created",
        key: "created",
        defaultWidth: 200,
        options: {search: {searchKey: 'created'}, freeze: true, sort: {sortKey: 'created'}}
    }, {
        title: "Active",
        key: "isActive",
        defaultWidth: 200,
        options: {
            search: {searchKey: 'isActive'},
            sort: {sortKey: 'isActive'},
            filter: {
                filterKey: 'isActive',
                options: [{value: true, label: 'Active'}, {value: false, label: 'Inactive'}]
            }
        }
    }, {
        title: "Actions",
        key: "actions",
    }
];
