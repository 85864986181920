import clsx from 'clsx';
import {observer} from 'mobx-react';
import * as React from 'react';
import useAlertsManager from '../hooks/useAlertsManager';
import theme from '../styles/theme.module.scss';
import AlertView from './AlertView';

function AlertsContainer(): JSX.Element {
    const alertsManager = useAlertsManager();

    return (
        <div className={clsx(theme.container, theme.right)}>
            {(() => {
                const children = [];
                alertsManager.alerts.forEach((alert) => children.push(
                    <AlertView model={alert} key={alert.id} />
                ));
                return children;
            })()}
        </div>
    );
}

export default observer(AlertsContainer);
