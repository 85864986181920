import * as React from "react";
import theme from "./theme.module.scss";
import {ChromePicker} from "react-color";
import {observer} from 'mobx-react';
import {useState} from 'react';

interface IColorPickerProps {
    color: string;
    name: string;
    label?: string;
    onChange: (color: any, name: string) => void;
}

export default observer(function ColorPicker(props: IColorPickerProps) {
    const [displayColorPicker, setDisplayColorPicker] = useState(false);

    const handleClick = () => {
        setDisplayColorPicker(!displayColorPicker);
    };

    const handleClose = () => {
        setDisplayColorPicker(false);
    };

    const handleChange = (color) => {
        props.onChange(color, props.name)
    }

    return (
        <div className={theme.colorPicker}>
            <div className={theme.label}>{props.label}</div>
            <div className={theme.swatch} onClick={handleClick}>
                <div className={theme.color} style={{backgroundColor: props.color}}/>
            </div>
            {displayColorPicker ? <div className={theme.popover}>
                <div className={theme.cover} onClick={handleClose}/>
                <ChromePicker color={props.color} onChange={handleChange}/>
            </div> : null}
        </div>
    )
});
