import BaseFilter from "../BaseFilter";
import {PlatformReportingFilterType} from "../ReportingFilterType";
import {alias, define, singleton} from '@injex/core';

@define()
@singleton()
@alias('PlatformReportingFilter')
export default class CountryGroupFilter extends BaseFilter {

	public type = PlatformReportingFilterType.CountryGroup;

	constructor() {
		super();

		this.items = [{value: "gdprCountries", label: "GDPR Countries"}];
		this.allowNew = false;
		this.labelKey = "label";
		this.valueKey = "value";
	}
}
