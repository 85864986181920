import {define, inject, singleton} from '@injex/core';
import {intermittentLoop} from '../../../common/utils/intermittentLoop';
import {formatNumber, isObject, keyBy} from '../../../common/utils/utils';
import {guid} from '@vidazoo/ui-kit';
import IReportingResults from '../common/interfaces/IReportingResults';
import LinksByVerticalManager from '../../../common/managers/linksByVerticalManager.mdl';
import ReportingMetaDataManager from '../../../common/managers/ReportingMetaDataManager.mdl';
import {computed, makeObservable, observable} from 'mobx';
import {IBiReportingParams} from '../common/interfaces/IReportingParams';
import IReportingResultsRow from '../common/interfaces/IReportingResultsRow';
import {
    IReportingPreparedField,
    IReportingPreparedResults,
    IReportingPreparedResultsRow
} from '../common/interfaces/IReportingPreparedResults';
import {cloneDeep} from 'lodash';
import ReportingParamsManager from "./reportingParamsManager.mdl";
import {ReportVerticalType} from "../../../common/enums/ReportVerticalType";

const DATA_CHUNK_SIZE: number = 50;

@define()
@singleton()
export default class ReportingBuildManager {

    @inject() private linksByVerticalManager: LinksByVerticalManager;
    @inject() private reportingMetaDataManager: ReportingMetaDataManager;
    @inject() private reportingParamsManager: ReportingParamsManager;

    @observable public requestedParams: IBiReportingParams;
    @observable public preparedRows: any[] = [];
    @observable public requestedParamsByKey: { [key: string]: number } = {};

    constructor() {
        makeObservable(this);
    }

    public setRequestedParams(requestedParams: IBiReportingParams) {
        this.requestedParams = cloneDeep(requestedParams);
        const requestedSubGroups = this.handleSubGroups();
        this.requestedParams.groups.push(...requestedSubGroups);
    }

    public async buildResults(data: IReportingResultsRow[]): Promise<IReportingPreparedResults> {

        return new Promise((resolve, reject) => {
            this.preparedRows = [];

            try {
                intermittentLoop(data, (item, index) => this.handleRow(item, index), DATA_CHUNK_SIZE, () => {
                    const results = {
                        result: this.preparedRows,
                        fields: this.preparedRows.length ? this.preparedRows[0].fields : [],
                        groups: this.preparedRows.length ? this.preparedRows[0].groups : [],
                        originResults: data
                    };

                    resolve(results);
                });
            } catch (e) {
                reject(e);
            }
        });
    }

    private handleRow(item: IReportingResultsRow, idx: number) {
        const dto: IReportingPreparedResultsRow = {
            id: guid(),
            fields: [],
            groups: [],
            indexed: {}
        };

        const requestedFields = [];
        const nonRequestedFields = [];

        this.handleFields(item, dto, requestedFields, nonRequestedFields);
        this.handleGroups(item, dto);

        dto.fields = [...requestedFields, ...nonRequestedFields];

        dto.indexed = keyBy(dto.groups.concat(dto.fields as any), "name");

        this.preparedRows.push(dto);
    }

    private handleFields(item: IReportingResultsRow, dto: IReportingResults, requestedFields: any[], nonRequestedFields: any[]) {

        for (const fieldName in item.fields) {

            const value = item.fields[fieldName];

            const fieldFromContext = this.reportingMetaDataManager.getFieldByValue(fieldName, this.requestedParams.verticalType);
            const requestedFieldIndex = this.requestedParams.fields.findIndex((field) => field.value === fieldName);
            const isRequested = requestedFieldIndex > -1;

            const fieldDto: IReportingPreparedField = {
                id: guid(),
                name: fieldName,
                key: fieldName,
                value,
                isRequested,
            };

            if (fieldFromContext) {
                fieldDto.type = fieldFromContext.type;
                fieldDto.name = fieldFromContext.label;

                if (this.requestedParams.isCompare) {
                    const [originalValue, compareValue, delta] = fieldDto.value;

                    fieldDto.displayValue = [originalValue, compareValue, delta].map((val) => {
                        return fieldFromContext.format
                            ? fieldFromContext.format(val)
                            : formatNumber(val, 2);
                    });
                } else {
                    try {
                        fieldDto.displayValue = fieldFromContext.format
                            ? fieldFromContext.format(fieldDto.value)
                            : formatNumber(fieldDto.value, 2);
                    } catch (e) {
                        console.error(e);
                    }
                }

            }

            // this is critical in order to preserve the requested fields order
            if (isRequested) {
                requestedFields[requestedFieldIndex] = fieldDto;
            } else {
                nonRequestedFields.push(fieldDto);
            }

            dto.fields.push(fieldDto);
        }
    }

    private handleGroups(item: IReportingResultsRow, dto: IReportingResults) {
        this.requestedParams.groups.forEach((group, gIndex) => {
            const value = item.groups[group.value];

            if (isObject(value)) {
                for (const key in value) {
                    let valueInObj = typeof value[key] === "boolean" ? JSON.stringify(value[key]) : value[key];
                    if (typeof valueInObj === "number") {
                        valueInObj = valueInObj.toLocaleString()
                    }

                    dto.groups.push({
                        id: gIndex + key,
                        key: group.value,
                        name: `${group.value}.${key}`,
                        valueDisplay: valueInObj || "",
                        value: valueInObj || "",
                        link: "",
                        originalId: "",
                        hasFilterType: !!group.filterType
                    });
                }
            } else {
                let originalId = group.value === "advertiserName" ? item.groups["advertiserId"] : item.groups[`${group.value}Original`];
                let valueName = item.groups[`${group.value}ValueName`];
                let userId = item.groups.userId ? item.groups.userId : null;

                let link: string;
                if (originalId) {
                    link = this.linksByVerticalManager.getLink(originalId, group, this.requestedParams.verticalType, userId);
                }
                if (valueName) {
                    link = this.linksByVerticalManager.getLink(value, group, this.requestedParams.verticalType, userId);
                }

                if (item.groups[`${group.value}type`] && item.groups[`${group.value}name`] && item.groups[`${group.value}id`]) {
                    originalId = item.groups[`${group.value}id`];
                    valueName = `${item.groups[`${group.value}name`]} - ${item.groups[`${group.value}type`]}`;
                    link = this.linksByVerticalManager.getLink(originalId, item.groups[`${group.value}type`], ReportVerticalType.PLATFORM, userId);
                }

                let valueDisplay = valueName || value || "";
                if (typeof value === "number" || typeof valueName === "number") {
                    valueDisplay = (valueName || value).toLocaleString()
                }

                const groupObj = {
                    id: gIndex,
                    key: group.value,
                    name: group.label,
                    valueDisplay,
                    originalValue: item.groups[group.value],
                    value: valueName || value,
                    link,
                    originalId,
                    hasFilterType: !!group.filterType,
                    isGroup: true
                };
                dto.groups.push(groupObj);
            }
        });
    }

    private handleSubGroups() {
        const requestedSubGroups = [];

        this.requestedParams.subGroups.forEach((subGroup, gIndex) => {
            subGroup.entities.forEach((entity) => {
                if (entity === "targeting") {
                    subGroup.targeting.types.forEach((targetingType) => {
                        subGroup.targeting.operators.forEach((targetingOperator) => {
                            const entityTargeting = `targeting:${targetingType}:${targetingOperator}`;
                            requestedSubGroups.push({label: entityTargeting, value: entityTargeting} as any);
                        });
                    });
                } else if (entity === "optionsWidget" && subGroup.freeField) {
                    requestedSubGroups.push({
                        label: `optionsWidget:${subGroup.freeField}`,
                        value: `optionsWidget:${subGroup.freeField}`
                    } as any);

                } else {
                    requestedSubGroups.push({label: entity, value: entity} as any);
                }
            });
        });
        return requestedSubGroups;
    }

    @computed
    public get requestedGroups() {
        const groupsParams = this.reportingParamsManager.params.groups.map(paramG => paramG.value);
        const requestedSubGroups = this.handleSubGroups();
        requestedSubGroups.forEach(subGroup => groupsParams.push(subGroup.value));
        return this.requestedParams.groups ? this.requestedParams.groups.filter(g => groupsParams.includes(g.value)) : []
    }

    @computed
    public get requestedFields() {
        return this.requestedParams.fields ? this.requestedParams.fields.filter(f => this.reportingParamsManager.params.fields.map(paramF => paramF.value).includes(f.value)) : []
    }
}
