import * as React from 'react';
import {Route} from 'react-router-dom';
import UrlsService from '../../../../common/router/services/urlsService.mdl';
import Reporting from '../../view/Reporting';

const ReportingRouting = (
    <React.Fragment>
        <Route path={UrlsService.reporting()} element={<Reporting/>}/>
        <Route path={UrlsService.historyReport()} element={<Reporting/>}/>
    </React.Fragment>
);

export default ReportingRouting
