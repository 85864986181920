
export enum FilterTypes {
    Scope = "scope",
    Account = "account",
    User = "user",
    Action = "action",
    ModelId = "modelId",
    ModelName = "modelName",
    PlatformTags = "platformTags"
}

export const FilterOptions = [
    {value: FilterTypes.User, label: "User"},
    {value: FilterTypes.Scope, label: "Scope"},
    {value: FilterTypes.Action, label: "Action"},
    {value: FilterTypes.Account, label: "Account"},
    {value: FilterTypes.ModelId, label: "model_id"},
    {value: FilterTypes.ModelName, label: "model_name"},
    {value: FilterTypes.PlatformTags, label: "Platform Tags"},
];
