import {observer} from 'mobx-react';
import React, {useCallback, useEffect} from 'react';
import theme from '../view/theme.module.scss';
import {Table} from '@vidazoo/ui-kit';
import {useNotificationSettingsListContext} from '../context/SettingsListContext';
import {SettingsTableHeader} from './SettingsTableHeader';
import SettingsTableRow from './SettingsTableRow';

const ROW_HEIGHT = 45;
const LOADING_ROW_HEIGHT = 24;
const LOADING_ITEMS_COUNT = () => Math.round(window.innerHeight / LOADING_ROW_HEIGHT);

function SettingsList(): JSX.Element {
    const {model, tableRowSelectionHook, allItemIds} = useNotificationSettingsListContext();
    const {clearSelection, selectedItems} = tableRowSelectionHook;

    useEffect(() => {
        clearSelection();
        model.items.invalidate().fetch();
        model.resetFilter();
    }, []);

    const onColumnResize = useCallback((width: number, colKey: string) => {
        model.setWidth(colKey, width);
    }, []);

    const renderTableRow = useCallback((itemIndex: number, viewportIndex: number, style: React.CSSProperties) => {
        const item = model.maskItems.at(itemIndex);

        return (
            <SettingsTableRow
                key={item ? item._id : itemIndex}
                columnsManager={model}
                tableRowSelectionHook={tableRowSelectionHook}
                itemIndex={itemIndex}
                viewportIndex={viewportIndex}
                style={style}/>
        );
    }, [model.maskItems, model.columns, model]);

    const renderTableHeader = useCallback(() => {
        return (
            <SettingsTableHeader/>
        );
    }, [model]);

    return (
        <Table
            className={theme.table}
            itemsCount={(model.items.isLoading && !model.items.data) ? LOADING_ITEMS_COUNT() : model.maskItems.length}
            rowHeight={ROW_HEIGHT}
            renderRow={renderTableRow}
            renderHeader={renderTableHeader}
            columnsManager={model}
            overscanItems={5}
        />
    );
}

export default observer(SettingsList);
