import React from 'react';
import {Column, LinkButton, Row} from '@vidazoo/ui-kit';
import dayjs from 'dayjs';
import useChangeLogsModel from '../../common/hooks/useChangeLogsModel';
import useAccountsMetaDataManager from '../../../../common/hooks/useAccountsMetaDataManager';
import BoxLoader from '../../../../common/components/box-loader/BoxLoader';
import {LOADING_ROW_HEIGHT} from '../../../dashboards/list/components/DashboardsTableRow';
import {ChangeLogsTableColumns} from '../../common/constants/ChangeLogsTableColumns';
import {observer} from 'mobx-react';

interface IChangeLogsRowProps {
    itemIndex: number;
    viewportIndex: number;
    style: React.CSSProperties;
}

function ChangeLogsRow(props: IChangeLogsRowProps) {
    const {itemIndex, style} = props;
    const model = useChangeLogsModel();
    const accountsMetaDataManager = useAccountsMetaDataManager();

    const onSetSelectedItemDiff = () => {
        model.setSelectedItem(item.diff);
    };

    const onSetSelectedItemAuth = () => {
        const user = accountsMetaDataManager.usersById[item.auth.id] || {};
        model.setSelectedItem({...item.auth, ...user});
    };

    const onGoToClick = () => {
        window.open(`https://vidazoo-accounts-client.herokuapp.com/user/${item.auth.id}/edit`);
    };

    if (model.items.isLoading || !model.data) {
        return (
            <Row style={style} key={itemIndex} index={itemIndex}>
                {ChangeLogsTableColumns.map(() => (
                    <Column width={300} className='flex items-center justify-center p-4'>
                        <BoxLoader.Row height={LOADING_ROW_HEIGHT}/>
                    </Column>
                ))}
            </Row>
        );
    }
    const item = model.maskItems.at(itemIndex);

    return (
        <Row style={style} key={itemIndex} index={itemIndex}>
            <Column colKey="scope">{item.scope}</Column>
            <Column colKey="account">{item.account}</Column>
            <Column colKey="user">
                <div onClick={onSetSelectedItemAuth}>
                    {(item.auth && item.auth.id !== "bot") ?
                        <LinkButton style={{marginRight: "1rem"}} type="button" onClick={onGoToClick}
                                    iconName="icon-go-to-link-small" iconSize={2}/> : null}
                    {item.auth ? item.auth.email : ""}
                </div>
            </Column>
            <Column colKey="action">{item.action}</Column>
            <Column colKey="model_name">{item.model_name}</Column>
            <Column colKey="model_id">
                <a href={item.model_link} target="_blank" rel="noopener noreferrer">
                    {model.nameByIdAndScope[item.scope + item.model_id] ? model.nameByIdAndScope[item.scope + item.model_id].name : item.model_id}
                </a>
            </Column>
            <Column colKey="date">{dayjs(Number(item.date)).format("MMM DD, YYYY, HH:MM")}</Column>
            <Column colKey="diff">
                <div onClick={onSetSelectedItemDiff}>
                    {JSON.stringify(item.diff)}</div>
            </Column>
        </Row>
    );
}

export default observer(ChangeLogsRow);
