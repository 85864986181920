import ReportingHeader from '../components/reportingHeader/ReportingHeader';
import {observer} from 'mobx-react';
import useReportingModel from '../common/hooks/useReportingModel';
import ReportingList from '../components/reportingList/ReportingList';
import ReportingEmpty from '../components/reportingEmpty/ReportingEmpty';
import ReportingHistory from "../components/reportingHistory/ReportingHistory";
import {useEffect} from "react";
import {useParams} from "react-router-dom";
import ReportingLoader from "../components/reportingLoader/ReportingLoader";

function Reporting() {
    const model = useReportingModel();
    const {id} = useParams<{ id: string; }>();

    useEffect(() => {
        model.setInitialParamsFromQuery(location.search, id);
    }, [location.search, id]);

    return (
        <>
            <ReportingHeader/>
            {model.isLoading ? <ReportingLoader/> : null}
            {!model.isLoading && model.data.length > 0 ? <ReportingList/> : null}
            {!model.isLoading && model.data.length == 0 ? <ReportingHistory/> : null}
            <ReportingEmpty/>
        </>
    );
}

export default observer(Reporting);
