import BaseNeededGroup from "../BaseNeededGroup";
import {PlatformNeededGroups} from "../NeededGroups";
import {PlatformReportingFilterType} from "../ReportingFilterType";
import {ReportVerticalType} from '../../../enums/ReportVerticalType';
import {alias, define, singleton} from '@injex/core';

@define()
@singleton()
@alias('PlatformReportingFilter')
export default class WidgetTypeFilter extends BaseNeededGroup {

	public type = PlatformReportingFilterType.WidgetType;

	constructor() {
		super(ReportVerticalType.PLATFORM, PlatformNeededGroups.WidgetsSettings);
		this.allowNew = false;
		this.labelKey = "type";
	}
}
