import {alias, AliasFactory, define, injectAlias, singleton} from '@injex/core';
import {makeObservable, observable, ObservableSet} from 'mobx';
import {AlertAccent, AlertType} from '../common/enums';
import IAlertModel from '../interfaces/IAlertModel';
import {IBasicAlertPayload} from '../types/basic/basicAlertModel.mdl';
import {IConfirmAlertPayload} from '../types/confirm/confirmAlertModel.mdl';
import {IPromptAlertPayload} from '../types/prompt/promptAlertModel.mdl';
@define()
@singleton()
@alias('CloudStorageAggregateRoot')
export class AlertsManager {

    @injectAlias('Alert', 'Type') private alertFactories: AliasFactory<AlertType, IAlertModel>;

    @observable public alerts: ObservableSet<IAlertModel>;

    private _alertsMap: Map<string, IAlertModel>;

    constructor() {
        makeObservable(this);
        this.alerts = new ObservableSet();
        this._alertsMap = new Map();
    }

    public setBasicAlert(payload: IBasicAlertPayload, accent?: AlertAccent, withLoader?: boolean): IAlertModel<IBasicAlertPayload> {
        return this.createAlert<IBasicAlertPayload>(AlertType.Basic, payload, accent, withLoader);
    }

    public setConfirmAlert(payload: IConfirmAlertPayload, accent?: AlertAccent, withLoader?: boolean): IAlertModel<IConfirmAlertPayload> {
        return this.createAlert<IConfirmAlertPayload>(AlertType.Confirm, payload, accent, withLoader);
    }

    public setPromptAlert(payload: IPromptAlertPayload, accent?: AlertAccent, withLoader?: boolean): IAlertModel<IPromptAlertPayload> {
        return this.createAlert<IPromptAlertPayload>(AlertType.Prompt, payload, accent, withLoader);
    }

    public createAlert<T>(type: AlertType, payload: any, accent?: AlertAccent, withLoader?: boolean): IAlertModel<T> {
        const factory = this.alertFactories[type];

        if (factory) {
            return this.push(factory(payload, accent, withLoader));
        }
    }

    public push(model: IAlertModel): IAlertModel {
        this._alertsMap.set(model.id, model);
        this.alerts.add(model);
        model.hooks.dismiss.tap(this._onDismiss, null, this);
        return model;
    }

    public remove(id: string, withDismiss = true) {
        if (this._alertsMap.has(id)) {
            const alert = this._alertsMap.get(id);
            this._alertsMap.delete(id);
            this.alerts.delete(alert);
            withDismiss && alert.dismiss();
        }
    }

    public getById(id: string) {
        return this._alertsMap.get(id);
    }

    private _onDismiss(alert: IAlertModel) {
        this.remove(alert.id, false);
    }
}
