import {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react';
import {Button, SliderPanel, PageContainer, Breadcrumbs, CircleLoader} from '@vidazoo/ui-kit';
import theme from '../../../../../common/styles/base.module.scss';
import UrlsService from '../../../../../common/router/services/urlsService.mdl';
import {ChartContext} from '../../context/ChartContext';
import * as React from 'react';
import ChartDetails from '../../components/chartDetails/ChartDetails';
import {FormMode} from '../../../../../common/enums/FormMode';
import ChartDisplay from '../../../../../common/components/chart-display/ChartDisplay';
import useManager from '../../../../../common/hooks/useManager';
import {ChartCreateModel} from '../model/ChartCreateModel.mdl';

export default observer(function ChartCreate() {
    const model = useManager<ChartCreateModel>('chartCreateModel');

    const contextValue = useMemo(() => ({model}), [model]);
    const navigate = useNavigate();

    const breadcrumbs = [{
        label: "Charts List",
        iconName: "handshake",
        link: {to: UrlsService.charts()}
    }, {
        label: "Create"
    }]

    const onCancelClick = () => {
        navigate(UrlsService.charts());
    };

    return (
        <ChartContext.Provider value={contextValue}>
            <PageContainer pageTitle="Create Chart" inner top={1.2}>
                <Breadcrumbs className={theme.breadcrumbs} breadcrumbs={breadcrumbs}/>
                <ChartDetails/>
                {/*<ChartDisplay chartModel={model} isEditMode={false}/>*/}
                <SliderPanel>
                    <div className={theme.sliderPanel}>
                        <Button type="button" medium gray uppercase onClick={onCancelClick}>Cancel</Button>
                        <Button type="button" medium blue uppercase
                                onClick={model.submit}>Create</Button>
                    </div>
                </SliderPanel>
            </PageContainer>
        </ChartContext.Provider>
    );
});
