import * as React from "react";
import {observer} from "mobx-react";
import {normalizeEventValue} from '../../utils/utils';
import {FormInput, FormSelect, Icon} from '@vidazoo/ui-kit';
import ICustomStyle from '../../../modules/charts/interfaces/ICustomStyle';
import ColorPicker from '../color-picker/ColorPicker';
import selfTheme from "./theme.module.scss";
import theme from "../../styles/base.module.scss";
import useReportingMetaDataManager from '../../hooks/useReportingMetaDataManager';
import IChartReportingParams from '../../../modules/charts/interfaces/IChartReportingParams';
import IReportingConstraint from '../../../modules/reporting/common/interfaces/IReportingConstraint';
import {ReportVerticalType} from '../../enums/ReportVerticalType';

interface IStyleFormProps {
    index?: number;
    onCustomStyleChange: (value: IReportingConstraint, name: string, index: number) => void;
    removeCustomStyle: (index: number) => void;
    customStyles: ICustomStyle[];
    reportParams: IChartReportingParams;
}

export default observer(function StyleReportForm(props: IStyleFormProps) {
    const reportingMetaDataManager = useReportingMetaDataManager();
    const {onCustomStyleChange, removeCustomStyle, customStyles, reportParams, index} = props;
    const current = customStyles[index];

    const onChange = (e: any) => {
        const {name} = e.target;
        const value = normalizeEventValue(e);
        onCustomStyleChange(value, name, props.index);
    }

    const onColorChange = (color: { hex: any; }, name: string) => {
        onCustomStyleChange(color.hex, name, props.index)
    }

    const onDelete = () => {
        removeCustomStyle(props.index);
    }

    const getSelectItems = () => {
        const alreadySelected = customStyles.map((style) => style.metric);
        const fieldsToGet = reportParams.fields.filter((field) => !alreadySelected.includes(field));
        return fieldsToGet.map((field) => reportingMetaDataManager.getFieldByValue(field, ReportVerticalType.PLATFORM))
    }

    return (
        <div className={theme.row}>
            <FormSelect
                fill
                className={theme.input}
                label="Metric"
                name="metric"
                maxItems={5}
                onChange={onChange}
                value={current.metric}
                items={getSelectItems()}/>
            <FormInput className={theme.input} name="threshold" onChange={onChange} type="number"
                       value={current.threshold} label="Threshold"/>
            <ColorPicker color={current.highColor} label="high color" name="highColor" onChange={onColorChange}/>
            <ColorPicker color={current.lowColor} label="low color" name="lowColor" onChange={onColorChange}/>
            <div className={selfTheme.delete} onClick={onDelete}>
                <Icon iconName="icon-delete" size={1.8}/>
            </div>
        </div>
    );
});
