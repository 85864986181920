import {Column, Row} from '@vidazoo/ui-kit';
import React from 'react';
import {observer} from 'mobx-react';
import BoxLoader from '../../../../common/components/box-loader/BoxLoader';
import For from '../../../../common/components/for/For';
import useReportingModel from '../../common/hooks/useReportingModel';
import useReportingParamsManager from "../../common/hooks/useReportingParamsManager";
import ReportFieldValueCell from "./components/ReportFieldValueCell";
import ReportGroupValueCell from "./components/ReportGroupValueCell";
import theme from '../../view/theme.module.scss';
import useReportingBuildManager from "../../common/hooks/useReportingBuildManager";

export interface IReportingTableRowProps {
    itemIndex: number;
    viewportIndex: number;
    style: React.CSSProperties;
}

export const LOADING_ROW_HEIGHT = 24;
export const LOADING_ROW_WIDTH = 250;

function ReportingTableRow({itemIndex, style}: IReportingTableRowProps) {
    const model = useReportingModel();
    const {prepareOriginFields, params, quickFilter, quickConstraint} = useReportingParamsManager();
    const {requestedGroups} = useReportingBuildManager()

    if (model.isLoading || !model.data) {
        return (
            <Row style={style} key={itemIndex} index={itemIndex}>
                <For count={params.groups.length + prepareOriginFields.length}>
                    <Column width={LOADING_ROW_WIDTH} className='flex items-center justify-center p-4'>
                        <BoxLoader.Row height={LOADING_ROW_HEIGHT}/>
                    </Column>
                </For>
            </Row>
        );
    }
    const row = model.maskItems.at(itemIndex);

    return (
        <Row style={style} key={row.id} index={itemIndex} className={theme.row}>
            {requestedGroups.map((paramGroup) => {
                const group = row.groups.find(group => paramGroup.value === group.key);

                if (!group) {
                    return null;
                }

                return (
                    <Column key={group.key} colKey={group.key}>
                        <ReportGroupValueCell model={model} group={group} onQuickFilter={quickFilter}/>
                    </Column>
                )
            })}
            {params.fields.map((paramField) => {
                const field = row.fields.find(field => paramField.value === field.key);

                if (!field) {
                    return null;
                }

                return (
                    <Column key={field.key} colKey={field.key}>
                        <ReportFieldValueCell field={field} onQuickConstraint={quickConstraint}/>
                    </Column>
                )
            })}
        </Row>
    )
}

export default observer(ReportingTableRow);
