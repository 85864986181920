import React, {useEffect, useMemo, useRef} from "react";
import {observer} from "mobx-react";
import {CircleLoader} from "@vidazoo/ui-kit";
import theme from "./theme.module.scss";
import useReportingMetaDataManager from '../../hooks/useReportingMetaDataManager';
import {ChartType} from '../../enums/ChartType';
import DiffTableChart from '../diff-table-chart/DiffTableChart';
import SummaryChart from '../summary-chart/SummaryChart';
import PerformanceChart from '../performance-chart/PerformanceChart';
import {DayField} from '../../enums/DayField';
import ChartHeader from '../chart-header/ChartHeader';
import {ChartEditModel} from '../../../modules/charts/item/edit/model/ChartEditModel.mdl';
import {ChartCreateModel} from '../../../modules/charts/item/create/model/ChartCreateModel.mdl';

export interface IChartDisplayProps {
    isEditMode: boolean;
    chartModel: ChartEditModel | ChartCreateModel;
    onDeleteClick?: (chartId: string) => void;
}

export default observer(function ChartDisplay(props: IChartDisplayProps) {

    const {isEditMode, chartModel, onDeleteClick} = props;
    const reportingMetaDataManager = useReportingMetaDataManager();
    const chart = useMemo(() => chartModel?.item.data, [chartModel]);

    const chartRef = useRef(null);

    if (chart) {
        let component;
        let classname;
        const reportByChart = chartModel?.chartReportByTimePreset?.[reportingMetaDataManager.timePreset];
        if (!reportByChart || reportByChart.isLoading) {
            return (
                <div>
                    <ChartHeader onDeleteClick={onDeleteClick} chartModel={chartModel} isEditMode={isEditMode}/>
                    <div className={theme.center}>
                        <CircleLoader size={4} gray/>
                    </div>
                </div>
            );
        }

        switch (chart?.type) {
            case ChartType.DATA_TABLE:
                component = <DiffTableChart
                    isEditMode={isEditMode}
                    model={chartModel}
                    report={reportByChart.results}/>;
                classname = theme.table;
                break;
            case ChartType.SUMMARY:
                component = <SummaryChart fields={reportByChart.results}/>;
                classname = theme.summary;
                break;
            case ChartType.PERFORMANCE:
                component = <PerformanceChart
                    isLoading={reportByChart.isLoading}
                    series={reportByChart.results.series}
                    dayField={DayField.DATE} height="100%"
                    labels={reportByChart.results.labels}
                />;
                classname = theme.summary;
                break;
        }

        return (
            <div ref={chartRef} className={classname}>
                <ChartHeader onDeleteClick={onDeleteClick} chartModel={chartModel} isEditMode={isEditMode}/>
                {component}
            </div>
        );
    }

    return (
        <div className={theme.center}>
            <CircleLoader size={4} gray/>
        </div>
    )
});
