import {createContext, useContext, useMemo, useState} from "react";
import {useModuleFactory} from '@injex/react-plugin';
import ProfileModel from '../../profile/model/profileModel.mdl';

export interface IMainMenuContext {
    selectedGroup?: string;
    setSelectedGroup?: (group: string) => void;
    profileModel?: ProfileModel;
}

export interface IMainMenuWithContext {
    children: any,
}

const MainMenuContext = createContext<IMainMenuContext>({});

export function MainMenuWithContext({children}: IMainMenuWithContext) {
    const [selectedGroup, setSelectedGroup] = useState(null);
    const profileModel = useModuleFactory<ProfileModel>('profileModel');

    const state: IMainMenuContext = useMemo(() => ({
        selectedGroup,
        setSelectedGroup,
        profileModel,
    }), [selectedGroup, profileModel]);

    return (
        <MainMenuContext.Provider value={state}>
            {children}
        </MainMenuContext.Provider>
    )
}

export function useMainMenuContext() {
    return useContext(MainMenuContext);
}
