import {observer} from 'mobx-react';
import useReportingModel from '../../common/hooks/useReportingModel';
import {Label, Loader, PageMessage} from '@vidazoo/ui-kit';
import theme from '../../view/theme.module.scss';

function ReportingLoader() {
    const model = useReportingModel();

    return (
        <div className={theme.message}>
            <Loader size={1} gray className={theme.loader}/>
            <PageMessage
                small
                title="Generating report"
                description="Please wait..."
            />
        </div>
    )
}

export default observer(ReportingLoader);
