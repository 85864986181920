import {makeObservable, observable} from 'mobx';
import {AlertAccent} from '../common/enums';
import IAlertModel, {AlertHooks, AlertView} from '../interfaces/IAlertModel';
import {guid, isDefined} from '@vidazoo/ui-kit';
import Hook from '../../utils/Hook';

export default abstract class BaseAlertModel<T = any> implements IAlertModel<T> {
    private _id: string;
    protected _didDismissed: boolean;

    public hooks: AlertHooks;

    private _promise: Promise<any>;
    private _resolve: (...args: any[]) => void;
    private _reject: (...args: any[]) => void;

    public get usePromise(): Promise<any> {
        return this._promise;
    }

    protected resolve(value?: any) {
        this._resolve(value);
    }

    protected reject(error?: any) {
        this._reject(error);
    }

    public get id(): string {
        return this._id;
    }

    public abstract get View(): AlertView;
    @observable public payload: T;
    @observable public withLoader: boolean;

    constructor(payload: T, public accent = AlertAccent.Natural, withLoader = false) {
        makeObservable(this);
        this.dismiss = this.dismiss.bind(this);
        this._id = guid();
        this.payload = payload;
        this.hooks = {
            dismiss: new Hook()
        };
        this.withLoader = withLoader;
        this._promise = new Promise((resolve, reject) => {
            this._resolve = resolve;
            this._reject = reject;
        });
    }

    public toggleLoader(force?: boolean) {
        if (isDefined(force)) {
            this.withLoader = force;
        } else {
            this.withLoader = !this.withLoader;
        }
    }

    public dismiss() {
        if (this._didDismissed) {
            return;
        }
        this._didDismissed = true;
        this.hooks.dismiss.call(this);

        this.resolve(null);
    }
}
