import {define, singleton} from '@injex/core';
import BaseLocalStorageManager from '../base/BaseLocalStorageManager';
import {TimePresets} from '../enums/TimePresets';
import IReportingParams, {IBiReportingParams} from '../../modules/reporting/common/interfaces/IReportingParams';

@define()
@singleton()
export default class LocalStorageManager extends BaseLocalStorageManager {

    public setColumnsWidth = (entity: string, columnsWidth: any) => {
        const allColumnsWidth = this.get("columnsWidth", {});
        allColumnsWidth[entity] = columnsWidth;
        this.set(`columnsWidth`, allColumnsWidth);
    };

    public getTimePreset = () => {
        return this.get(`timePreset`, TimePresets.TwoHours);
    };

    public setTimePreset = (timePreset: TimePresets) => {
        this.set(`timePreset`, timePreset);
    };

    public getGlobalTimezone = (): string => {
        return this.get(`global.timezone`, "");
    };

    public getReportingParams = (): IBiReportingParams => {
        const reportingParams = this.get("reportingParams", {});
        return reportingParams || {};
    }

    public setReportingParams = (reportingParams: IReportingParams) => {
        this.set("reportingParams", reportingParams);
    }

}
