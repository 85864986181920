import {Column, LinkButton, MaterialIcon, Row, Tooltip} from '@vidazoo/ui-kit';
import React from 'react';
import {observer} from 'mobx-react';
import BoxLoader from '../../../../../common/components/box-loader/BoxLoader';
import dayjs from 'dayjs';
import MarkeredText from '../../../../../common/components/markered-text/MarkeredText';
import {ReportingHistoryListColumns} from './ReportingHistoryListColumns';
import {ReportHistoryStatus} from "../../../common/enums/ReportHistoryStatus";
import useHistoryReportsColumnsManager from "../../../common/hooks/useHistoryReportsColumnsManager";
import useReportingParamsManager from "../../../common/hooks/useReportingParamsManager";
import useHistoryReportsManager from "../../../common/hooks/useHistoryReportsManager";
import {HistoryReportGetUrlAction} from "../../../common/enums/HistoryReportGetUrlAction";
import theme from '../theme.module.scss';

export const LOADING_ROW_HEIGHT = 24;

export interface IDashboardsTableRowProps {
    itemIndex: number;
    viewportIndex: number;
    style: React.CSSProperties;
}

function ReportingHistoryListRow({itemIndex, style}: IDashboardsTableRowProps) {
    const historyReportsColumnsManager = useHistoryReportsColumnsManager();
    const historyReportsManager = useHistoryReportsManager();
    const reportingParamsManager = useReportingParamsManager();

    if (historyReportsColumnsManager.isLoading || !historyReportsColumnsManager.data) {
        return (
            <Row style={style} key={itemIndex} index={itemIndex}>
                {ReportingHistoryListColumns.map((item) => (
                    <Column width={200} className='flex items-center justify-center p-4' key={item.key}>
                        <BoxLoader.Row height={LOADING_ROW_HEIGHT}/>
                    </Column>
                ))}
            </Row>
        );
    }
    const item = historyReportsColumnsManager.maskItems.at(itemIndex);

    const getColorByStatus = () => {
        switch (item.status) {
            case ReportHistoryStatus.Finished:
                return "var(--primary-success-color)";
            case ReportHistoryStatus.Error:
                return "var(--primary-error-color)";
            case ReportHistoryStatus.Processing:
                return "var(--primary-pending-color)";
        }
    }

    const onEditDescription = () => {
        historyReportsColumnsManager.setEditHistoryReport(item);
    };

    const onReuseReportParams = () => {
        reportingParamsManager.setParamsFromHistoryReport(item);
    };

    const onReuseReport = () => {
        historyReportsManager.getReportFromReportingHistoryUrls(item, HistoryReportGetUrlAction.Reuse)
    };

    const onDownload = () => {
        historyReportsManager.getReportFromReportingHistoryUrls(item, HistoryReportGetUrlAction.Download)
    };

    const statusTooltip = () => {
        let tooltip = "";
        if (item.error) {
            tooltip = `Error: ${item.error} `;
        }
        if (item.msg) {
            tooltip += `Message: ${item.msg}`;
        }

        return tooltip;
    };

    return (
        <Row style={style} key={item._id} index={itemIndex} className={theme.row}>
            <Column colKey="status" style={{backgroundColor: getColorByStatus()}}>
                <Tooltip title={statusTooltip()}>
						<span className={theme.action}>
							{item.status}
						</span>
                </Tooltip>
            </Column>
            <Column colKey="actions">
                <div>
                    <Tooltip title="Reuse Params">
					    <span onClick={onReuseReportParams} className={theme.action}>
					        <MaterialIcon iconName="settings_backup_restore" size="1.8rem"/>
					    </span>
                    </Tooltip>
                    {item.fileIds.length > 0 ? (
                        <>
                            <Tooltip title="Reuse Report">
							    <span onClick={onReuseReport} className={theme.action}>
                                    <MaterialIcon iconName="switch_access_shortcut" size="1.8rem"/>
                                </span>
                            </Tooltip>
                            <Tooltip title="Download Report">
                                <span onClick={onDownload} className={theme.action}>
                                    <MaterialIcon iconName="download" size="1.8rem"/>
                                </span>
                            </Tooltip>
                        </>
                    ) : null}
                </div>
            </Column>
            <Column colKey="created">{dayjs(item.created).format("MMM DD, YYYY, h:mm:ss a")}</Column>
            <Column colKey="duration">{item.duration}</Column>
            <Column colKey="description">
                <div className="flex">
                    <LinkButton onClick={onEditDescription} iconName="icon-edit" iconSize={1.8}/>
                    <div style={{marginLeft: 10}}>{item.description}</div>
                </div>
            </Column>
            <Column colKey="dimensions">
                <MarkeredText
                    marker={historyReportsColumnsManager.getColumnSearchQuery('dimensions')}>{item.dimensions || ""}</MarkeredText>
            </Column>
            <Column colKey="metrics">
                <MarkeredText
                    marker={historyReportsColumnsManager.getColumnSearchQuery('metrics')}>{item.metrics || ""}</MarkeredText>
            </Column>
            <Column colKey="filtersStr">
                <MarkeredText
                    marker={historyReportsColumnsManager.getColumnSearchQuery('filtersStr')}>{item.filtersStr || ""}</MarkeredText>
            </Column>
            <Column colKey="constraintsStr">
                <MarkeredText
                    marker={historyReportsColumnsManager.getColumnSearchQuery('constraintsStr')}>{item.constraintsStr || ""}</MarkeredText>
            </Column>
            <Column colKey="subGroupsStr">
                <MarkeredText
                    marker={historyReportsColumnsManager.getColumnSearchQuery('subGroupsStr')}>{item.subGroupsStr || ""}</MarkeredText>
            </Column>
            <Column colKey="size">{item.size}</Column>
            <Column colKey="sample">{item.sample}</Column>
            <Column
                colKey="executionTime">{item.executionTime ? `${Math.ceil(item.executionTime / 1000)}s` : dayjs(item.created).fromNow()}</Column>
        </Row>
    );
}

export default observer(ReportingHistoryListRow);
