import {define, singleton} from '@injex/core';
import EntityRequestAdapter from '../../../common/utils/EntityRequestAdapter';
import {IChangeLog} from '../common/interfaces/IChangeLog';
import {IGetAllPg} from '../../../common/interfaces/IGetAllPg';

@define()
@singleton()
export class ChangeLogsApi extends EntityRequestAdapter<IChangeLog> {
    protected basePathName: string = 'change-logs';

    protected get apiEndpoint(): string {
        return this.env.biDashboardsAPI;
    }

    public getAllChangeLogs({ page = 1, pageSize = 100, filter = [], fields = [], sort = {} }: IGetAllPg) {
        return this.requestWithContext<{ results: IChangeLog[] }>({
            method: "post",
            url: "/api/change-logs/",
            data: { page, pageSize, filter, fields, sort }
        });
    }

}
