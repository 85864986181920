import SettingsList from '../components/SettingsList';
import SettingsHeader from '../components/SettingsHeader';
import {NotificationSettingsListWithContext} from '../context/SettingsListContext';

export default function NotificationSettings() {

    return (
        <NotificationSettingsListWithContext>
            <SettingsHeader/>
            <SettingsList/>
        </NotificationSettingsListWithContext>
    );
}
