import React from 'react';
import {HeaderColumn, Row} from '@vidazoo/ui-kit';

export function ReportingHistoryListHeader() {
    return (
        <Row>
            <HeaderColumn colKey="status" />
            <HeaderColumn colKey="actions" />
            <HeaderColumn colKey="created" />
            <HeaderColumn colKey="duration" />
            <HeaderColumn colKey="description" />
            <HeaderColumn colKey="dimensions" />
            <HeaderColumn colKey="metrics" />
            <HeaderColumn colKey="filtersStr" />
            <HeaderColumn colKey="constraintsStr" />
            <HeaderColumn colKey="subGroupsStr" />
            <HeaderColumn colKey="size" />
            <HeaderColumn colKey="sample" />
            <HeaderColumn colKey="executionTime" />
        </Row>
    )
}
