import React, { useEffect, useRef } from "react";
import clsx from "clsx";
import Highcharts from "highcharts";
import theme from "./theme.module.scss";
import { PageMessage } from "@vidazoo/ui-kit";
import {observer} from 'mobx-react';

function tooltipFormatter() {
    let title = this.x;
    if (this.points[0].point.series.chart.options.tooltip.xDateFormat) {
        title = Highcharts.dateFormat(
            this.points[0].point.series.chart.options.tooltip.xDateFormat,
            this.x
        );
    }

    let s = `<span class="${theme["tooltip-title"]}">${title}</span>`;

    this.points.forEach((point) => {
        s += `<span class="${theme["tooltip-series"]}"><span class="${theme["tooltip-label"]}">${point.series.name}:</span> <span class="${theme["tooltip-value"]}" style="color: ${point.color};">${point.y.toLocaleString()}</span></span>`;
    });

    return s;
}

export interface IChartProps {
    className?: string;
    style?: React.CSSProperties;
    config: Highcharts.Options;
    displayNoData?: boolean;
}

const Chart: React.FC<IChartProps> = (props) => {
    const {
        className,
        style,
        config,
        displayNoData,
    } = props;
    const $el = useRef<HTMLDivElement | null>(null);
    const chart = useRef<any | null>(null);

    useEffect(() => {
        config.chart.renderTo = $el.current;

        setTooltipFormatter(config);

        chart.current = Highcharts.chart(config);

        return () => {
            if (chart.current) {
                chart.current.destroy();
            }
        };
    }, [config]);

    useEffect(() => {
        setTooltipFormatter(config);

        if (chart.current) {
            chart.current.update(config, true);
        }
    }, [config]);

    const setTooltipFormatter = (config: Highcharts.Options) => {
        if (config.tooltip && !config.tooltip.formatter) {
            config.tooltip.formatter = tooltipFormatter;
        }
    };

    const classes = clsx(theme.chart, className);

    return (
        <div className={classes} style={style}>
            <div className={theme.inner} ref={$el}/>
            {displayNoData && <PageMessage small title="No data available." />}
        </div>
    );
};

Chart.defaultProps = {
    config: {
        credits: { enabled: false },
        chart: {},
    },
    displayNoData: false,
};

export default observer(Chart);
