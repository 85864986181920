import {ContextMenu, LinkButton, Chips} from '@vidazoo/ui-kit';
import {ReportingContextMenuType} from '../../common/enums/ReportingContextMenuType';
import theme from '../reportingHeader/theme.module.scss';
import MetricsInfo from '../metricsInfo/MetricsInfo';
import {useCallback, useMemo} from 'react';
import {observer} from 'mobx-react';
import MemoryClipboard from '../../../../common/utils/MemoryClipboard';
import useReportingMetaDataManager from '../../../../common/hooks/useReportingMetaDataManager';
import {ReportVerticalType} from '../../../../common/enums/ReportVerticalType';
import IReportingEntry from '../../common/interfaces/IReportingEntry';
import IReportingFilter from '../../common/interfaces/IReportingFilter';
import IReportingConstraint from '../../common/interfaces/IReportingConstraint';
import IReportingParams from '../../common/interfaces/IReportingParams';
import useReportingModel from "../../common/hooks/useReportingModel";
import useAutoCompleteListWidth from "../../../../common/hooks/useAutoCompleteListWidth";

export interface IReportingMetricsProps {
    fields: IReportingEntry [];
    onClearAllParams: (key: ReportingContextMenuType) => void;
    onMultiplePaste: (param: string, filter?: IReportingFilter) => void;
    addConstraint: (values?: Partial<IReportingConstraint>) => void;
    pushReportParam: (key: string, value: string, label: string) => void;
    removeReportParam: (key: string, value: string) => void;
    setParam: (key: keyof IReportingParams, value: any) => void;
}

function ReportingMetrics(props: IReportingMetricsProps) {

    const {
        fields,
        setParam,
        removeReportParam,
        pushReportParam,
        addConstraint,
        onMultiplePaste,
        onClearAllParams
    } = props;
    const reportingMetadataManager = useReportingMetaDataManager();
    const reportingModel = useReportingModel();
    const autoCompleteListWidth = useAutoCompleteListWidth();

    const copyMetrics = () => {
        MemoryClipboard.copy(window.getSelection().toString() || fields.map((m) => m.label).join(", "));
    }

    const contextMenuItems = useMemo(() => {
        return [
            {label: "Clear All Metrics", onClick: () => onClearAllParams(ReportingContextMenuType.fields)},
            {label: "Copy", onClick: copyMetrics},
            {label: "Paste Multiple", onClick: () => onMultiplePaste(ReportingContextMenuType.fields)},
        ];
    }, []);

    const onAddMetric = useCallback((value: string, label: string) => {
        pushReportParam("fields", value, label);
        reportingModel.handleSetColumns();
    }, []);

    const onDeleteMetric = useCallback((value: string) => {
        removeReportParam("fields", value);
        reportingModel.handleSetColumns();
    }, []);

    const onOrderMetrics = (metrics: any[]) => {
        setParam("fields", metrics);
        reportingModel.handleSetColumns();
    }

    return (
        <ContextMenu items={contextMenuItems}>
            <div className={theme.metrics}>
                <div className={theme.chipsHeader}>
                    <div className={theme.description}>
                        <div className={theme.label}>Metrics</div>
                        <MetricsInfo/>
                    </div>
                    <LinkButton type="button" className={theme.link} iconName="icon-plus-small" deepPurple small
                                uppercase spaced bold onClick={() => addConstraint()}>Add Constraint</LinkButton>
                </div>
                <Chips
                    allowOrder
                    chips={fields}
                    maxItems={5}
                    leaveOpenAfterSelect
                    onDelete={onDeleteMetric}
                    onOrder={onOrderMetrics}
                    onSelect={onAddMetric}
                    dataSource={reportingMetadataManager.metaDataByVertical?.[ReportVerticalType.PLATFORM]?.fields?.entries}
                    searchPlaceholder="Add Metric"
                    emptyPlaceholder="Add Metric"
                    autoCompleteListWidth={autoCompleteListWidth}
                    itemLabelKey={"label"}
                    itemValueKey={"value"}
                />
            </div>
        </ContextMenu>
    )
}

export default observer(ReportingMetrics);
