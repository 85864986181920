import {observer} from 'mobx-react';
import {ContextMenu} from "@vidazoo/ui-kit";
import {useCallback} from "react";
import {ReportingContextMenuType} from "../../common/enums/ReportingContextMenuType";
import ReportingSubDimension from "./ReportingSubDimension";
import {ISubGroup} from "../../common/interfaces/ISubGroup";

export interface IReportingSubDimensionsProps {
    subGroups?: ISubGroup[];
    onChangeSubGroup?: (subGroup: ISubGroup, key: string, value: any) => void;
    onDeleteSubGroup?: (subGroup: ISubGroup) => void;
    onAddSubGroupValue?: (subGroup: ISubGroup, value: string, label: string) => void;
    onRemoveSubGroupValue?: (subGroup: ISubGroup, value: string) => void;
    onAddTargetingType?: (subGroup: ISubGroup, value: string, label: string) => void;
    onDeleteTargetingType?: (subGroup: ISubGroup, value: string) => void;
    onAddTargetingOperator: (subGroup: ISubGroup, value: string, label: string) => void;
    onDeleteTargetingOperator: (subGroup: ISubGroup, value: string) => void;
    onClearAllParams: (key: ReportingContextMenuType) => void;
}

function ReportingSubDimensions(props: IReportingSubDimensionsProps) {
    const {
        onClearAllParams,
        subGroups,
        onChangeSubGroup,
        onDeleteSubGroup,
        onRemoveSubGroupValue,
        onAddSubGroupValue,
        onAddTargetingType,
        onDeleteTargetingType,
        onAddTargetingOperator,
        onDeleteTargetingOperator
    } = props;

    const getContextMenuItems = useCallback((pasteMultiple?: () => void, customCopy?: () => void) => {
        const contextMenuItems = [
            {label: "Clear All Sub Dimensions", onClick: () => onClearAllParams(ReportingContextMenuType.filters)},
        ];

        if (customCopy) {
            contextMenuItems.push({label: "Copy", onClick: customCopy});
        }

        if (pasteMultiple) {
            contextMenuItems.push({label: "Paste Multiple", onClick: pasteMultiple});
        }

        return contextMenuItems;
    }, []);

    return (
        <ContextMenu items={getContextMenuItems()}>
            <div>
                {subGroups.map((subGroup, idx) => <ReportingSubDimension
                    key={subGroup.id} index={idx}
                    subGroup={subGroup}
                    onChange={onChangeSubGroup}
                    onDelete={onDeleteSubGroup}
                    onAddValue={onAddSubGroupValue}
                    onAddTargetingType={onAddTargetingType}
                    onDeleteTargetingType={onDeleteTargetingType}
                    onAddTargetingOperator={onAddTargetingOperator}
                    onDeleteTargetingOperator={onDeleteTargetingOperator}
                    onDeleteValue={onRemoveSubGroupValue}/>)}
            </div>
        </ContextMenu>
    )
}

export default observer(ReportingSubDimensions);
