import {PlatformReportingFilterType} from "../ReportingFilterType";
import BaseFilter from "../BaseFilter";
import {action} from "mobx";
import {alias, define, inject, singleton} from '@injex/core';
import ApiManager from '../../../api/ApiManager.mdl';

@define()
@singleton()
@alias('PlatformReportingFilter')
export default class EnterpriseAccountFilter extends BaseFilter {

    @inject() public apiManager: ApiManager;

	public type = PlatformReportingFilterType.EnterpriseAccount;
	private promise: Promise<any>;

	constructor() {
		super();

		this.allowNew = false;
		this.labelKey = "name";
		this.valueKey = "_id";
	}

	public initialize(): Promise<any> {
		if (this.isLoading) {
			return this.promise;
		}
		this.isLoading = true;

		return this.promise = this.apiManager.accounts.getEnterpriseAccountFilter()
			.then(action((res: { data: { results: any[]; }; }) => {
				this.items = res.data.results;
				this.isLoading = false;
			}));
	}
}
