import {AliasMap, define, inject, injectAlias, singleton} from '@injex/core';
import {Injex} from '@injex/webpack';
import ReportingFilterType from '../../../modules/reporting/common/enums/ReportingFilterType';
import IReportingFilterHandler from '../interfaces/IReportingFilterHandler';
import {ReportVerticalType} from '../../enums/ReportVerticalType';
import IReportingFilter from '../../../modules/reporting/common/interfaces/IReportingFilter';
import BaseFilter from "../filters/BaseFilter";

@define()
@singleton()
export default class ReportingFiltersManager {

    @injectAlias('PlatformReportingFilter', "type") private readonly platformFilters: AliasMap<ReportingFilterType, IReportingFilterHandler>;
    @injectAlias('OpenRtbReportingFilter', "type") private readonly openRtbFilters: AliasMap<ReportingFilterType, IReportingFilterHandler>;

    public getFilter(type: string, vertical: ReportVerticalType): IReportingFilterHandler {

        const filters = this._getFiltersByVertical(vertical);

        if (!filters[type]) {
            return new BaseFilter();
        }

        filters[type].initialize();

        return filters[type];
    }

    private _getFiltersByVertical(vertical: ReportVerticalType) {

        switch (vertical) {
            case ReportVerticalType.PLATFORM:
                return this.platformFilters;

            case ReportVerticalType.OPEN_RTB:
                return this.openRtbFilters;
        }
    }

    public getFilterListValueByLabel(filter: IReportingFilter, label: string, vertical: ReportVerticalType): string {
        const handler = this.getFilter(filter.key, vertical);

        if (handler) {
            return handler.getValueByLabel(label);
        }

        return "";
    }
    public getFilterListLabelByValue(filter: IReportingFilter, value: string, vertical: ReportVerticalType): string {
        const handler = this.getFilter(filter.key, vertical);

        if (handler) {
            return handler.getLabelByValue(value);
        }

        return "";
    }
}
