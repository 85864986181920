import React, {useState} from "react";
import selfTheme from "./theme.module.scss";
import {observer} from "mobx-react";
import {Label, ButtonsGroup, GroupButton} from "@vidazoo/ui-kit";
import clsx from "clsx";
import {ChartType, ChartTypesWithImg} from '../../enums/ChartType';

export interface IChartTypesSelectProps {
    onSelectType: (type: ChartType) => void;
    selectedType: ChartType;
}

export default observer(function ChartTypesSelect({onSelectType, selectedType}: IChartTypesSelectProps) {

    const [hover, setHover] = useState("")

    return (
        <div className={selfTheme.container}>
            <ButtonsGroup dark active={selectedType} onChange={(item: ChartType) => onSelectType(item)}>
                {ChartTypesWithImg.map((type) => {
                    const classNames = clsx(selfTheme.type, {
                        [selfTheme.selected]: type.value === selectedType,
                    });
                    const imageClassNames = clsx(selfTheme.image, {
                        [selfTheme.imageHover]: type.value === hover,
                    });

                    return (
                        <GroupButton key={type.value} className={classNames} onMouseOver={() => setHover(type.value)} onMouseLeave={() => setHover("")} name={type.value}>
                            <div className={imageClassNames} style={{backgroundImage: `url(${type.image})`}}/>
                            {type.label}
                        </GroupButton>
                    );
                })}
            </ButtonsGroup>
        </div>
    );
});

