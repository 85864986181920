import BaseNeededGroup from "../BaseNeededGroup";
import {OpenRtbNeededGroups} from "../NeededGroups";
import {OpenRtbReportingFilterType} from "../ReportingFilterType";
import {ReportVerticalType} from '../../../enums/ReportVerticalType';
import {alias} from '@injex/core';

@alias('OpenRtbReportingFilter')
export default class ConnectionFilter extends BaseNeededGroup {

	public type = OpenRtbReportingFilterType.Connection;

	constructor() {
		super(ReportVerticalType.OPEN_RTB, OpenRtbNeededGroups.Connections);
	}
}
