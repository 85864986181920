import {observer} from 'mobx-react';
import {Column, Row} from '@vidazoo/ui-kit';
import React, {useCallback} from 'react';
import {ChartEditModel} from '../../../../modules/charts/item/edit/model/ChartEditModel.mdl';
import {ChartCreateModel} from '../../../../modules/charts/item/create/model/ChartCreateModel.mdl';
import ReportGroupValueCell
    from "../../../../modules/reporting/components/reportingTableRow/components/ReportGroupValueCell";
import ReportFieldValueCell
    from "../../../../modules/reporting/components/reportingTableRow/components/ReportFieldValueCell";

export interface IDiffTableChartRowProps {
    itemIndex: number;
    viewportIndex: number;
    style: React.CSSProperties;
    model: ChartEditModel | ChartCreateModel;
}

function DiffTableChartRow({itemIndex, style, model}: IDiffTableChartRowProps): JSX.Element {
    const item = model.maskItems.at(itemIndex);
    const chart = model.item.data

    const getStyle = useCallback((row) => {
        let threshold = 30;
        let highColor = "#1D8000";
        let lowColor = "#FB0201";
        if (chart.customStyles && chart.customStyles.length > 0) {
            const customStyle = chart.customStyles.find((style) => `${style.metric}_d` === row.type);
            if (customStyle) {
                threshold = customStyle.threshold;
                lowColor = customStyle.lowColor;
                highColor = customStyle.highColor;
            }
        }
        if (row.rowType === "field" && row.name.includes("(D)")) {
            const style: any = {color: "black", fontWeight: "900"};
            if (row.value < -threshold) {
                style.backgroundColor = lowColor;
                style.color = "white";
                return style;
            } else if (row.value > threshold) {
                style.backgroundColor = highColor;
                style.color = "white";
                return style;
            }
        }
        return {};
    }, []);

    return (
        <Row style={style} key={itemIndex} index={itemIndex}>
            {(item as any).map((row) => (
                <Column style={getStyle(row)} key={row.type || row.name} colKey={row.type || row.name}>
                    {row.rowType === "group" ? (
                        <ReportGroupValueCell groupValueKey={"value"} model={model} group={row}/>
                    ) : (
                        <ReportFieldValueCell field={row}/>
                    )}
                </Column>
            ))}
        </Row>
    )
}

export default observer(DiffTableChartRow);
