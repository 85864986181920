import * as React from 'react';
import {ConfirmAlertModel} from './confirmAlertModel.mdl';
import theme from '../../styles/theme.module.scss';
import {observer} from 'mobx-react';
import {Button} from '@vidazoo/ui-kit';

export interface IConfirmAlertViewProps {
    model: ConfirmAlertModel;
}

function ConfirmAlertView({model}: IConfirmAlertViewProps): JSX.Element {
    return (
        <div>
            <div>
                {model.payload.content}
            </div>
            <div className={theme.footer}>
                <Button className={theme.button} onClick={model.cancel}>{model.payload.cancelText}</Button>
                <Button className={theme.button} onClick={model.confirm} data-primary>{model.payload.confirmText}</Button>
            </div>
        </div>
    );
}

export default observer(ConfirmAlertView);
