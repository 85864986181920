import * as React from "react";
import theme from "../../../modules/dashboards/item/common/styles/theme.module.scss";
import {observer} from "mobx-react";
import {Button, FormMultiSelect, Loader, MultiSelectOption} from "@vidazoo/ui-kit";
import {useModuleFactory} from '@injex/react-plugin';
import {AddChartModel} from '../model/AddChartModel.mdl';
import ChartTypesSelect from '../../components/chart-types-select/ChartTypesSelect';
import useReportingMetaDataManager from '../../hooks/useReportingMetaDataManager';
import useEditChartsManager from '../../hooks/useEditChartsManager';
import useChartsManager from '../../hooks/useChartsManager';
import ChartDisplay from '../../components/chart-display/ChartDisplay';

export interface IAddChartProps {
    onAddClick: (selectedChart: string) => void;
    onCancelClick: () => void;
}

export default observer(function AddChart({onAddClick, onCancelClick}: IAddChartProps) {
    const model = useModuleFactory<AddChartModel>('addChartModel');
    const {selectedChart, filteredCharts, selectedType} = model;
    const metaDataManager = useReportingMetaDataManager();
    const editChartsManager = useEditChartsManager();
    const chartModel = editChartsManager.getModel(selectedChart);
    const chartsManager = useChartsManager();

    return (
        <div className={theme.modal}>
            <div className={theme.row}>
                {chartsManager.items.isLoading ? (
                    <Loader/>
                ) : (
                    <FormMultiSelect className={theme.select} surfaceLevel={0} searchable focus maxItems={5}
                                     value={selectedChart}
                                     onSelectionChange={model.selectChart} multiple={false}>
                        {filteredCharts.map((chart) => {
                            return (
                                <MultiSelectOption label={chart.label} value={chart._id} key={chart._id}/>
                            );
                        })}
                    </FormMultiSelect>
                )}
                <div className={theme.chartSelect}>
                    <ChartTypesSelect
                        onSelectType={model.onSelectType}
                        selectedType={selectedType}/>
                </div>
            </div>
            <div className={theme.chartView}>
                {(selectedChart && chartModel) && (
                    <ChartDisplay chartModel={chartModel} isEditMode={false}/>
                )}
            </div>
            <div className={theme.actions}>
                <Button type="button" medium gray uppercase onClick={onCancelClick}>Cancel</Button>
                <Button type="button" disabled={!selectedChart} medium blue uppercase
                        onClick={() => onAddClick(model.selectedChart)}>Add</Button>
            </div>
        </div>
    );
});
