import {define, singleton} from '@injex/core';
import EntityRequestAdapter from '../../../common/utils/EntityRequestAdapter';
import {ReportVerticalType} from '../../../common/enums/ReportVerticalType';

@define()
@singleton()
export class ReportingApi extends EntityRequestAdapter<any> {
    protected basePathName: string = 'reporting';

    protected get apiEndpoint(): string {
        return this.env.biDashboardsAPI;
    }

    public getNeededData(vertical: ReportVerticalType, neededGroup: string) {
        return this.requestWithContext({
            method: "get",
            url: this.makeAPIPath(`/needed_data/${vertical}/${neededGroup}`)
        });
    }

    public getSubGroupsEntities(vertical: ReportVerticalType, group: string) {
        return this.requestWithContext({
            method: "get",
            url: this.makeAPIPath(`/sub_groups_entities/${vertical}/${group}`)
        });
    }

}
