import {Column, LinkButton, MaterialIcon, Row, Switch, TableRowSelectionHook} from '@vidazoo/ui-kit';
import React from 'react';
import {observer} from 'mobx-react';
import BoxLoader from '../../../../../common/components/box-loader/BoxLoader';
import MarkeredText from '../../../../../common/components/markered-text/MarkeredText';
import dayjs from 'dayjs';
import clsx from 'clsx';
import {useNotificationSettingsListContext} from '../context/SettingsListContext';
import {UserNotificationSettingsTableColumns} from "../common/constants";
import {NotificationSettingsModel} from "../model/NotificationSettingsModel.mdl";
import theme from '../view/theme.module.scss';
import {normalizeEventValue} from "../../../../../common/utils/utils";

export const LOADING_ROW_HEIGHT = 24;

export interface ISettingsTableRowProps {
    columnsManager: NotificationSettingsModel;
    tableRowSelectionHook: TableRowSelectionHook;
    itemIndex: number;
    viewportIndex: number;
    style: React.CSSProperties;
}

function SettingsTableRow({itemIndex, style, columnsManager}: ISettingsTableRowProps) {
    const {model, tableRowSelectionHook} = useNotificationSettingsListContext();
    const {isItemSelected, toggleItem} = tableRowSelectionHook;

    if (columnsManager.isLoading && !columnsManager.data) {
        return (
            <Row style={style} key={itemIndex} index={itemIndex}>
                <Column width={40} className='flex items-center justify-center p-4'>
                    <BoxLoader.Row height={LOADING_ROW_HEIGHT}/>
                </Column>
                {UserNotificationSettingsTableColumns.map(() => (
                    <Column width={40} className='flex items-center justify-center p-4'>
                        <BoxLoader.Row height={LOADING_ROW_HEIGHT}/>
                    </Column>
                ))}
            </Row>
        );
    }
    const item = columnsManager.maskItems.at(itemIndex);

    const onChange = (e) => {
        const value = normalizeEventValue(e);
        model.updateSetting(item._id, e.target.name, value);
    };

    const onOpen = (app: string, entityId: string, params: any) => {
        switch (app) {
            case 'Nightwatch':
                window.open(`https://vidazoo-nightwatch.herokuapp.com/test/${entityId}/edit`);
                break;
            case 'Vroku':
                window.open(`https://os.vidazoo.com/app/vroku/edit-host?id=${entityId}`);
                break;
            case 'Alert Rules':
                if (params && params.entityType) {
                    if (params.entityType === 'Alert Rule') {
                        window.open(`https://bo.vidazoo.com/alert-rule/${entityId}/edit`);
                    } else if (params.entityType === 'Smart Alert') {
                        window.open(`https://bo.vidazoo.com/smart-alert/${entityId}/edit`);
                    }
                }
                break;
        }
    };

    const getEntityName = (app: string, params: any) => {
        switch (app) {
            case 'Alert Rules':
                if (params && params.entityType) {
                    return `${params.entityName} (${params.entityType})`;
                }
                break;
            default:
                return params?.entityName;
        }
    };

    const onDeleteSetting = () => {
        model.deleteItem(item);
    };


    return (
        <Row style={style} key={item._id} index={itemIndex}>
            <Column colKey="checkbox" width={40} className='flex items-center justify-center'>
                <input type="checkbox" className="flex w-8 h-8 cursor-pointer" checked={isItemSelected(item._id)}
                       onChange={() => toggleItem(item._id)}/>
            </Column>
            {item.params && item.params.entityId ?
                <Column colKey="name">
                    <div className={theme.name} onClick={() => onOpen(item.app, item.params.entityId, item.params)}>
                        {getEntityName(item.app, item.params)}
                    </div>
                </Column>
                :
                <Column colKey="name">
                    <div>{item.name}</div>
                </Column>
            }
            <Column colKey="app">{
                <MarkeredText
                    marker={model.getColumnSearchQuery('type')}>{item.app}</MarkeredText>
            }</Column>
            <Column colKey="notification">
                <div className="flex items-center">
                    <Switch className={theme.switch} name="notification"
                            defaultChecked={item.notification}
                            onChange={onChange}/>
                    <div className="truncate ml-5">{item.notification ? 'TRUE' : 'FALSE'}</div>
                </div>
            </Column>
            <Column colKey="email">
                <div className="flex items-center">
                    <Switch className={theme.switch} name="email"
                            defaultChecked={item.email}
                            onChange={onChange}/>
                    <div className="truncate ml-5">{item.email ? 'TRUE' : 'FALSE'}</div>
                </div>
            </Column>
            <Column colKey="sms">
                <div className="flex items-center">
                    <Switch className={theme.switch} name="sms"
                            defaultChecked={item.sms}
                            onChange={onChange}/>
                    <div className="truncate ml-5">{item.sms ? 'TRUE' : 'FALSE'}</div>
                </div>
            </Column>
            <Column colKey="created">{dayjs(item.created).format('MMM DD, YYYY')}</Column>
            <Column colKey="lastSent">{dayjs(item.lastSent).format('MMM DD, YYYY')}</Column>
            <Column colKey="actions">
                <div className={theme.links}>
                    <LinkButton onClick={onDeleteSetting} iconName="icon-delete" iconSize={1.8}/>
                </div>
            </Column>
        </Row>
    );
}

export default observer(SettingsTableRow);
