export default class MemoryClipboard {

    private static createElement<T>(type: string): T {
        return (document.createElement(type) as any) as T;
    }

    public static copy(str: string) {
        let input = MemoryClipboard.createElement<HTMLInputElement>('textarea');

        input.style.opacity = '0';
        input.style.position = 'fixed';
        input.style.top = '0';
        input.style.left = '0';

        input.innerHTML = str;

        document.body.appendChild(input);

        input.select();

        document.execCommand('copy');

        input.parentElement.removeChild(input);

        input = null;
    }
}