import * as React from 'react';
import {useMainMenuContext} from '../../main-menu/contexts/MainMenuContext';
import {observer} from 'mobx-react';
import theme from '../../../../../common/styles/_baseForm.module.scss';
import {AggregateChangeComponent, Button, Form, FormInput, Label} from '@vidazoo/ui-kit';
import {useState} from 'react';
import {useDebounce} from '../../../../../common/hooks/useDebounce';

function ProfilePasswordForm(): JSX.Element {
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [changingPassword, setChangingPassword] = useState(false);
    const {profileModel} = useMainMenuContext();
    const {updatePassword, checkPassword, onCloseClick, isCheckingPassword, passwordScore} = profileModel;

    const disableButton = isCheckingPassword || !newPassword || (newPassword !== confirmPassword) || passwordScore < 3

    const debouncedCheckPassword: (password: string) => void = useDebounce((password: any) => {
        setChangingPassword(false);
        checkPassword(password);
    }, 500);

    const onSubmit = () => {
        if (disableButton) {
            return;
        }
        updatePassword(currentPassword, newPassword);
    }

    const onChange = (e) => {
        switch (e.target.name) {
            case "currentPassword":
                setCurrentPassword(e.target.value);
                break;
            case "newPassword":
                setChangingPassword(true);
                debouncedCheckPassword(e.target.value);
                setNewPassword(e.target.value);
                break;
            case "confirmPassword":
                setConfirmPassword(e.target.value);
                break;
        }
    }

    return (
        <Form className={theme.password} onSubmit={onSubmit}>
            <Label className={theme.label} spacing={1.25} size={13} weight={600}>Update Password</Label>
            <AggregateChangeComponent onChange={onChange}>
                <FormInput type="password" className={theme.input} value={currentPassword} name="currentPassword"
                           label="Current Password" required fill autoFocus autoComplete="nope"/>
                <FormInput type="password" className={theme.input} value={newPassword} name="newPassword"
                           label="New Password" required fill autoComplete="nope"/>
                <FormInput type="password" className={theme.input} value={confirmPassword} name="confirmPassword"
                           label="Confirm Password" required fill autoComplete="nope"/>
            </AggregateChangeComponent>
            <div className={theme.options}>
                <Button type="button" medium gray onClick={onCloseClick}>Cancel</Button>
                <Button type="submit" disabled={disableButton} medium blue>Update Password</Button>
            </div>
        </Form>
    );
}

export default observer(ProfilePasswordForm);
