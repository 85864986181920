import {observer} from 'mobx-react';
import React, {useCallback, useEffect} from 'react';
import theme from '../view/theme.module.scss';
import {Table} from '@vidazoo/ui-kit';
import {useScheduleReportsListContext} from '../context/ScheduleReportsListContext';
import ScheduleReportsTableRow from './ScheduleReportsTableRow';
import ScheduleReportsTableHeader from './ScheduleReportsTableHeader';

const ROW_HEIGHT = 45;
const LOADING_ROW_HEIGHT = 24;
const LOADING_ITEMS_COUNT = () => Math.round(window.innerHeight / LOADING_ROW_HEIGHT);

function ScheduleReportsList(): JSX.Element {
    const {model, tableRowSelectionHook, allItemIds} = useScheduleReportsListContext();
    const {clearSelection, selectedItems} = tableRowSelectionHook;

    useEffect(() => {
        clearSelection();
        model.items.invalidate().fetch();
        model.resetFilter();
    }, []);

    const renderTableRow = useCallback((itemIndex: number, viewportIndex: number, style: React.CSSProperties) => {
        const item = model.maskItems.at(itemIndex);

        return (
            <ScheduleReportsTableRow
                key={item ? item._id : itemIndex}
                itemIndex={itemIndex}
                viewportIndex={viewportIndex}
                style={style}/>
        );
    }, [model.maskItems, selectedItems, model.columns]);

    const renderTableHeader = useCallback(() => {
        return (
            <ScheduleReportsTableHeader/>
        );
    }, [selectedItems, allItemIds]);

    return (
        <Table
            className={theme.table}
            itemsCount={(model.items.isLoading && !model.items.data) ? LOADING_ITEMS_COUNT() : model.maskItems.length}
            rowHeight={ROW_HEIGHT}
            renderRow={renderTableRow}
            renderHeader={renderTableHeader}
            columnsManager={model}
            overscanItems={5}
        />
    );
}

export default observer(ScheduleReportsList);
