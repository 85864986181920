import {define, inject} from '@injex/core';
import {makeObservable, observable} from 'mobx';
import IChart from '../../interfaces/IChart';
import {ColumnsManager} from '@vidazoo/ui-kit';
import ApiManager from '../../../../common/api/ApiManager.mdl';
import {DataListQuery} from '../../../../common/models/DataListQuery';
import {ChartsTableColumns} from '../common/constants';
import dayjs from 'dayjs';

@define()
export class ChartsListModel extends ColumnsManager<IChart> {

    @inject() private apiManager: ApiManager;
    public items: DataListQuery<IChart, []>;

    constructor() {
        super({
            persistKey: 'charts_list_columns',
        });

        this.setColumns(ChartsTableColumns);

        this.items = new DataListQuery(this._fetchItems.bind(this), {defaultValue: [], dataObservType: observable.ref});

        makeObservable(this);

    }

    public get data(): IChart[] {
        return this.items.data;
    }

    public get isLoading(): boolean {
        return this.items.isLoading;
    }

    private async _fetchItems() {
        const requestFields = {};
        ChartsTableColumns.forEach((column) => {
            requestFields[column.key] = 1;
        });
        const response = await this.apiManager.charts.getAll({fields: requestFields});
        return response.results;
    }

    public fetch() {
        return this.items.forceFetch();
    }
}
