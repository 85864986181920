import {define, init} from '@injex/core';
import BaseDashboardModel from '../../common/base/BaseDashboardModel';
import IDashboard from '../../../interfaces/IDashboard';
import {AlertAccent} from '../../../../../common/alerts/common/enums';
import {Layout} from 'react-grid-layout';
import UrlsService from "../../../../../common/router/services/urlsService.mdl";

@define()
export class DashboardCreateModel extends BaseDashboardModel {

    protected fetchItem(id: string): Promise<IDashboard> {
        throw new Error('Method not implemented.');
    }

    public submit = async () => {
        try {
            const doc = await this.apiManager.dashboards.create(this.item.data);
            this.alertsManager.setBasicAlert({content: `Operation Complete, Created successfully`}, AlertAccent.Success);
            this.routerService.push(UrlsService.editDashboard(doc._id))

        } catch (err) {
            this.alertsManager.setBasicAlert({content: `Operation Error, Failed to create`}, AlertAccent.Error);
        }
    }

    public removeChart = (chartId: string) => {
        const idx = this.item.data.layouts.lg.findIndex((layout) => layout.i === chartId);
        if (idx > -1) {
            this.item.data.layouts.lg.splice(idx, 1);
        }
    };

    public onLayoutChange = (layouts: Layout[]) => {
        this.item.data.layouts.lg = layouts;
    };


}
