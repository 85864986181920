import {observer} from "mobx-react";
import {FormSelect} from "@vidazoo/ui-kit";
import theme from "../theme.module.scss";
import * as React from "react";
import {IChangeLogFilter} from "../../../common/interfaces/IChangeLogFilter";
import useChangeLogsModel from '../../../common/hooks/useChangeLogsModel';
import {FilterOperators} from '../../../../../common/enums/FilterOperators';
import {AuthScopeTypes} from '../../../common/constants/authScopeTypes';

export interface IScopeFilterProps extends React.InputHTMLAttributes<HTMLInputElement> {
	filter: IChangeLogFilter;
}

function ScopeFilter({filter}: IScopeFilterProps): JSX.Element {
    const model = useChangeLogsModel();

	function onChangeFilterParam(e) {
		const {name, value} = e.target;
        model.setFilterParam(filter, name, value);
	}

	return (
		<>
			<FormSelect
				className={theme.select}
				items={[FilterOperators.isAnyOf, FilterOperators.isNotAnyOf]}
				maxItems={5}
				itemValueKey="value"
				itemLabelKey="label"
				value={filter.operator || FilterOperators.isAnyOf}
				name="operator"
				required
				hideValidationMessage
				onChange={onChangeFilterParam}
				searchable
			/>
			<FormSelect
				className={theme.select}
				items={AuthScopeTypes}
				maxItems={5}
				itemValueKey="value"
				itemLabelKey="name"
				value={filter.value}
				name="value"
				required
				hideValidationMessage
				onChange={onChangeFilterParam}
				searchable
			/>
		</>
	);
}

export default observer(ScopeFilter);
