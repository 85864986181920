import {Icon, FormSelect, Chips} from '@vidazoo/ui-kit';
import theme from './theme.module.scss';
import clsx from 'clsx';
import {FilterOperators} from '../../common/enums/FilterOperators';
import IReportingFilter from '../../common/interfaces/IReportingFilter';
import {useCallback, useMemo} from 'react';
import useReportingMetaDataManager from '../../../../common/hooks/useReportingMetaDataManager';
import {ReportVerticalType} from '../../../../common/enums/ReportVerticalType';
import {observer} from 'mobx-react';
import useAutoCompleteListWidth from "../../../../common/hooks/useAutoCompleteListWidth";

export interface IReportingFilterRowProps {
    index: number;
    filter: IReportingFilter;
    setFilterKey: (filter: IReportingFilter, key: string) => Promise<any>;
    setFilterParam: (filter: IReportingFilter, key: string, value: any) => void;
    removeFilter: (filter: IReportingFilter) => void;
    pushFilterValue: (filter: IReportingFilter, value: string, label: string) => void;
    removeFilterValue: (filter: IReportingFilter, value: string) => void;
}

function ReportingFilterRow(props: IReportingFilterRowProps) {
    const {removeFilterValue, pushFilterValue, removeFilter, setFilterParam, setFilterKey, index, filter} = props;
    const reportingMetaDataManager = useReportingMetaDataManager();
    const filters = reportingMetaDataManager.metaDataByVertical[ReportVerticalType.PLATFORM].filters.entries;
    const autoCompleteListWidth = useAutoCompleteListWidth();

    const filterKeyLabel = useMemo(() => {
        let label = "Item";

        const currentFilterGroup = filters.find((group) => filter.key === group.value);

        if (currentFilterGroup) {
            label = currentFilterGroup.label;
        }

        return `Add ${label}...`;
    }, [filter]);

    const filterOptions = useMemo(() => {
        let options = [];
        const currentGroup = filters.find((group) => filter.key === group.value);
        if (currentGroup) {
            options = currentGroup.operators.map((operator) => ({label: FilterOperators[operator], value: operator}));
        }
        return options;
    }, [filter, filter.key]);

    const onDeleteFilter = useCallback(() => {
        removeFilter(filter);
    }, [filter]);

    const onAddFilterValue = useCallback((value: string, label: string) => {
        pushFilterValue(filter, value, label);
    }, [filter]);

    const onDeleteFilterValue = useCallback((value: string) => {
        removeFilterValue(filter, value);
    }, [filter]);

    const onChangeFilter = useCallback((e) => {
        const {name, value} = e.target;

        if (name === "key") {
            setFilterKey(filter, value);
            const defaultValue = filters.find((group) => filter.key === group.value).operators[0];
            setFilterParam(filter, "operator", defaultValue);
            return;
        }
        setFilterParam(filter, name, value);

    }, [filter, filters]);

    return (
        <div className={theme.filter}>
            <div className={theme.label}>
                Filter {index + 1}
            </div>
            <div className={theme.inputs}>
                <FormSelect
                    className={clsx(theme.input, theme.groups)}
                    items={filters}
                    maxItems={5}
                    itemValueKey="value"
                    itemLabelKey="label"
                    value={filter.key}
                    name="key"
                    required
                    hideValidationMessage
                    onChange={onChangeFilter}
                    searchable
                />
                <FormSelect
                    className={clsx(theme.input, theme.groups)}
                    items={filterOptions}
                    maxItems={5}
                    itemValueKey="value"
                    itemLabelKey="label"
                    value={filter.operator}
                    name="operator"
                    required
                    hideValidationMessage
                    onChange={onChangeFilter}
                    searchable
                />
                <Chips
                    allowOrder={false}
                    leaveOpenAfterSelect
                    allowCreate={filter.allowNew}
                    selectValueOnBlur={filter.allowNew}
                    dataSource={filter.filterList}
                    itemLabelKey={filter.filterLabelKey}
                    itemValueKey={filter.filterValueKey}
                    chips={filter.values}
                    maxItems={5}
                    searchPlaceholder={filterKeyLabel}
                    onSelect={onAddFilterValue}
                    onDelete={onDeleteFilterValue}
                    isLoading={filter.isLoading}
                    autoCompleteListWidth={autoCompleteListWidth}
                />
                <div className={theme.delete} onClick={onDeleteFilter}>
                    <Icon iconName="icon-delete" size={1.8}/>
                </div>
            </div>
        </div>
    );
}

export default observer(ReportingFilterRow);
