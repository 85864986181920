import * as React from "react";
import theme from "./theme.module.scss";
import * as _ from "lodash";
import {NativeScrollable} from '@vidazoo/ui-kit';

export const renderJSONValue = (data: any) => (
    <NativeScrollable className={theme.codeScrollArea}>
        <pre className={theme.code}>
            {JSON.stringify(data, undefined, 2)}
        </pre>
    </NativeScrollable>
);

export const renderStringValue = (value: any) => `"${value.toString()}"`;

export const flattenValue = (data: any): string | JSX.Element => !_.isUndefined(data) && !_.isNull(data)
    ? typeof data === "object"
        ? renderJSONValue(data)
        : renderStringValue(data)
    : "";

export const renderValueSnapshot = (value: any): JSX.Element => <span className={theme.value}>{flattenValue(value)}</span>;
