import ILabelValuePair from '../interfaces/ILabelValuePair';

export enum PrebidAdapter {
    BEACH_FRONT = "beachfront",
    FREEWHEEL_SSP = "freewheel-ssp",
    RUBICON = "rubicon",
    SPOTX_ADOS = "spotx-ados",
    SPOTX = "spotx",
    OPENX = "openx",
    APP_NEXUS = "appnexus",
    ONE_VIDEO = "oneVideo",
    LKQD = "lkqd",
    INDEX = "ix",
    ADFORM = "adform",
    ADFORM_OPEN_RTB = "adformOpenRTB",
    AUDIENCE_NETWORK = "audienceNetwork",
    LOCKER_DOME = "lockerDome",
    UNDERDOG_MEDIA = "underdogMedia",
    SOVRN = "sovrn",
    SONOBI = "sonobi",
    SHARETHROUGH = "sharethrough",
    PUBMATIC = "pubmatic",
    PULSE_POINT = "pulsepoint",
    ONE_DISPLAY = "oneDisplay",
    ONE_MOBILE = "oneMobile",
    RHYTHM_ONE = "rhythmone",
    TRIPLE_LIFT = "triplelift",
    TRUSTX = "trustx",
    UNRULY = "unruly",
    _33_ACROSS = "33across",
    CRITEO = "criteo",
    EMX_DIGITAL = "emx_digital",
    IMPROVE_DIGITAL = "improvedigital",
    MEDIA_NET = "medianet",
    SMART_AD_SERVER = "smartadserver",
    SYNACOR_MEDIA = "synacormedia",
    YIELDMO = "yieldmo",
    COLOSSUS = "colossusssp",
    TELARIA = "telaria",
    CONVERSANT = "conversant",
    GUMGUM = "gumgum",
    UNDERTONE = "undertone",
    RISE = "rise",
    SMAATO = "smaato",
    AMAZON = "amazon",
    GRID = "grid",
    ONE_TAG = "onetag",
    VIDEOBYTE = "videobyte",
    YAHOOSSP = "yahoossp",
    AMX = "amx",
    ADMAN = "adman",
    ONE_FIFTY_TWO_MEDIA = "onefiftytwomedia",
    ADYOULIKE = "adyoulike",
    TEADS = "teads",
    ZETA_GLOBAL_SSP = "zeta_global_ssp",
    RICHAUDIENCE = "richaudience",
    MINUTE_MEDIA = "minutemedia",
    YANDEX = "yandex",
    KRUSHMEDIA = "krushmedia",
    RESET_DIGITAL = "resetdigital",
    SMART_CLIP = "smartx",
    SMILEWANTED = "smilewanted",
    PGAM = "pgam",
    OPENWEB = "openweb",
    ANIVIEW = "aniview",
    VIDAZOO = "vidazoo",
    Nobid = "nobid",
    STROEER_CORE = "stroeerCore",
    KARGO = "kargo",
    VIDOOMY = "vidoomy",
    BOLDWIN = "boldwin",
    SEEDTAG = "seedtag",
    KUEEZRTB = "kueezrtb",
    INSTICATOR = "insticator",
    EPLANNING = "eplanning",
    NEXT_MILLENNIUM = "nextMillennium",
    OUTBRAIN = "outbrain",
    ADTELLIGENT = "adtelligent",
    ADAGIO = "adagio",
    MINUTE_MEDIA_PLUS = "mmplus",
    ADF = "adf",
    IMDS = "imds",
}

export const PrebidAdapters: ILabelValuePair[] = [
    {value: PrebidAdapter.VIDAZOO, label: "Vidazoo"},
    {value: PrebidAdapter.BEACH_FRONT, label: "Beach Front"},
    {value: PrebidAdapter.FREEWHEEL_SSP, label: "Freewheel SSP"},
    {value: PrebidAdapter.RUBICON, label: "Rubicon"},
    {value: PrebidAdapter.SPOTX, label: "SpotX"},
    {value: PrebidAdapter.SPOTX_ADOS, label: "SpotX AdOS"},
    {value: PrebidAdapter.OPENX, label: "Open X"},
    {value: PrebidAdapter.APP_NEXUS, label: "AppNexus"},
    {value: PrebidAdapter.LKQD, label: "LKQD"},
    {value: PrebidAdapter.ONE_VIDEO, label: "One Video"},
    {value: PrebidAdapter.INDEX, label: "Index Exchange"},
    {value: PrebidAdapter.ADFORM, label: "Adform"},
    {value: PrebidAdapter.ADFORM_OPEN_RTB, label: "Adform Open RTB"},
    {value: PrebidAdapter.AUDIENCE_NETWORK, label: "Audience Network"},
    {value: PrebidAdapter.LOCKER_DOME, label: "Locker Dome"},
    {value: PrebidAdapter.UNDERDOG_MEDIA, label: "Underdog Media"},
    {value: PrebidAdapter.SOVRN, label: "Sovrn"},
    {value: PrebidAdapter.SONOBI, label: "Sonobi"},
    {value: PrebidAdapter.SHARETHROUGH, label: "Sharethrough"},
    {value: PrebidAdapter.PUBMATIC, label: "Pubmatic"},
    {value: PrebidAdapter.PULSE_POINT, label: "Pulse Point"},
    {value: PrebidAdapter.ONE_DISPLAY, label: "One Display"},
    {value: PrebidAdapter.ONE_MOBILE, label: "One Mobile"},
    {value: PrebidAdapter.RHYTHM_ONE, label: "Rhythm One"},
    {value: PrebidAdapter.TRIPLE_LIFT, label: "Triple Lift"},
    {value: PrebidAdapter.TRUSTX, label: "TrustX"},
    {value: PrebidAdapter.UNRULY, label: "Unruly"},
    {value: PrebidAdapter._33_ACROSS, label: "33 Across"},
    {value: PrebidAdapter.CRITEO, label: "Criteo"},
    {value: PrebidAdapter.EMX_DIGITAL, label: "EMX Digital"},
    {value: PrebidAdapter.IMPROVE_DIGITAL, label: "Improve Digital"},
    {value: PrebidAdapter.MEDIA_NET, label: "Media.net"},
    {value: PrebidAdapter.SMART_AD_SERVER, label: "Smart AdServer"},
    {value: PrebidAdapter.SYNACOR_MEDIA, label: "Synacor Media"},
    {value: PrebidAdapter.YIELDMO, label: "Yieldmo"},
    {value: PrebidAdapter.COLOSSUS, label: "Colossus"},
    {value: PrebidAdapter.TELARIA, label: "Telaria"},
    {value: PrebidAdapter.CONVERSANT, label: "Conversant"},
    {value: PrebidAdapter.GUMGUM, label: "GumGum"},
    {value: PrebidAdapter.UNDERTONE, label: "Undertone"},
    {value: PrebidAdapter.RISE, label: "Rise"},
    {value: PrebidAdapter.SMAATO, label: "Smaato"},
    {value: PrebidAdapter.AMAZON, label: "Amazon"},
    {value: PrebidAdapter.GRID, label: "Grid"},
    {value: PrebidAdapter.ONE_TAG, label: "One Tag"},
    {value: PrebidAdapter.VIDEOBYTE, label: "Videobyte"},
    {value: PrebidAdapter.YAHOOSSP, label: "Yahoo SSP"},
    {value: PrebidAdapter.AMX, label: "AMX"},
    {value: PrebidAdapter.ADMAN, label: "Adman"},
    {value: PrebidAdapter.ONE_FIFTY_TWO_MEDIA, label: "152 Media"},
    {value: PrebidAdapter.ADYOULIKE, label: "Adyoulike"},
    {value: PrebidAdapter.TEADS, label: "Teads"},
    {value: PrebidAdapter.ZETA_GLOBAL_SSP, label: "Zeta Global SSP"},
    {value: PrebidAdapter.RICHAUDIENCE, label: "Rich Audience"},
    {value: PrebidAdapter.MINUTE_MEDIA, label: "Minute Media"},
    {value: PrebidAdapter.YANDEX, label: "Yandex"},
    {value: PrebidAdapter.KRUSHMEDIA, label: "Krushmedia"},
    {value: PrebidAdapter.RESET_DIGITAL, label: "Reset Digital"},
    {value: PrebidAdapter.SMART_CLIP, label: "Smartclip"},
    {value: PrebidAdapter.SMILEWANTED, label: "Smilewanted"},
    {value: PrebidAdapter.PGAM, label: "PGAM"},
    {value: PrebidAdapter.OPENWEB, label: "OpenWeb"},
    {value: PrebidAdapter.ANIVIEW, label: "Aniview"},
    {value: PrebidAdapter.Nobid, label: "Nobid"},
    {value: PrebidAdapter.STROEER_CORE, label: "Stroeer Core"},
    {value: PrebidAdapter.KARGO, label: "Kargo"},
    {value: PrebidAdapter.VIDOOMY, label: "Vidoomy"},
    {value: PrebidAdapter.BOLDWIN, label: "Boldwin"},
    {value: PrebidAdapter.SEEDTAG, label: "Seed Tag"},
    {value: PrebidAdapter.KUEEZRTB, label: "Kueez RTB"},
    {value: PrebidAdapter.INSTICATOR, label: "Insticator"},
    {value: PrebidAdapter.EPLANNING, label: "Eplanning"},
    {value: PrebidAdapter.NEXT_MILLENNIUM, label: "Next Millennium"},
    {value: PrebidAdapter.OUTBRAIN, label: "Outbrain"},
    {value: PrebidAdapter.ADTELLIGENT, label: "Adtelligent"},
    {value: PrebidAdapter.ADAGIO, label: "Adagio"},
    {value: PrebidAdapter.MINUTE_MEDIA_PLUS, label: "Minute Media Plus"},
    {value: PrebidAdapter.ADF, label: "ADF (Adform New)"},
    {value: PrebidAdapter.IMDS, label: "IMDS"},
];
