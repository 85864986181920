import clsx from 'clsx';
import theme from './theme.module.scss';

function ProgressIndicator({absolute = false, white = false}): JSX.Element {
    return (
        <div className={clsx(theme.indicator, {
            [theme.absolute]: absolute,
            [theme.white]: white
        })}></div>
    );
}

export default ProgressIndicator;
