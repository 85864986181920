import {define, inject, init, singleton} from '@injex/core';
import {computed, makeObservable, observable} from 'mobx';
import {Injex} from '@injex/webpack';
import {HistoryReportsManager} from '../managers/historyReportsManager.mdl';
import {RegularReportsManager} from '../managers/regularReportsManager.mdl';
import MemoryClipboard from '../../../common/utils/MemoryClipboard';
import {ColumnsManager, guid} from '@vidazoo/ui-kit';
import ReportingBuildManager from '../managers/reportingBuildManager.mdl';
import IReportingResults from '../common/interfaces/IReportingResults';
import {IReportingPreparedResultsRow} from '../common/interfaces/IReportingPreparedResults';
import {AlertAccent} from '../../../common/alerts/common/enums';
import {AlertsManager} from '../../../common/alerts/managers/alertsManager.mdl';
import UrlsService from '../../../common/router/services/urlsService.mdl';
import {RouterService} from '../../../common/router/services/routerService.mdl';
import {GenerateReportUrlParamsManager} from '../managers/generateReportUrlParamsManager.mdl';
import {getFieldValue, getGroupValue, searchInMetrics} from '../common/utils/customGetReportingSortValue';
import {ReportHistoryStatus} from '../common/enums/ReportHistoryStatus';
import {HistoryReportGetUrlAction} from '../common/enums/HistoryReportGetUrlAction';
import {HistoryReportsColumnsManager} from '../managers/historyReportsColumnsManager.mdl';
import ReportingMetaDataManager from '../../../common/managers/ReportingMetaDataManager.mdl';
import {defaultFieldFormat} from '../common/constants/constants';
import {ReportingFieldType} from '../common/enums/ReportingFieldType';
import ReportingParamsManager from '../managers/reportingParamsManager.mdl';
import {ReportVerticalType} from '../../../common/enums/ReportVerticalType';
import ExportToCsvManager from '../../../common/managers/ExportToCsvManager.mdl';
import UserManager from "../../../common/managers/UserManager.mdl";
import {IDynamicFormula} from "../common/interfaces/IDynamicFormula";
import {compileFieldFormula} from "../../../common/utils/compileFieldFormula";
import {ReportPresetType} from "../common/enums/ReportPresetType";

@define()
@singleton()
export default class ReportingModel extends ColumnsManager<IReportingPreparedResultsRow> {
    @inject() private $injex: Injex;
    @inject() private historyReportsManager: HistoryReportsManager;
    @inject() private regularReportsManager: RegularReportsManager;
    @inject() private reportingParamsManager: ReportingParamsManager;
    @inject() private reportingBuildManager: ReportingBuildManager;
    @inject() public alertsManager: AlertsManager;
    @inject() private routerService: RouterService;
    @inject() private generateReportUrlParamsManager: GenerateReportUrlParamsManager;
    @inject() private historyReportsColumnsManager: HistoryReportsColumnsManager;
    @inject() private reportingMetaDataManager: ReportingMetaDataManager;
    @inject() private exportToCsvManager: ExportToCsvManager;
    @inject() private userManager: UserManager;

    @observable public isGenerateReportDisabled: boolean;
    @observable public useHistoryReports: boolean;
    @observable public isLoading: boolean;

    @observable public executionTime: number;
    @observable public startTime: number;

    @observable public reportIsEmpty: boolean;
    @observable public results: IReportingResults;

    @observable public editDynamicFormula: IDynamicFormula;
    @observable public dynamicFormulaModalOpen: boolean;

    constructor() {
        super({
            persistKey: 'reporting_list_columns',
        });

        this.columns = {};
        this.tableColumns = [];
        this.isLoading = false;
        this.useHistoryReports = true;
        this.dynamicFormulaModalOpen = false;
        this.editDynamicFormula = {
            name: "",
            formula: "",
        };

        this.results = this.initialResults;
        makeObservable(this);
    }

    @init()
    public init() {
        this.connectHooks();
    }

    public async getReport() {
        try {
            this.startTime = Date.now();

            this.handleSetColumns();
            this.isLoading = true;

            this.setGenerateReportStatus();
            this.reportingBuildManager.setRequestedParams(this.reportingParamsManager.params);

            if (this.useHistoryReports && this.historyReportsManager.allowedHistoryReports) {
                await this.historyReportsManager.get();
            } else {
                const data = await this.regularReportsManager.get();
                this.onReportReady(data);
            }
        } catch (e) {
            this.onReportError(e);
        }
    }

    public onReportReady = async (data: any, build: boolean = true) => {
        if (!data || data.length === 0) {
            this.alertsManager.setBasicAlert(
                {content: `We couldn\'t find any results`},
                AlertAccent.Warning
            );
            this.isLoading = false;
            this.reportIsEmpty = true;
            return;
        }
        this.handleSetColumns();
        this.reportIsEmpty = false;

        if (build) {
            this.results = await this.reportingBuildManager.buildResults(data);

        } else {
            this.results = {
                fields: observable.array(data.fields, {deep: false}),
                groups: observable.array(data.groups, {deep: false}),
                result: observable.array(data.result, {deep: false}),
            }
        }

        this.isLoading = false;
        this.executionTime = Date.now() - this.startTime;
    };

    public handleSetColumns = () => {
        let columns = [];
        this.reportingParamsManager.params.groups.forEach((group) =>
            columns.push({
                title: group.label,
                key: group.value,
                defaultWidth: 100,
                getValue: getGroupValue,
                options: {
                    order: false,
                    search: {searchKey: group.value},
                    freeze: true,
                    sort: {sortKey: group.value, sortOnHeaderClick: true},
                },
            })
        );
        this.reportingParamsManager.params.subGroups.forEach((subGroup) => {
            subGroup.entities.forEach((entity) => {
                if (entity === 'targeting') {
                    subGroup.targeting.types.forEach((targetingType) => {
                        subGroup.targeting.operators.forEach((targetingOperator) => {
                            const entityTargeting = `targeting:${targetingType}:${targetingOperator}`;
                            columns.push({
                                title: entityTargeting,
                                key: entityTargeting,
                                defaultWidth: 100,
                                getValue: getGroupValue,
                                options: {
                                    order: false,
                                    search: {searchKey: entityTargeting},
                                    freeze: true,
                                    sort: {sortKey: entityTargeting, sortOnHeaderClick: true},
                                },
                            });
                        });
                    });
                } else if (entity === 'optionsWidget' && subGroup.freeField) {
                    columns.push({
                        title: `optionsWidget:${subGroup.freeField}`,
                        key: `optionsWidget:${subGroup.freeField}`,
                        defaultWidth: 100,
                        getValue: getGroupValue,
                        options: {
                            order: false,
                            search: {searchKey: `optionsWidget:${subGroup.freeField}`},
                            freeze: true,
                            sort: {sortKey: `optionsWidget:${subGroup.freeField}`, sortOnHeaderClick: true},
                        },
                    });
                } else {
                    columns.push({
                        title: entity,
                        key: entity,
                        defaultWidth: 100,
                        getValue: getGroupValue,
                        options: {
                            order: false,
                            search: {searchKey: entity},
                            freeze: true,
                            sort: {sortKey: entity, sortOnHeaderClick: true},
                        },
                    });
                }
            });
        });

        this.reportingParamsManager.params.fields.forEach((field) =>
            columns.push({
                title: field.label,
                key: field.value,
                defaultWidth: 100,
                getValue: getFieldValue,
                options: {
                    order: false,
                    search: {searchKey: searchInMetrics},
                    freeze: true,
                    sort: {sortKey: field.value, sortOnHeaderClick: true},
                },
            })
        );
        this.resetOrder();
        this.setColumns(observable.array(columns));
    };

    public setColumnPosition(colKey: string, nextIndex: number) {
        super.setColumnPosition(colKey, nextIndex);
        const groups = [];
        const fields = [];
        this.columnsOrder.forEach((key) => {
            const group =
                this.reportingMetaDataManager.metaDataByVertical[ReportVerticalType.PLATFORM]?.groups.get(
                    key
                );
            if (group) {
                groups.push(group);
            }
            const field =
                this.reportingMetaDataManager.metaDataByVertical[ReportVerticalType.PLATFORM]?.fields.get(
                    key
                );
            if (field) {
                fields.push(field);
            }
        });

        this.reportingParamsManager.setGroups(groups);
        this.reportingParamsManager.setFields(fields);
    }

    public onLoadingReport = () => {
        this.isLoading = true;
    };

    public onReportError = (err: any) => {
        this.alertsManager.setBasicAlert(
            {content: `We couldn\'t generate the report`},
            AlertAccent.Error
        );
        this.$injex.logger.error('onReportError', err);
        this.isLoading = false;
    };

    @computed
    public get data(): any[] {
        return this.results.result.length > 0 ? this.results.result : [];
    }

    public cleanResults() {
        this.results = this.initialResults;
        this.isLoading = false;
    }

    public generateReportLink = () => {
        const params = this.reportingParamsManager.preparedParams;
        const urlParams = this.generateReportUrlParamsManager.generate(
            params,
            this.reportingParamsManager.params
        );
        MemoryClipboard.copy(`${window.location.origin}/reporting?${urlParams}`);
        this.alertsManager.setBasicAlert({content: `Link copied to clipboard`}, AlertAccent.Success);
    };

    public connectHooks() {
        this.historyReportsManager.hooks.onReportReady.tap(this.onReportReady);
        this.historyReportsManager.hooks.onReportError.tap(this.onReportError);
        this.historyReportsManager.hooks.onLoadingReport.tap(this.onLoadingReport);

        this.regularReportsManager.hooks.onReportError.tap(this.onReportError);
    }

    private _onSetUserData = async () => {
        this.useHistoryReports = !this.userManager.isRootAdminAndVidazoo();
    }

    private setGenerateReportStatus = () => {
        let secondsLeft = 10;
        const disabledTimer = setInterval(() => {
            if (!this.isLoading || secondsLeft <= 0) {
                clearInterval(disabledTimer);
                return (this.isGenerateReportDisabled = false);
            }
            this.isGenerateReportDisabled = true;
            secondsLeft -= 1;
        }, 1000);
    };

    public downloadCSV = () => {
        this.alertsManager.setBasicAlert(
            {content: `Generating CSV, Please wait...`},
            AlertAccent.Info
        );
        this.exportToCsvManager.exportReport(
            this.results.result,
            this.reportingParamsManager.extractedParamsFields
        );
    };

    private get initialResults(): IReportingResults {
        return {
            result: observable([], {deep: false}),
            originResults: [],
            fields: [],
            groups: [],
            totalFields: {},
        };
    }

    public toggleUseHistoryReports = () => {
        if (this.useHistoryReports) {
            this.routerService.push(UrlsService.reporting());
        }

        this.useHistoryReports = !this.useHistoryReports;
    };

    public setInitialParamsFromQuery = async (query: string, id?: string) => {
        if (id) {
            if (!this.historyReportsManager.selectedReport || this.historyReportsManager.selectedReport._id !== id) {
                this.isLoading = true;
                if (this.historyReportsColumnsManager.items.data.length > 0) {
                    this.findItemAndSetSelectedReport(id);
                } else {
                    this.historyReportsColumnsManager.items.hooks.fetchCompleted.tap(() => this.findItemAndSetSelectedReport(id));
                }
            }
        } else if (query) {
            await this.reportingParamsManager.setInitialParams(query);
        } else {
            this.cleanResults();
            this.historyReportsManager.clearSelectedReport();
        }
    };

    public findItemAndSetSelectedReport = async (id: string) => {
        let selectedItem = this.historyReportsColumnsManager.items.data.find(
            (item) => item._id === id
        );
        if (!selectedItem) {
            const item = await this.historyReportsColumnsManager.getById(id);
            console.log(item);
            item && (selectedItem = item);
        }
        if (selectedItem.status === ReportHistoryStatus.Finished) {
            this.historyReportsManager.getReportFromReportingHistoryUrls(
                selectedItem,
                HistoryReportGetUrlAction.Reuse
            );
        }
    };

    @computed
    public get maskItemsTotal() {
        const totalByField = this.maskItems.reduce((acc, item) => {
            item.fields.forEach((field) => {
                if (!acc[field.key]) {
                    acc[field.key] = 0;
                }
                if (field.value) {
                    acc[field.key] += field.value;
                }
            });

            return acc;
        }, {});

        Object.keys(totalByField).forEach((key) => {
            const fieldFromContext = this.reportingMetaDataManager.getFieldByValue(
                key,
                this.reportingParamsManager.params.verticalType
            );

            if (!fieldFromContext) {
                return;
            }
            const formula = fieldFromContext.formula;

            switch (fieldFromContext.type) {
                case ReportingFieldType.SUMABLE:
                    break;
                case ReportingFieldType.AVERAGABLE:
                    totalByField[key].value = totalByField[key] / this.maskItems.length;
                    break;
                case ReportingFieldType.CALCULATED:
                    totalByField[key] = formula ? formula(totalByField) : totalByField[key];
                    break;
            }
        });

        Object.keys(totalByField).forEach((key) => {
            const fieldFromContext = this.reportingMetaDataManager.getFieldByValue(
                key,
                this.reportingParamsManager.params.verticalType
            );
            const format = fieldFromContext?.format || defaultFieldFormat;
            totalByField[key] = format && format(totalByField[key]);
        });

        return totalByField;
    }

    public onChangeDynamicFormula = (value: string, key: string) => {
        this.editDynamicFormula[key] = value;
    };

    public toggleDynamicFormulaModalOpen = () => {
        this.dynamicFormulaModalOpen = !this.dynamicFormulaModalOpen;
    }

    public createDynamicFormula = () => {
        const id = guid();

        const formulaFunction = compileFieldFormula(this.editDynamicFormula.formula.toLowerCase().replaceAll(" ", ""))

        const reportingEntry: any = {
            type: "formula",
            label: this.editDynamicFormula.name,
            formulaFunction,
            formula: this.editDynamicFormula.formula,
            isGroup: false,
            name: this.editDynamicFormula.name,
            isRequested: true,
            id: this.editDynamicFormula.id || id,
            value: this.editDynamicFormula.id || id,
            key: this.editDynamicFormula.id || id
        };

        if (!this.editDynamicFormula.id) {
            this.reportingBuildManager.requestedParams.fields.push(reportingEntry);
            this.reportingParamsManager.params.fields.push(reportingEntry);
            this.results.result.forEach((row) => {
                const dto = row.fields.reduce((acc, currField: any) => {
                    acc[currField.name.toLowerCase().replaceAll(" ", "")] = currField.value;
                    return acc;
                }, {});
                const value = reportingEntry.formulaFunction(dto);
                const dynamicField = {...reportingEntry, value, displayValue: value.toLocaleString()};
                row.indexed[reportingEntry.label] = dynamicField;
                row.fields.push(dynamicField);
            });
            this.handleSetColumns()
        }
    };

    @computed
    public get dynamicFormulaSuggestions() {
        const suggestions = []
        const fields = this.maskItems?.[0]?.fields || [];

        for (let i = 0; i < fields.length; i++) {
            const fieldA = fields[i];
            const fieldB = fields[i + 1];
            if (fieldA && fieldB) {
                suggestions.push(...[
                    `${fieldA.name} / ${fieldB.name}`,
                    `${fieldB.name} / ${fieldA.name}`,
                    `${fieldA.name} / ${fieldB.name} * 100`,
                    `${fieldB.name} / ${fieldA.name} * 100`,
                    `${fieldA.name} * ${fieldB.name}`,
                    `${fieldA.name} - ${fieldB.name}`,
                    `${fieldB.name} - ${fieldA.name}`,
                    `${fieldA.name} + ${fieldB.name}`,
                    `(${fieldA.name} + ${fieldB.name}) / 2`,
                    `${fieldA.name} / (${fieldA.name} + ${fieldB.name})`,
                    `${fieldB.name} / (${fieldA.name} + ${fieldB.name})`,
                    `${fieldA.name} * (${fieldA.name} - ${fieldB.name})`,
                    `${fieldB.name} * (${fieldB.name} - ${fieldA.name})`,
                    `(${fieldA.name} - ${fieldB.name}) / ${fieldB.name}`,
                    `(${fieldB.name} - ${fieldA.name}) / ${fieldA.name}`,
                ]);
            }
        }
        return suggestions
    }


}
