import {observer} from 'mobx-react';
import React, {useCallback, useEffect} from 'react';
import theme from './theme.module.scss';
import {Table} from '@vidazoo/ui-kit';
import useChangeLogsModel from '../../common/hooks/useChangeLogsModel';
import ChangeLogsRow from '../changeLogRow/ChangeLogsRow';
import ChangeLogsTableHeader from '../changeLogsTableHeader/ChangeLogsTableHeader';

const ROW_HEIGHT = 45;
const LOADING_ROW_HEIGHT = 24;
const LOADING_ITEMS_COUNT = () => Math.round(window.innerHeight / LOADING_ROW_HEIGHT);

function ChangeLogsList(): JSX.Element {
    const model = useChangeLogsModel();

    useEffect(() => {
        model.resetFilter();
    }, []);

    const renderTableRow = useCallback((itemIndex: number, viewportIndex: number, style: React.CSSProperties) => {
        const item = model.maskItems?.at(itemIndex) ?? null
        return (
            <ChangeLogsRow
                key={item?.id ?? itemIndex}
                itemIndex={itemIndex}
                viewportIndex={viewportIndex}
                style={style}/>
        );
    }, [model.maskItems, model, model.columns]);

    const renderTableHeader = useCallback(() => {
        return (
            <ChangeLogsTableHeader/>
        );
    }, [model]);

    return (
        <Table
            className={theme.table}
            itemsCount={(model.items.isLoading) ? LOADING_ITEMS_COUNT() : model.maskItems.length}
            rowHeight={ROW_HEIGHT}
            renderRow={renderTableRow}
            renderHeader={renderTableHeader}
            columnsManager={model}
            overscanItems={5}
        />
    );
}

export default observer(ChangeLogsList);
