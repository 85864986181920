import {init, inject} from '@injex/core';
import IDashboard from '../../../interfaces/IDashboard';
import ApiManager from '../../../../../common/api/ApiManager.mdl';
import {DataUpdaterQuery} from '../../../../../common/models/DataUpdaterQuery';
import {AlertsManager} from '../../../../../common/alerts/managers/alertsManager.mdl';
import ExportToCsvManager from '../../../../../common/managers/ExportToCsvManager.mdl';
import {makeObservable, observable} from 'mobx';
import {ChartHeights} from '../../../../../common/enums/ChartHeights';
import EditChartsManager from '../../../../../common/managers/EditChartsManager.mdl';
import ReportingMetaDataManager from '../../../../../common/managers/ReportingMetaDataManager.mdl';
import ChartsManager from '../../../../../common/managers/ChartsManager.mdl';
import {RouterService} from "../../../../../common/router/services/routerService.mdl";

export default abstract class BaseDashboardModel {

    @inject() public apiManager: ApiManager;
    @inject() public alertsManager: AlertsManager;
    @inject() public editChartsManager: EditChartsManager;
    @inject() public reportingMetaDataManager: ReportingMetaDataManager;
    @inject() public exportToCsvManager: ExportToCsvManager;
    @inject() private chartsManager: ChartsManager;
    @inject() public routerService: RouterService;

    @observable public reportParams: { filters: any[], constraints: any[] };

    protected abstract fetchItem(id: string): Promise<IDashboard>

    public item: DataUpdaterQuery<IDashboard, [id: string]>;

    constructor() {
        makeObservable(this);
        this.item = new DataUpdaterQuery(this.fetchItem.bind(this), {defaultValue: this._defaultValue});
    }

    public onAddLayout = async (chartId: string) => {
        const chartType = this.chartsManager.items.byId[chartId].type;
        this.item.data.layouts.lg.push({i: chartId, x: 0, y: Infinity, w: 12, h: ChartHeights[chartType]});
        const chartModel = await this.editChartsManager.initChartModel(chartId);
        await chartModel.getReport();
    };

    public reportByChart(chartId: string) {
        const modelByChart = this.editChartsManager.getModel(chartId);
        if (modelByChart && modelByChart.chartReportByTimePreset) {
            return modelByChart.chartReportByTimePreset[this.reportingMetaDataManager.timePreset];
        }
    }

    private get _defaultValue(): IDashboard {
        return {
            _id: '',
            created: 0,
            date: 0,
            isActive: true,
            isDeleted: false,
            network: null,
            updated: 0,
            user: null,
            name: "",
            description: "",
            layouts: {lg: []}
        }
    }
}
