import React from 'react';
import {Button, FormInput, Modal} from "@vidazoo/ui-kit";
import useHistoryReportsManager from "../../../common/hooks/useHistoryReportsManager";
import selfTheme from '../theme.module.scss';
import theme from "../../../../../common/styles/_baseForm.module.scss";
import {normalizeEventValue} from "../../../../../common/utils/utils";
import useHistoryReportsColumnsManager from "../../../common/hooks/useHistoryReportsColumnsManager";
import {observer} from "mobx-react";

function EditHistoryReportModal() {
    const {editHistoryReport, cancelEditHistoryReport, submit, changeHistoryReport} = useHistoryReportsColumnsManager();

    const onEditHistoryReport = (e) => {
        const value = normalizeEventValue(e);
        changeHistoryReport({...editHistoryReport, description: value})
    }

    return (
        <Modal exitOnClickOutside onClose={cancelEditHistoryReport} isOpen={!!editHistoryReport}>
            {editHistoryReport ? (
                <div className={selfTheme.editHistoryModal}>
                    <FormInput style={{marginBottom: 10, minWidth: 400}}
                               className={theme.input}
                               value={editHistoryReport.description}
                               name="description"
                               placeholder="Description"
                               onChange={onEditHistoryReport}
                               hideValidationMessage/>
                    <div className={selfTheme.actions}>
                        <Button type="button" uppercase gray medium style={{marginRight: 10}}
                                onClick={cancelEditHistoryReport}>Cancel</Button>
                        <Button type="button" uppercase green medium
                                onClick={submit}>Update</Button>
                    </div>
                    <pre>
							{JSON.stringify(editHistoryReport, null, 2)}
						</pre>
                </div>
            ) : null}
        </Modal>
    )
}

export default observer(EditHistoryReportModal);
