import {observer} from "mobx-react";
import {FormSelect, Chips} from "@vidazoo/ui-kit";
import * as theme from "../theme.scss";
import * as React from "react";
import {IChangeLogFilter} from "../../../common/interfaces/IChangeLogFilter";
import useChangeLogsModel from '../../../common/hooks/useChangeLogsModel';
import {FilterOperators} from '../../../../../common/enums/FilterOperators';
import useAccountsMetaDataManager from '../../../../../common/hooks/useAccountsMetaDataManager';

export interface IAccountFilterProps extends React.InputHTMLAttributes<HTMLInputElement> {
    filter: IChangeLogFilter;
}

function AccountFilter({filter}: IAccountFilterProps): JSX.Element {

    const model = useChangeLogsModel();
    const accountsMetaDataManager = useAccountsMetaDataManager();

    const onChangeFilterParam = (e) => {
        const {name, value} = e.target;
        model.setFilterParam(filter, name, value);
    }

    const addFilterAccount = (value: string) => {
        model.addFilterParamArray(filter, "value", value);
    }

    const onDeleteValue = (value: string, label: string) => {
        model.removeFilterParamArray(filter, "value", value);
    }

    return (
        <>
            <FormSelect
                items={[FilterOperators.isAnyOf, FilterOperators.isNotAnyOf]}
                maxItems={5}
                itemValueKey="value"
                itemLabelKey="label"
                value={filter.operator || FilterOperators.isAnyOf}
                name="operator"
                required
                hideValidationMessage
                onChange={onChangeFilterParam}
                searchable
            />
            <Chips
                allowOrder={false}
                leaveOpenAfterSelect
                allowCreate={false}
                selectValueOnBlur={false}
                dataSource={accountsMetaDataManager.accounts}
                itemLabelKey="username"
                itemValueKey="_id"
                chips={filter.value}
                maxItems={5}
                onSelect={addFilterAccount}
                onDelete={onDeleteValue}
                isLoading={(!accountsMetaDataManager.accounts || accountsMetaDataManager.accounts.length === 0)}
            />
        </>
    );
}

export default observer(AccountFilter);
