import * as React from "react";
import theme from "./theme.module.scss";
import {extractReportingFieldValue} from "../../../common/utils/extractReportingFieldValue";
import {ReportFieldValueType} from "../../../common/enums/ReportFieldValueType";
import clsx from "clsx";
import {ContextMenu, isDefined, Label, Tooltip} from "@vidazoo/ui-kit";
import MemoryClipboard from "../../../../../common/utils/MemoryClipboard";
import QuickReportAction from "../../quickReportAction/QuickReportAction";
import {IReportingPreparedField} from "../../../common/interfaces/IReportingPreparedResults";

export interface IReportFieldValueCellProps {
    field: IReportingPreparedField;
    onQuickConstraint?: (field: IReportingPreparedField) => void;
}

export interface IReportFieldValueCellState {

}

export default React.memo((props: IReportFieldValueCellProps): JSX.Element => {

    const {field, onQuickConstraint} = props;

    const displayValue = extractReportingFieldValue(field, "displayValue", ReportFieldValueType.ORIGINAL);

    let deltaPct, tooltip;

    if (field && field.value && field.value.length) {

        const deltaPctValue = parseInt(extractReportingFieldValue(field, "value", ReportFieldValueType.DELTA_PCT) * 100 as any, 10)
            , deltaValue = extractReportingFieldValue(field, "displayValue", ReportFieldValueType.DELTA)
            , value = extractReportingFieldValue(field, "displayValue", ReportFieldValueType.ORIGINAL)
            , compared = extractReportingFieldValue(field, "displayValue", ReportFieldValueType.COMPARE)
            , sign = deltaPctValue > 0 ? "+" : "";

        deltaPct = <span className={clsx(theme.trend, {
            [theme.up]: deltaPctValue > 0,
            [theme.down]: deltaPctValue < 0
        })}>({sign}{deltaPctValue}%)</span>;

        tooltip = (
            <div className={theme.popover}>
                <div className={theme.grid}>
                    <div className={theme.item}>
                        <Label weight="bold" className={theme.title} size={10} uppercase>Current</Label>
                        <div className={theme.value}>{value}</div>
                    </div>
                    <div className={theme.item}>
                        <Label weight="bold" className={theme.title} size={10} uppercase>Compared To</Label>
                        <div className={theme.value}>{compared}</div>
                    </div>
                    <div className={theme.item}>
                        <Label weight="bold" className={theme.title} size={10} uppercase>Change</Label>
                        <div className={theme.value}>{deltaValue}</div>
                    </div>
                    <div className={theme.item}>
                        <Label weight="bold" className={theme.title} size={10} uppercase>Change (%)</Label>
                        <div className={theme.value}>{deltaPct}</div>
                    </div>
                </div>
            </div>
        );
    }

    const cellToRender = onQuickConstraint ? (<QuickReportAction iconName="icon-filter" item={field}
                                             onClick={onQuickConstraint}>
                                        {displayValue} {deltaPct}</QuickReportAction>) : `${displayValue} ${isDefined(deltaPct) ? deltaPct : ""}`;

    const valueRender = tooltip
        ? <Tooltip title={tooltip}><span>{cellToRender}</span></Tooltip>
        : <span>{cellToRender}</span>;

    return (
        <div className={theme.cell}>
            {valueRender}
        </div>
    );
});
