import BaseNeededGroup from "../BaseNeededGroup";
import {OpenRtbNeededGroups} from "../NeededGroups";
import {OpenRtbReportingFilterType} from "../ReportingFilterType";
import {ReportVerticalType} from '../../../enums/ReportVerticalType';
import {alias} from '@injex/core';

@alias('OpenRtbReportingFilter')
export default class ExchangeFilter extends BaseNeededGroup {

	public type = OpenRtbReportingFilterType.Exchange;

	constructor() {
		super(ReportVerticalType.OPEN_RTB, OpenRtbNeededGroups.Exchanges);
	}
}
