import {computed, makeObservable, observable} from "mobx";
import Bluebird from "bluebird";
import {define, init, inject, singleton} from '@injex/core';
import {ChartEditModel} from '../../modules/charts/item/edit/model/ChartEditModel.mdl';
import CloudManager from './CloudManager.mdl';
import {ChartType} from '../enums/ChartType';
import ChartsManager from './ChartsManager.mdl';
import {DataQueryFetcher, DataQueryOptions} from '../models/DataQuery';

@define()
@singleton()
export default class EditChartsManager {

    @observable public chartModels: { [index: string]: ChartEditModel };
    @inject() public cloudManager: CloudManager;
    @inject() public chartsManager: ChartsManager;
    @inject(ChartEditModel) public createChartEditModel: () => ChartEditModel;

    constructor() {
        this.chartModels = {};
        makeObservable(this);
    }

    @init()
    protected initialize() {
        // this.chartsManager.items.hooks.fetchCompleted.tapAsync(this.initChartModels, null, this);
    }

    public async initChartModels() {
        if (!this.chartModels) {
            this.chartModels = {};
        }
        await Bluebird.map(this.defaultCharts.slice(0, 2), async (chart) => {
            await this.initChartModel(chart._id);
        }, {concurrency: 1});
    }

    @computed get defaultCharts() {
        const defaultCharts = this.chartsManager.items.data.filter((chart) => chart.isDefault && chart.type === ChartType.DATA_TABLE);
        return defaultCharts.sort((a, b) => {
            const countA = this.cloudManager.topCharts[a._id] ? this.cloudManager.topCharts[a._id].count || 0 : 0;
            const countB = this.cloudManager.topCharts[b._id] ? this.cloudManager.topCharts[b._id].count || 0 : 0;
            return countB - countA;
        });
    }

    public async initChartModel(chartId: string): Promise<ChartEditModel> {
        if (!this.chartModels) {
            this.chartModels = {};
        }
        if (!this.chartModels[chartId]) {
            const model = this.createChartEditModel();
            await model.item.invalidate().fetch(chartId);
            this.setModel(model, chartId);
        }
        return this.chartModels[chartId];
    }

    public setModel(model: ChartEditModel, chartId: string) {
        if (!this.chartModels) {
            this.chartModels = {};
        }
        this.chartModels[chartId] = model;
    }

    public getModel(chartId: string): ChartEditModel {
        if (chartId && this.chartModels[chartId]) {
            return this.chartModels[chartId];
        }
    }
}
