import Hook from '../../../common/utils/Hook';

export type ReportsHooks = {
    onReportReady: Hook;
    onReportError: Hook;
    onLoadingReport: Hook;
};

export abstract class BaseReportsManager {

    public hooks: ReportsHooks;

    protected abstract get(params: any): Promise<void>

    constructor() {
        this.hooks = {
            onReportReady: new Hook(),
            onReportError: new Hook(),
            onLoadingReport: new Hook()
        };
    }

}
