import * as React from 'react';
import theme from './theme.module.scss';
import useSessionManager from '../../../../../session/hooks/useSessionManager';
import {observer} from "mobx-react";
import useGravatar from '../../../../../../common/hooks/useGravatar';
import NavGroup from '../nav-group/NavGroup';
import {MaterialIcon} from '@vidazoo/ui-kit';
import {useCallback} from 'react';
import {useMainMenuContext} from "../../contexts/MainMenuContext";

const GROUP = 'USER_MENU';

function UserMenu() {
    const sessionManager = useSessionManager();
    const gravatar = useGravatar(sessionManager.email, 96);
    const {selectedGroup, setSelectedGroup, profileModel} = useMainMenuContext();
    const onLogoutClick = useCallback(() => sessionManager.logout(), []);

    return (
        <div onMouseEnter={() => setSelectedGroup(GROUP)} onMouseLeave={() => setSelectedGroup(null)}
             className={theme.userMenu}>
            <div className="rounded-full overflow-hidden w-16 h-16 border-white border-2">
                <img src={gravatar} alt={sessionManager.fullName}/>
            </div>
            {selectedGroup === GROUP ? (
                <div className={theme.drawer}>
                    <NavGroup>
                        <div className="flex items-center p-6 pr-10">
                            <div className="rounded-full overflow-hidden w-20 h-20 border-white border-2">
                                <img src={gravatar} alt={sessionManager.fullName}/>
                            </div>
                            <div className="pl-5">
                                <div className={theme.name}>{sessionManager.fullName}</div>
                                <div className={theme.email}>{sessionManager.email}</div>
                            </div>
                        </div>
                        <div className="m-6 flex items-center" onClick={profileModel.onOpenClick}>
                            <MaterialIcon className="text-gray-500 pr-3" iconName="settings"/>
                            My Profile
                        </div>
                        <div className="m-6 flex items-center" onClick={onLogoutClick}>
                            <MaterialIcon className="text-gray-500 pr-3" iconName="logout"/>
                            Sign Out
                        </div>
                        <div className="p-5 flex justify-between items-center">
                            <a rel="noreferrer" className="uppercase text-blue-800 text-base"
                               href="https://vidazoo.gitbook.io/vidazoo-legal/terms-and-conditions" target="_blank">Terms
                                & Conditions</a>
                            <div>
                                |
                            </div>
                            <a rel="noreferrer" className="uppercase text-blue-800 text-base"
                               href="https://vidazoo.gitbook.io/vidazoo-legal/privacy-policy" target="_blank">Privacy
                                Policy</a>
                        </div>
                    </NavGroup>
                </div>
            ) : null}
        </div>
    );
}

export default observer(UserMenu);
