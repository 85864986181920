import {createContext, useContext, useMemo} from "react";
import {DashboardsListModel} from '../model/DashboardsListModel.mdl';
import {useModuleFactory} from '@injex/react-plugin';
import {TableRowSelectionHook, useTableRowSelection} from '@vidazoo/ui-kit';

export interface IDashboardsListContext {
    model: DashboardsListModel;
    tableRowSelectionHook: TableRowSelectionHook;
    allItemIds: string[];
}

export interface IDashboardsListWithContext {
    children: any,
}

export const DashboardsListContext = createContext<IDashboardsListContext>({
    model: null,
    tableRowSelectionHook: null,
    allItemIds: null
})

export function DashboardsListWithContext({children}: IDashboardsListWithContext) {

    const model = useModuleFactory<DashboardsListModel>('dashboardsListModel');
    const allItemIds = useMemo<string[]>(() => model.maskItems.map((item) => item._id), [model.maskItems]);
    const tableRowSelectionHook = useTableRowSelection(allItemIds, false);

    const contextValue: IDashboardsListContext = useMemo(() => ({
        model,
        tableRowSelectionHook,
        allItemIds
    }), [model, tableRowSelectionHook, allItemIds]);

    return (
        <DashboardsListContext.Provider value={contextValue}>
            {children}
        </DashboardsListContext.Provider>
    )
}

export function useDashboardsListContext(): IDashboardsListContext {
    return useContext<IDashboardsListContext>(DashboardsListContext);
}
