import * as React from 'react';
import {Route} from 'react-router-dom';
import UrlsService from '../../../../common/router/services/urlsService.mdl';
import ChangeLogs from '../../view/ChangeLogs';

const ChangeLogsRouting = (
    <React.Fragment>
        <Route path={UrlsService.changeLogs()} element={<ChangeLogs/>}/>
    </React.Fragment>
);

export default ChangeLogsRouting
