import BaseFilter from "../BaseFilter";
import {PlatformReportingFilterType} from "../ReportingFilterType";
import devices from '../../../enums/Devices';
import {alias, define, singleton} from '@injex/core';

@define()
@singleton()
@alias('PlatformReportingFilter')
export default class DeviceFilter extends BaseFilter {

	public type = PlatformReportingFilterType.Device;

	constructor() {
		super();

		this.items = devices;
		this.valueKey = "key";
		this.labelKey = "label";
		this.allowNew = false;
	}
}
