import {observable, computed, makeObservable} from "mobx";
import {IPublisher} from '../interfaces/IPublisher';
import {INetwork} from '../interfaces/INetwork';
import IUser from '../interfaces/IUser';
import {init} from '@injex/core';
import {ISession} from '../../modules/session/interfaces/IAccountsSDK';

export default class BaseUserManager {

    public _id: string;
    public date: number;
    public created: number;
    public updated: number;
    public isDeleted: boolean;
    public avatar: string;
    @observable public username: string;
    @observable public publisher: IPublisher;
    @observable public network: INetwork;
    @observable public roles: string[];
    @observable public firstName: string;
    @observable public lastName: string;
    @observable public email: string;
    @observable public isActive: boolean;
    @observable public credentials: any;
    @observable public children: any[];
    @observable public features: string[];
    @observable public organization: any;
    @observable public scopes: any[];

    constructor() {
        makeObservable(this);
    }

    public setData(user: IUser, session: ISession) {
        this._id = user._id;
        this.date = user.date;
        this.username = user.username;
        this.network = user.network;
        this.roles = user.roles;
        this.firstName = user.firstName;
        this.lastName = user.lastName;
        this.email = user.email;
        this.isActive = user.isActive;
        this.credentials = user.credentials;
        this.publisher = user.publisher;
        this.children = user.children || [];
        this.features = user.features || [];
        this.organization = user.organization;
        this.scopes = session?.user?.scopes || [];
    }

    @init()
    public reset() {
        this._id = null;
        this.date = null;
        this.username = "";
        this.network = null;
        this.roles = ["user"];
        this.firstName = "";
        this.lastName = "";
        this.email = "";
        this.isActive = true;
        this.credentials = [];
        this.publisher = null;
        this.children = [];
        this.features = [];
        this.organization = {};
    }

    public updateParam(key: string, value: any) {
        if (this[key] !== undefined) {
            this[key] = value;
        }
    }

    public updateParams(partial: Partial<IUser>) {
        for (const key in partial) {
            this.updateParam(key, partial[key]);
        }
    }

    @computed get fullName(): string {
        return `${this.firstName} ${this.lastName}`;
    }

    public toJSON() {
        return {
            id: this._id,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            roles: this.roles,
            features: this.features
        };
    }
}
