import {observer} from 'mobx-react';
import {Button, Form, Label, SliderPanel} from '@vidazoo/ui-kit';
import theme from "../../../../../common/styles/_baseForm.module.scss";
import useRouterService from '../../../../../common/router/hooks/useRouterService';
import UrlsService from '../../../../../common/router/services/urlsService.mdl';
import DetailsForm from '../../common/components/details-form/DetailsForm';
import ChartsForm from '../../common/components/charts-form/ChartsForm';
import {useModuleFactory} from '@injex/react-plugin';
import {DashboardCreateModel} from '../model/DashboardCreateModel.mdl';

export default observer(function DashboardCreateForm() {
    const model = useModuleFactory<DashboardCreateModel>('dashboardCreateModel');
    const routerService = useRouterService();

    const onCancelClick = () => {
        routerService.push(UrlsService.dashboards());
    };

    return (
        <Form className={theme.form} onSubmit={model.submit}>
            <DetailsForm model={model}/>
            {(!model.item?.data?.layouts?.lg || model.item?.data?.layouts?.lg.length === 0) ? (
                <Label className={theme.label} uppercase={false} size={13}>No charts yet..</Label>
            ) : (
                <ChartsForm model={model} isEditMode={true}/>
            )}

            <SliderPanel>
                <div className={theme.actions} style={{marginTop: 30}}>
                    <Button type="button" medium gray uppercase onClick={onCancelClick}>Cancel</Button>
                    <Button type="submit" medium blue uppercase>Create</Button>
                </div>
            </SliderPanel>
        </Form>
    );
});
