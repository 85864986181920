import {FormSelect} from '@vidazoo/ui-kit';
import useUserManager from '../../../../common/hooks/useUserManager';
import {observer} from 'mobx-react';
import {ReportingDbType, AdminReportingDbTypes, UsersReportingDbTypes} from '../../common/enums/ReportingDbType'
import {IBiReportingParams} from "../../common/interfaces/IReportingParams";

export interface IReportingDBsProps {
    dbType?: ReportingDbType;
    setParam: (key: keyof IBiReportingParams, value: any) => void;

}

function ReportingDBs(props: IReportingDBsProps) {
    const userManager = useUserManager();
    const {
        dbType,
        setParam
    } = props;

    const onChangeDbTypes = (e) => {
        const value = e.target.value;
        setParam("dbType", value);
    };


    return (
        <FormSelect
            items={userManager.isUserHasHighDbPermission() ? AdminReportingDbTypes : UsersReportingDbTypes}
            onChange={onChangeDbTypes}
            searchable
            name="dbType"
            placeholder="DB Type"
            value={dbType}/>
    )
}

export default observer(ReportingDBs);
