import {AlertAccent} from '../alerts/common/enums';
import {inject} from '@injex/core';
import ApiManager from '../api/ApiManager.mdl';
import {AlertsManager} from '../alerts/managers/alertsManager.mdl';
import {DataUpdaterQuery} from '../models/DataUpdaterQuery';
import {ColumnsManager} from '@vidazoo/ui-kit';
import IDashboard from '../../modules/dashboards/interfaces/IDashboard';

export default abstract class BaseItemModel<T, Args extends Array<any> = any[]> extends ColumnsManager<T> {

    @inject() public apiManager: ApiManager;
    @inject() public alertsManager: AlertsManager;

    protected abstract itemType: string;

    protected abstract fetchItem(id: string): Promise<T>;

    protected abstract get defaultValue(): T;

    protected abstract delete(): Promise<void>

    public item: DataUpdaterQuery<T, [id: string]>;

    constructor() {
        super()
        this.init();
    }

    public init() {
        this.item = new DataUpdaterQuery(this.fetchItem.bind(this), {defaultValue: this.defaultValue});
    }

    public onDelete = () => {
        this.alertsManager.setConfirmAlert({
            content: `Are you sure you want to delete this ${this.itemType}?`,
            onConfirm: this.delete
        }, AlertAccent.Natural);
    };

    public onSubmitSuccess() {
        this.alertsManager.setBasicAlert({content: `Operation Complete, ${this.itemType} updated successfully`}, AlertAccent.Success);
    }

    public onCreateSuccess() {
        this.alertsManager.setBasicAlert({content: `Operation Complete, ${this.itemType} created successfully`}, AlertAccent.Success);
    }

    public onUpdatedSuccess() {
        this.alertsManager.setBasicAlert({content: `Operation Complete, ${this.itemType} updated successfully`}, AlertAccent.Success);
    }

    public onSubmitFailed(err: any) {
        this.alertsManager.setBasicAlert({content: `Operation Error, Failed to update ${this.itemType}`}, AlertAccent.Error);
    }

    public onDeleteSuccess() {
        this.alertsManager.setBasicAlert({content: `Operation Complete, ${this.itemType} deleted successfully`}, AlertAccent.Success);
    }

    public onDeleteError(err: any) {
        this.alertsManager.setBasicAlert({content: `Operation Failed, Failed to delete ${this.itemType}`}, AlertAccent.Error);
    }

}
