import BaseFilter from "../BaseFilter";
import {PlatformReportingFilterType} from "../ReportingFilterType";
import {alias, define, singleton} from '@injex/core';

@define()
@singleton()
@alias('PlatformReportingFilter')
export default class PublisherIdFilter extends BaseFilter {

	public type = PlatformReportingFilterType.PublisherId;

	constructor() {
		super();
	}
}
