import BaseFilter from "../BaseFilter";
import {PlatformReportingFilterType} from "../ReportingFilterType";
import {alias, define, singleton} from '@injex/core';

export const streams = [
	{name: "Mobile JS", value: "mobile_js"},
	{name: "Mobile", value: "mobile"},
	{name: "IMA", value: "ima"},
	{name: "IMA SWF", value: "ima_swf"},
	{name: "SWF", value: "swf"},
	{name: "JS", value: "js"},
	{name: "Video", value: "video"}
];

@define()
@singleton()
@alias('PlatformReportingFilter')
export default class StreamFilter extends BaseFilter {

	public type = PlatformReportingFilterType.Stream;

	constructor() {
		super();

		this.items = streams;
		this.valueKey = "value";
		this.labelKey = "name";
		this.allowNew = false;
	}
}
