import BaseFilter from "../BaseFilter";
import {PlatformReportingFilterType} from "../ReportingFilterType";
import countries from '../../../enums/Countries';
import {alias, define, singleton} from '@injex/core';

@define()
@singleton()
@alias('PlatformReportingFilter')
export default class CountryFilter extends BaseFilter {

	public type = PlatformReportingFilterType.Country;

	constructor() {
		super();

		this.items = countries;
		this.labelKey = "name";
		this.valueKey = "code";
		this.allowNew = false;
	}
}
