import {observer} from 'mobx-react';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import PageTitle from '../../../../../common/components/page-title/PageTitle';
import clsx from 'clsx';
import theme from '../view/theme.module.scss';
import {useNotificationSettingsListContext} from "../context/SettingsListContext";

function SettingsHeader(): JSX.Element {
    const navigate = useNavigate();
    const {model} = useNotificationSettingsListContext();

    return (
        <div className={clsx("h-24 flex items-center justify-between pr-5 pl-5", theme.header)}>
            <PageTitle iconName="handshake" title="Notification Settings List"
                       itemsCount={model.items.isLoading ? "Loading..." : model.items.data.length}/>
        </div>
    );
}

export default observer(SettingsHeader);
