import {TableColumn} from '@vidazoo/ui-kit';
import {IUserNotificationSetting} from "../../../interfaces/IUserNotificationSetting";

export const UserNotificationSettingsTableColumns: TableColumn<IUserNotificationSetting>[] = [
    {
        title: 'Checkbox',
        key: 'checkbox',
    },
    {
        title: 'Name',
        key: 'name',
        defaultWidth: 300,
        options: {search: {searchKey: 'name'}, freeze: true, sort: {sortKey: 'name'}}
    },
    {
        title: 'Application',
        key: 'app',
        defaultWidth: 300,
        options: {search: {searchKey: 'app'}, freeze: true, sort: {sortKey: 'app'}}
    },
    {
        title: "Notification",
        key: "notification",
        defaultWidth: 200,
        options: {search: {searchKey: 'notification'}, sort: {sortKey: 'notification'}}
    }, {
        title: "Email",
        key: "email",
        defaultWidth: 200,
        options: {search: {searchKey: 'email'}, sort: {sortKey: 'email'}}
    }, {
        title: "SMS",
        key: "sms",
        defaultWidth: 200,
        options: {search: {searchKey: 'sms'}, sort: {sortKey: 'sms'}}
    }, {
        title: "Created",
        key: "created",
        defaultWidth: 200,
        options: {search: {searchKey: 'created'}, freeze: true, sort: {sortKey: 'created'}}
    },{
        title: "Last Sent",
        key: "lastSent",
        defaultWidth: 200,
        options: {search: {searchKey: 'lastSent'}, freeze: true, sort: {sortKey: 'lastSent'}}
    }, {
        title: "Actions",
        key: "actions",
    }
];
