
export const ChangeLogModelNames = [
    {value: "tags", label: "Tags"},
    {value: "players", label: "Players"},
    {value: "widgets", label: "Widgets"},
    {value: "videos", label: "Videos"},
    {value: "crawlers", label: "Crawlers"},
    {value: "scenarios", label: "Scenarios"},
    {value: "domainlists", label: "Lists"},
    {value: "widgetsettings", label: "Widget Settings"},
    {value: "demandpartners", label: "Demand Partners"},
    {value: "users", label: "Users"},
    {value: "platformsettings", label: "Platform Settings"},
    {value: "presetreports", label: "Preset Reports"},
    {value: "networks", label: "Networks"},
    {value: "dynamicmacros", label: "Dynamic Macros"},
    {value: "contextualplaylists", label: "Contextual Playlists"},
    {value: "publishers", label: "Publishers"},
    {value: "countrylists", label: "Country Lists"},
    {value: "schedulereports", label: "Schedule Reports"},
    {value: "connections", label: "Connections"},
    {value: "networksettings", label: "Network Settings"},
    {value: "abtestpolicies", label: "AB Test Policies"},
    {value: "exchanges", label: "Exchanges"},
];
