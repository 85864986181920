import {BaseSocket} from "../../../common/socket/baseSocket";
import {alias, define, init, singleton} from "@injex/core";

@define()
@singleton()
@alias('Disposable')
export default class UserNotificationsSocket extends BaseSocket {

    public name = "UserNotifications";

    public get getUri(): string {
        return this.env.userNotificationsAPI;
    }

    public startListeningUserNotificationsCreated(fn: (data: any) => void) {
        this.on('user_notifications_created', fn);
    }

    public startListeningUserNotificationsMarkAsSeen(fn: (data: any) => void) {
        this.on('user_notifications_mark_as_seen', fn);
    }

    public startListeningUserNotificationsUpdated(fn: (data: any) => void) {
        this.on('user_notifications_updated', fn);
    }

    public getNotificationsById(userId) {
        return this.request('get_notifications_by_id', {userId});
    }

    public markAsSeen(notificationId) {
        return this.request('user_notifications_mark_as_seen', {notificationId});
    }

    public markAllAsSeen(userId) {
        return this.request('user_notifications_mark_all_as_seen', {userId});
    }

    public getNotificationsSettingsById(userId) {
        return this.request('get_notifications_settings_by_id', {userId});
    }

    public updateSetting(id, data) {
        return this.request('update_notifications_setting', {id, data});
    }

    public deleteSetting(id) {
        return this.request('delete_setting', {id});
    }
}
