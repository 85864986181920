export enum AlertType {
    Basic = 'basic',
    Confirm = 'confirm',
    Prompt = 'prompt',
}

export enum AlertStatus {
    Info = 1,
    Success,
    Warning,
    Error,
}

export enum AlertAccent {
    Natural = 'natural',
    Info = 'info',
    Success = 'success',
    Warning = 'warning',
    Error = 'error',
}