import * as React from "react";
import {observer} from "mobx-react";
import theme from "./theme.module.scss";
import {Label} from '@vidazoo/ui-kit';
import {useScaleRelativeToParent} from "../../hooks/useScaleRelativeToParent";

export interface ISummaryProps {
    fields: any[];
}

const SummaryItem = ({value, label}) => (
    <div className={theme.item}>
        <div className={theme.value}>{value}</div>
        <Label className={theme.label} uppercase monserrat size={13} spacing={1.25}>{label}</Label>
    </div>
);

export default observer(function SummaryChart({fields}: ISummaryProps) {
    const element = React.useRef<HTMLDivElement>(null);
    useScaleRelativeToParent({element, buffer: 0.17});

    return (
        <div className={theme.summary} ref={element}>
            {fields && fields.map((field) => <SummaryItem key={field.name} value={field.displayValue}
                                                          label={field.name}/>)}
        </div>
    );
});
