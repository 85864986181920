import {define, singleton, inject} from '@injex/core';
import {SessionAPI} from '../../modules/session/api/session.mdl';
import {ReportingApi} from '../../modules/reporting/api/ReportingApi.mdl';
import {ChangeLogsApi} from '../../modules/change-logs/api/ChangeLogsApi.mdl';
import {ChartsApi} from '../../modules/charts/api/ChartsApi.mdl';
import {DashboardsApi} from '../../modules/dashboards/api/DashboardsApi.mdl';
import {ScheduleReportsApi} from '../../modules/schedule-reports/api/ScheduleReportsApi.mdl';
import {AccountsAPI} from '../../modules/accounts/api/accounts.mdl';
import {CloudApi} from './CloudApi.mdl';
import {ColumnsWidthApi} from './ColumnsWidthApi.mdl';
import {PresetReportsApi} from '../../modules/reporting/api/PresetReportsApi.mdl';
import {HistoryReportsApiMdl} from "../../modules/reporting/api/HistoryReportsApi.mdl";

@define()
@singleton()
export default class ApiManager {

    @inject(AccountsAPI) public accounts: AccountsAPI;
    @inject(SessionAPI) public session: SessionAPI;
    @inject(ChangeLogsApi) public changeLogs: ChangeLogsApi;
    @inject(ChartsApi) public charts: ChartsApi;
    @inject(DashboardsApi) public dashboards: DashboardsApi;
    @inject(ReportingApi) public reporting: ReportingApi;
    @inject(PresetReportsApi) public presetReports: PresetReportsApi;
    @inject(ScheduleReportsApi) public scheduleReports: ScheduleReportsApi;
    @inject(CloudApi) public cloud: CloudApi;
    @inject(ColumnsWidthApi) public columnsWidth: ColumnsWidthApi;
    @inject(HistoryReportsApiMdl) public historyReports: HistoryReportsApiMdl;

}
