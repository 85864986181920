import {inject, init} from '@injex/core';
import EditChartsManager from '../../../../../common/managers/EditChartsManager.mdl';
import ReportingMetaDataManager from '../../../../../common/managers/ReportingMetaDataManager.mdl';
import ExportToCsvManager from '../../../../../common/managers/ExportToCsvManager.mdl';
import BaseItemModel from '../../../../../common/base/BaseItemModel';
import IChart from '../../../interfaces/IChart';
import {makeObservable, observable, toJS} from 'mobx';
import LocalStorageManager from '../../../../../common/managers/LocalStorageManager.mdl';
import IReportingFilter from '../../../../reporting/common/interfaces/IReportingFilter';
import {
    ReportingContextMenuType,
    ReportingContextMenuTypeValues
} from '../../../../reporting/common/enums/ReportingContextMenuType';
import {guid} from '@vidazoo/ui-kit';
import IReportingConstraint from '../../../../reporting/common/interfaces/IReportingConstraint';
import * as _ from "lodash";
import IReportingParams from '../../../../reporting/common/interfaces/IReportingParams';
import ReportingFiltersManager from '../../../../../common/reporting-filters/managers/ReportingFiltersManager.mdl';
import {AlertAccent} from '../../../../../common/alerts/common/enums';
import {ReportVerticalType} from '../../../../../common/enums/ReportVerticalType';
import {ChartType} from '../../../../../common/enums/ChartType';

export default abstract class BaseChartModel extends BaseItemModel<IChart> {

    @observable public chartReportByTimePreset: { [index: string]: { results: any, isLoading: boolean } };
    @observable public timezone: string;

    @inject() public editChartsManager: EditChartsManager;
    @inject() public reportingMetaDataManager: ReportingMetaDataManager;
    @inject() public exportToCsvManager: ExportToCsvManager;
    @inject() public localStorageManager: LocalStorageManager;
    @inject() public reportingFiltersManager: ReportingFiltersManager;

    protected itemType: string = "chart";

    constructor() {
        super();
        this.chartReportByTimePreset = observable({}, {deep: true}) as any;
        makeObservable(this);
    }

    @init()
    protected initialize() {
        this.reset();
    }

    public reset() {
        this.timezone = this.localStorageManager.getGlobalTimezone() || "Etc/GMT+0";
    }

    public get defaultValue(): IChart {
        return {
            _id: "",
            created: 0,
            date: 0,
            description: '',
            isActive: false,
            isDeleted: false,
            name: '',
            network: undefined,
            preparedReportParams: undefined,
            reportParams: {
                fields: [],
                groups: [],
                filters: [],
                constraints: [],
                verticalType: ReportVerticalType.PLATFORM,
                ids: [],
            },
            type: ChartType.DATA_TABLE,
            updated: 0,
            user: undefined
        }
    }

    public getTitle() {
        return this.item.isLoading ? "Loading..." : this.item.error ? "Error" : this.item.data.name;
    }

    public onClearAllParams = (key: ReportingContextMenuType) => {
        if (ReportingContextMenuTypeValues.includes(key)) {
            this.item.data.reportParams[key] = [];
        }
    };

    public addConstraint = (values?: Partial<IReportingConstraint>) => {
        this.item.data.reportParams.constraints = this.item.data.reportParams.constraints.concat({
            id: guid(),
            name: "",
            op: "",
            value: "",
            ...values
        });

        return this.item.data.reportParams.constraints[this.item.data.reportParams.constraints.length - 1];
    };

    public setConstraintParam = (constraint: IReportingConstraint, key: string, value: any) => {
        constraint[key] = value;
    };

    public removeFromArrayInObjByKey = (obj: string, key: string, value: string, objKey: string) => {
        this.item.data[obj][key] = this.item.data[obj][key].filter((item: {
            [x: string]: string;
        }) => (item[objKey] !== value));
    };

    public addToArrayInObj = (obj: string, key: string, item: any) => {
        this.item.data[obj][key] = [...this.item.data[obj][key], item];
    }

    public setParam = (key: keyof IReportingParams, value: any) => {
        this.item.data.reportParams[key] = value;
    };

    public removeReportParam = (key: string, value: string) => {
        this.item.data.reportParams[key] = this.item.data.reportParams[key].filter((paramValue: string) => paramValue !== value);
    };

    public addFilter = (): IReportingFilter => {
        const filter = observable<IReportingFilter>({
            id: guid(),
            key: "",
            values: [],
            filterList: [],
            isLoading: false,
            filterValueKey: "",
            filterLabelKey: "",
            allowNew: false,
            exclude: false,
            operator: "",
        });

        this.item.data.reportParams.filters.push(filter);

        return filter;
    };

    public pushFilterValue = (filter: IReportingFilter, value: string, label: string) => {
        let item: any = value;

        if (filter.filterLabelKey && filter.filterValueKey) {
            item = {
                [filter.filterLabelKey]: label,
                [filter.filterValueKey]: value
            };
        }

        filter.values = filter.values.concat(item);
    };

    public removeFilterValue = (filter: IReportingFilter, value: string) => {
        filter.values = filter.filterValueKey
            ? _.filter(filter.values, (item) => item[filter.filterValueKey] !== value)
            : _.filter(filter.values, (item) => item !== value);
    };

    public setFilterParam = (filter: IReportingFilter, key: string, value: any) => {
        if (key === "key") {
            this.setFilterKey(filter, value);
            return;
        }

        filter[key] = value;
    };

    public addCustomStyle = () => {
        if (!this.item.data.customStyles) {
            this.item.data.customStyles = [];
        }

        if (this.item.data.customStyles.length >= this.item.data.reportParams.fields.length) {
            return;
        }
        this.item.data.customStyles.push({
            metric: "",
            threshold: 30,
            highColor: "var(--color-green)",
            lowColor: "var(--color-red)",
            id: guid()
        });
    };

    public removeCustomStyle = (indexToDelete: number): void => {
        this.item.data.customStyles = this.item.data.customStyles.filter((style, index) => indexToDelete !== index);
    };

    public onCustomStyleChange = (value, name, index) => {
        this.item.data.customStyles[index][name] = value;
    };

    public setFilterKey = async (filter: IReportingFilter, key: string, values?: any): Promise<any> => {
        const filterHandler = this.reportingFiltersManager.getFilter(key, this.item.data.reportParams.verticalType);

        if (!filterHandler) {
            return;
        }

        filter.key = key;
        filter.filterLabelKey = filterHandler.labelKey;
        filter.filterValueKey = filterHandler.valueKey;
        filter.allowNew = filterHandler.allowNew;
        filter.isLoading = filterHandler.isLoading;
        filter.values = values || [];

        return filterHandler.initialize().then(() => {
            filter.filterList = filterHandler.items;
            filter.isLoading = filterHandler.isLoading;
        });
    };

    public downloadCSV = () => {
        this.alertsManager.setBasicAlert({content: 'Generating CSV, Please wait...'}, AlertAccent.Success);
        const report = toJS(this.chartReportByTimePreset[this.reportingMetaDataManager.timePreset].results);
        this.exportToCsvManager.exportDashboardReport(report);
    };

    public get data() {
        return this.chartReportByTimePreset?.[this.reportingMetaDataManager.timePreset]?.results || [];
    }

    public get isLoading() {
        return this.chartReportByTimePreset[this.reportingMetaDataManager.timePreset].isLoading;
    }

}
