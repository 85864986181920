import {observer} from 'mobx-react';
import React, {useCallback} from 'react';
import theme from './theme.module.scss';
import {Table} from '@vidazoo/ui-kit';
import DiffTableChartRow from './components/DiffTableChartRow';
import {ChartEditModel} from '../../../modules/charts/item/edit/model/ChartEditModel.mdl';
import {DiffTableChartHeader} from './components/DiffTableChartHeader';
import {ChartCreateModel} from '../../../modules/charts/item/create/model/ChartCreateModel.mdl';

const ROW_HEIGHT = 45;
const LOADING_ROW_HEIGHT = 24;
const LOADING_ITEMS_COUNT = () => Math.round(window.innerHeight / LOADING_ROW_HEIGHT);

export interface IDiffTableChartProps {
    isEditMode: boolean;
    report: any;
    listClassName?: any;
    columnsWidth?: {
        [index: string]: number;
    };
    setColumnsWidth?: (columnsWidth: { [index: string]: number }) => void;
    model: ChartEditModel | ChartCreateModel;
}

function DiffTableChart(props: IDiffTableChartProps): JSX.Element {

    const {model, isEditMode} = props;

    const renderTableRow = useCallback((itemIndex: number, viewportIndex: number, style: React.CSSProperties) => {
        return (
            <DiffTableChartRow
                key={itemIndex}
                itemIndex={itemIndex}
                viewportIndex={viewportIndex}
                model={model}
                style={style}/>
        );
    }, [model.maskItems, model.columns, model]);

    const renderTableHeader = useCallback(() => {
        return (
            <DiffTableChartHeader model={model}/>
        );
    }, [model.maskItems, model.columns, model]);

    return (
        <Table
            className={isEditMode ? theme.editDiffTable : theme.diffTable}
            itemsCount={(model.isLoading && !model.data) ? LOADING_ITEMS_COUNT() : model.maskItems.length}
            rowHeight={ROW_HEIGHT}
            renderRow={renderTableRow}
            renderHeader={renderTableHeader}
            columnsManager={model}
            minColumnWidth={50}
            overscanItems={5}
            searchFromColumn
        />
    );
}

export default observer(DiffTableChart);
