import React, {useCallback, useMemo} from 'react';
import {Column, ContextMenu, HeaderColumn, Row} from '@vidazoo/ui-kit';
import useReportingModel from '../../common/hooks/useReportingModel';
import useReportingBuildManager from "../../common/hooks/useReportingBuildManager";
import {observer} from "mobx-react";

export default function ReportingTableHeader() {
    const {requestedFields, requestedGroups} = useReportingBuildManager();
    const model = useReportingModel();

    if (model.isLoading || !model.data) {
        return null;
    }

    const editDynamicFormula = () => {
        model.onChangeDynamicFormula("Formula", "name");
        model.toggleDynamicFormulaModalOpen()
    };

    const contextMenuItems = useMemo(() => {
        return [
            {
                label: "Create Dynamic Formula",
                onClick: editDynamicFormula,
            }]
    }, []);

    return (
        <>
            <ContextMenu items={contextMenuItems}>
                <Row>
                    {requestedGroups.map((group) => (
                        <HeaderColumn disableNowrap key={group.value} colKey={group.value}/>
                    ))}
                    {requestedFields.map((field) => (
                        <HeaderColumn disableNowrap key={field.value} colKey={field.value}/>))}
                </Row>
            </ContextMenu>
            <Row style={{zIndex: -1}}>
                {requestedGroups.map((group, index) => (
                    <Column style={{filter: "brightness(85%)"}} key={group.value} colKey={group.value}>
                        {index === 0 && `Total Rows: ${model.maskItems.length ? model.maskItems.length.toLocaleString() : ""}`}
                    </Column>
                ))}
                {requestedFields.map((field) => (
                    <Column style={{filter: "brightness(85%)"}} key={field.value} colKey={field.value}>
                        {model.maskItemsTotal[field.value]}
                    </Column>
                ))}
            </Row>
        </>
    )
}
