import {inject, init} from '@injex/core';
import BaseItemModel from '../../../../../common/base/BaseItemModel';
import {makeObservable, observable} from 'mobx';
import {IScheduleReport} from '../../../interfaces/IScheduleReport';
import {ReportVerticalType} from '../../../../../common/enums/ReportVerticalType';
import UserManager from '../../../../../common/managers/UserManager.mdl';
import IReportingFilter from '../../../../reporting/common/interfaces/IReportingFilter';
import {guid} from '@vidazoo/ui-kit';
import IReportingConstraint from '../../../../reporting/common/interfaces/IReportingConstraint';
import ReportingFiltersManager from '../../../../../common/reporting-filters/managers/ReportingFiltersManager.mdl';
import {clone} from 'lodash';
import {AlertAccent} from '../../../../../common/alerts/common/enums';
import {RouterService} from "../../../../../common/router/services/routerService.mdl";
import {ISubGroup} from "../../../../reporting/common/interfaces/ISubGroup";
import ReportingSubGroupsManager, {RequestedGroupLabel, RequestedGroupValue} from "../../../../reporting/managers/reportingSubGroupsManager.mdl";
import {
    ReportingContextMenuType,
    ReportingContextMenuTypeValues
} from "../../../../reporting/common/enums/ReportingContextMenuType";

export default abstract class BaseScheduleReportModel extends BaseItemModel<IScheduleReport> {

    @inject() public userManager: UserManager;
    @inject() public reportingFiltersManager: ReportingFiltersManager;
    @inject() public routerService: RouterService;
    @inject() private reportingSubGroupsManager: ReportingSubGroupsManager;

    @observable public runNowRecipients: string[];

    protected itemType: string = "chart";

    constructor() {
        super();
        this.runNowRecipients = []
        makeObservable(this);
    }

    @init()
    protected initialize() {
        this.reset();
    }

    public reset() {
    }

    public get defaultValue(): IScheduleReport {
        return {
            name: "",
            description: "",
            _id: '',
            created: 0,
            date: 0,
            error: '',
            isActive: false,
            isDeleted: false,
            lastSchedule: 0,
            network: undefined,
            nextSchedule: 0,
            updated: 0,
            user: undefined,
            schedule: "",
            dateRange: "",
            timezone: "",
            fields: observable([]),
            groups: observable([]),
            filters: observable([]),
            constraints: observable([]),
            delimiter: ";",
            sendEmpty: true,
            recipients: [],
            dataSource: "user",
            dataSourceIds: [],
            verticalType: ReportVerticalType.PLATFORM,
            subGroups: []
        }
    }

    public addFilter = (): IReportingFilter => {
        const filter = observable<IReportingFilter>({
            id: guid(),
            key: "",
            values: [],
            filterList: [],
            isLoading: false,
            filterValueKey: "",
            filterLabelKey: "",
            allowNew: false,
            exclude: false,
            operator: "",
        });

        this.item.data.filters.push(filter);

        return filter;
    };

    public addConstraint = (values?: Partial<IReportingConstraint>) => {
        this.item.data.constraints = this.item.data.constraints.concat({
            id: guid(),
            name: "",
            op: "",
            value: "",
            ...values
        });

        return this.item.data.constraints[this.item.data.constraints.length - 1];
    };

    public setFilterParam = (filter: IReportingFilter, key: string, value: any) => {
        if (key === "key") {
            this.setFilterKey(filter, value);
            return;
        }

        filter[key] = value;
    };

    public setFilterKey = (filter: IReportingFilter, key: string): Promise<any> => {
        const filterHandler = this.reportingFiltersManager.getFilter(key, this.item.data.verticalType);

        if (!filterHandler) {
            return;
        }

        filter.key = key;
        filter.filterLabelKey = filterHandler.labelKey;
        filter.filterValueKey = filterHandler.valueKey;
        filter.allowNew = filterHandler.allowNew;
        filter.isLoading = filterHandler.isLoading;
        filter.values = [];

        return filterHandler.initialize().then(() => {
            filter.filterList = filterHandler.items;
            filter.isLoading = filterHandler.isLoading;
        });
    };

    public setConstraintParam = (constraint: IReportingConstraint, key: string, value: any) => {
        constraint[key] = value;
    };

    public clearAllConstraint = () => {
        this.item.data.constraints = [];
    }

    public clearAllFilters = () => {
        this.item.data.filters = [];
    }

    public pushFilterValue = (filter: IReportingFilter, value: string, label: string) => {
        let item: any = value;

        if (filter.filterLabelKey && filter.filterValueKey) {
            item = {
                [filter.filterLabelKey]: label,
                [filter.filterValueKey]: value
            };
        }

        filter.values.push(item)
    };

    public removeFilterValue = (filter: IReportingFilter, value: string) => {
        filter.values = filter.filterValueKey
            ? filter.values.filter((item) => item[filter.filterValueKey] !== value)
            : filter.values.filter((item) => item !== value);
    };

    public addToRunNowRecipients = (item: any) => {
        this.runNowRecipients = [...this.runNowRecipients, item];
    };

    public removeFromRunNowRecipients = (value: string, prop: string = "value") => {
        this.runNowRecipients = this.runNowRecipients.filter((item) => (prop ? item[prop] : item) !== value);
    };

    public async runNow() {
        try {
            let data = this.submitify();
            data = {...data, recipients: this.runNowRecipients};
            await this.apiManager.scheduleReports.runNow(data._id, data);

            this.alertsManager.setBasicAlert({
                content: "Operation Complete, Your report was generated successfully",
                ttl: 3000
            }, AlertAccent.Success);

        } catch (err) {
            this.alertsManager.setBasicAlert({
                content: "Operation Failed, Failed to get your report",
                ttl: 3000
            }, AlertAccent.Error);
        }
    }

    protected submitify(item?: IScheduleReport) {
        const cloneItem: any = clone(item || this.item.data);

        cloneItem.groups = cloneItem.groups.map((x) => ({value: x.value, label: x.label}));
        cloneItem.fields = cloneItem.fields.map((x) => ({value: x.value, label: x.label}));
        cloneItem.constraints = cloneItem.constraints.map((x) => ({name: x.name, op: x.op, value: x.value}));
        cloneItem.filters = cloneItem.filters.map((x) => {
            const filter = this.reportingFiltersManager.getFilter(x.key, this.item.data.verticalType);

            return filter.reportify(x);
        });

        return cloneItem;
    }

    public setSubGroupParam = (subGroup: ISubGroup, key: string, value: any) => {
        if (key === "group") {
            this.setSubGroup(subGroup, value);
            return;
        }
        subGroup[key] = value;
    };

    public setSubGroup = (subGroup: ISubGroup, group: string) => {
        subGroup.group = group;
        this.reportingSubGroupsManager.getSubGroupsEntities(group, this.item.data.verticalType);

        if (this.item.data.groups.findIndex((g) => g.value === RequestedGroupValue[group]) === -1) {
            this.pushReportParam("groups", RequestedGroupValue[group], RequestedGroupLabel[group]);
        }
    };

    public removeSubGroup = (subGroup: ISubGroup) => {
        this.item.data.subGroups = this.item.data.subGroups.filter((sub) => sub.id !== subGroup.id);
    };

    public pushSubGroupValue = (subGroup: ISubGroup, value: string, label: string) => {
        subGroup.entities = subGroup.entities.concat(value);
    };

    public removeSubGroupValue = (subGroup: ISubGroup, value: string) => {
        subGroup.entities = subGroup.entities.filter((item) => item !== value);
    };

    public pushSubGroupTargetingType = (subGroup: ISubGroup, value: string, label: string) => {
        subGroup.targeting.types = subGroup.targeting.types.concat(value);
    };

    public removeSubGroupTargetingType = (subGroup: ISubGroup, value: string) => {
        subGroup.targeting.types = subGroup.targeting.types.filter((item) => item !== value);
    };

    public pushSubGroupTargetingOperator = (subGroup: ISubGroup, value: string, label: string) => {
        subGroup.targeting.operators = subGroup.targeting.operators.concat(value);
    };

    public removeSubGroupTargetingOperator = (subGroup: ISubGroup, value: string) => {
        subGroup.targeting.operators = subGroup.targeting.operators.filter((item) => item !== value);
    };

    public onClearAllParams = (key: ReportingContextMenuType) => {
        if (ReportingContextMenuTypeValues.includes(key)) {
            this.item.data[key] = [];
        }
    };

    public pushReportParam = (key: string, value: string, label: string) => {
        this.item.data[key] = this.item.data[key].concat([{value, label}]);
    };

    public addSubDimension = () => {
        const subDimension = {
            group: "",
            entities: [],
            id: guid(),
            targeting: {
                types: [],
                operators: []
            }
        };
        this.item.data.subGroups.push(subDimension);

        return subDimension;
    };

}
