import * as React from 'react';
import {Route} from 'react-router-dom';
import UrlsService from '../../../common/router/services/urlsService.mdl';
import Charts from '../list/view/Charts';
import ChartEdit from '../item/edit/view/ChartEdit';
import NotFound from '../../../common/components/not-found/NotFound';
import ChartCreate from '../item/create/view/ChartCreate';

const ChartsRouting = (
    <React.Fragment>
        <Route path={UrlsService.charts()} element={<Charts/>}/>
        <Route path={UrlsService.createChart()} element={<ChartCreate/>}/>
        <Route path={UrlsService.editChart()} element={<ChartEdit/>}>
            <Route path="*" element={<NotFound/>}/>
        </Route>
        <Route path={UrlsService.viewChart()} element={<ChartEdit viewOnly/>}>
            <Route path="*" element={<NotFound/>}/>
        </Route>
    </React.Fragment>
);

export default ChartsRouting
