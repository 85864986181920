import {observer} from 'mobx-react';
import useReportingModel from '../../common/hooks/useReportingModel';
import {Label} from '@vidazoo/ui-kit';
import theme from '../../view/theme.module.scss';

function ReportingEmpty() {
    const model = useReportingModel();

    return model.reportIsEmpty && !model.isLoading && (
        <div className={theme.message}>
            <Label uppercase={false} size={16}>Oops!</Label>
            <Label uppercase={false} size={16}>We couldn't find any results</Label>
        </div>
    );
}

export default observer(ReportingEmpty);
