import {ButtonsGroup, GroupButton} from '@vidazoo/ui-kit';
import theme from '../reportingHeader/theme.module.scss';
import useReportingParamsManager from '../../common/hooks/useReportingParamsManager';
import {observer} from 'mobx-react';
import {TimePresets} from '@vidazoo/date-range';

function ReportingTimePresetSelector() {
    const {applyTimePreset, params} = useReportingParamsManager();

    return (
        <div>
            <ButtonsGroup onChange={applyTimePreset} active={params.time} className={theme.preset}>
                {TimePresets.map((preset) => <GroupButton key={preset.value}
                                                          name={preset.value}>{preset.label}</GroupButton>)}
            </ButtonsGroup>
        </div>
    )
}

export default observer(ReportingTimePresetSelector);
