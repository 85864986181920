class UrlsServiceMdl {

    public app(): string {
        return `/`;
    }

    public details(): string {
        return "details";
    }

    public changeLogs(): string {
        return `/change-logs`;
    }

    public dashboards(): string {
        return `/dashboards`;
    }

    public dashboard(): string {
        return `/dashboard`;
    }

    public createDashboard(duplicate_id?: string): string {
        return `${this.dashboard()}/create${duplicate_id ? `?duplicate_id=${duplicate_id}` : ''}`;
    }

    public editDashboard(id: string = ":id"): string {
        return `${this.dashboard()}/${id}`;
    }

    public charts(): string {
        return `/charts`;
    }

    public chart(): string {
        return `/chart`;
    }

    public createChart(): string {
        return `${this.chart()}/create`;
    }

    public editChart(id: string = ":id"): string {
        return `${this.chart()}/${id}/edit`;
    }

    public viewChart(id: string = ":id"): string {
        return `${this.chart()}/${id}/view`;
    }

    public reporting(): string {
        return `/reporting`;
    }

    public historyReport(id: string = ":id"): string {
        return `/reporting/${id}`;
    }

    public scheduleReports(): string {
        return `/schedule-reports`;
    }

    public scheduleReport(): string {
        return `/schedule-report`;
    }

    public createScheduleReport(): string {
        return `${this.scheduleReport()}/create`;
    }

    public editScheduleReport(id: string = ":id"): string {
        return `${this.scheduleReport()}/${id}/edit`;
    }

    public notificationCenter(): string {
        return `/notification-center`;
    }

    public notificationSettings(): string {
        return `/notification-settings`;
    }
}

const UrlsService = new UrlsServiceMdl()

export default UrlsService;
