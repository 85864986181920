import BaseFilter from "../BaseFilter";
import {PlatformReportingFilterType} from "../ReportingFilterType";
import osOptions from '../../../enums/OsOptions';
import {alias, define, singleton} from '@injex/core';

@define()
@singleton()
@alias('PlatformReportingFilter')
export default class OsFilter extends BaseFilter {

	public type = PlatformReportingFilterType.OS;

	constructor() {
		super();

		this.items = osOptions;
		this.valueKey = "value";
		this.labelKey = "label";
		this.allowNew = true;
	}
}
