import {computed, observable} from "mobx";
import _ from "lodash";
import {define, inject, singleton} from '@injex/core';
import {IConfigurations} from '../interfaces/IConfigurations';
import ApiManager from '../api/ApiManager.mdl';
import {AxiosResponse} from "axios";
import IDashboard from '../../modules/dashboards/interfaces/IDashboard';
import {DefaultTopCharts} from '../enums/DefaultTopCharts';

@define()
@singleton()
export default class CloudManager {

    @inject() public apiManager: ApiManager;

    @observable public isLoadingCloud: boolean;
    @observable public configurations: IConfigurations;
    @observable public dashboardsStarsById: { [index: string]: string };

    private saveConfigurationsDebounce: () => void;

    public initialize() {
        this.dashboardsStarsById = {};
        this.getMyCloud();

        this.saveConfigurationsDebounce = _.debounce(() => {
            this.apiManager.cloud.updateMyCloud(this.configurations);
        }, 1000);
    }


    public async getMyCloud() {
        try {
            if (this.configurations) {
                return;
            }
            this.configurations = {} as any;
            this.isLoadingCloud = true;
            const res = await this.apiManager.cloud.getMyCloud()
            this.onGetMyCloudSuccess(res)

        } catch (e) {
            this.onGetMyCloudError()
        }
    }

    private onGetMyCloudSuccess(res: AxiosResponse<any, any>) {
        this.configurations = res.data.configurations;
        this.isLoadingCloud = false;

        if (this.configurations.dashboardsStars) {
            this.dashboardsStarsById = _.keyBy(this.configurations.dashboardsStars);
        }
    }

    private onGetMyCloudError() {
        this.isLoadingCloud = false;
    }


    public setConfigurations(configurations: Partial<IConfigurations> = {}) {
        this.configurations = {...this.configurations, ...configurations};
        this.saveConfigurationsDebounce();
    }

    @computed
    public get dashboardsStars(): string[] {
        if (this.configurations && this.configurations.dashboardsStars) {
            return this.configurations.dashboardsStars;
        }

        return [];
    }

    public toggleStar = (item: IDashboard) => {

        if (this.dashboardsStarsById[item._id]) {
            const idx = this.dashboardsStars.findIndex((dashboardId) => dashboardId === item._id);
            if (idx > -1) {
                this.setConfigurations({dashboardsStars: this.dashboardsStars.filter((dashboardId) => dashboardId !== item._id)});
            }
            delete this.dashboardsStarsById[item._id];

        } else {

            if (!this.configurations.dashboardsStars) {
                this.configurations.dashboardsStars = [];
            }
            const clonedDashboardsStars = JSON.parse(JSON.stringify(this.configurations.dashboardsStars));
            clonedDashboardsStars.push(item._id);
            this.dashboardsStarsById[item._id] = item._id;
            this.setConfigurations({dashboardsStars: clonedDashboardsStars});
        }
    };

    public addTopCharts(chartId: string) {
        const clonedTopCharts = this.configurations.topCharts ? JSON.parse(JSON.stringify(this.configurations.topCharts)) : DefaultTopCharts;

        const idx = clonedTopCharts.findIndex((topChart) => topChart.id === chartId);
        if (idx > -1) {
            clonedTopCharts[idx].count++;
        } else {
            clonedTopCharts.push({id: chartId, count: 1});
        }
        this.setConfigurations({topCharts: clonedTopCharts});
    }

    @computed
    public get topCharts(): { [index: string]: { count: number; id: string } } {
        if (this.configurations && this.configurations.topCharts) {
            return _.keyBy(this.configurations.topCharts, "id");
        }

        return {};
    }
}
