import {define, inject, singleton} from '@injex/core';
import {ColumnsManager} from '@vidazoo/ui-kit';
import {makeObservable, observable} from 'mobx';
import {IReportHistory} from '../common/interfaces/IReportHistory';
import {AlertAccent} from "../../../common/alerts/common/enums";
import ApiManager from "../../../common/api/ApiManager.mdl";
import {AlertsManager} from "../../../common/alerts/managers/alertsManager.mdl";
import {DataListQuery} from "../../../common/models/DataListQuery";
import {ReportingHistoryListColumns} from "../components/reportingHistory/components/ReportingHistoryListColumns";
import dayjs from "dayjs";
import ReportingMetaDataManager from "../../../common/managers/ReportingMetaDataManager.mdl";

@define()
@singleton()
export class HistoryReportsColumnsManager extends ColumnsManager<IReportHistory> {

    @inject() private apiManager: ApiManager;
    @inject() private alertsManager: AlertsManager;
    @inject() private reportingMetaDataManager: ReportingMetaDataManager;

    @observable public editHistoryReport: IReportHistory;

    public items: DataListQuery<IReportHistory, []>;

    constructor() {
        super({
            persistKey: 'history_reports_list_columns',
        });
        makeObservable(this);

        this.setColumns(ReportingHistoryListColumns);

        this.items = new DataListQuery(this._fetchItems.bind(this), {defaultValue: [], dataObservType: observable.ref});
    }

    public getById = async (id: string) => {
        return this.apiManager.historyReports.getById(id, {lean: true});
    }

    private async _fetchItems() {
        const response = await this.apiManager.historyReports.getAll({origin: "bi_dashboards"} as any);
        return response.results.map((row) => this._prepareItem(row));
    }

    public prepareItem(item: IReportHistory) {
        return this._prepareItem(item);
    }

    private _prepareItem(item: IReportHistory) {
        item.duration = `${dayjs(item.params.from * 1000).format("DD/MM/YYYY, hh:mm")} - ${dayjs(item.params.to * 1000).format("DD/MM/YYYY, hh:mm")}`;
        item.dimensions = item.params.groups.map((group) => {
            const reportingEntry = this.reportingMetaDataManager.getGroupByValue(group, item.params.verticalType);
            return reportingEntry ? reportingEntry.label : "";
        }).join(", ");
        item.metrics = item.params.fields.map((field) => {
            const reportingEntry = this.reportingMetaDataManager.getFieldByValue(field, item.params.verticalType);
            return reportingEntry ? reportingEntry.label : "";
        }).join(", ");

        if (item.params.filters && item.params.filters.length > 0) {
            item.filtersStr = item.params.filters.map((filter) => {
                const reportingEntry = this.reportingMetaDataManager.getGroupByValue(filter.key, item.params.verticalType);
                return reportingEntry ? reportingEntry.label : filter.key;
            }).join(", ");
        }

        if (item.params.constraints && item.params.constraints.length > 0) {
            item.constraintsStr = item.params.constraints.map((constraint) => {
                const reportingEntry = this.reportingMetaDataManager.getFieldByValue(constraint.name, item.params.verticalType);
                return reportingEntry ? reportingEntry.label : constraint.name;
            }).join(", ");
        }

        if (item.params.subGroups && item.params.subGroups.length > 0) {
            item.subGroupsStr = item.params.subGroups.map((subGroup) => {
                return `${subGroup.group} - ${subGroup.entities.join(" -")}`;
            }).join(", ");
        }

        if (item.params.sample) {
            item.sample = item.params.sample * 100 + "%";
        }

        return item;
    }

    public get data(): IReportHistory[] {
        return this.items.data;
    }

    public get isLoading(): boolean {
        return this.items.isLoading;
    }

    public submit = async () => {
        try {
            await this.apiManager.historyReports.updateById(this.editHistoryReport._id, this.editHistoryReport)
            this.onUpdateReportingHistorySuccess()
        } catch (e) {
            this.alertsManager.setBasicAlert({
                content: "Operation Failed, Failed to get your report",
                ttl: 3000
            }, AlertAccent.Error);
        }
    };

    private onUpdateReportingHistorySuccess = () => {
        this.alertsManager.setBasicAlert({
            content: "Operation Failed, Failed to get your report",
            ttl: 3000
        }, AlertAccent.Success);
        const readyItemIdx = this.items.data.findIndex((item) => item._id === this.editHistoryReport._id);
        if (readyItemIdx > -1) {
            this.items.data[readyItemIdx] = {...this.items.data[readyItemIdx], ...this.editHistoryReport};
        }
    };
    public changeHistoryReport = (report: IReportHistory) => {
        this.editHistoryReport = report;
    }

    public setEditHistoryReport = (report: IReportHistory) => {
        this.editHistoryReport = report;
    }

    public cancelEditHistoryReport = () => {
        this.editHistoryReport = null;
    }
}
