import * as React from "react";
import {Button, CircleLoader, Icon, Label, LinkButton, Loader, SearchBar, Surface} from "@vidazoo/ui-kit";
import theme from "./theme.module.scss";
import clsx from "clsx";
import dayjs from "dayjs";
import {normalizeEventValue} from "../../../../common/utils/utils";
import useReportingPresetReportsManager from "../../common/hooks/useReportingPresetReportsManager";
import {useEffect, useRef} from "react";
import {observer} from "mobx-react";
import useReportingParamsManager from "../../common/hooks/useReportingParamsManager";
import IReportingPreset from "../../common/interfaces/IReportingPreset";

function PresetsSelect() {

    const {
        onCreateItem,
        onDeleteItem,
        activePreset,
        setActivePreset,
        historyItems,
        publicItems,
        privateItems,
        items,
        update,
    } = useReportingPresetReportsManager();
    const {applyReportPreset} = useReportingParamsManager();
    const [isOpen, setIsOpen] = React.useState(false);
    const [search, setSearch] = React.useState("");
    const ref = useRef(null);

    const toggleIsOpen = (e?: any) => {
        if (e) {
            e.stopPropagation();
        }
        setIsOpen(prevState => !prevState);
    };

    const isClickOutsideRef = (e) => {
        return isOpen && ref?.current?.contains && !ref.current.contains(e.target);
    };

    const onWindowClick = (e) => {
        if (isClickOutsideRef(e)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            if (items && items.data.length === 0) {
                items.invalidate().fetch();
            }
            window.addEventListener('click', onWindowClick);
            return () => window.removeEventListener('click', onWindowClick);
        }
    }, [isOpen]);

    const onSelectPreset = (preset: IReportingPreset) => {
        toggleIsOpen();
        applyReportPreset(preset);
        setActivePreset(preset);
    };

    const onSearch = (e) => {
        const value = normalizeEventValue(e);
        setSearch(value);
    };

    const clearSearch = () => {
        setSearch("");
    };

    const onSavePreset = (preset) => {
        update(preset._id, preset);
    };

    const onDeletePreset = (preset) => {
        onDeleteItem(preset);
    };

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <div onClick={toggleIsOpen} className={clsx(theme.presetsLabel, {[theme.open]: isOpen})}>
                {activePreset ? `Report: ${activePreset.name}` : "Select Preset"}
                <Icon className={clsx(theme.icon, {[theme.open]: isOpen})} size={1}
                      iconName="icon-arrow-down-small"/>
            </div>
            {isOpen ? (
                <Surface ref={ref} className={theme.presetsModal} level={3}>
                    {items && items.data.length ? (
                        <SearchBar className={theme.searchBar} onClear={clearSearch} onChange={onSearch}
                                   fill autoFocus value={search} crossOrigin={undefined}/>
                    ) : <CircleLoader/>}
                    <div className={theme.presetsList}>
                        {(historyItems && historyItems.length) ? (
                            <>
                                <Label className={theme.presetsModalLabel} size={14} spacing={1}>Last
                                    10 Reports:</Label>
                                <div>
                                    {historyItems
                                        .filter((historyPreset) => historyPreset.name.toLowerCase().includes(search.toLowerCase()))
                                        .map((historyPreset) => {
                                            return (
                                                <div onClick={() => onSelectPreset(historyPreset)}
                                                     key={historyPreset._id}
                                                     className={theme.presetRow}>
                                                    <div
                                                        className={theme.presetRowName}>
                                                        {historyPreset.name}
                                                    </div>
                                                    <div className={theme.links}>
                                                        {dayjs(historyPreset.updated).format("DD/MM/YYYY, HH:mm")}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </>
                        ) : null}

                        {(publicItems && publicItems.length) ? (
                            <>
                                <Label className={theme.presetsModalLabel} size={14} spacing={1}>Global
                                    Presets:</Label>
                                <div>
                                    {publicItems
                                        .filter((publicPreset) => publicPreset.name.toLowerCase().includes(search.toLowerCase()))
                                        .map((publicPreset) => {
                                            return (
                                                <div onClick={() => onSelectPreset(publicPreset)}
                                                     key={publicPreset._id}
                                                     className={theme.presetRow}>
                                                    <div
                                                        className={theme.presetRowName}>
                                                        {publicPreset.name}
                                                    </div>
                                                    <div className={theme.links}>
                                                        {dayjs(publicPreset.updated).format("DD/MM/YYYY, HH:mm")}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </>
                        ) : null}

                        {(privateItems && privateItems.length) ? (
                            <>
                                <Label className={theme.presetsModalLabel} size={14} spacing={1}>Private
                                    Presets:</Label>
                                <div>
                                    {privateItems
                                        .filter((preset) => preset.name.toLowerCase().includes(search.toLowerCase()))
                                        .map((preset) => {
                                            return (
                                                <div onClick={() => onSelectPreset(preset)} key={preset._id}
                                                     className={theme.presetRow}>
                                                    <div
                                                        className={theme.presetRowName}>
                                                        {preset.name}
                                                    </div>
                                                    <div className={theme.links}>
                                                        <LinkButton onClick={() => onSavePreset(preset)}
                                                                    iconName="icon-save" type="button"
                                                                    iconSize={1.8}/>
                                                        <LinkButton onClick={() => onDeletePreset(preset)}
                                                                    iconName="icon-delete" type="button"
                                                                    iconSize={1.8}/>
                                                        <div
                                                            style={{marginLeft: 10}}>{dayjs(preset.updated).format("DD/MM/YYYY, HH:mm")}</div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </>
                        ) : null}
                    </div>
                    <div className={theme.actions}>
                        <Button iconName="icon-save" onClick={onCreateItem} medium flat>Save Current report as
                            preset</Button>
                    </div>
                </Surface>
            ) : null}
        </div>
    );
}

export default observer(PresetsSelect);
