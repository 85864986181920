import {observer} from 'mobx-react';
import React, {useCallback, useEffect} from 'react';
import theme from '../../view/theme.module.scss';
import {Table} from '@vidazoo/ui-kit';
import ReportingTableRow from '../reportingTableRow/ReportingTableRow';
import ReportingTableHeader from '../reportingTableHeader/ReportingTableHeader';
import useReportingModel from '../../common/hooks/useReportingModel';

const ROW_HEIGHT = 40;
const LOADING_ROW_HEIGHT = 24;
const LOADING_ITEMS_COUNT = () => Math.round(window.innerHeight / LOADING_ROW_HEIGHT);

function ReportingList(): JSX.Element {
    const model = useReportingModel();

    useEffect(() => {
        model.resetFilter();
    }, []);

    const renderTableRow = useCallback((itemIndex: number, viewportIndex: number, style: React.CSSProperties) => {
        const item = model.maskItems?.at(itemIndex) ?? null
        return (
            <ReportingTableRow
                key={item?.id ?? itemIndex}
                itemIndex={itemIndex}
                viewportIndex={viewportIndex}
                style={style}/>
        );
    }, [model.maskItems, model.maskItemsTotal, model, model.columns]);

    const renderTableHeader = useCallback(() => {
        return (
            <ReportingTableHeader/>
        );
    }, [model.maskItems, model.maskItemsTotal, model, model.columns]);

    return (
        <Table
            className={theme.table}
            itemsCount={(model.isLoading && model.data.length == 0) ? LOADING_ITEMS_COUNT() : model.maskItems.length}
            rowHeight={ROW_HEIGHT}
            renderRow={renderTableRow}
            minColumnWidth={50}
            renderHeader={renderTableHeader}
            columnsManager={model}
            overscanItems={100}
            searchFromColumn
            disableContextMenu
            useStickyScrollbar
        />
    );
}

export default observer(ReportingList);
