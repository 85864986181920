import React from 'react';
import {
    Button,
    FormSelect
} from '@vidazoo/ui-kit';
import theme from './theme.module.scss';
import {IChangeLogFilter} from '../../common/interfaces/IChangeLogFilter';
import ScopeFilter from './filters/ScopeFilter';
import AccountFilter from './filters/AccountFilter';
import PlatformTagsFilter from './filters/PlatformTagsFilter';
import UsersFilter from './filters/UsersFilter';
import ActionFilter from './filters/ActionFilter';
import ModelIdFilter from './filters/ModelIdFilter';
import ModelNameFilter from './filters/ModelNameFilter';
import useChangeLogsModel from '../../common/hooks/useChangeLogsModel';
import {FilterOptions, FilterTypes} from '../../common/enums/FilterTypes';
import {observer} from 'mobx-react';

interface IChangeLogsFilterProps {
    index: number;
    filter: IChangeLogFilter;
}

function ChangeLogsFilter({index, filter}: IChangeLogsFilterProps) {
    const model = useChangeLogsModel();

    const onChangeFilterParam = (e) => {
        const {name, value} = e.target;
        model.setFilterParam(filter, name, value);
    };

    const onDelete = () => {
        model.removeFilter(index);
    };

    const renderFilterByType = () => {
        switch (filter.type) {
            case FilterTypes.Scope:
                return <ScopeFilter filter={filter}/>;
            case FilterTypes.Account:
                return <AccountFilter filter={filter}/>;
            case FilterTypes.PlatformTags:
                return <PlatformTagsFilter filter={filter}/>;
            case FilterTypes.User:
                return <UsersFilter filter={filter}/>;
            case FilterTypes.Action:
                return <ActionFilter filter={filter}/>;
            case FilterTypes.ModelId:
                return <ModelIdFilter filter={filter}/>;
            case FilterTypes.ModelName:
                return <ModelNameFilter filter={filter}/>;
        }
    };

    if (model.params) {
        return (
            <div className={theme.filter}>
                <div className={theme.label}>
                    Filter {index + 1}
                </div>
                <div className={theme.actions}>
                    <FormSelect
                        className={theme.select}
                        items={FilterOptions}
                        maxItems={5}
                        itemValueKey="value"
                        itemLabelKey="label"
                        value={filter.type}
                        name="type"
                        required
                        hideValidationMessage
                        onChange={onChangeFilterParam}
                        searchable
                    />
                    {renderFilterByType()}
                    <Button className={theme.delete} type="button" medium flat uppercase iconName="icon-delete"
                            onClick={onDelete}/>
                </div>
            </div>
        );
    }
}

export default observer(ChangeLogsFilter);
