import {NavLink as RouterNavLink} from 'react-router-dom';

export default function NavLink({children, to, className = ""}) {
    return (
        <RouterNavLink to={to}>
            <div className={className}>
                {children}
            </div>
        </RouterNavLink>
    );
}
