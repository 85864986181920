import {createContext, useContext} from 'react';
import {ChartEditModel} from '../edit/model/ChartEditModel.mdl';
import {ChartCreateModel} from '../create/model/ChartCreateModel.mdl';

export interface IChartContext {
    model: ChartEditModel | ChartCreateModel;
}

export const ChartContext = createContext<IChartContext>({
    model: null
})

export function useChartContext(): IChartContext {
    return useContext<IChartContext>(ChartContext);
}
