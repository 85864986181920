import {Suspense} from 'react';
import {Outlet} from 'react-router-dom';
import PrivateRoute from '../../session/components/private-router/PrivateRoute';
import ProgressBanner from '../../../common/components/progress-banner/ProgressBanner';
import MainMenu from '../components/main-menu/view/MainMenu';
import AlertsContainer from '../../../common/alerts/view/AlertsContainer';
import {observer} from "mobx-react";
import DefaultCharts from "../../../common/components/default-charts/DefaultCharts";

function Loading() {
    return (
        <ProgressBanner>Loading, please wait...</ProgressBanner>
    );
}

function App() {
    return (
        <PrivateRoute>
            <AlertsContainer/>
            <div className="flex flex-col flex-1">
                <div className="w-full h-[68px] top-0 left-0 z-20">
                    <MainMenu/>
                </div>
                <DefaultCharts/>
                <div className="flex flex-col min-w-0 flex-1">
                    <Suspense fallback={<Loading/>}>
                        <Outlet/>
                    </Suspense>
                </div>
            </div>
        </PrivateRoute>
    );
}

export default observer(App);
