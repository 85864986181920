import BaseNeededGroup from "../BaseNeededGroup";
import {PlatformNeededGroups} from "../NeededGroups";
import {PlatformReportingFilterType} from "../ReportingFilterType";
import {action} from "mobx";
import {ReportVerticalType} from '../../../enums/ReportVerticalType';
import {alias, define, singleton} from '@injex/core';

@define()
@singleton()
@alias('PlatformReportingFilter')
export default class PartnerTagIdFilter extends BaseNeededGroup {

	public type = PlatformReportingFilterType.PartnerTagId;

	constructor() {
		super(ReportVerticalType.PLATFORM, PlatformNeededGroups.DemandPartners);
	}

	public setData(res) {
		this.items = [...res.data, {_id: "Vidazoo Marketplace", name: "Vidazoo Marketplace"}];
		this.isLoading = false;
	}
}
