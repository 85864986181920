import {ReportFieldValueType} from "../enums/ReportFieldValueType";

export function extractReportingFieldValue<T = number>(data: any, key: "value" | "displayValue" = "value", reportType: ReportFieldValueType = ReportFieldValueType.ORIGINAL): T {
    if (data[key] instanceof Array) {
        const [originalValue, compareValue, delta, diff] = data[key];

        switch (reportType) {
            case ReportFieldValueType.ORIGINAL:
                return originalValue;
            case ReportFieldValueType.COMPARE:
                return compareValue;
            case ReportFieldValueType.DELTA:
                return delta;
            case ReportFieldValueType.DELTA_PCT:
                return diff;
        }
    }

    return data[key];
}
