export enum NetworkFeatures {
    TAGS = "tags",
    SCENARIOS = "scenarios",
    ADVERTISERS = "advertisers",
    VIDAZOO = "vidazoo",
    FEATURED = "featured",
    IP_BLOCKER_GENERAL = "ip_blocker_general",
    IP_BLOCKER_NETWORK = "ip_blocker_network",
    CMS = "cms",
    REMOVE_SENSITIVE_OPTIONS = "remove_sensitive_options",
    FAKE_REFERRER_BLOCKER = "fake_referrer_blocker",
    EU_TRAFFIC = "eu_traffic",
    CACHE_PARAM1 = "cacheParam1",
    CACHE_PARAM2 = "cacheParam2",
    CACHE_CAMPAIGN = "cacheCampaign",
    WIDGETS_SAAS = "widgets_saas",
    SAAS_LITE = "saas_lite",
    WORKING_HOURS = "working_hours",
    SUPERVISOR = "supervisor",
    ADS_TXT_AUTO_UPDATE = "ads_txt_auto_update",
    CONNECTIONS = "connections",
    THIRD_PARTY_PUBLISHERS = "third_party_publishers",
    PLAYLIST_BY_TARGETING = "playlist_by_targeting"
}
