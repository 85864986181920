import * as React from "react";
import {FormInput, Button, Modal, AutoCompleteInput, Label} from "@vidazoo/ui-kit";
import theme from "./theme.module.scss";
import {observer} from "mobx-react";
import useReportingModel from "../../common/hooks/useReportingModel";

function DynamicFormulaModal() {
    const {onChangeDynamicFormula, dynamicFormulaModalOpen, editDynamicFormula, createDynamicFormula, toggleDynamicFormulaModalOpen, dynamicFormulaSuggestions} = useReportingModel();

    const onCreateDynamicFormula = () => {
        createDynamicFormula();
        toggleDynamicFormulaModalOpen();
    };

    return (
        <Modal exitOnClickOutside onClose={toggleDynamicFormulaModalOpen}
               isOpen={dynamicFormulaModalOpen}>
            <div className={theme.editHistoryModal}>
                <Label>Formula</Label>
                <AutoCompleteInput
                           value={editDynamicFormula.formula}
                           name="formula"
                           label="Formula"
                           onChange={(e) => onChangeDynamicFormula(e.target.value, "formula")}
                           hideValidationMessage suggestionsList={dynamicFormulaSuggestions}/>
                <FormInput style={{marginBottom: 10, minWidth: 400}}
                           value={editDynamicFormula.name}
                           name="name"
                           placeholder="Name"
                           label="Name"
                           onChange={(e) => onChangeDynamicFormula(e.target.value, "name")}
                           hideValidationMessage/>
                <div className={theme.actions}>
                    <Button type="button" uppercase gray medium style={{marginRight: 10}}
                            onClick={toggleDynamicFormulaModalOpen}>Cancel</Button>
                    <Button type="button" uppercase green medium
                            onClick={onCreateDynamicFormula}>{editDynamicFormula.id ? "Update" : "Create"}</Button>
                </div>
            </div>
        </Modal>
    );
}

export default observer(DynamicFormulaModal);
