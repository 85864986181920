import React from 'react';
import {Column, HeaderColumn, Row} from '@vidazoo/ui-kit';
import {useChartsListContext} from '../context/ChartsListContext';

interface ITableHeaderProps {
}

export function ChartsTableHeader() {
    const {model, tableRowSelectionHook} = useChartsListContext();
    const {isAllSelected, toggleAllItems} = tableRowSelectionHook

    return (
        <Row>
            <Column colKey="checkbox" width={40} className='flex items-center justify-center'>
                <input className="flex w-8 h-8 cursor-pointer" disabled={model.isLoading} type="checkbox"
                       checked={isAllSelected}
                       onChange={toggleAllItems}/>
            </Column>
            <HeaderColumn colKey="name" />
            <HeaderColumn colKey="type" />
            <HeaderColumn colKey="created" />
            <HeaderColumn colKey="isActive" />
        </Row>
    )
}
