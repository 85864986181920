import {memo} from 'react';
import {observer} from "mobx-react";

export interface IMarkeredTextProps {
    marker: string;
    children: string;
}

function MarkeredText({marker, children}: IMarkeredTextProps) {
    const checkChildren = children.toLowerCase();
    const checkMarker = marker.toLowerCase();

    if (!checkChildren.includes(checkMarker)) {
        return <>{children}</>;
    }

    const index = checkChildren.indexOf(checkMarker);

    return (
        <>{children.substr(0, index)}<span className="bg-yellow-200 rounded-md text-black">{children.substr(index, marker.length)}</span>{children.substr(index + marker.length)}</>
    );
}

export default observer(MarkeredText);
