import {define, inject, singleton} from '@injex/core';
import SocketManager from '../../../../../common/managers/SocketManager.mdl';
import BaseScheduleReportModel from '../../common/base/BaseScheduleReportModel';
import {IScheduleReport} from '../../../interfaces/IScheduleReport';
import UrlsService from "../../../../../common/router/services/urlsService.mdl";

@define()
export class ScheduleReportCreateModel extends BaseScheduleReportModel {

    @inject() socketManager: SocketManager;

    public get data() {
        return [];
    }

    protected fetchItem(id: string): Promise<IScheduleReport> {
        throw new Error('Method not implemented.');
    }

    public submit = async () => {
        try {
            const doc = await this.apiManager.scheduleReports.create(this.item.data);

            this.onSubmitSuccess();

            this.routerService.push(UrlsService.editScheduleReport(doc._id))

        } catch (err) {
            this.onSubmitFailed(err);
        }
    }

    public delete = async () => {
        try {
            await this.apiManager.charts.deleteById(this.item.data._id);
            this.onDeleteSuccess();

        } catch (e) {
            this.onDeleteError(e);
        }
    }
}
