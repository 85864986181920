import {define, singleton} from '@injex/core';
import EntityRequestAdapter from '../utils/EntityRequestAdapter';

@define()
@singleton()
export class ColumnsWidthApi extends EntityRequestAdapter<{ [index: number]: number }> {

    protected basePathName: string = 'columns-width';

    protected get apiEndpoint(): string {
        return this.env.biDashboardsAPI;
    }

    public getColumnsWidth(entity: string) {
        return this.request({
            method: "get",
            url: `/api/columns-width/get/${entity}`,
        });
    }

    public setColumnsWidth(entity: string, columnsWidth: any) {
        return this.request({
            method: "patch",
            url: `/api/columns-width/set`,
            data: {columnsWidth, entity}
        });
    }
}
