
export enum AuthScope {
    AUTH = "auth",
    PLATFORM = "platform",
    CONNECTIONS = "connections",
    PUBLISHERS = "publishers",
    IP_BLOCKER = "ip_blocker",
    MONEY_BOOKER = "moneybooker",
    ALGOTRADER = "algotrader",
    SKYNET = "skynet",
    AUTOSCALER = "autoscaler",
    NIGHTWATCH = "nightwatch",
    DISPLAY = "display",
    OPEN_RTB = "open_rtb",
    ADVERTISERS = "advertisers",
    DSP_GATEWAY = "dsp_gateway",
    CAPTAINS_LOG = "captains_log",
    BI = "bi",
    CONTENT = "content",
    CAPCHU = "capchu",
    CAMPAIGN_DUPLICATOR = "campaign_duplicator",
    VAULT = "vault",
    NETWORK = "network",
    VROKU = "vroku",
    MESSAGES = "messages",
    REPORTING = "reporting",
    BI_DASHBOARD = "bi_dashboard",
    BI_DASHBOARDS = "bi_dashboards",
    GAMES = "games",
    WEB_DIRECTORY = "web_directory",
    ADS_TXT = "ads_txt",
    SITES = "sites",
    FEATURE_TOGGLES = "feature_toggles",
    AUTOMATIONS = "automations",
    CONSOLE_RADIO = "console_radio",
    OS = "os",
    WIDGETS = "widgets"
}
