import ChartsList from '../components/ChartsList';
import ChartsHeader from '../components/ChartsHeader';
import {ChartsListWithContext} from '../context/ChartsListContext';

export default function Charts() {

    return (
        <ChartsListWithContext>
            <ChartsHeader/>
            <ChartsList/>
        </ChartsListWithContext>
    );
}
