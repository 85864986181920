import {Form, FormInput} from '@vidazoo/ui-kit';
import {observer} from 'mobx-react';
import * as React from 'react';
import theme from '../../styles/theme.module.scss';
import {PromptAlertModel} from './promptAlertModel.mdl';

export interface IPromptAlertViewProps {
    model: PromptAlertModel;
}

function PromptAlertView({model}: IPromptAlertViewProps): JSX.Element {
    const onValueChange = React.useCallback((e) => model.setValue(e.target.value), []);

    return (
        <Form onSubmit={model.confirm} submitOnEnter>
            {model.payload.label ? <div className={theme.label}>{model.payload.label}</div> : null}
            <div className={theme.input}>
                <FormInput autoFocus fill type={model.payload.inputType} placeholder={model.payload.placeholder} value={model.value} onChange={onValueChange} {...model.payload.inputProps} />
            </div>
            <div className={theme.footer}>
                <button type="submit" className={theme.button} data-primary>{model.payload.confirmText}</button>
            </div>
        </Form>
    );
}

export default observer(PromptAlertView);
