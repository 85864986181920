import {alias, define, singleton} from '@injex/core';
import {BaseSocket} from '../../../../common/socket/baseSocket';
import {IBiReportingParams} from "../interfaces/IReportingParams";
import {IReportHistory} from "../interfaces/IReportHistory";

@define()
@singleton()
@alias('Disposable')
export class HistoryReportsSocket extends BaseSocket {

    public name = "historyReports";

    public get getUri(): string {
        return this.env.reportingHistoryAPI;
    }

    public getReporting(data: { params: IBiReportingParams, originFields: string[] }): Promise<IReportHistory> {
        return this.request("getReportingHistory", data, 10 * 60 * 1000);
    }

    public getCompareReportingHistory(data: { params: IBiReportingParams, originFields: string[] }): Promise<IReportHistory> {
        return this.request("getCompareReportingHistory", data, 10 * 60 * 1000);
    }

    public startListenToReportHistoryIsReady(authUserId: string, fn: (data: any) => void) {
        this.on(`reportHistoryIsReady-bi_dashboards-${authUserId}`, fn);
    }

    public startListenToReportHistoryUploadToS3(authUserId: string, fn: (data: any) => void) {
        this.on(`reportHistoryUploadToS3-${authUserId}`, fn);
    }

    public removeListenToReportHistoryIsReady(authUserId: string) {
        this.off(`reportHistoryIsReady-bi_dashboards-${authUserId}`);
    }
}
