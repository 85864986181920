import {define, singleton} from '@injex/core';
import EntityRequestAdapter from '../../../common/utils/EntityRequestAdapter';

@define()
@singleton()
export class PresetReportsApi extends EntityRequestAdapter {

    protected basePathName: string = 'preset_reports';

    protected get apiEndpoint(): string {
        return this.env.biDashboardsAPI;
    }

    public updateLastUsed(id: string) {
        this.requestWithContext({
            method: 'patch',
            url: this.makeAPIPath(`/${id}/last-used`),
        });
    }
}
