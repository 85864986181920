import * as React from 'react';
import {PageMessage, Loader} from '@vidazoo/ui-kit';
import theme from '../../../../../common/styles/_baseForm.module.scss';

function ProfileLoader(): JSX.Element {
    return (
        <div className={theme.message}>
            <Loader size={1} gray className={theme.loader} />
            <PageMessage
                small
                title="Loading Profile"
                description="Please wait..."
            />
        </div>
    );
}

export default ProfileLoader;
