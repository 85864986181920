import {TableColumn} from '@vidazoo/ui-kit';
import {IReportHistory} from '../../../common/interfaces/IReportHistory';

export const ReportingHistoryListColumns: TableColumn<IReportHistory>[] = [
    {
        title: "Status",
        key: "status",
        defaultWidth: 100,
        options: {
            order: true,
            freeze: true,
            sort: {sortKey: 'status'},
            filter: {
                filterKey: 'status',
                options: [{label: 'Success', value: 'SUCCESS'}, {label: 'Failed', value: 'FAILED'}]
            }
        }
    },
    {
        title: "Actions",
        key: "actions",
        defaultWidth: 100,
    },
    {
        title: "Date",
        key: "created",
        defaultWidth: 200,
        options: {order: true, freeze: true, sort: {sortKey: 'created'}, search: {searchKey: 'created'}}
    },
    {
        title: "Duration",
        key: "duration",
        defaultWidth: 200,
        options: {order: true, freeze: true, sort: {sortKey: 'duration'}, search: {searchKey: 'created'}}
    },
    {
        title: "Description",
        key: "description",
        defaultWidth: 150,
        options: {order: true, freeze: true, sort: {sortKey: 'description'}, search: {searchKey: 'description'}}
    },
    {
        title: "Dimensions",
        key: "dimensions",
        defaultWidth: 200,
        options: {order: true, freeze: true, sort: {sortKey: 'dimensions'}, search: {searchKey: 'dimensions'}}
    },
    {
        title: "Metrics",
        key: "metrics",
        defaultWidth: 200,
        options: {order: true, freeze: true, sort: {sortKey: 'metrics'}, search: {searchKey: 'metrics'}}
    },
    {
        title: "Filters",
        key: "filtersStr",
        defaultWidth: 150,
        options: {order: true, freeze: true, sort: {sortKey: 'filtersStr'}, search: {searchKey: 'filtersStr'}}
    },
    {
        title: "Constraints",
        key: "constraintsStr",
        defaultWidth: 150,
        options: {order: true, freeze: true, sort: {sortKey: 'constraintsStr'}, search: {searchKey: 'constraintsStr'}}
    },
    {
        title: "Sub Dimensions",
        key: "subGroupsStr",
        defaultWidth: 150,
        options: {order: true, freeze: true, sort: {sortKey: 'subGroupsStr'}, search: {searchKey: 'subGroupsStr'}}
    },
    {
        title: "Size",
        key: "size",
        defaultWidth: 150,
        options: {order: true, freeze: true, sort: {sortKey: 'size'}, search: {searchKey: 'size'}}
    },
    {
        title: "Sample %",
        key: "sample",
        defaultWidth: 150,
        options: {order: true, freeze: true, sort: {sortKey: 'sample'}, search: {searchKey: 'sample'}}
    },
    {
        title: "Execution Time",
        key: "executionTime",
        defaultWidth: 150,
        options: {order: true, freeze: true, sort: {sortKey: 'executionTime'}, search: {searchKey: 'executionTime'}}
    }
];
