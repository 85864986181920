import {Column, MaterialIcon, Row, TableRowSelectionHook} from '@vidazoo/ui-kit';
import React from 'react';
import {observer} from 'mobx-react';
import BoxLoader from '../../../../common/components/box-loader/BoxLoader';
import {ChartsListModel} from '../model/ChartsListModel.mdl';
import {ChartsTableColumns} from '../common/constants';
import {Link} from 'react-router-dom';
import UrlsService from '../../../../common/router/services/urlsService.mdl';
import MarkeredText from '../../../../common/components/markered-text/MarkeredText';
import dayjs from 'dayjs';
import clsx from 'clsx';
import {useChartsListContext} from '../context/ChartsListContext';

export const LOADING_ROW_HEIGHT = 24;

export interface IChartsTableRowProps {
    columnsManager: ChartsListModel;
    tableRowSelectionHook: TableRowSelectionHook;
    itemIndex: number;
    viewportIndex: number;
    style: React.CSSProperties;
}

function ChartsTableRow({itemIndex, style, columnsManager}: IChartsTableRowProps) {
    const {model, tableRowSelectionHook} = useChartsListContext();
    const {isItemSelected, toggleItem} = tableRowSelectionHook;

    if (columnsManager.isLoading && !columnsManager.data) {
        return (
            <Row style={style} key={itemIndex} index={itemIndex}>
                <Column width={40} className='flex items-center justify-center p-4'>
                    <BoxLoader.Row height={LOADING_ROW_HEIGHT}/>
                </Column>
                {ChartsTableColumns.map(() => (
                    <Column width={40} className='flex items-center justify-center p-4'>
                        <BoxLoader.Row height={LOADING_ROW_HEIGHT}/>
                    </Column>
                ))}
            </Row>
        );
    }
    const item = columnsManager.maskItems.at(itemIndex);

    return (
        <Row style={style} key={item._id} index={itemIndex}>
            <Column colKey="checkbox" width={40} className='flex items-center justify-center'>
                <input type="checkbox" className="flex w-8 h-8 cursor-pointer" checked={isItemSelected(item._id)}
                       onChange={() => toggleItem(item._id)}/>
            </Column>
            <Column colKey="name">{
                <Link to={UrlsService.editChart(item._id)}>
                    <MarkeredText
                        marker={model.getColumnSearchQuery('name')}>{item.name}</MarkeredText>
                </Link>
            }</Column>
            <Column colKey="type">{
                <MarkeredText
                    marker={model.getColumnSearchQuery('type')}>{item.type}</MarkeredText>
            }</Column>
            <Column colKey="created">{dayjs(item.created).format('MMM DD, YYYY')}</Column>
            <Column colKey="isActive">
                <div className="flex items-center">
                    <MaterialIcon iconName={item.isActive ? 'toggle_on' : 'toggle_off'}
                                  className={clsx('mr-4 text-4xl', {
                                      'text-vdzblue-300': item.isActive,
                                      'text-vdzgray-400': !item.isActive,
                                  })}/>
                    <div className="truncate">{item.isActive ? 'TRUE' : 'FALSE'}</div>
                </div>
            </Column>
        </Row>
    );
}

export default observer(ChartsTableRow);
