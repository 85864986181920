import {MaterialIcon} from '@vidazoo/ui-kit';
import * as React from 'react';
import {useColorScheme} from "@vidazoo/ui-kit";
import {useMainMenuContext} from '../../contexts/MainMenuContext';
import theme from './theme.module.scss';
import clsx from 'clsx';

const GROUP = 'THEME_SWITCH';

function ThemeSwitch(): JSX.Element {
    const {scheme, setColorScheme} = useColorScheme();
    const {selectedGroup, setSelectedGroup} = useMainMenuContext();

    const rowClassName = clsx("py-2 px-3 flex items-center cursor-pointer", theme.drawerRow)
    return (
        <div onMouseEnter={() => setSelectedGroup(GROUP)} onMouseLeave={() => setSelectedGroup(null)}
             className={theme.themeSwitch}>
            <button type="button" className="flex">
                <MaterialIcon style={scheme === "light" ? {} : {display: "none"}} className="text-white"
                              iconName="light_mode"/>
                <MaterialIcon style={scheme === "dark" ? {} : {display: "none"}} className="text-white"
                              iconName="dark_mode"/>
                <MaterialIcon style={scheme === "auto" ? {} : {display: "none"}} className="text-white"
                              iconName="computer"/>
            </button>
            {selectedGroup === GROUP ? (
                <div className={theme.drawer}>
                    <ul>
                        <li className={rowClassName}
                            data-theme="light" onClick={() => setColorScheme("light")}>
                            <MaterialIcon className={theme.icon} iconName="light_mode"/>
                            Light
                        </li>
                        <li className={rowClassName}
                            data-theme="dark" onClick={() => setColorScheme("dark")}>
                            <MaterialIcon className={theme.icon} iconName="dark_mode"/>
                            Dark
                        </li>
                        <li className={rowClassName}
                            data-theme="auto" onClick={() => setColorScheme("auto")}>
                            <MaterialIcon className={theme.icon} iconName="computer"/>
                            System
                        </li>
                    </ul>
                </div>

            ) : null}
        </div>
    );
}

export default ThemeSwitch;
