import {memo} from 'react';
import {Loader, PageMessage} from '@vidazoo/ui-kit';
import theme from "../../styles/_baseForm.module.scss";

export interface IPageMessageLoaderProps {
    title: string;
    description: string;
}

function PageMessageLoader({title, description}: IPageMessageLoaderProps): JSX.Element {
    return (
        <div className={theme.message}>
            <Loader size={1} gray className={theme.loader}/>
            <PageMessage
                small
                title={title}
                description={description}
            />
        </div>
    );
}

export default memo(PageMessageLoader);
