import {init, inject} from '@injex/core';
import {AxiosPromise, AxiosRequestConfig} from 'axios';
import RequestAdapter from './RequestAdapter';
import {Injex} from '@injex/webpack';
import hash from "object-hash";
import {Objects} from '@vidazoo/ui-kit';
import IEnv from '../interfaces/IEnv';
import {SessionManager} from '../../modules/session/managers/sessionManager.mdl';

export type CrudItemParams<T> = { [J in keyof Partial<T>]: any };

export type GetByIdModel<T> = {
    fields?: CrudItemParams<T>;
    populate?: any[];
    lean?: boolean;
};

export type GetAllModel<T> = {
    page?: number;
    pageSize?: number;
    sort?: CrudItemParams<T>;
    filter?: CrudItemParams<T>;
    fields?: CrudItemParams<T>;
    populate?: any[];
    lean?: boolean;
};

export type RequestContext = {
    accessToken?: string;
    accounts?: string[];
};

export type ListResults<T> = {
    count: number;
    results: T[]
};

export interface IRequestContextResolver {
    getContext(): RequestContext;
}

const CACHE_TIME = 1000 * 60 * 2; // 2 minutes
const CACHE_TIME_FIELDS = 1000 * 60 * 10; // 2 minutes

export default abstract class EntityRequestAdapter<T = any> extends RequestAdapter {
    @inject() protected env: IEnv;
    @inject() private sessionManager: SessionManager;

    @inject() private $injex: Injex;

    protected abstract basePathName: string;

    protected abstract get apiEndpoint(): string

    public getAllLocalCache: { [index: string]: { data: { results: T[]; count: number; }, time: number } } = {};

    protected get useAuthorizationHeaders(): boolean {
        return true;
    }

    @init()
    protected initialize() {
        this.createHTTPClient(this.apiEndpoint);
    }

    public async getAllActive(model: GetAllModel<T>): Promise<{ results: T[]; count: number; }> {
        model.filter = model.filter || {} as any;
        model.filter['isActive'] = true;
        return this.getAll(model);
    }

    public async getAll(model: GetAllModel<T>, cache: boolean = true, cacheTime?: number): Promise<{
        results: T[];
        count: number;
    }> {

        let keyModel;
        if (cache) {
            keyModel = this._createKeyFromModel(model)
            const data = this._getFromCache(keyModel);
            if (data) {
                return data;
            }
        }

        const response = await this.requestWithContext<{ results: T[]; count: number; }>({
            method: 'get',
            url: this.makeAPIPath('/'),
            params: model
        });

        if (keyModel) {
            this._saveToCache(keyModel, response.data, cacheTime);
        }

        return response.data;
    }

    public async getAllFields(): Promise<string[]> {

        const data = this._getFromCache(this.basePathName);
        if (data) {
            return data;
        }

        const response = await this.requestWithContext<string[]>({
            method: 'get',
            url: this.makeAPIPath('/fields'),
        });

        this._saveToCache(`${this.basePathName}_fields`, response.data, CACHE_TIME_FIELDS);

        return response.data;
    }

    public async getById(id: string, model: GetByIdModel<T>): Promise<T> {
        const response = await this.requestWithContext<T>({
            method: 'get',
            url: this.makeAPIPath(`/${id}`),
            params: model
        });

        return response.data;
    }

    public async create(model: Partial<T>): Promise<T> {
        const response = await this.requestWithContext<T>({
            method: 'post',
            url: this.makeAPIPath('/'),
            data: model
        });

        return response.data;
    }

    public async updateById(id: string, model: Partial<T>): Promise<T> {
        const response = await this.requestWithContext<T>({
            method: 'patch',
            url: this.makeAPIPath(`/${id}`),
            data: model
        });

        return response.data;
    }

    public async deleteById(id: string): Promise<void> {
        const response = await this.requestWithContext<void>({
            method: 'delete',
            url: this.makeAPIPath(`/${id}`)
        });

        return response.data;
    }

    public async activeById(id: string, isActive: boolean): Promise<T> {
        const response = await this.requestWithContext<T>({
            method: 'patch',
            url: this.makeAPIPath(`/${id}/active`),
            data: {isActive}
        });

        return response.data;
    }

    protected requestWithContext<K>(config: AxiosRequestConfig): AxiosPromise<K> {
        if (this.useAuthorizationHeaders) {
            const {accounts = [], accessToken = ''} = this.sessionManager.getRequestContext();
            if (!accessToken) {
                this.$injex.logger.error('Failed to get accessToken');
            }
            config.headers = {
                'Authorization': `Bearer ${accessToken}`,
                'X-Accounts': accounts.join(','),
                ...config.headers
            };
        }

        return super.request<K>(config);
    }

    protected makeAPIPath(path: string): string {
        return `/api/${this.basePathName}${path}`;
    }

    private _createKeyFromModel(model: GetAllModel<T>): string {
        return hash(JSON.stringify({
            basePathName: this.basePathName,
            page: model.page,
            pageSize: model.pageSize,
            sort: Objects.sort(model.sort),
            filter: Objects.sort(model.filter),
            fields: Objects.sort(model.fields),
            populate: model.populate,
            lean: model.lean,
        }));
    }

    private _clearLocalCache(keyModel: string) {
        this.$injex.logger.info('[CLEAR LOCAL CACHE]', this.basePathName, keyModel);
        delete this.getAllLocalCache[keyModel];
    }

    private _getFromCache(key: string): any {
        if (this.getAllLocalCache[key]) {
            this.$injex.logger.info('[GET ALL FROM LOCAL CACHE]', this.basePathName, key);
            return this.getAllLocalCache[key].data;
        }
    }

    private _saveToCache(key: string, data: any, cacheTime?: number) {
        this.$injex.logger.info('[SAVE GET ALL TO LOCAL CACHE]', this.basePathName, key);
        this.getAllLocalCache[key] = {data, time: Date.now()};
        setTimeout(() => this._clearLocalCache(key), (cacheTime || CACHE_TIME));
    }
}
