import {observer} from 'mobx-react';
import * as React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {AuthRole, AuthStatus} from '../../common/enums';
import useSessionManager from '../../hooks/useSessionManager';
import useSessionStatus from '../../hooks/useSessionStatus';
import AuthorizingLoader from '../authorizing-loader/AuthorizingLoader';

const UNAUTH_STATUS = [AuthStatus.LoggedIn, AuthStatus.Authorizing];

export interface IPrivateRouteProps extends React.PropsWithChildren<any> {
    roles?: AuthRole[];
}

function PrivateRoute({children, roles = []}: IPrivateRouteProps): JSX.Element {
    const sessionManager = useSessionManager();
    const status = useSessionStatus();
    const navigate = useNavigate();
    const location = useLocation();

    React.useEffect(() => {
        if (!UNAUTH_STATUS.includes(status)) {
            const navTo = `/login${location.pathname !== '/' && !sessionManager.userLoggedOut ? `?callback=${location.pathname}` : ''}`;
            navigate(navTo, {replace: true});
        } else if (roles.length && !sessionManager.userHasRole(roles)) {
            navigate('/', {replace: true});
        }
    }, [status, navigate]);

    return status === AuthStatus.Authorizing
        ? <AuthorizingLoader/>
        : status === AuthStatus.LoggedOut
            ? null
            : children;
}

export default observer(PrivateRoute);
