import {OpenRtbReportingFilterType} from "../ReportingFilterType";
import BaseFilter from "../BaseFilter";
import countries from '../../../enums/Countries';
import {alias} from '@injex/core';

@alias('OpenRtbReportingFilter')
export default class CountryFilter extends BaseFilter {

	public type = OpenRtbReportingFilterType.Country;

	constructor() {
		super();

		this.items = countries;
		this.labelKey = "name";
		this.valueKey = "code";
		this.allowNew = false;
	}
}
