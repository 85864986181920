import React, {useEffect} from 'react';
import ReportingHistoryList from "./components/ReportingHistoryList";
import EditHistoryReportModal from "./components/EditHistoryReportModal";
import {Label} from "@vidazoo/ui-kit";
import useReportingModel from "../../common/hooks/useReportingModel";
import useHistoryReportsColumnsManager from "../../common/hooks/useHistoryReportsColumnsManager";
import useHistoryReportsManager from "../../common/hooks/useHistoryReportsManager";
import theme from './theme.module.scss';
import {observer} from "mobx-react";

function ReportingHistory() {
    const model = useReportingModel();
    const historyReportsManager = useHistoryReportsManager();
    const historyReportsColumnsManager = useHistoryReportsColumnsManager();

    useEffect(() => {
        if (!historyReportsColumnsManager.items.data.length) {
            historyReportsColumnsManager.items.invalidate().fetch();
            historyReportsColumnsManager.resetFilter();
        }
    }, []);

    if (!model.useHistoryReports || historyReportsManager.selectedReport) {
        return null;
    }

    return (
        <>
            <ReportingHistoryList/>
            <EditHistoryReportModal/>
        </>
    );
}

export default observer(ReportingHistory);
