import * as React from 'react';
import {Icon} from '@vidazoo/ui-kit';
import {MaterialIcon} from '@vidazoo/ui-kit';
import {IconSource} from '../../enums/IconSource';

export interface IGenericIconProps {
    iconName: string;
    iconSource: IconSource;
    [index: string]: any;
}

function GenericIcon({iconSource, ...others}: IGenericIconProps): JSX.Element {
    switch (iconSource) {
        case IconSource.Vidazoo:
            return <Icon {...others} />;
        case IconSource.Material:
            return <MaterialIcon size="" {...others} />;
        default:
            return null;
    }
}

export default GenericIcon;
