import * as React from "react";
import {Form, Label, FormInput, Button, GroupButton, ButtonsGroup, Modal} from "@vidazoo/ui-kit";
import theme from "./theme.module.scss";
import {ReportPresetType} from "../../common/enums/ReportPresetType";
import {normalizeEventValue} from "../../../../common/utils/utils";
import useReportingPresetReportsManager from "../../common/hooks/useReportingPresetReportsManager";
import {observer} from "mobx-react";

function CreatePresetModel() {
    const {create, showCreatePresetModal, onCloseCreatePresetModal} = useReportingPresetReportsManager();
    const [name, setName] = React.useState("");
    const [type, setType] = React.useState(ReportPresetType.Private);

    const onChangeName = (e) => {
        setName(normalizeEventValue(e))
    };

    const onChangeType = (type: ReportPresetType) => {
        setType(type);
    };

    const onClose = () => {
        setName("");
        setType(ReportPresetType.Private);
        onCloseCreatePresetModal();
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setName((prevName) => {
            create({name: prevName, type});
            return prevName;
        });
        onClose();
    };

    return (
        <Modal exitOnClickOutside basic windowClass={theme.modal} onClose={onClose} isOpen={showCreatePresetModal}>
            <Form onSubmit={onSubmit} className={theme.modal}>
                <Label monserrat uppercase spacing={1.5} weight={600}>Name your preset report</Label>
                <FormInput onChange={onChangeName} value={name} required name="name" label="Name" fill
                           className={theme.input}/>
                <div className={theme.type}>
                    <ButtonsGroup borders active={type} onChange={onChangeType}>
                        <GroupButton className={theme.groupButton}
                                     name={ReportPresetType.Public}>Public</GroupButton>
                        <GroupButton className={theme.groupButton}
                                     name={ReportPresetType.Private}>Private</GroupButton>
                    </ButtonsGroup>
                </div>
                <div className={theme.actions}>
                    <Button gray medium onClick={onClose}>Cancel</Button>
                    <Button type="submit" blue medium>Create Preset</Button>
                </div>
            </Form>
        </Modal>
    );
}

export default observer(CreatePresetModel);
