import {define, init, inject, singleton} from '@injex/core';
import BaseUserManager from '../base/BaseUserManager';
import LocalStorageManager from './LocalStorageManager.mdl';
import {INetwork} from '../interfaces/INetwork';
import {NetworkFeatures} from '../enums/NetworkFeatures';
import {UserRole} from '../enums/UserRole';
import {ImportantOrganizations} from '../enums/ImportantOrganizations';
import {SessionManager} from '../../modules/session/managers/sessionManager.mdl';
import {IUserMetadata} from '../../modules/session/interfaces/IUserMetadata';
import {ISession, IUser} from '../../modules/session/interfaces/IAccountsSDK';
import {makeObservable} from "mobx";
import Hook from "../utils/Hook";

export type UserHooks = {
    setDataSuccess: Hook;
};

@define()
@singleton()
export default class UserManager extends BaseUserManager {

    @inject() public localStorageManager: LocalStorageManager;
    @inject() private sessionManager: SessionManager;
    public hooks: UserHooks;

    constructor() {
        super();
        makeObservable(this);

        this.hooks = {
            setDataSuccess: new Hook(),
        };
    }

    @init()
    protected initialize() {
        this.sessionManager.hooks.metadataLoad.tapAsync(this._onMetadataLoad, null, this);
    }

    private _onMetadataLoad(metadata: IUserMetadata, session: ISession) {
        this.setData(metadata.auth, session);
        this.localStorageManager.initialize(this._id);
        this.hooks.setDataSuccess.call();
    }

    public setNetwork = (network: INetwork) => {
        this.network = network;
    };

    public isInRoles = (scope: UserRole[]): boolean => {
        return (scope && scope.length > 0)
            ? scope.some((role) => this.roles.includes(role))
            : true;
    };

    public isBelongToUser(userId: string): boolean {
        return this._id === userId;
    }

    public isDeveloper(): boolean {
        return this.isInRoles([UserRole.DEVELOPER]);
    }

    public isAnyAdmin = () => {
        return this.isInRoles([UserRole.ADMIN, UserRole.ROOT_ADMIN]);
    }

    public isAdmin = () => {
        return this.isInRoles([UserRole.ADMIN]);
    }

    public isRootAdmin = () => {
        return this.isInRoles([UserRole.ROOT_ADMIN]);
    };

    public isRootAdminAndVidazoo = () => {
        return (this.isInRoles([UserRole.ROOT_ADMIN]) && this.isVidazooOrganization);
    };

    public isSuperAdminAndVidazoo = () => {
        return (this.isInRoles([UserRole.SUPER_ADMIN]) && this.isVidazooOrganization);
    };

    public shouldViewAll = () => {
        return this.isInRoles([UserRole.VIEW_ALL]);
    }

    public isSuperAdmin = () => {
        return this.isInRoles([UserRole.SUPER_ADMIN]);
    }

    public isPublisher = () => {
        return this.isInRoles([UserRole.PUBLISHER]);
    }

    public isDealInfoWrite = () => {
        return this.isInRoles(["DEAL_INFO_WRITE" as any]);
    }

    public isVidazooNetwork = () => {
        return this.isFeatureAllowed([NetworkFeatures.VIDAZOO]);
    };

    public isEuTraffic = () => {
        return this.isFeatureAllowed([NetworkFeatures.EU_TRAFFIC], null, true);
    }

    public isSaasLite = () => {
        return this.isFeatureAllowed(NetworkFeatures.SAAS_LITE, null, true)
    }

    public isThirdPartyPublishers = () => {
        return this.isFeatureAllowed(NetworkFeatures.THIRD_PARTY_PUBLISHERS, null, true)
    }

    public isAllowToUsePlaylistByTargeting = () => {
        return this.isFeatureAllowed(NetworkFeatures.PLAYLIST_BY_TARGETING, null, true)
    }

    public isSaasLiteAndNotVidazooOrganization = () => {
        return (this.isSaasLite() && !this.isVidazooOrganization);
    }

    public isFeatureAllowed = (include?: NetworkFeatures[] | NetworkFeatures, exclude?: NetworkFeatures[] | NetworkFeatures, strict = false): boolean => {
        if (include && !(include instanceof Array)) {
            include = [include];
        }

        if (exclude && !(exclude instanceof Array)) {
            exclude = [exclude];
        }

        const isRootAdminOrShouldViewAll = this.isRootAdmin() || this.shouldViewAll();

        if (this.network) {
            const allFeatures = this.network.features || [];
            const included = include && include.length > 0 ? (include as Array<NetworkFeatures>).some((feature: NetworkFeatures) => allFeatures.includes(feature)) || (!strict && isRootAdminOrShouldViewAll) : true;
            const excluded = exclude && exclude.length > 0 ? !(exclude as Array<any>).some((feature) => allFeatures.includes(feature)) && (strict || !isRootAdminOrShouldViewAll) : true;

            return included && excluded;
        }

        return false;
    };

    public isInviteVidazoo = () => {
        return this.isInRoles([UserRole.INVITE_VIDAZOO]);
    }

    public get isVidazooOrPerionOrganization(): boolean {
        return this.isVidazooOrganization || this.isPerionOrganization;
    }

    public get isPerionOrganization(): boolean {
        return this.organization._id === ImportantOrganizations.Perion;
    }

    public get isVidazooOrganization(): boolean {
        return this.organization._id === ImportantOrganizations.Vidazoo;
    }

    public isScopeAvailableToUser = (scope: string) => {
        return this.scopes && this.scopes.find((userScope) => userScope.scope === scope);
    };

    public isUserHasHighDbPermission = () => {
        return ["5c975c1a5806dd0004525dd9", "5a8027834ece770004ecb671", "5aa018feb1c11c000453cec3", "5a564e9432017a5bfd44eb5e"].includes(this._id);
    }

}
