import {ContextMenu, Infocon, LinkButton, Chips, FormChips} from '@vidazoo/ui-kit';
import {ReportingContextMenuType} from '../../common/enums/ReportingContextMenuType';
import theme from '../reportingHeader/theme.module.scss';
import {useCallback, useMemo} from 'react';
import {observer} from 'mobx-react';
import MemoryClipboard from '../../../../common/utils/MemoryClipboard';
import useReportingMetaDataManager from '../../../../common/hooks/useReportingMetaDataManager';
import {ReportVerticalType} from '../../../../common/enums/ReportVerticalType';
import IReportingFilter from '../../common/interfaces/IReportingFilter';
import IReportingEntry from '../../common/interfaces/IReportingEntry';
import IReportingParams from '../../common/interfaces/IReportingParams';
import useReportingModel from "../../common/hooks/useReportingModel";
import useAutoCompleteListWidth from "../../../../common/hooks/useAutoCompleteListWidth";

export interface IReportingDimensionsProps {
    onClearAllParams: (key: ReportingContextMenuType) => void;
    onMultiplePaste: (param: string, filter?: IReportingFilter) => Promise<void>;
    groups: IReportingEntry[];
    removeReportParam: (key: string, value: string) => void;
    setParam: (key: keyof IReportingParams, value: any) => void;
    addFilter: () => IReportingFilter;
    addSubDimension?: () => void;
    pushReportParam: (key: string, value: string, label: string) => void;
}

function ReportingDimensions(props: IReportingDimensionsProps) {

    const {
        pushReportParam,
        addFilter,
        addSubDimension,
        setParam,
        removeReportParam,
        onMultiplePaste,
        onClearAllParams,
        groups
    } = props;
    const reportingModel = useReportingModel();
    const reportingMetadataManager = useReportingMetaDataManager();
    const autoCompleteListWidth = useAutoCompleteListWidth();

    const copyDimensions = () => {
        MemoryClipboard.copy(window.getSelection().toString() || groups.map((d) => d.label).join(", "));
    }

    const contextMenuItems = useMemo(() => {
        return [
            {label: "Clear All Dimensions", onClick: () => onClearAllParams(ReportingContextMenuType.groups)},
            {label: "Copy", onClick: copyDimensions},
            {label: "Paste Multiple", onClick: () => onMultiplePaste(ReportingContextMenuType.groups)},
        ];
    }, []);

    const onDeleteDimension = useCallback((value: string) => {
        removeReportParam("groups", value);
        reportingModel.handleSetColumns();
    }, []);

    const onOrderDimensions = useCallback((dimensions: any[]) => {
        setParam("groups", dimensions);
        reportingModel.handleSetColumns();
    }, []);

    const onAddDimension = useCallback((value: string, label: string) => {
        pushReportParam("groups", value, label);
        reportingModel.handleSetColumns();
    }, []);

    return (
        <ContextMenu items={contextMenuItems}>
            <div className={theme.dimentions}>
                <div className={theme.chipsHeader}>
                    <div className={theme.description}>
                        <div className={theme.label}>Dimensions</div>
                        <Infocon className={theme.infocon} leaveOpenOnMouseOver={true}
                                 title={<div>Dimensions are attributes of your data.<br/>The values
                                     of dimensions are groups of<br/>Strings and Booleans.</div>}
                                 position="bottom"/>
                    </div>
                    <div className={theme.actions}>
                        <LinkButton type="button" className={theme.link} iconName="icon-plus-small" deepPurple
                                    small uppercase spaced bold onClick={addFilter}>Add Filter</LinkButton>
                        {addSubDimension ? (
                            <LinkButton type="button" className={theme.link} iconName="icon-plus-small"
                                        deepPurple small uppercase spaced bold
                                        onClick={addSubDimension}>Add
                                SubDimension</LinkButton>
                        ) : null}
                    </div>
                </div>
                <FormChips
                    allowOrder
                    chips={groups}
                    maxItems={5}
                    leaveOpenAfterSelect
                    onDelete={onDeleteDimension}
                    onOrder={onOrderDimensions}
                    onSelect={onAddDimension}
                    isLoading={reportingMetadataManager.isLoading}
                    dataSource={reportingMetadataManager.metaDataByVertical[ReportVerticalType.PLATFORM]?.groups.entries}
                    searchPlaceholder="Add Dimension"
                    emptyPlaceholder="Add Dimension"
                    autoCompleteListWidth={autoCompleteListWidth}
                    itemLabelKey={"label"}
                    itemValueKey={"value"}
                />
            </div>
        </ContextMenu>
    )
}

export default observer(ReportingDimensions);
