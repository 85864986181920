import IChart from '../../interfaces/IChart';
import {TableColumn} from '@vidazoo/ui-kit';

export const ChartsTableColumns: TableColumn<IChart>[] = [
    {
        title: 'Checkbox',
        key: 'checkbox',
    },
    {
        title: 'Name',
        key: 'name',
        defaultWidth: 300,
        options: {order: false, search: {searchKey: 'name'}, freeze: true, sort: {sortKey: 'name'}}
    },
    {
        title: "Type",
        key: "type",
        defaultWidth: 200,
        options: {
            order: false,
            freeze: true,
            sort: {sortKey: 'type'},
            filter: {
                filterKey: 'type',
                options: [{value: "data_table", label: "Diff Table"}, {
                    value: "summary",
                    label: "Summary"
                }, {value: "performance", label: "Performance"}]
            }
        }
    }, {
        title: "Created",
        key: "created",
        defaultWidth: 200,
        options: {order: false, search: {searchKey: 'created'}, freeze: true, sort: {sortKey: 'created'}}
    }, {
        title: "Active",
        key: "isActive",
        defaultWidth: 200,
        options: {order: false, search: {searchKey: 'isActive'}, sort: {sortKey: 'isActive'}}
    }, {
        title: "Actions",
        key: "actions",
    }
];
