import {
    Surface,
    Form,
    addEventsToDocument,
    removeEventsFromDocument, Switch,
    Attention, TimezonePicker
} from '@vidazoo/ui-kit';
import theme from './theme.module.scss';
import ReportingActions from '../reportingActions/ReportingActions';
import {useCallback, useEffect} from 'react';
import ReportingConstraints from '../reportingConstraints/ReportingConstraints';
import ReportingDBs from '../reportingDBs/ReportingDBs';
import ReportingTimePresetSelector from '../reportingTimePresetSelector/ReportingTimePresetSelector';
import ReportingDimensions from '../reportingDimensions/ReportingDimensions';
import ReportingFilters from '../reportingFilters/ReportingFilters';
import ReportingMetrics from '../reportingMetrics/ReportingMetrics';
import useReportingParamsManager from '../../common/hooks/useReportingParamsManager';
import {observer} from 'mobx-react';
import {DateRangePicker, TimePreset} from '@vidazoo/date-range';
import useReportingModel from '../../common/hooks/useReportingModel';
import dayjs from 'dayjs';
import ReportingSubDimensions from "../reportingSubDimensions/ReportingSubDimensions";
import {ISubGroup} from "../../common/interfaces/ISubGroup";
import {ReportingContextMenuType} from "../../common/enums/ReportingContextMenuType";
import DynamicFormulaModal from "../dynamicFormulaModal/DynamicFormulaModal";

function ReportingHeader() {
    const {
        pushReportParam, addFilter, addSubDimension, setParam, removeReportParam,
        rollBackParams, params, onClearAllParams, onMultiplePaste,
        setFilterKey, setFilterParam, removeFilter, pushFilterValue, removeFilterValue,
        setConstraintParam, removeConstraint, addConstraint, setPreset, setSubGroupParam,
        removeSubGroup, pushSubGroupValue, removeSubGroupValue, pushSubGroupTargetingType,
        removeSubGroupTargetingType, pushSubGroupTargetingOperator, removeSubGroupTargetingOperator,
        applyTimePreset
    } = useReportingParamsManager();

    const model = useReportingModel();

    useEffect(() => {
        addEventsToDocument({
            keydown: onDocumentKeyDown,
        });
        applyTimePreset(TimePreset.Now);

        return () => {
            removeEventsFromDocument({
                keydown: onDocumentKeyDown,
            });
        };
    }, []);

    const onDocumentKeyDown = useCallback((e) => {
        if (e.keyCode === 90 && (e.ctrlKey || e.metaKey)) {
            rollBackParams();
        }
    }, []);

    const getReport = () => {
        model.getReport();
    };

    let message = "You are using History Reports 🤘";
    if (!model.useHistoryReports) {
        message = "You are using reporting without History Reports 😭";
    }

    return (
        <Form onSubmit={getReport}>
            <Surface className={theme.surface} level={1}>
                <div className={theme.head}>
                    <Attention green={model.useHistoryReports} red={!model.useHistoryReports}
                               label={message}/>
                    <Switch className={theme.switch} green label="Use reporting with history reports"
                            defaultChecked={model.useHistoryReports}
                            onChange={model.toggleUseHistoryReports}/>
                    <ReportingDBs
                        dbType={params.dbType}
                        setParam={setParam}
                    />
                </div>
                <div className={theme.top}>
                    <div className={theme.presets}>
                        <ReportingTimePresetSelector/>
                    </div>
                    <div className={theme.date}>
                        <DateRangePicker
                            onFromChange={(from) => setParam("from", dayjs(from))}
                            fromDate={params.from || dayjs().startOf('day')}
                            onToChange={(to) => setParam("to", dayjs(to))}
                            toDate={params.to || dayjs().startOf('day')}

                            onCompareFromChange={(from) => setParam("compareFrom", dayjs(from))}
                            compareFromDate={params.compareFrom || dayjs().startOf('day')}
                            onCompareToChange={(to) => setParam("compareTo", dayjs(to))}
                            compareToDate={params.compareTo || dayjs().startOf('day')}
                            isCompareActive={params.isCompare}
                            onCompareActiveChange={(isCompare) => setParam("isCompare", isCompare)}

                            onPresetChange={setPreset}
                            preset={params.time as any}
                        />
                        <TimezonePicker
                            className={theme.timezone}
                            onChange={(e) => setParam("timezone", e.target.value)}
                            timezone={params.timezone}
                            disabled={model.isLoading}
                            searchable
                        />
                        <Switch className={theme.compare} small label="Compare Dates" checked={params.isCompare}
                                onChange={() => setParam("isCompare", !params.isCompare)}/>
                    </div>
                </div>
                <div className={theme.bottom}>
                    <div className={theme.left}>
                        <ReportingDimensions
                            pushReportParam={pushReportParam}
                            addFilter={addFilter}
                            addSubDimension={addSubDimension}
                            setParam={setParam}
                            removeReportParam={removeReportParam}
                            onMultiplePaste={onMultiplePaste}
                            onClearAllParams={onClearAllParams}
                            groups={params.groups}
                        />
                        <ReportingFilters
                            filters={params.filters}
                            onClearAllParams={onClearAllParams}
                            pasteFilter={(filter) => onMultiplePaste("filters", filter)}
                            setFilterKey={setFilterKey}
                            setFilterParam={setFilterParam}
                            removeFilter={removeFilter}
                            pushFilterValue={pushFilterValue}
                            removeFilterValue={removeFilterValue}
                        />
                        <ReportingSubDimensions
                            subGroups={params.subGroups}
                            onChangeSubGroup={setSubGroupParam}
                            onDeleteSubGroup={removeSubGroup}
                            onAddSubGroupValue={pushSubGroupValue}
                            onRemoveSubGroupValue={removeSubGroupValue}
                            onAddTargetingType={pushSubGroupTargetingType}
                            onDeleteTargetingType={removeSubGroupTargetingType}
                            onAddTargetingOperator={pushSubGroupTargetingOperator}
                            onDeleteTargetingOperator={removeSubGroupTargetingOperator}
                            onClearAllParams={onClearAllParams}
                        />
                    </div>
                    <div className={theme.right}>
                        <ReportingMetrics
                            fields={params.fields}
                            setParam={setParam}
                            removeReportParam={removeReportParam}
                            pushReportParam={pushReportParam}
                            addConstraint={addConstraint}
                            onMultiplePaste={onMultiplePaste}
                            onClearAllParams={onClearAllParams}
                        />
                        <ReportingConstraints
                            constraints={params.constraints}
                            onClearAllParams={onClearAllParams}
                            setConstraintParam={setConstraintParam}
                            removeConstraint={removeConstraint}
                        />
                    </div>
                </div>
                <ReportingActions/>
            </Surface>
            <DynamicFormulaModal/>
        </Form>
    );
}

export default observer(ReportingHeader);

