import {observer} from 'mobx-react';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import UrlsService from '../../../../common/router/services/urlsService.mdl';
import PageTitle from '../../../../common/components/page-title/PageTitle';
import {useChartsListContext} from '../context/ChartsListContext';
import {Button} from "@vidazoo/ui-kit";
import clsx from 'clsx';
import theme from '../view/theme.module.scss';

function ChartsHeader(): JSX.Element {
    const navigate = useNavigate();
    const {model} = useChartsListContext();

    return (
        <div className={clsx("h-24 flex items-center justify-between pr-5 pl-5", theme.header)}>
            <PageTitle iconName="handshake" title="Charts List"
                       itemsCount={model.items.isLoading ? "Loading..." : model.items.data.length}/>
            <div className="flex items-center">
                <Button type="button" uppercase blue medium iconName="add_circle"
                        onClick={() => navigate(UrlsService.createChart())}>Create</Button>
            </div>
        </div>
    );
}

export default observer(ChartsHeader);
