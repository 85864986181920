import {observer} from 'mobx-react';
import React, {useCallback, useEffect} from 'react';
import theme from '../../../view/theme.module.scss';
import {Table} from '@vidazoo/ui-kit';
import {ReportingHistoryListHeader} from './ReportingHistoryListHeader';
import ReportingHistoryListRow from './ReportingHistoryListRow';
import useHistoryReportsColumnsManager from "../../../common/hooks/useHistoryReportsColumnsManager";

const ROW_HEIGHT = 40;
const LOADING_ROW_HEIGHT = 24;
const LOADING_ITEMS_COUNT = () => Math.round(window.innerHeight / LOADING_ROW_HEIGHT);

function ReportingHistoryList(): JSX.Element {
    const model = useHistoryReportsColumnsManager();

    useEffect(() => {
        model.resetFilter();
    }, []);

    const renderTableRow = useCallback((itemIndex: number, viewportIndex: number, style: React.CSSProperties) => {
        const item = model.maskItems?.at(itemIndex) ?? null
        return (
            <ReportingHistoryListRow
                key={item ? item._id : itemIndex}
                itemIndex={itemIndex}
                viewportIndex={viewportIndex}
                style={style}/>
        );
    }, [model.maskItems, model, model.columns]);

    const renderTableHeader = useCallback(() => {
        return (
            <ReportingHistoryListHeader/>
        );
    }, [model]);

    return (
        <Table
            className={theme.table}
            itemsCount={(model.isLoading && !model.data) ? LOADING_ITEMS_COUNT() : model.maskItems.length}
            rowHeight={ROW_HEIGHT}
            minColumnWidth={50}
            renderRow={renderTableRow}
            renderHeader={renderTableHeader}
            columnsManager={model}
            overscanItems={5}
            useStickyScrollbar
        />
    );
}

export default observer(ReportingHistoryList);
