import NavLinkGroup from '../../app/components/main-menu/components/nav-link-group/NavLinkGroup';
import NavLink from '../../app/components/main-menu/components/NavLink';
import UrlsService from '../../../common/router/services/urlsService.mdl';

export default function DashboardsLinks(): JSX.Element {

    return (
        <NavLinkGroup to={UrlsService.dashboards()} label="Dashboards" iconName="icon-dashboard-2">
            <NavLink className="m-6" to={UrlsService.dashboards()}>Dashboards List</NavLink>
            <NavLink className="m-6" to={UrlsService.createDashboard()}>Create Dashboard</NavLink>
        </NavLinkGroup>
    );
}
