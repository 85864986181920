import {alias, define, inject, injectAlias, singleton} from '@injex/core';
import {Injex} from '@injex/webpack';
import {makeObservable, observable} from 'mobx';
import IDisposable from '../../../common/interfaces/IDisposable';
import DataQuery from '../../../common/models/DataQuery';
import {isDefined, Arrays} from '@vidazoo/ui-kit';
import {AuthRole, AuthStatus} from '../common/enums';
import IAccountsSDK, {ISession, IUser} from '../interfaces/IAccountsSDK';
import IViewAsUser from '../interfaces/IViewAsUser';
import {IUserMetadata} from '../interfaces/IUserMetadata';
import ApiManager from '../../../common/api/ApiManager.mdl';
import UserManager from "../../../common/managers/UserManager.mdl";
import Hook from '../../../common/utils/Hook';
import {RequestContext} from '../../../common/utils/EntityRequestAdapter';

export type SessionHooks = {
    loginSuccess: Hook;
    statusChange: Hook<[status: AuthStatus]>;
    metadataLoad: Hook<[metadata: IUserMetadata, session: ISession]>;
    initCompleted: Hook<[user: IUser]>
};

export const VIEW_AS_ROLES = [AuthRole.VIEW_AS, AuthRole.VIEW_AS_STRICT];
export const ADMIN_ROLES = [AuthRole.ADMIN, AuthRole.ROOT_ADMIN, AuthRole.SUPER_ADMIN];
export const CURRENT_SCOPE = "bi_dashboards";

const OPTIMISTIC_LOAD = process.env.NODE_ENV === 'development';


@define()
@singleton()
@alias('Disposable')
export class SessionManager implements IDisposable {
    @inject() private $injex: Injex;
    @inject() private apiManager: ApiManager;
    @inject() private userManager: UserManager;

    @injectAlias('Disposable') private disposables: IDisposable[];

    public hooks: SessionHooks;
    public accountsSdk: IAccountsSDK;
    public canViewAs: boolean;
    public hasAdminRole: boolean;
    public userLoggedOut: boolean;
    public userMetadata: DataQuery<IUserMetadata>;

    @observable public status: AuthStatus;
    @observable public loginError: boolean;
    @observable public initialAuthorization: boolean;
    @observable public viewAsUsers: DataQuery<IViewAsUser[]>;

    private _lastSeen: number;

    constructor() {
        makeObservable(this);

        this.status = AuthStatus.Authorizing;
        this.initialAuthorization = true;
        this.canViewAs = false;
        this.hasAdminRole = false;
        this.userMetadata = new DataQuery<IUserMetadata>(this._fetchUserMetadata.bind(this));
        this.userLoggedOut = false;
        this._lastSeen = 0;
        this._onVisibilityChange = this._onVisibilityChange.bind(this);

        this.hooks = {
            loginSuccess: new Hook(),
            statusChange: new Hook(),
            metadataLoad: new Hook(),
            initCompleted: new Hook()
        };
    }

    public async loadViewAsUsers(useForce?: boolean) {
        if (useForce) {
            this.viewAsUsers.invalidate();
        }

        this.viewAsUsers.fetch();
    }

    public selectViewAsUser(email: string) {
        this.accountsSdk.auth.viewAs(email);
    }

    public stopViewAs() {
        if (this.isViewAsSession) {
            window.sessionStorage.removeItem('viewAsData');
            window.location.href = '/';
        }
    }

    public getRequestContext(): RequestContext {

        if (this.isAuthenticated) {
            return {
                accessToken: this.accountsSdk.auth.session.accessToken,
                accounts: this.accountsSdk.auth.selectedAccounts
            };
        }

        return {};
    }

    public get isAuthenticated(): boolean {
        return this.accountsSdk?.auth.isAuthenticated;
    }

    public get isViewAsSession(): boolean {
        return this.accountsSdk?.auth.isViewAsSession;
    }

    public get session() {
        if (!this.isAuthenticated) {
            return null;
        }

        return this.accountsSdk.auth.session;
    }

    public get user() {
        if (!this.isAuthenticated) {
            return null;
        }

        return this.session.user;
    }

    public get fullName(): string {
        if (!this.isAuthenticated) {
            return '';
        }

        return `${this.user.firstName} ${this.user.lastName}`;
    }

    public get email(): string {
        if (!this.isAuthenticated) {
            return '';
        }

        return this.user.email;
    }

    public get org(): string {
        if (!this.isAuthenticated) {
            return '';
        }

        return this.user.organization.name;
    }

    public async initialize() {
        this.accountsSdk = await window['VidazooAccountsSDK'].create({
            // accountsServiceUrl: 'http://localhost:8459',
            scope: 'bi_dashboards',
            afterLogoutUrl: '/login'
        });

        this.accountsSdk.auth.hooks.sessionChanged.tap(this._onSessionChanged, null, this);

        this._setStatus(AuthStatus.Authorizing);

        this.accountsSdk.auth.authorize(true);
    }

    public login(email: string, password: string) {
        this.initialAuthorization = false;
        this.loginError = false;
        this._setStatus(AuthStatus.Authorizing);
        this.accountsSdk.auth.login(email, password);
    }

    public logout() {
        this.userLoggedOut = true;
        this.accountsSdk.auth.logout(true);
    }

    public sendPasswordRecoveryLink(email: string, recaptchaToken: string) {
        this.apiManager.session.forgotPassword(email, recaptchaToken);
    }

    private async _onSessionChanged(error: Error, session: ISession) {
        if (session) {
            this.$injex.logger.debug('[SESSION CHANGED]', session);
            /**
             * If you want more fast and optimistic load,
             * remove this `await` and the app will
             * load with partial data.
             *
             * In development environment we're using the
             * optimistic load in order to make pages load
             * faster between refreshes but it also can be
             * used in production.
             */

            OPTIMISTIC_LOAD
                ? this.hooks.loginSuccess.call()
                : await this.hooks.loginSuccess.call();

            await this.userMetadata.fetch();
            this.hooks.metadataLoad.call(this.userMetadata.data, session);

            this.canViewAs = this.userHasRole(VIEW_AS_ROLES);

            this.hasAdminRole = this.userHasRole(ADMIN_ROLES);

            this._setStatus(AuthStatus.LoggedIn);

            this._lastSeen = Date.now();

            document.addEventListener('visibilitychange', this._onVisibilityChange);

            this._initCompleted(this.user);

        } else {
            this.loginError = error !== null;
            this._setStatus(AuthStatus.LoggedOut);
            this.disposables.forEach((disposable) => disposable.dispose());
        }
    }

    /**
     * Checks for session validity when user returns to the
     * Tab/Browser after idle time.
     *
     * If the user returns after 1 minute, we check the session.
     * If the session has been expired, move to login using the
     * session changed hook.
     */
    private async _onVisibilityChange() {
        if (!this.accountsSdk.auth.isAuthenticated) {
            return;
        }

        const now = Date.now();

        if (document.visibilityState === 'visible') {
            if (this._lastSeen + 60_000 < now) {
                this.$injex.logger.debug('[PING SESSION]');
                await this.accountsSdk.auth.authorize(true);
            }
        } else {
            this._lastSeen = now;
        }
    }

    private _setStatus(status: AuthStatus) {
        if (this.status === status) {
            return;
        }

        this.status = status;
        this.hooks.statusChange.call(this.status);
    }

    public userHasRole(roles: AuthRole | AuthRole[]): boolean {
        if (!Array.isArray(roles)) {
            roles = [roles];
        }

        const scope = this.session.user.scopes.find((scope) => scope.scope === CURRENT_SCOPE);

        return isDefined(scope.roles.find((role) => roles.includes(role.name as AuthRole)));
    }

    public get allowedMpPublishers(): string[] {
        return this.currentScope.allowedMpPublishers;
    }

    public get maxRole(): number {
        return Arrays.sortBy(this.currentScope.roles, (role) => role.order)[0].order;
    }

    public get currentScope() {
        return this.session.user.scopes.find((scope) => scope.scope === CURRENT_SCOPE);
    }

    public get userHasAllowedMpPublishers(): boolean {
        return this.allowedMpPublishers.length > 0;
    }

    public dispose() {
        this.initialAuthorization = true;
        this.canViewAs = false;
        this.hasAdminRole = false;
        this.viewAsUsers && this.viewAsUsers.reset();
        this.userMetadata.reset();
        document.removeEventListener('visibilitychange', this._onVisibilityChange);
    }

    private async _fetchUserMetadata(): Promise<IUserMetadata> {
        const userMetadata = await this.apiManager.accounts.me();

        this.$injex.logger.debug('[USER METADATA]', userMetadata);

        return userMetadata;
    }

    private _initCompleted(user: IUser) {
        this.hooks.initCompleted.call(user);
    }
}
