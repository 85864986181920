import * as React from "react";
import {observer} from "mobx-react";
import {DayField} from '../../enums/DayField';
import {useEffect, useState} from 'react';
import randomColor from "randomcolor";
import {PageLoader} from '@vidazoo/ui-kit';
import theme from "./theme.module.scss";
import Chart from '../chart/Chart';
import chartConfig2 from './chartConfig2';
import {clone} from 'lodash';

export interface IPerformanceChartProps {
    series?: any[][];
    height?: number | string;
    labels?: string[];
    dayField?: DayField;
    isLoading?: boolean;
}

export default observer(function PerformanceChart(props: IPerformanceChartProps) {

    const [config, setConfig] = useState(clone(chartConfig2) as any)
    const {
        series = [],
        labels = [],
        height = 262,
        dayField,
        isLoading,
    } = props;

    let displayNoData = !isLoading;

    useEffect(() => {
        const clonedConfig = clone(config);

        if (!isLoading) {
            const colors = randomColor({
                count: series.length,
                luminosity: "random",
                hue: "random",
                format: "rgb",
            });

            if (colors) {
                clonedConfig.colors = colors;
            }

            clonedConfig.series = series;

            if (series?.[0]?.length > 0) {
                displayNoData = false;
            }

            if (dayField) {
                if (dayField === DayField.DATE) {
                    clonedConfig.tooltip.xDateFormat = "%H:%M";
                } else if (dayField === DayField.DAY) {
                    clonedConfig.tooltip.xDateFormat = "%e %b '%y %A";
                }
            }
            setConfig(clonedConfig);
        }
    }, []);

    return (
        <div className={theme.container}>
            {(isLoading || !(series as any)?.[0]?.data) ? (
                <PageLoader blue/>
            ) : (
                <Chart
                    config={config}
                    displayNoData={displayNoData}
                    style={{height}}
                />
            )}
        </div>
    );
});

