import * as React from 'react';
import theme from './theme.module.scss';
import clsx from 'clsx';
import UrlsService from "../../../../../../common/router/services/urlsService.mdl";
import {useNavigate} from "react-router-dom";

export interface INotificationProps {
    notification: {
        _id: string;
        userId: string;
        subject: string;
        message: string;
        app: string;
        seen: boolean;
        created: number;
        updated: number;
        type: string;
    };
    onSelectItem: (notificationId: string) => void;
}

function Notification(props: INotificationProps): JSX.Element {
    const {notification, onSelectItem} = props;
    const navigate = useNavigate();

    const onNotificationClick = () => {
        onSelectItem(notification._id);
       navigate(UrlsService.notificationCenter());
    };

    return (
        <div className={theme.notification} onClick={onNotificationClick}>
            <div className={clsx(theme.dot, {[theme.seen]: notification.seen})}/>
            <div className={clsx(theme.text, {[theme.seen]: notification.seen})}>
                <div className={theme.subjectContainer}>
                    <div className={theme.subject}>{notification.subject}</div>
                </div>
                <div className={theme.message}>{notification.app || 'Vidazoo'}</div>
            </div>
        </div>
    );
}

export default Notification;
