import * as React from "react";
import theme from "./theme.module.scss";
import {useLocation, Link} from "react-router-dom";
import clsx from "clsx";
import {normalizeEventValue} from "../../utils/utils";
import {Icon} from "@vidazoo/ui-kit";
import UrlsService from "../../router/services/urlsService.mdl";
import useEditChartsManager from "../../hooks/useEditChartsManager";
import {observer} from "mobx-react";
import {useEffect} from "react";

export default observer(function DefaultCharts() {
    const [searchValue, setSearchValue] = React.useState("");
    const [showDefaultCharts, setShowDefaultCharts] = React.useState(false);
    const ref = React.useRef(null);
    const inputRef = React.useRef(null);
    const location = useLocation();
    const {defaultCharts} = useEditChartsManager();

    const onChangeSearchValue = (e) => {
        const value = normalizeEventValue(e);
        setSearchValue(value);
    };

    const clearSearchValue = () => {
        setSearchValue("");
    };

    const setFocusSearchValue = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    React.useEffect(() => {
        const onWheel = (e) => {
            if (e.deltaY === 0) {
                return;
            }
            e.preventDefault();
            if (ref.current) {
                ref.current.scrollTo({
                    left: ref.current.scrollLeft + e.deltaY,
                    behavior: "smooth"
                });
            }
        };

        const element = ref.current;
        if (element) {
            element.addEventListener("wheel", onWheel);
        }

        return () => {
            if (element) {
                element.removeEventListener("wheel", onWheel);
            }
        };
    }, []);

    useEffect(() => {
        if (!window.location.pathname.startsWith("/reporting") && defaultCharts.length > 0) {
            setShowDefaultCharts(true)
        } else {
            setShowDefaultCharts(false)
        }
    }, [window.location.pathname, defaultCharts]);

    if (!showDefaultCharts) {
        return null;
    }

    return (
        <div className={theme.chipsContainer}>
            <div className={theme.searchContainer}>
                <div onClick={searchValue ? clearSearchValue : setFocusSearchValue}
                     className={theme.iconContainer}>
                    <Icon size={2.2} iconName={searchValue ? "icon-cancel-small" : "icon-search"}/>
                </div>
                <input ref={inputRef} onChange={onChangeSearchValue} className={theme.searchValue}
                       value={searchValue}
                       name="searchValue"/>
            </div>
            <div ref={ref} className={theme.chipsOutside}>
                <div className={theme.chips}>
                    {defaultCharts.filter((chart) => chart.name.toLowerCase().includes(searchValue.toLowerCase())).map((chart) => {
                        return (
                            <div key={chart._id}
                                 className={clsx(theme.chip, {[theme.chipSelected]: location.pathname.includes(chart._id)})}>
                                <Link to={UrlsService.viewChart(chart._id)}>{chart.name}</Link>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
});
