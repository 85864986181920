import React from 'react';
import ChangeLogsHeader from '../components/changeLogsHeader/ChangeLogsHeader';
import ChangeLogsList from '../components/changeLogsList/ChangeLogsList';
import {observer} from 'mobx-react';
import useChangeLogsModel from '../common/hooks/useChangeLogsModel';
import {Label, Modal, Surface} from '@vidazoo/ui-kit';
import theme from './theme.module.scss';
import {renderValueSnapshot} from '../../../common/components/render-value-snapshot/renderValueSnapshot';

function ChangeLogs() {
    const model = useChangeLogsModel();

    return (
        <div>
            <ChangeLogsHeader/>
            <ChangeLogsList/>
            <Modal external exitOnClickOutside onClose={() => model.setSelectedItem(null)} isOpen={!!model.selectedItem}>
                <Surface className={theme.modal}>
                    <Label style={{cursor: 'text'}} uppercase={false}>
                        {renderValueSnapshot(model.selectedItem)}
                    </Label>
                </Surface>
            </Modal>
        </div>
    );
}

export default observer(ChangeLogs);
