import {observable, computed, makeObservable} from "mobx";
import {define, inject} from '@injex/core';
import {SessionManager} from '../../modules/session/managers/sessionManager.mdl';


@define()
export default class RemotePasswordValidator {

    @observable public isCheckingPassword: boolean;
    @observable public passwordStrength: any;

    @inject() private sessionManager: SessionManager;

    private readonly minValidScore: number;

    constructor(minValidScore: number = 3) {
        makeObservable(this);
        this.reset();
        this.minValidScore = minValidScore;
    }

    public reset() {
        this.isCheckingPassword = false;
        this.passwordStrength = {};
    }

    public setCheckState(state: boolean) {
        this.isCheckingPassword = state;
    }

    public checkPassword = (password: string) => {

        this.passwordStrength = {};

        if (!password) {
            return;
        }

        this.isCheckingPassword = true;

        this.sessionManager.accountsSdk.utils.passwordStrength(password)
            .then((res) => this.onCheckPasswordComplete(res))
            .catch(() => this.onCheckPasswordError());
    }

    private onCheckPasswordComplete(res) {
        this.isCheckingPassword = false;
        this.passwordStrength = res;
    }

    private onCheckPasswordError() {
        this.isCheckingPassword = false;
        this.passwordStrength = {};
    }

    @computed get passwordScore(): number {
        return this.passwordStrength && !isNaN(this.passwordStrength.score)
            ? this.passwordStrength.score
            : -1;
    }

    @computed get isValid(): boolean {
        return this.passwordScore >= this.minValidScore;
    }
}
