import {Button} from '@vidazoo/ui-kit';
import UrlsService from '../../../../common/router/services/urlsService.mdl';
import {useCallback} from 'react';
import useHistoryReportsManager from '../../common/hooks/useHistoryReportsManager';
import {useNavigate} from 'react-router-dom';
import useReportingModel from '../../common/hooks/useReportingModel';
import {observer} from "mobx-react";

function HistoryReportsButton() {
    const model = useReportingModel();
    const historyReportsManager = useHistoryReportsManager();
    const {useHistoryReports} = model;

    const onBackToHistoryReports = useCallback(() => {
        model.cleanResults();
        historyReportsManager.clearSelectedReport();
    }, []);

    if (useHistoryReports) {
        return (
            <Button type="button" iconName="icon-history" uppercase green medium
                    onClick={onBackToHistoryReports}>History Reports</Button>
        )
    }
    return null;
}

export default observer(HistoryReportsButton);
