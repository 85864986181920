export default [
    {value: "Android", label: "Android"},
    {value: "Windows", label: "Windows"},
    {value: "iOS", label: "iOS"},
    {value: "Mac OS", label: "Mac OS"},
    {value: "Chromium OS", label: "Chromium OS"},
    {value: "Linux", label: "Linux"},
    {value: "Ubuntu", label: "Ubuntu"},
    {value: "unknown", label: "unknown"},
    {value: "Fedora", label: "Fedora"},
    {value: "Xbox", label: "Xbox"},
    {value: "Tizen", label: "Tizen"},
    {value: "PlayStation", label: "PlayStation"},
    {value: "Windows Phone", label: "Windows Phone"},
    {value: "KAIOS", label: "KAIOS"},
    {value: "FreeBSD", label: "FreeBSD"},
    {value: "Raspbian", label: "Raspbian"},
    {value: "Chromecast", label: "Chromecast"},
    {value: "Symbian", label: "Symbian"},
    {value: "BlackBerry", label: "BlackBerry"},
    {value: "Windows Phone OS", label: "Windows Phone OS"},
    {value: "Sailfish", label: "Sailfish"},
    {value: "Solaris", label: "Solaris"},
    {value: "OpenBSD", label: "OpenBSD"},
    {value: "NetBSD", label: "NetBSD"},
    {value: "Mint", label: "Mint"},
    {value: "Slackware", label: "Slackware"},
    {value: "Gentoo", label: "Gentoo"},
    {value: "Nintendo", label: "Nintendo"},
    {value: "Arch", label: "Arch"},
    {value: "OS/2", label: "OS/2"},
    {value: "webOS", label: "webOS"},
    {value: "Xubuntu", label: "Xubuntu"},
    {value: "Debian", label: "Debian"},
    {value: "Haiku", label: "Haiku"},
    {value: "SUSE", label: "SUSE"},
    {value: "Fuchsia", label: "Fuchsia"},
    {value: "Firefox OS", label: "Firefox OS"},
    {value: "MeeGo", label: "MeeGo"},
    {value: "RIM Tablet OS", label: "RIM Tablet OS"},
    {value: "BSD", label: "BSD"},
    {value: "BeOS", label: "BeOS"},
    {value: "DragonFly", label: "DragonFly"},
    {value: "Kubuntu", label: "Kubuntu"},
    {value: "GNU", label: "GNU"},
    {value: "Windows Mobile", label: "Windows Mobile"},
    {value: "Bada", label: "Bada"},
    {value: "CentOS", label: "CentOS"},
];
