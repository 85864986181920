import {ContextMenu} from '@vidazoo/ui-kit';
import theme from './theme.module.scss';
import ReportingConstraintRow from './ReportingConstraintRow';
import {observer} from 'mobx-react';
import {ReportingContextMenuType} from '../../common/enums/ReportingContextMenuType';
import IReportingConstraint from '../../common/interfaces/IReportingConstraint';

export interface IReportingConstraintsProps {
    constraints?: IReportingConstraint[];
    onClearAllParams: (key: ReportingContextMenuType) => void;
    setConstraintParam: (constraint: IReportingConstraint, key: string, value: any) => void;
    removeConstraint: (constraint: IReportingConstraint) => void;
}

function ReportingConstraints(props: IReportingConstraintsProps) {

    const {constraints, onClearAllParams, setConstraintParam, removeConstraint} = props;

    const getContextMenuItems = () => {
        return [
            {label: "Clear All Constraints", onClick: () => onClearAllParams(ReportingContextMenuType.constraints)},
        ];
    }

    return (
        <ContextMenu items={getContextMenuItems()}>
            <div className={theme.constraints}>
                {constraints.map((constraint, idx) => (
                    <ReportingConstraintRow
                        key={constraint.id || idx}
                        index={idx}
                        constraint={constraint}
                        setConstraintParam={setConstraintParam}
                        removeConstraint={removeConstraint}
                    />
                ))}
            </div>
        </ContextMenu>
    )
}
export default observer(ReportingConstraints);
