import BaseFilter from "./BaseFilter";
import {ReportVerticalType} from '../../enums/ReportVerticalType';
import {inject} from '@injex/core';
import ApiManager from '../../api/ApiManager.mdl';

export default class BaseNeededGroup extends BaseFilter {

	private promise: Promise<any>;
	private vertical: ReportVerticalType;
	private neededGroup: string;
    @inject() public apiManager: ApiManager;

	constructor(vertical: ReportVerticalType, neededGroup: string) {
		super();

		this.allowNew = false;
		this.labelKey = "name";
		this.valueKey = "_id";
		this.vertical = vertical;
		this.neededGroup = neededGroup;
	}

	public initialize(): Promise<any> {
		if (this.items && this.items.length > 0 ) {
			return this.promise;
		}

		if (this.isLoading) {
			return this.promise;
		}
		this.isLoading = true;
		return this.promise = this.apiManager.reporting.getNeededData(this.vertical, this.neededGroup)
			.then((res) => this.setData(res));
	}

	public setData(res) {
		this.items = res.data;
		this.isLoading = false;
	}
}
