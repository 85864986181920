import {define, inject, singleton} from '@injex/core';
import {AlertsManager} from "../../../common/alerts/managers/alertsManager.mdl";
import ReportingMetaDataManager from "../../../common/managers/ReportingMetaDataManager.mdl";
import IReportingParams, {IBiReportingParams} from "../common/interfaces/IReportingParams";
import URLQueryParams from "../../../common/utils/URLQueryParams";
import IReportingFilter from "../common/interfaces/IReportingFilter";
import IReportingConstraint from "../common/interfaces/IReportingConstraint";
import {ReportVerticalType} from "../../../common/enums/ReportVerticalType";
import {AlertAccent} from "../../../common/alerts/common/enums";

@define()
@singleton()
export class GenerateReportUrlParamsManager {

    @inject() private alertsManager: AlertsManager;
    @inject() private reportingMetaDataManager: ReportingMetaDataManager;

    public generate(params: IReportingParams, biParams: IBiReportingParams) {
        try {
            const raw: any = {
                t: biParams.time || params.time,
                tz: biParams.timezone || params.timezone,
                from: typeof params.from === "number" ? params.from : params.from.unix(),
                to: typeof params.to === "number" ? params.to : params.to.unix(),
                fs: params.fields.map((x) => x.label || x),
                gs: params.groups.map((x) => x.label || x),
                f: this.flattenFilters(params.filters, params.verticalType),
                c: this.flattenConstraints(params.constraints, params.verticalType),
            };

            if (params.isCompare) {
                raw.compare = true;
                params.compareFrom && (raw.cfrom = params.compareFrom.unix());
                params.compareTo && (raw.cto = params.compareTo.unix());
                params.period && (raw.p = params.period);
            }

            return URLQueryParams.stringify(raw);
        } catch (e) {
            this.alertsManager.setBasicAlert({content: "Failed to generate report URL params"}, AlertAccent.Error);
            return "";
        }
    }

    public flattenFilters(filters: IReportingFilter[], vertical: ReportVerticalType) {

        return filters.map((filter) => {
            if (filter.key && filter.values && filter.values.length) {
                const group = this.reportingMetaDataManager.getFilterByValue(filter.key, vertical);

                if (group) {
                    return {
                        key: group.label,
                        exclude: filter.exclude,
                        operator: filter.operator,
                        values: filter.values.map((value) => value[filter.filterLabelKey] || value)
                    };
                }
            }
            return null;
        }).filter(Boolean);
    }

    public flattenConstraints(constraints: IReportingConstraint[], vertical: ReportVerticalType) {

        return constraints.map((constraint) => {
            if (constraint.name && constraint.op && constraint.value) {
                const field = this.reportingMetaDataManager.getFieldByValue(constraint.name, vertical);

                if (field) {
                    const {value, label} = field;
                    return {
                        name: {label, value},
                        op: constraint.op,
                        value: constraint.value
                    };
                }
            }
            return null;
        }).filter(Boolean);
    }

}
