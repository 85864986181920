import {define, singleton} from '@injex/core';
import EntityRequestAdapter from '../../../common/utils/EntityRequestAdapter';
import IChart from '../interfaces/IChart';

@define()
@singleton()
export class ChartsApi extends EntityRequestAdapter<IChart> {
    protected basePathName: string = 'charts';

    protected get apiEndpoint(): string {
        return this.env.biDashboardsAPI;
    }
}
