import React, {useState} from 'react';
import {
    Surface,
    Button,
    GroupButton,
    ButtonsGroup
} from "@vidazoo/ui-kit";
import theme from "./theme.module.scss";
import ChangeLogsFilter from "../changeLogsFilter/ChangeLogsFilter";
import useChangeLogsModel from '../../common/hooks/useChangeLogsModel';
import dayjs from 'dayjs';
import {DateRangePicker, TimePreset, TimePresets} from '@vidazoo/date-range';
import {observer} from 'mobx-react';

function ChangeLogsHeader() {
    const [hideFilters, setHideFilters] = useState<boolean>(false);
    const model = useChangeLogsModel();

    const onFromChange = (from: dayjs.Dayjs) => {
        model.setParam('from', from);
    };

    const onToChange = (to: dayjs.Dayjs) => {
        model.setParam('to', to);
    };

    const toggleHideFilters = () => {
        setHideFilters(!hideFilters);
    };

    const onPresetChange = (preset: TimePreset) => {
        model.applyTimePreset(preset);
    };

    return (
        <Surface className={theme.header}>
            <ButtonsGroup flat={window.innerWidth < 600} onChange={onPresetChange}
                          active={model.activePreset} disabled={model.items.isLoading}
                          className={theme.preset}>
                {TimePresets.map((preset) => (
                    <GroupButton key={preset.value} name={preset.value}>{preset.label}</GroupButton>
                ))}
            </ButtonsGroup>
            <div className={theme.top}>
                <DateRangePicker
                    fromDate={model.params.from || dayjs()}
                    toDate={model.params.to || dayjs()}
                    onFromChange={onFromChange}
                    onToChange={onToChange}
                    preset={model.activePreset}
                    onPresetChange={onPresetChange}
                />
                {model.params.filters.length > 0 && (
                    <Button type="button" medium flat uppercase
                            iconName={hideFilters ? "icon-arrow-down-small" : "icon-arrow-up-small"}
                            onClick={toggleHideFilters}>
                        {hideFilters ? `Show Filters (${model.params.filters.length})` : "Hide Filters"}
                    </Button>
                )}
                {model.params.filters.length > 0 && (
                    <Button type="button" medium flat uppercase onClick={model.clearAllFilters}>
                        Clear all Filters
                    </Button>
                )}
                <Button type="button" medium flat uppercase iconName="icon-plus-small" onClick={model.addFilter}>
                    Add Filter
                </Button>
            </div>

            {!hideFilters && (
                <div>
                    {model.params.filters.map((filter, index) => (
                        <ChangeLogsFilter key={index} filter={filter} index={index}/>
                    ))}
                </div>
            )}

            <div className={theme.actions}>
                <Button type="button" uppercase green medium onClick={model.generateChangelogLink}>
                    Copy Change Logs Link
                </Button>
                <Button type="button" uppercase blue medium onClick={model.getItems}>
                    Get Change logs
                </Button>
            </div>
        </Surface>
    );
}

export default observer(ChangeLogsHeader);
