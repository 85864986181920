import {observer} from 'mobx-react';
import {ScheduleReportCreateModel} from '../model/ScheduleReportCreateModel.mdl';
import useManager from '../../../../../common/hooks/useManager';
import {Breadcrumbs, Button, Form, PageContainer, SliderPanel, Surface} from '@vidazoo/ui-kit';
import DetailsForm from '../../common/components/details-form/DetailsForm';
import theme from "../../../../../common/styles/base.module.scss";
import * as React from 'react';
import UrlsService from '../../../../../common/router/services/urlsService.mdl';
import {useNavigate} from 'react-router-dom';
import ReportForm from '../../common/components/report-form/ReportForm';
import {useModuleFactory} from "@injex/react-plugin";

export default observer(function ScheduleReportCreate() {
    const model = useModuleFactory<ScheduleReportCreateModel>('scheduleReportCreateModel');
    const navigate = useNavigate();

    const breadcrumbs = [{
        label: "Schedule Reports",
        iconName: "icon-calendar",
        link: {to: UrlsService.scheduleReports()}
    }, {
        label: "Create Schedule Report",
        primary: true
    }]

    const onCancelClick = () => {
        navigate(UrlsService.scheduleReports());
    };

    return (
        <PageContainer pageTitle="Create Schedule Report" inner top={1.2}>
            <Breadcrumbs className={theme.breadcrumbs} breadcrumbs={breadcrumbs}/>
            <Form className={theme.form} onSubmit={model.submit}>
                <div className={theme.midContent}>
                    <Surface className={theme.surface} level={1} style={{marginBottom: 68}}>
                        <DetailsForm model={model}/>
                    </Surface>
                    <Surface className={theme.surface} level={1}>
                        <ReportForm model={model}/>
                    </Surface>
                </div>
                <SliderPanel>
                    <div className={theme.actions}>
                        <Button type="button" medium gray uppercase onClick={onCancelClick}>Cancel</Button>
                        <Button type="submit" medium blue uppercase>Create</Button>
                    </div>
                </SliderPanel>
            </Form>
        </PageContainer>
    );
});
