import React from 'react';
import {HeaderColumn, Row} from '@vidazoo/ui-kit';
import {ChartEditModel} from '../../../../modules/charts/item/edit/model/ChartEditModel.mdl';
import {ChartCreateModel} from '../../../../modules/charts/item/create/model/ChartCreateModel.mdl';

export interface IDiffTableChartHeaderProps {
    model: ChartEditModel | ChartCreateModel;
}

export function DiffTableChartHeader({model}: IDiffTableChartHeaderProps) {
    return (
        <Row>
            {model.tableColumns.map((col) => <HeaderColumn disableNowrap key={col.key} colKey={col.key}/>)}
        </Row>
    )
}
