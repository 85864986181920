import {define, inject} from '@injex/core';
import IChart from '../../../modules/charts/interfaces/IChart';
import {computed, makeObservable, observable} from 'mobx';
import {ChartType} from '../../enums/ChartType';
import EditChartsManager from '../../managers/EditChartsManager.mdl';
import ChartsManager from '../../managers/ChartsManager.mdl';

@define()
export class AddChartModel {

    @inject() private editChartsManager: EditChartsManager;
    @inject() private chartsManager: ChartsManager;

    @observable public selectedType: ChartType;
    @observable public selectedChart: string;

    constructor() {
        makeObservable(this);
    }

    public onSelectType = (type: ChartType) => {
        if (type === this.selectedType) {
            this.selectedType = null;
            return;
        }
        this.selectedType = type;
    };

    public selectChart = async (value: string) => {
        this.selectedChart = value;
        const modelByChart = await this.editChartsManager.initChartModel(this.selectedChart);
        await modelByChart.getReport();
    };

    @computed get filteredCharts(): IChart[] {
        if (this.chartsManager.items.isLoading) {
            return [];
        }
        return this.selectedType ? this.chartsManager.items.data.filter((chart) => chart.type === this.selectedType) : this.chartsManager.items.data;
    }

}
