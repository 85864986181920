import DashboardsHeader from '../components/DashboardsHeader';
import DashboardsList from '../components/DashboardsList';
import {DashboardsListWithContext} from '../context/DashboardsListContext';

export default function Charts() {

    return (
        <DashboardsListWithContext>
            <DashboardsHeader/>
            <DashboardsList/>
        </DashboardsListWithContext>
    );
}
