import ILabelValuePair from '../../../common/interfaces/ILabelValuePair';

export const ReportSchedules: ILabelValuePair[] = [
    {label: "Hourly", value: "hourly"},
    {label: "Daily", value: "daily"},
    {label: "Weekly", value: "weekly"},
    {label: "Monthly", value: "monthly"},
    {label: "Sundays", value: "sundays"},
    {label: "Mondays", value: "mondays"},
    {label: "Tuesdays", value: "tuesdays"},
    {label: "Wednesdays", value: "wednesdays"},
    {label: "Thursdays", value: "thursdays"},
    {label: "Fridays", value: "fridays"},
    {label: "Saturdays", value: "saturdays"}
];

export const ReportDelimiters: ILabelValuePair[] = [
    {label: "Comma (,)", value: ","},
    {label: "Semicolon (;)", value: ";"},
    {label: "Pipe (|)", value: "|"},
    {label: "Tab (\\t)", value: "\\t"}
];

export const ReportDateRange: ILabelValuePair[] = [
    {label: "Yesterday", value: "yesterday"},
    {label: "Current Week", value: "currentWeek"},
    {label: "Last Seven Days", value: "lastSevenDays"},
    {label: "Last Week", value: "lastWeek"},
    {label: "Current Month", value: "currentMonth"},
    {label: "Last Thirty Days", value: "lastThirtyDays"},
    {label: "Last Month", value: "lastMonth"},
];

export const DateRanges = [{
    label: "Today",
    value: "today"
}].concat(ReportDateRange)
