import {define, inject} from '@injex/core';
import {makeObservable, observable} from 'mobx';
import {IScheduleReport} from '../../interfaces/IScheduleReport';
import {ColumnsManager} from '@vidazoo/ui-kit';
import ApiManager from '../../../../common/api/ApiManager.mdl';
import {DataListQuery} from '../../../../common/models/DataListQuery';
import {ScheduleReportsTableColumns} from '../common/constants';

@define()
export class ScheduleReportsListModel extends ColumnsManager<IScheduleReport> {

    @inject() private apiManager: ApiManager;
    public items: DataListQuery<IScheduleReport, []>;

    constructor() {
        super({
            persistKey: 'schedule_reports_list_columns',
        });

        this.setColumns(ScheduleReportsTableColumns);

        this.items = new DataListQuery(this._fetchItems.bind(this), {defaultValue: [], dataObservType: observable.ref});

        makeObservable(this);

    }

    public get data(): IScheduleReport[] {
        return this.items.data;
    }

    public get isLoading(): boolean {
        return this.items.isLoading;
    }

    private async _fetchItems() {
        const requestFields = {};
        ScheduleReportsTableColumns.forEach((column) => {
            requestFields[column.key] = 1;
        });
        const response = await this.apiManager.scheduleReports.getAll({fields: requestFields});
        return response.results;
    }

    public fetch() {
        return this.items.forceFetch();
    }
}
