import * as React from 'react';
import theme from './theme.module.scss';
import useUserNotificationsManager from '../../../../hooks/useUserNotificationsManager';
import Notification from './Notification';
import {Icon} from '@vidazoo/ui-kit';

function PanelBody(): JSX.Element {
    const manager = useUserNotificationsManager();
    const {items, userId, onSelectItem} = manager;

    const markAllAsRead = () => {
        manager.markAllAsRead(userId);
    };

    const renderNoNotificationMessage = (): JSX.Element => {
        return (
            <div className={theme.notification}>
                <div className={theme.icon}>
                    <Icon iconName="icon-rejected-status" iconColor="black" size={4}/>
                </div>
                <div className={theme.text}>
                    <div className={theme.subjectContainer}>
                        <div className={theme.subject}>No notifications found</div>
                    </div>
                    <div className={theme.message}>We are sorry for the inconvenience</div>
                </div>
            </div>
        );
    };

    return (
        <div className={theme.container}>
            <div className={theme.inner}>
                <div className={theme.notifications}>
                    {items.length ? items.map((notification) => (
                            <Notification
                                key={notification._id}
                                notification={notification}
                                onSelectItem={onSelectItem}
                            />
                        )) :
                        renderNoNotificationMessage()}
                </div>
            </div>
        </div>
    );
}

export default PanelBody;
