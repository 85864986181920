import React from 'react';
import {HeaderColumn, Row} from '@vidazoo/ui-kit';
import {observer} from 'mobx-react';

function ChangeLogsTableHeader() {

    return (
        <Row>
            <HeaderColumn colKey="scope" />
            <HeaderColumn colKey="account" />
            <HeaderColumn colKey="user" />
            <HeaderColumn colKey="action" />
            <HeaderColumn colKey="model_name" />
            <HeaderColumn colKey="model_id" />
            <HeaderColumn colKey="date" />
            <HeaderColumn colKey="diff" />
        </Row>
    )
}
export default observer(ChangeLogsTableHeader);
