import {define, init, inject} from '@injex/core';
import BaseChartModel from '../../common/base/BaseChartModel';
import IChart from '../../../interfaces/IChart';
import UserManager from '../../../../../common/managers/UserManager.mdl';
import {AlertAccent} from '../../../../../common/alerts/common/enums';
import SocketManager from '../../../../../common/managers/SocketManager.mdl';
import {cloneDeep} from 'lodash';
import ReportingParamsManager from '../../../../reporting/managers/reportingParamsManager.mdl';
import {Strings} from "@vidazoo/ui-kit";

@define()
export class ChartEditModel extends BaseChartModel {

    @inject() socketManager: SocketManager;
    @inject() userManager: UserManager;
    @inject() private reportingParamsManager: ReportingParamsManager;

    @init()
    protected initialize() {
        this.item.hooks.fetchCompleted.tap(this.onFetchCompleted, null, this);
    }

    public async onFetchCompleted(): Promise<void> {
        await this._setFilters();
        await this.getReport();
    }

    private async _setFilters() {
        for (let filter of this.item.data.reportParams.filters) {
            await this.setFilterKey(filter, filter.key, filter.values);
        }
    }

    public fetchItem(id: string): Promise<IChart> {
        return this.apiManager.charts.getById(id, {});
    }

    public submit = async () => {
        try {
            const item = cloneDeep(this.item.data);

            item.preparedReportParams = this.reportingParamsManager.getReportParamsForChart(item.reportParams);

            await this.apiManager.charts.updateById(this.item.data._id, item);

            this.onUpdatedSuccess();

        } catch (err) {
            this.onSubmitFailed(err);
        }
    }

    public async duplicate() {

    }

    public delete = async () => {
        try {
            await this.apiManager.charts.deleteById(this.item.data._id);
            this.onDeleteSuccess();

        } catch (e) {
            this.onDeleteError(e);
        }
    }

    public getReport = async () => {

        if (this.chartReportByTimePreset[this.reportingMetaDataManager.timePreset] && (this.chartReportByTimePreset[this.reportingMetaDataManager.timePreset].isLoading || this.chartReportByTimePreset[this.reportingMetaDataManager.timePreset].results)) {
            return;
        }

        if (!this.item.data || !this.item.data._id) {
            return;
        }

        const reportingParams = {
            filters: [],
            constraints: []
        };

        try {

            this.socketManager.bdReporting.startListenToReportByChart(this.userManager._id, this.item.data._id, this.reportingMetaDataManager.timePreset, (data) => this.setReportOnChart(data, this.item.data._id));
            this.chartReportByTimePreset[this.reportingMetaDataManager.timePreset] = {results: [], isLoading: true};

            const res = await this.socketManager.bdReporting.getReportByChart(this.item.data._id, this.reportingMetaDataManager.timePreset, this.timezone, reportingParams);
            this.setReportOnChart(res, this.item.data._id)

        } catch (e) {
            this.socketManager.bdReporting.removeListenToReportByChart(this.userManager._id, this.item.data._id, this.reportingMetaDataManager.timePreset);
            this.alertsManager.setBasicAlert({content: `Failed to get report for chart ${this.item.data.name}`}, AlertAccent.Error);
        }
    };

    private setReportOnChart = (res: any, chartId: string) => {
        if (res && res.results && res.timePreset && this.chartReportByTimePreset[res.timePreset]) {

            if (this.item.data.type === 'data_table') {
                const getValue = (item, colKey) => {
                    const value = item.find((col) => colKey === (col.type || col.name))?.value || null;
                    return !value ? value : Strings.isString(value) ? value.toLowerCase() : value;
                }

                this.setColumns(res.results[0].map((item: any) => {
                    const colKey = item.type || item.name;
                    return {
                        title: item.name,
                        key: colKey,
                        getValue,
                        defaultWidth: 200,
                        options: {
                            order: false,
                            search: {searchKey: colKey},
                            freeze: true,
                            sort: {sortKey: colKey, sortOnHeaderClick: true},
                        },
                    }
                }));
            }

            this.chartReportByTimePreset[res.timePreset].results = res.results;
            this.chartReportByTimePreset[res.timePreset].isLoading = false;

            this.socketManager.bdReporting.removeListenToReportByChart(this.userManager._id, chartId, res.timePreset);
        }
    };

}
