import {useEffect, useMemo} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {observer} from 'mobx-react';
import {Button, SliderPanel, PageContainer, Tooltip, Breadcrumbs, CircleLoader, Surface} from '@vidazoo/ui-kit';
import theme from '../../../../../common/styles/base.module.scss';
import UrlsService from '../../../../../common/router/services/urlsService.mdl';
import {ChartContext} from '../../context/ChartContext';
import * as React from 'react';
import useUserManager from '../../../../../common/hooks/useUserManager';
import useEditChartsManager from '../../../../../common/hooks/useEditChartsManager';
import ChartDetails from '../../components/chartDetails/ChartDetails';
import ChartDisplay from '../../../../../common/components/chart-display/ChartDisplay';

export default observer(function ChartEdit({viewOnly}: { viewOnly?: boolean }) {
    const userManager = useUserManager();
    const {id} = useParams<{ id: string; }>();
    const editChartsManager = useEditChartsManager();
    const model = editChartsManager.getModel(id);

    useEffect(() => {
        editChartsManager.initChartModel(id);
    }, [id]);

    const contextValue = useMemo(() => ({model}), [model]);
    const navigate = useNavigate();

    if (!model || model.item.isLoading) {
        return (
            <div className={theme.center}>
                <CircleLoader size={4} gray/>
            </div>
        );
    }

    const isBelongToUser = userManager.isBelongToUser(model.item.data?.user?._id || (model.item.data?.user) as any);

    const breadcrumbs = [{
        label: "Charts List",
        iconName: "handshake",
        link: {to: UrlsService.charts()}
    }, {
        label: "Edit"
    }, {
        label: model.getTitle(),
        primary: true
    }]

    const onCancelClick = () => {
        navigate(UrlsService.charts());
    };

    return (
        <ChartContext.Provider value={contextValue}>
            <PageContainer pageTitle={model.getTitle()} top={1.2} inner={!viewOnly}>
                {!viewOnly && (
                    <>
                        <Surface className="mt-2 pt-5 pb-0.5">
                            <Breadcrumbs className={theme.breadcrumbs} breadcrumbs={breadcrumbs}/>
                        </Surface>
                        <ChartDetails/>
                    </>
                )}
                <Surface className="mt-2">
                    <ChartDisplay chartModel={model} isEditMode={false}/>
                </Surface>
                {!viewOnly && (
                    <SliderPanel>
                        <div className={theme.sliderPanel}>
                            <Button type="button" medium gray uppercase onClick={onCancelClick}>Cancel</Button>
                            <Button type="button" medium red uppercase onClick={model.onDelete}>Delete</Button>
                            <Button medium gray60 uppercase onClick={model.duplicate}>Duplicate</Button>
                            <Tooltip disabled={isBelongToUser} title="You can not update item that you not create">
                                <div>
                                    <Button type="button" disabled={!isBelongToUser} medium blue uppercase
                                            onClick={model.submit}>Update</Button>
                                </div>
                            </Tooltip>
                        </div>
                    </SliderPanel>
                )}
            </PageContainer>
        </ChartContext.Provider>
    );
});
