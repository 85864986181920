import React, {memo} from 'react';
import {MaterialIcon} from '@vidazoo/ui-kit';

export interface IPageTitleProps {
    title: any;
    center?: boolean;
    full?: boolean;
    maxWidth?: number;
    iconName?: string;
    itemsCount?: number | string;
}

function PageTitle({title, center, full, iconName, itemsCount, maxWidth = 1280}: IPageTitleProps): JSX.Element {
    const style: React.CSSProperties = {};

    if (maxWidth && !full) {
        style.maxWidth = maxWidth;
    }

    if (center) {
        style.margin = '0 auto';
    }

    return (
        <div style={style}>
            <div className="text-vdzpurple-200 text-[2rem] flex items-center justify-center">
                {iconName && <MaterialIcon className="text-black dark:text-white pr-3" iconName={iconName}/>}
                {itemsCount ? (
                    <div className="flex flex-col items-start">
                        <span className="font-semibold text-black dark:text-white">{title}</span>
                        <span className="font-semibold text-black dark:text-white text-xl">
                            Total items: {itemsCount}
                        </span>
                    </div>
                ) : (
                    <span className="font-semibold text-black dark:text-white">{title}</span>
                )}
            </div>
        </div>
    );
}

export default memo(PageTitle);
