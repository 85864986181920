import * as React from 'react';
import theme from './theme.module.scss';
import useUserNotificationsManager from "../../../../hooks/useUserNotificationsManager";
import {useNavigate} from "react-router-dom";
import UrlsService from "../../../../../../common/router/services/urlsService.mdl";
import {Button} from "@vidazoo/ui-kit";


function PanelHeader(): JSX.Element {
    const manager = useUserNotificationsManager();
    const navigate = useNavigate();
    const {userId} = manager;

    const markAllAsRead = () => {
        if (manager.unseenCount === null) {
            return;
        }
        manager.markAllAsRead(userId);
    };

    const onViewAllClick = () => {
        if (manager.unseenCount === null) {
            return;
        }
        navigate(UrlsService.notificationCenter());
    };

    const onSettingsClick = () => {
        if (manager.unseenCount === null) {
            return;
        }
        navigate(UrlsService.notificationSettings());
    };

    return (
        <div className={theme.info}>
            <div className={theme.header}>
                <div className={theme.leftHeader}>
                    <div className={theme.pendingNotifications} aria-disabled={manager.unseenCount === null}
                         onClick={onViewAllClick}>
                        <div className={theme.counterLabel}>{manager.unseenCount || 0}</div>
                        <div className={theme.pendingLabel}>pending notifications</div>
                    </div>
                    <div className={theme.settings} onClick={onSettingsClick}>Settings</div>
                </div>
                <div className={theme.rightHeader}>
                    <div className={theme.markAllAsRead} onClick={markAllAsRead}>Mark All as Read</div>
                </div>
            </div>
        </div>
    );
}

export default PanelHeader;
