import {observer} from 'mobx-react';
import {Button, Form, Label, SliderPanel, useToggle} from '@vidazoo/ui-kit';
import theme from "../../../../../common/styles/_baseForm.module.scss";
import selfTheme from "../../common/styles/theme.module.scss";
import useRouterService from '../../../../../common/router/hooks/useRouterService';
import UrlsService from '../../../../../common/router/services/urlsService.mdl';
import DetailsForm from '../../common/components/details-form/DetailsForm';
import useUserManager from '../../../../../common/hooks/useUserManager';
import ChartsForm from '../../common/components/charts-form/ChartsForm';
import {DashboardEditModel} from '../model/DashboardEditModel.mdl';

export interface IDashboardEditFormProps {
    model: DashboardEditModel;
}

export default observer(function DashboardEditForm(props: IDashboardEditFormProps) {
    const {model} = props
    const routerService = useRouterService();
    const userManager = useUserManager();
    const [isEditMode, toggleEditMode] = useToggle(false);

    const onDuplicate = () => {
        routerService.push(UrlsService.createDashboard(model.item.data._id));
    };

    const onCancelClick = () => {
        routerService.push(UrlsService.dashboards());
    };

    const userId = (model.item.data ? model.item.data?.user?._id ? model.item.data.user._id : model.item.data.user : "") as string;

    return (
        <Form className={theme.form} onSubmit={model.submit}>
            <div className={theme.header}>
                {userManager.isBelongToUser(userId) ? (
                    <Button className={selfTheme.btnEditMode} type="button" iconName="icon-edit" medium blue
                            uppercase onClick={toggleEditMode}>
                        {`${isEditMode ? "Close Edit" : "Edit"} Dashboard ${model.item.data.name}`}
                    </Button>
                ) : null}
            </div>

            {isEditMode && <DetailsForm model={model}/>}

            {(!model.item?.data?.layouts?.lg || model.item?.data?.layouts?.lg.length === 0) ? (
                <Label className={theme.label} uppercase={false} size={13}>No charts yet..</Label>
            ) : (
                <ChartsForm model={model} isEditMode={isEditMode}/>
            )}

            {isEditMode && (
                <SliderPanel>
                    <div className={theme.actions} style={{marginTop: 30}}>
                        <Button type="button" medium gray uppercase onClick={onCancelClick}>Cancel</Button>
                        <Button type="button" medium red uppercase onClick={model.onDelete}>Delete</Button>
                        <Button medium gray60 uppercase onClick={onDuplicate}>Duplicate</Button>
                        <Button type="submit" medium blue uppercase>Update</Button>
                    </div>
                </SliderPanel>
            )}
        </Form>
    );
});
