import {define, singleton} from '@injex/core';
import {IUserMetadata} from '../../session/interfaces/IUserMetadata';
import EntityRequestAdapter from '../../../common/utils/EntityRequestAdapter';

@define()
@singleton()
export class AccountsAPI extends EntityRequestAdapter {
    protected basePathName: string = 'accounts';

    protected get apiEndpoint(): string {
        return this.env.biDashboardsAPI;
    }

    public async me(): Promise<IUserMetadata> {
        const response = await this.requestWithContext<IUserMetadata>({
            method: 'get',
            url: this.makeAPIPath('/me')
        });

        return response.data;
    }

    public getEnterpriseAccountFilter(): any {
        return this.requestWithContext({
            method: "get",
            url: this.makeAPIPath('/enterprise_reporting_filter')
        });
    }
}
