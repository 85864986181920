export enum ReportingContextMenuType {
    fields = "fields",
    groups = "groups",
    filters = "filters",
    constraints = "constraints",
}

export const ReportingContextMenuTypeValues = [
    ReportingContextMenuType.fields,
    ReportingContextMenuType.groups,
    ReportingContextMenuType.filters,
    ReportingContextMenuType.constraints,
];
