import DataQuery from './DataQuery';
import IDictionary from '../interfaces/IDictionary';

export class DataListQuery<T, Args extends Array<any> = any[]> extends DataQuery<T[], Args> {

    public _itemsById: IDictionary<T>;

    public get byId(): IDictionary<T> {
        if (!this._itemsById && this.data.length > 0) {
            this._itemsById = {};
            if (Array.isArray(this.data)) {
                this.data.forEach((item: any) => {
                    this._itemsById[item._id] = item;
                });
            }
        }
        return this._itemsById || {};
    }
}
