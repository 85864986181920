import {AuthScope} from '../enums/AuthScope';

export const AuthScopeTypes = [
    {name: "Auth", value: AuthScope.AUTH},
    {name: "Platform", value: AuthScope.PLATFORM},
    {name: "Connections", value: AuthScope.CONNECTIONS},
    {name: "Publishers", value: AuthScope.PUBLISHERS},
    {name: "IpBlocker", value: AuthScope.IP_BLOCKER},
    {name: "MoneyBooker", value: AuthScope.MONEY_BOOKER},
    {name: "AlgoTrader", value: AuthScope.ALGOTRADER},
    {name: "Skynet", value: AuthScope.SKYNET},
    {name: "AutoScaler", value: AuthScope.AUTOSCALER},
    {name: "Nightwatch", value: AuthScope.NIGHTWATCH},
    {name: "OpenRtb", value: AuthScope.OPEN_RTB},
    {name: "Advertisers", value: AuthScope.ADVERTISERS},
    {name: "Dsp Gateway", value: AuthScope.DSP_GATEWAY},
    {name: "Captains Log", value: AuthScope.CAPTAINS_LOG},
    {name: "Bi", value: AuthScope.BI},
    {name: "Capchu", value: AuthScope.CAPCHU},
    {name: "Campaign Duplicator", value: AuthScope.CAMPAIGN_DUPLICATOR},
    {name: "Vault", value: AuthScope.VAULT},
    {name: "Capchu", value: AuthScope.NETWORK},
    {name: "Vroku", value: AuthScope.VROKU},
    {name: "Messages", value: AuthScope.MESSAGES},
    {name: "Reporting", value: AuthScope.REPORTING},
    {name: "Content", value: AuthScope.CONTENT},
    {name: "Display", value: AuthScope.DISPLAY},
    {name: "BI Dashboard", value: AuthScope.BI_DASHBOARD},
    {name: "BI Dashboards", value: AuthScope.BI_DASHBOARDS},
    {name: "Games", value: AuthScope.GAMES},
    {name: "Web Directory", value: AuthScope.WEB_DIRECTORY},
    {name: "Ads.txt", value: AuthScope.ADS_TXT},
    {name: "Sites", value: AuthScope.SITES},
    {name: "Feature Toggles", value: AuthScope.FEATURE_TOGGLES},
    {name: "Automations", value: AuthScope.AUTOMATIONS},
    {name: "Console Radio", value: AuthScope.CONSOLE_RADIO},
    {name: "OS", value: AuthScope.OS},
    {name: "Widgets", value: AuthScope.WIDGETS},
];
