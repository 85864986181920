import {define, init, inject, singleton} from '@injex/core';
import SocketManager from '../../../../../common/managers/SocketManager.mdl';
import BaseScheduleReportModel from '../../common/base/BaseScheduleReportModel';
import {IScheduleReport} from '../../../interfaces/IScheduleReport';
import {observable} from "mobx";
import IReportingFilterHandler from "../../../../../common/reporting-filters/interfaces/IReportingFilterHandler";

@define()
export class ScheduleReportEditModel extends BaseScheduleReportModel {

    @inject() socketManager: SocketManager;
    @observable public filters: IReportingFilterHandler[] = [];

    @init()
    protected initialize() {
        this.item.hooks.fetchCompleted.tapAsync(() => this._onFetchCompleted());
    }

    public get data() {
        return [];
    }

    private async _onFetchCompleted() {
        this.item.data.filters.map(filter => {
            const values = filter.values;
            this.setFilterKey(filter, filter.key);
            filter.values = values;
        });
    }

    public fetchItem(id: string): Promise<IScheduleReport> {
        return this.apiManager.scheduleReports.getById(id, {});
    }

    public submit = async () => {
        try {
            await this.apiManager.scheduleReports.updateById(this.item.data._id, this.item.data);
            this.onSubmitSuccess();

        } catch (err) {
            this.onSubmitFailed(err);
        }
    }

    public async duplicate() {

    }

    public delete = async () => {
        try {
            await this.apiManager.charts.deleteById(this.item.data._id);
            this.onDeleteSuccess();

        } catch (e) {
            this.onDeleteError(e);
        }
    }
}
