import {createContext, useContext, useMemo} from "react";
import {ChartsListModel} from '../model/ChartsListModel.mdl';
import {useModuleFactory} from '@injex/react-plugin';
import {TableRowSelectionHook, useTableRowSelection} from '@vidazoo/ui-kit';

export interface IChartsListContext {
    model: ChartsListModel;
    tableRowSelectionHook: TableRowSelectionHook;
    allItemIds: string[];
}

export interface IChartsListWithContext {
    children: any,
}

export const ChartsListContext = createContext<IChartsListContext>({
    model: null,
    tableRowSelectionHook: null,
    allItemIds: null
})

export function ChartsListWithContext({children}: IChartsListWithContext) {

    const model = useModuleFactory<ChartsListModel>('chartsListModel');
    const allItemIds = useMemo<string[]>(() => model.maskItems.map((item) => item._id), [model.maskItems]);
    const tableRowSelectionHook = useTableRowSelection(allItemIds, false);

    const contextValue: IChartsListContext = useMemo(() => ({
        model,
        tableRowSelectionHook,
        allItemIds
    }), [model, tableRowSelectionHook, allItemIds]);

    return (
        <ChartsListContext.Provider value={contextValue}>
            {children}
        </ChartsListContext.Provider>
    )
}

export function useChartsListContext(): IChartsListContext {
    return useContext<IChartsListContext>(ChartsListContext);
}
