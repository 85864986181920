import * as React from 'react';

export interface IForProps {
    count: number;
    children: any;
}

function For({count, children}: IForProps): JSX.Element {
    return (
        <>
            {
                new Array(count).fill(null).map((_, i) => (
                    typeof children === 'function' ? children(i) : React.cloneElement(React.Children.only(children), {key: i})
                ))}
        </>
    );
}

export default For;