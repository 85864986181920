import React from 'react';
import {Column, HeaderColumn, Row} from '@vidazoo/ui-kit';
import {useNotificationSettingsListContext} from '../context/SettingsListContext';

interface ITableHeaderProps {
}

export function SettingsTableHeader() {
    const {model, tableRowSelectionHook} = useNotificationSettingsListContext();
    const {isAllSelected, toggleAllItems} = tableRowSelectionHook

    return (
        <Row>
            <Column colKey="checkbox" width={40} className='flex items-center justify-center'>
                <input className="flex w-8 h-8 cursor-pointer" disabled={model.isLoading} type="checkbox"
                       checked={isAllSelected}
                       onChange={toggleAllItems}/>
            </Column>
            <HeaderColumn colKey="name" />
            <HeaderColumn colKey="app" />
            <HeaderColumn colKey="notification" />
            <HeaderColumn colKey="email" />
            <HeaderColumn colKey="sms" />
            <HeaderColumn colKey="created" />
            <HeaderColumn colKey="lastSent" />
            <HeaderColumn colKey="actions" />
        </Row>
    )
}
