export function intermittentLoop(collection: any[], iterator: (value: any, index: number) => void, batchSize: number, onDone: () => void, startAt: number = 0) {
    const stop = Math.min(batchSize, collection.length - startAt) + startAt;
    let i = startAt;
    for (; i < stop; i++) {
        iterator(collection[i], i);
    }
    if (i < collection.length) {
        setTimeout(() => intermittentLoop(collection, iterator, batchSize, onDone, i), 0);
    } else {
        onDone();
    }
}
