import {define, inject, singleton, ready, init} from '@injex/core';
import {ColumnsManager} from '@vidazoo/ui-kit';
import {IChangeLog} from '../common/interfaces/IChangeLog';
import {DataListQuery} from '../../../common/models/DataListQuery';
import {computed, makeObservable, observable} from 'mobx';
import {ChangeLogsTableColumns} from '../common/constants/ChangeLogsTableColumns';
import ApiManager from '../../../common/api/ApiManager.mdl';
import {FilterTypes} from '../common/enums/FilterTypes';
import {FilterOperators} from '../../../common/enums/FilterOperators';
import {IChangeLogsParams} from '../common/interfaces/IChangeLogsParams';
import {
    getCurrentHour,
    getCurrentMonth,
    getCurrentWeek,
    getLastMonth,
    getPastDays,TimePreset
} from '@vidazoo/date-range';
import dayjs from 'dayjs';
import URLQueryParams from '../../../common/utils/URLQueryParams';
import MemoryClipboard from '../../../common/utils/MemoryClipboard';
import {AlertAccent} from '../../../common/alerts/common/enums';
import {AlertsManager} from '../../../common/alerts/managers/alertsManager.mdl';
import {IChangeLogFilter} from '../common/interfaces/IChangeLogFilter';
import {PlatformReportingFilterType} from '../../../common/reporting-filters/filters/ReportingFilterType';
import {ReportVerticalType} from '../../../common/enums/ReportVerticalType';
import _ from 'lodash';
import AccountsMetaDataManager from '../../../common/managers/AccountsMetaDataManager.mdl';
import ReportingFiltersManager from '../../../common/reporting-filters/managers/ReportingFiltersManager.mdl';

const FIELDS = ["id", "scope", "account", "auth", "action", "model_name", "model_id", "date", "diff"];
const GAL_ACCOUNT = "551424a21b1b1f08006fb6f9";
const PLATFORM_BASE_URL = "https://bo.vidazoo.com";
const OPEN_RTB_BASE_URL = "https://openrtb.vidazoo.com";

@define()
@singleton()
export default class ChangeLogsModel extends ColumnsManager<IChangeLog> {

    public items: DataListQuery<IChangeLog, []>;

    @observable public count: number;
    @observable public params: IChangeLogsParams;
    @observable public account: string;
    @observable public tagsByAccount: { [index: string]: any };
    @observable public nameByIdAndScope: { [index: string]: any };
    @observable public activePreset: TimePreset;
    @observable public selectedItem: any;

    @inject() private reportingFiltersManager: ReportingFiltersManager;
    @inject() private apiManager: ApiManager;
    @inject() private alertsManager: AlertsManager;
    @inject() private accountsMetaDataManager: AccountsMetaDataManager;

    constructor() {
        super({
            persistKey: 'change_logs_list_columns',
        });

        this.setColumns(ChangeLogsTableColumns);

        this.items = new DataListQuery(this._fetchItems.bind(this), {defaultValue: [], dataObservType: observable.ref});
        this.reset();
        makeObservable(this);
    }

    @init()
    protected initialize() {
        this.accountsMetaDataManager.hooks.accountsReady.tapAsync(this.init, null, this);
    }

    private async init() {
        await this.getPlatformTags();
        await this.items.invalidate().fetch();
    }

    public reset() {
        this.count = 0;
        this.params = this.initialParams;
        this.account = GAL_ACCOUNT;
        this.tagsByAccount = {};
        this.nameByIdAndScope = {};
        this.activePreset = TimePreset.Now;
    }

    private get initialParams(): IChangeLogsParams {
        return {
            from: dayjs().subtract(1, "hour"),
            to: dayjs(),
            filters: [],
        };
    }

    public getItems = async () => {
        await this.items.invalidate().fetch();
    }

    private async _fetchItems() {
        try {
            const res = await this.apiManager.changeLogs.getAllChangeLogs({
                filter: this.prepareFilters(),
                fields: FIELDS
            });
            return res.data.results.map((item) => this._prepareItem(item));
        } catch (e) {
            this.alertsManager.setBasicAlert({content: `Failed to get change logs`}, AlertAccent.Error);
        }
    }

    private _prepareItem(item: IChangeLog) {
        if (this.accountsMetaDataManager?.accountsById?.[item.account]?.username) {
            item.account = this.accountsMetaDataManager.accountsById[item.account].username;
        }
        item.model_link = this._getLink(item);
        return item;
    }

    public _getLink(item: IChangeLog): string {
        switch (`${item.scope}_${item.model_name}`) {
            case "platform_players":
                return `${PLATFORM_BASE_URL}/player/${item.model_id}/edit`;
            case "platform_scenarios":
                return `${PLATFORM_BASE_URL}/scenario/${item.model_id}/edit`;
            case "platform_tags":
                return `${PLATFORM_BASE_URL}/tag/${item.model_id}/edit`;
            case "platform_publishers":
                return `${PLATFORM_BASE_URL}/supply-partner/${item.model_id}/edit`;
            case "platform_demandpartners":
                return `${PLATFORM_BASE_URL}/demand-partner/${item.model_id}/edit`;
            case "widgets_widgets":
                return `${PLATFORM_BASE_URL}/widget_new/${item.model_id}/edit`;
            case "platform_users":
                return `${PLATFORM_BASE_URL}/account/${item.model_id}/edit`;
            case "platform_videos":
                return `${PLATFORM_BASE_URL}/cms-video/${item.model_id}/edit`;
            case "platform_crawlers":
                return `${PLATFORM_BASE_URL}/crawler/${item.model_id}/edit`;
            case "platform_domainlists":
                return `${PLATFORM_BASE_URL}/list/${item.model_id}/edit`;
            case "platform_widgetsettings":
                return `${PLATFORM_BASE_URL}/widget-settings/${item.model_id}/edit`;
            case "platform_networks":
                return `${PLATFORM_BASE_URL}/network/${item.model_id}/edit`;
            case "platform_contextualplaylists":
                return `${PLATFORM_BASE_URL}/contextual-playlist/${item.model_id}/edit`;
            case "platform_countrylists":
                return `${PLATFORM_BASE_URL}/country-list/${item.model_id}/edit`;
            case "platform_networksettings":
                return `${PLATFORM_BASE_URL}/network-settings`;
            case "platform_abtestpolicies":
                return `${PLATFORM_BASE_URL}/ab-test-policy/${item.model_id}/edit`;

            case "open_rtb_exchanges":
                return `${OPEN_RTB_BASE_URL}/exchange/${item.model_id}/edit`;
            case "open_rtb_connections":
                return `${OPEN_RTB_BASE_URL}/connection/${item.model_id}/edit`;
            default:
                return "";
        }
    }

    private prepareFilters = () => {
        const filters = [];
        this.params.filters.forEach((filter) => {
            switch (filter.type) {
                case FilterTypes.Scope:
                    filters.push({
                        path: `scope ${filter.operator === FilterOperators.isNotAnyOf ? "!=" : "="}:value`,
                        value: {value: filter.value}
                    });
                    break;
                case FilterTypes.User:
                    filters.push({
                        path: `auth->>'id' ${filter.operator === FilterOperators.isNotAnyOf ? "NOT IN" : "IN"} (:...value)`,
                        value: {value: filter.value}
                    });
                    break;
                case FilterTypes.Account:
                    filters.push({
                        path: `account ${filter.operator === FilterOperators.isNotAnyOf ? "NOT IN" : "IN"} (:...value)`,
                        value: {value: filter.value}
                    });
                    break;
                case FilterTypes.Action:
                    filters.push({
                        path: `action ${filter.operator === FilterOperators.isNotAnyOf ? "!=" : "="}:value`,
                        value: {value: filter.value}
                    });
                    break;
                case FilterTypes.PlatformTags:
                    filters.push({
                        path: `model_id ${filter.operator === FilterOperators.isNotAnyOf ? "NOT IN" : "IN"} (:...value)`,
                        value: {value: filter.value}
                    });
                    break;
                case FilterTypes.ModelId:
                    filters.push({
                        path: `model_id ${filter.operator === FilterOperators.isNotAnyOf ? "NOT IN" : "IN"} (:...value)`,
                        value: {value: filter.value}
                    });
                    break;
                case FilterTypes.ModelName:
                    filters.push({
                        path: `model_name ${filter.operator === FilterOperators.isNotAnyOf ? "NOT IN" : "IN"} (:...value)`,
                        value: {value: filter.value}
                    });
                    break;
            }
        });

        this.params.from && (filters.push({path: "date>=:from", value: {from: this.params.from.unix() * 1000}}));
        this.params.to && (filters.push({path: "date<=:to", value: {to: this.params.to.unix() * 1000}}));

        return filters;
    };

    @computed
    public get data(): IChangeLog[] {
        return this.items.data || [];
    }

    public setParam = (key: string, value: any) => {
        this.params[key] = value;
    };

    public applyTimePreset = (preset?: TimePreset) => {
        if (preset && preset !== this.activePreset) {
            this.activePreset = preset;
        }

        switch (this.activePreset) {
            case "now":
                this.setDate(1, 1);
                break;
            case "today":
                this.setDate(getCurrentHour(), -getCurrentHour() + 24);
                break;
            case "yesterday":
                this.setDate(getCurrentHour() + 24, -getCurrentHour());
                break;
            case "last7days":
                this.setDate(getPastDays(7), -getCurrentHour() + 24);
                break;
            case "weektodate":
                this.setDate(getCurrentWeek(), -getCurrentHour() + 24);
                break;
            case "lastmonth":
                this.setDate(getLastMonth(), -getCurrentMonth());
                break;
            case "monthtodate":
                this.setDate(getCurrentMonth(), -getCurrentHour() + 24);
                break;
        }
    };

    public setDate(from: number, to: number) {
        this.params.from = dayjs().utc().minute(0).second(0).subtract(from, "hour");
        this.params.to = dayjs().utc().minute(0).second(0).add(to, "hour");
    }

    public clearAllFilters = () => {
        this.params.filters = [];
    };

    public addFilter = () => {
        this.params.filters.push({type: ""});
    };

    public generateChangelogLink = () => {
        const raw: any = {
            t: this.activePreset,
            from: this.params.from.unix(),
            to: this.params.to.unix(),
            f: this.params.filters,
        };
        const params = URLQueryParams.stringify(raw);

        MemoryClipboard.copy(`${window.location.origin}/change-logs?${params}`);

        this.alertsManager.setBasicAlert({content: `Change Logs Link Copied to your clipboard`}, AlertAccent.Success);
    };

    public setFilterParam = (filter: IChangeLogFilter, key: string, value: string) => {
        filter[key] = value;
    };

    public removeFilter = (index: number) => {
        this.params.filters.splice(index, 1);
    };

    public setSelectedItem = (item: any) => {
        this.selectedItem = item;
    };

    public async getPlatformTags() {
        const filterHandler = this.reportingFiltersManager.getFilter(PlatformReportingFilterType.TagName, ReportVerticalType.PLATFORM);
        this.setPlatformTags(filterHandler.items);
    }

    public setPlatformTags = (items: any[]) => {
        this.tagsByAccount = _.groupBy(items, "user");
        this.nameByIdAndScope = _.keyBy(items, (item) => "platform" + item._id);
    };

    public addFilterParamArray = (filter: IChangeLogFilter, key: string, value: string) => {
        if (!filter[key]) {
            filter[key] = observable.array([], {deep: false});
        }
        filter[key] = [...filter[key], value];
    };

    public removeFilterParamArray = (filter: IChangeLogFilter, key: string, value: string) => {
        filter[key] = filter[key].filter((row) => row !== value);
    };

    @computed get platformTagsByAccount() {
        return this.tagsByAccount[this.account];
    }

    public setAccount = (account: string) => {
        this.account = account;
    };

}
