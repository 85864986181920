import {define, inject} from '@injex/core';
import {action, makeObservable, observable} from 'mobx';
import {ColumnsManager} from '@vidazoo/ui-kit';
import ApiManager from '../../../../../common/api/ApiManager.mdl';
import {DataListQuery} from '../../../../../common/models/DataListQuery';
import {IUserNotificationSetting} from "../../../interfaces/IUserNotificationSetting";
import {UserNotificationsManager} from "../../../managers/userNotificationsManager.mdl";
import {SessionManager} from "../../../../session/managers/sessionManager.mdl";
import {UserNotificationSettingsTableColumns} from "../common/constants";
import {AlertsManager} from "../../../../../common/alerts/managers/alertsManager.mdl";
import {AlertAccent} from "../../../../../common/alerts/common/enums";

@define()
export class NotificationSettingsModel extends ColumnsManager<IUserNotificationSetting> {

    @inject() private apiManager: ApiManager;
    @inject() private userNotificationsManager: UserNotificationsManager;
    @inject() private sessionManager: SessionManager;
    @inject() public alertsManager: AlertsManager;
    public items: DataListQuery<IUserNotificationSetting, []>;

    constructor() {
        super({
            persistKey: 'notification_settings_list_columns',
        });

        this.setColumns(UserNotificationSettingsTableColumns);

        this.items = new DataListQuery(this._fetchItems.bind(this), {defaultValue: [], dataObservType: observable.ref});

        makeObservable(this);

    }

    public get data(): IUserNotificationSetting[] {
        return this.items.data;
    }

    public get isLoading(): boolean {
        return this.items.isLoading;
    }

    private async _fetchItems() {
        const results = await this.userNotificationsManager.socketManager.userNotifications.getNotificationsSettingsById(this.sessionManager.user._id) as IUserNotificationSetting[];
        return results;
    }

    public fetch() {
        return this.items.forceFetch();
    }

    @action public updateSetting = (id: string, key: string, value: any) => {
        const idx = this.items.data.findIndex((setting) => setting._id === id);
        if (idx >= 0) {
            this.items.data[idx][key] = value;
            const data = {};
            data[key] = value;
            this.onUpdate(id, data);
        }
    };

    @action public onUpdate = (id: string, data: any) => {
        this.userNotificationsManager.socketManager.userNotifications.updateSetting(id, data)
            .then(action((res: any) => this.onUpdateSuccess(res)))
            .catch(() => this.onUpdateFailed());
    };

    @action private onUpdateSuccess(res) {
        this.alertsManager.setBasicAlert({content: 'Setting updated successfully'}, AlertAccent.Info);
    }

    @action private onUpdateFailed() {
        this.alertsManager.setBasicAlert({content: 'Failed to update notifications settings'}, AlertAccent.Error);
    }

    @action public deleteItem = (item: IUserNotificationSetting) => {
        this.userNotificationsManager.socketManager.userNotifications.deleteSetting(item._id)
            .then(() => this.onDeleteItemSuccess(item))
            .catch(() => this.onDeleteItemError());
    };

    private onDeleteItemSuccess(item: IUserNotificationSetting) {
        this.items.data = this.items.data.filter((i) => i._id !== item._id)
        this.alertsManager.setBasicAlert({content: "Preset report deleted successfully"}, AlertAccent.Success);
    }

    private onDeleteItemError() {
        this.alertsManager.setBasicAlert({content: "Failed to delete preset report"}, AlertAccent.Error);
    }
}
