import * as React from "react";
import theme from "./theme.module.scss";

interface INavLinkGroupProps {
    children?: any
}

export default function NavGroup({children}: INavLinkGroupProps) {
    return React.Children.map(children, ((child: React.ComponentElement<any, any>) => {
        return (
            <div className={theme.navGroup}>
                {child}
            </div>
        )
    }))
}
