import ILabelValuePair from '../interfaces/ILabelValuePair';

export enum ChartType {
    DATA_TABLE = "data_table",
    SUMMARY = "summary",
    PERFORMANCE = "performance",
}

export const ChartTypes: ILabelValuePair[] = [
    {value: ChartType.DATA_TABLE, label: "Data Table"},
    {value: ChartType.SUMMARY, label: "Summary"},
    {value: ChartType.PERFORMANCE, label: "Performance"},
];

export const ChartTypesLabels = {
    [ChartType.DATA_TABLE]: "Data Table",
    [ChartType.SUMMARY]: "Summary",
    [ChartType.PERFORMANCE]: "Performance"
};

export const ChartTypesWithImg = [
    {
        value: ChartType.DATA_TABLE,
        label: "Data Table",
        image: "https://s3.amazonaws.com/vidazoo-cms/videos/733765ed-342a-b1b7-0b7a-80e5bdf9a667.png"
    },
    {
        value: ChartType.SUMMARY,
        label: "Summary",
        image: "https://s3.amazonaws.com/vidazoo-cms/videos/7109e1c3-f0b5-5bc2-4d33-7a03ea5ccb12.png"
    },
    {
        value: ChartType.PERFORMANCE,
        label: "Performance",
        image: "https://s3.amazonaws.com/vidazoo-cms/videos/c28540db-cb43-7810-b1bc-e6f8ad80d98e.png"
    },
];
