import BaseFilter from "../BaseFilter";
import {PlatformReportingFilterType} from "../ReportingFilterType";
import ILabelValuePair from '../../../interfaces/ILabelValuePair';
import {alias, define, init, inject, singleton} from '@injex/core';
import UserManager from '../../../managers/UserManager.mdl';
import {TagTypeLabels, TagTypes} from '../../../enums/TagType';
import availablePrebidAdapters from '../../../constants/availablePrebidAdapters';
import {PrebidAdapter, PrebidAdapters} from '../../../enums/PrebidAdapter';

@define()
@singleton()
@alias('PlatformReportingFilter')
export default class TagTypePrebidFilter extends BaseFilter {

    public type = PlatformReportingFilterType.TagTypePrebid;
    @inject() public userManager: UserManager;

    constructor() {
        super();

        this.allowNew = true;
        this.labelKey = "label";
        this.valueKey = "value";
        this.items = [];
    }

    @init()
    public init() {
        this.items = [...getAdapters(availablePrebidAdapters, this.userManager.isVidazooNetwork()), ...TagTypeLabels, ...TagTypes];
    }
}

function getAdapters(availableAdapters: PrebidAdapter[], isVidazooUser: boolean): ILabelValuePair[] {
    if (!availableAdapters || !availableAdapters.length || isVidazooUser) {
        return PrebidAdapters;
    }

    return PrebidAdapters.filter((item) => availableAdapters.includes(item.value as PrebidAdapter));
}
