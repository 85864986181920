import {observer} from 'mobx-react';
import {DashboardEditModel} from '../model/DashboardEditModel.mdl';
import {useModuleFactory} from '@injex/react-plugin';
import {useParams} from 'react-router-dom';
import {useEffect, useMemo} from 'react';
import PageMessageLoader from '../../../../../common/components/page-message-loader/PageMessageLoader';
import DashboardEditForm from '../components/DashboardEditForm';

export default observer(function DashboardEdit() {

    const model = useModuleFactory<DashboardEditModel>('dashboardEditModel');
    const {id} = useParams<{ id: string; }>();

    useEffect(() => {
        model.item.invalidate().fetch(id);
    }, [id]);

    return (
        <div>
            {model.item.isLoading ? (
                <PageMessageLoader title="Loading Dashboard" description="Please wait..."/>
            ) : (
                <DashboardEditForm model={model}/>
            )}
        </div>
    );
});
