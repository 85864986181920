import IDictionary from '../../../../common/interfaces/IDictionary';
import IReportingEntry from '../interfaces/IReportingEntry';
import {makeObservable, observable} from 'mobx';

export default class ReportingEntries {

    @observable public entries: IReportingEntry[];
    @observable public entriesByValue: IDictionary<IReportingEntry>;
    @observable public entriesByLabel: IDictionary<IReportingEntry>;
    @observable public entriesById: IDictionary<IReportingEntry>;

    constructor(entries?: IReportingEntry[]) {
        makeObservable(this);

        this.entries = [];
        this.entriesByValue = {};
        this.entriesByLabel = {};
        this.entriesById = {}

        if (entries) {
            this.set(entries);
        }
    }

    public set(entries: IReportingEntry[]) {
        this.entries = entries;
        this.indexEntries();
    }

    private indexEntries() {
        for (let i = 0, len = this.entries.length; i < len; i++) {
            const field = this.entries[i];
            this.entriesByLabel[field.label] = this.entriesByValue[field.value] = this.entriesById[field._id] = field;
        }
    }

    public get(labelOrValue: string): IReportingEntry {
        return this.getByLabel(labelOrValue) || this.getByValue(labelOrValue);
    }

    public getByValue(value: string, defaultValue?: any): IReportingEntry {
        return this.entriesByValue[value] || defaultValue;
    }

    public getByLabel(label: string, defaultValue?: any): IReportingEntry {
        return this.entriesByLabel[label] || defaultValue;
    }

    public getById(id: string, defaultValue?: any): IReportingEntry {
        return this.entriesById[id] || defaultValue;
    }

}
