import * as React from 'react';
import {Route} from 'react-router-dom';
import UrlsService from '../../../common/router/services/urlsService.mdl';
import Dashboards from '../list/view/Dashboards';
import DashboardEdit from '../item/edit/view/DashboardEdit';
import DashboardCreate from '../item/create/view/DashboardCreate';

const DashboardsRouting = (
    <React.Fragment>
        <Route path={UrlsService.dashboards()} element={<Dashboards/>}/>
        <Route path={UrlsService.createDashboard()} element={<DashboardCreate/>}/>
        <Route path={UrlsService.editDashboard()} element={<DashboardEdit/>}/>
    </React.Fragment>
);

export default DashboardsRouting
