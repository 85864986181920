import {observer} from "mobx-react";
import theme from "../theme.module.scss";
import * as React from "react";
import {IChangeLogFilter} from "../../../common/interfaces/IChangeLogFilter";
import {FormSelect} from '@vidazoo/ui-kit';
import {FilterOperators} from '../../../../reporting/common/enums/FilterOperators';
import {ChangeLogsActions} from '../../../common/constants/changeLogsActions';
import useChangeLogsModel from '../../../common/hooks/useChangeLogsModel';

export interface IActionFilterProps extends React.InputHTMLAttributes<HTMLInputElement> {
    filter: IChangeLogFilter;
}

function ActionFilter({filter}: IActionFilterProps): JSX.Element {
    const model = useChangeLogsModel();

    const onChangeFilterParam = (e) => {
        const {name, value} = e.target;
        model.setFilterParam(filter, name, value);
    }

    return (
        <>
            <FormSelect
                className={theme.select}
                items={[FilterOperators.isAnyOf, FilterOperators.isNotAnyOf]}
                maxItems={5}
                itemValueKey="value"
                itemLabelKey="label"
                value={filter.operator || FilterOperators.isAnyOf}
                name="operator"
                required
                hideValidationMessage
                onChange={onChangeFilterParam}
                searchable
            />
            <FormSelect
                className={theme.select}
                items={ChangeLogsActions}
                maxItems={5}
                itemValueKey="value"
                itemLabelKey="label"
                value={filter.value}
                name="value"
                required
                hideValidationMessage
                onChange={onChangeFilterParam}
                searchable
            />
        </>
    );
}

export default observer(ActionFilter);
