import {define, inject} from '@injex/core';
import {computed, makeObservable, observable} from 'mobx';
import IToken from '../../../../../common/interfaces/IToken';
import {SessionManager} from '../../../../session/managers/sessionManager.mdl';
import IProfile from '../interfaces/IProfile';
import RemotePasswordValidator from '../../../../../common/models/RemotePasswordValidator.mdl';
import {AlertsManager} from '../../../../../common/alerts/managers/alertsManager.mdl';
import {AlertAccent} from '../../../../../common/alerts/common/enums';
import {getGravatarUrl} from '../../../../../common/utils/gravatar';

@define()
export default class ProfileModel {

    @inject() private sessionManager: SessionManager;
    @inject() private remotePasswordValidator: RemotePasswordValidator;
    @inject() public alertsManager: AlertsManager;

    @observable public isOpen: boolean;
    @observable public isLoading: boolean;
    @observable public loadError: boolean;
    @observable public dataUrl: string;
    @observable public isLoadingTokensApi: boolean;
    @observable public tokensApi: IToken[];
    @observable public newTokenApi: string;
    @observable public avatarUrl: string;
    @observable public item: IProfile;

    constructor() {
        makeObservable(this);
        this.reset();
    }

    public reset() {
        this.item = {
            ...this.item,
            firstName: "",
            lastName: "",
            email: "",
            twoFactorAuth: {
                enableTwoFactorAuth: false
            },
            phone: ""
        };

        this.tokensApi = observable.array([], {deep: false});
        this.isLoadingTokensApi = false;
        this.avatarUrl = "";
        this.dataUrl = "";
        this.isOpen = false;
        this.newTokenApi = "";
    }

    public onOpenClick = () => {
        this.isOpen = true;
    }

    public onCloseClick = () => {
        this.isOpen = false;
    }

    public getTokensApi = async () => {
        this.isLoadingTokensApi = true;
        const res = await this.sessionManager.accountsSdk.auth.users.getTokensApi();
        this.isLoadingTokensApi = false;
        this.tokensApi = res.results;
    }

    public updateParam(key: string, value: any) {
        this.item[key] = value;
    }

    public async submit() {
        try {
            const item = this.getProfileChanges();
            await this.sessionManager.accountsSdk.auth.users.updateMe(item)
            this.onSubmitSuccess()
        } catch (err) {
            this.onSubmitFailed(err)
        }
    }

    public updatePassword = async (currentPassword: string, newPassword: string) => {
        try {
            await this.sessionManager.accountsSdk.auth.users.changePassword(currentPassword, newPassword);
            this.onSubmitSuccess()
        } catch (err) {
            this.onSubmitFailed(err)
        }
    }

    public onSubmitSuccess() {
        this.alertsManager.setBasicAlert({content: `Operation Complete, updated successfully`}, AlertAccent.Success);
    }

    public onSubmitFailed(err: any) {
        this.alertsManager.setBasicAlert({content: `Operation Error, Failed to update`}, AlertAccent.Error);
    }

    private getProfileChanges() {
        return {
            firstName: this.item.firstName,
            lastName: this.item.lastName,
            phone: this.item.phone
        };
    }

    public checkPassword = (password: string) => {
        this.remotePasswordValidator.checkPassword(password);
    }

    @computed get isCheckingPassword(): boolean {
        return this.remotePasswordValidator.isCheckingPassword;
    }

    @computed get passwordScore(): number {
        return this.remotePasswordValidator.passwordScore;
    }

    public enableTwoFactoAuth = async (token: string) => {
        try {
            const data = await this.sessionManager.accountsSdk.auth.users.enableTwoFactor(token)
            this.onEnableSuccess(data)
            this.onSubmitSuccess()
        } catch (err) {
            this.onEnableFailed(err)
        }
    }

    private onEnableSuccess(data: any) {
        this.setDataUrl("");
        this.setItem(data);
    }

    public setItem(data) {
        this.isLoading = false;
        this.item = {...this.item, ...data};
        this.avatarUrl = this.item.email ? getGravatarUrl(this.item.email, 100) : "";
    }

    private setDataUrl = (url) => {
        this.dataUrl = url;
    }

    private onEnableFailed(err) {
        this.isLoading = false;
        this.loadError = true;
        // this.notificationsStore.pushErrorNotification({
        //     title: "Operation Error",
        //     text: "Failed to enable two factor authentication, please try to enter the token again",
        //     timeout: 5000
        // });
    }

    public disableTwoFactorAuth = async (password: string) => {
        try {
            const data = await this.sessionManager.accountsSdk.auth.users.disableTwoFactor(password);
            this.setItem(data);
            this.onSubmitSuccess()
        } catch (err) {
            this.onDisableFailed(err);
        }
    }

    private onDisableFailed(err) {
        this.isLoading = false;
        this.loadError = true;
        this.alertsManager.setBasicAlert({content: `Failed to disable two factor authentication, please try to enter the token again`}, AlertAccent.Error);
    }

    public generateTwoFactorSecret = async (password: string) => {
        try {
            const res = await this.sessionManager.accountsSdk.auth.users.generateTwoFactorSecret(password);
            this.setDataUrl(res.dataUrl);
            this.onSubmitSuccess()
        } catch (err) {
            this.onGenerateFailed(err);
        }
    }

    private onGenerateFailed(err) {
        this.isLoading = false;
        this.loadError = true;
        this.alertsManager.setBasicAlert({content: `Failed to generate two factor auth, please try to enter the password again`}, AlertAccent.Error);
    }

    public createToken = () => {
        try {
            this.sessionManager.accountsSdk.auth.createTokenApi();
            this.onSubmitSuccess()
        } catch (err) {
            this.onGenerateFailed(err);
        }
    }

    public deleteToken = async (tokenId: string) => {
        try {
            await this.sessionManager.accountsSdk.auth.users.deleteTokenApi(tokenId)
            await this.getTokensApi()
            this.onSubmitSuccess()
        } catch (err) {
            this.onGenerateFailed(err);
        }
    }

    public clearNewToken = () => {
        this.newTokenApi = "";
    }

}
