import {define, singleton} from '@injex/core';
import EntityRequestAdapter, {GetByIdModel} from '../../../common/utils/EntityRequestAdapter';
import {IReportHistory} from "../common/interfaces/IReportHistory";

@define()
@singleton()
export class HistoryReportsApiMdl extends EntityRequestAdapter<IReportHistory> {

    protected basePathName: string = 'report-history';

    protected get apiEndpoint(): string {
        return this.env.reportingHistoryAPI;
    }

    public async getReportFromUrls(id: string): Promise<string> {
        const response = await this.requestWithContext<string>({
            method: 'get',
            url: this.makeAPIPath(`/report-from-urls`),
            params: {id}
        });

        return response.data;
    }

    public async getById(id: string, model: GetByIdModel<IReportHistory>): Promise<IReportHistory> {
        const response = await this.requestWithContext<IReportHistory>({
            method: 'get',
            url: this.makeAPIPath(`/get-by-id/${id}`),
            params: model
        });

        return response.data;
    }

}
