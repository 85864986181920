import ILabelValuePair from '../interfaces/ILabelValuePair';

export enum ReportVerticalType {
    PLATFORM = "platform",
    CONNECTION = "connection",
    OPEN_RTB = "open_rtb",
}

export const ReportVerticalTypes: ILabelValuePair[] = [
    {value: ReportVerticalType.PLATFORM, label: "Platform"},
    {value: ReportVerticalType.CONNECTION, label: "Connection"},
    {value: ReportVerticalType.OPEN_RTB, label: "OpenRtb"},
];
