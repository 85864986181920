import * as React from 'react';
import theme from './theme.module.scss';
import {Icon, Badge} from "@vidazoo/ui-kit";
import PanelHeader from "./components/panel-header/PanelHeader";
import PanelBody from "./components/panel-body/PanelBody";
import useUserNotificationsManager from "../../hooks/useUserNotificationsManager";
import {observer} from "mobx-react";
import {useMainMenuContext} from "../../../app/components/main-menu/contexts/MainMenuContext";
import useUserManager from "../../../../common/hooks/useUserManager";

const GROUP = 'USER_NOTIFICATION';

function NotificationPanel(): JSX.Element {
    const manager = useUserNotificationsManager();
    const {selectedGroup, setSelectedGroup} = useMainMenuContext();
    const userManager = useUserManager();

    if (!userManager.isVidazooOrganization) {
        return null;
    }

    return (
        <div onMouseEnter={() => setSelectedGroup(GROUP)} onMouseLeave={() => setSelectedGroup(null)}
             className={theme.notificationPanel}>
            <div className={theme.icon}>
                <Badge red className={theme.badge}>{manager.unseenCount}</Badge>
                <Icon className="text-white" iconName="icon-publishers" iconColor="white" size={2.2}/>
            </div>
            {selectedGroup === GROUP ? (
                <div className={theme.drawer}>
                    <PanelHeader/>
                    <PanelBody/>
                </div>
            ) : null}
        </div>
    );
}

export default observer(NotificationPanel);
