import {alias, define} from '@injex/core';
import {makeObservable, observable} from 'mobx';
import {AlertAccent, AlertType} from '../../common/enums';
import {AlertView} from '../../interfaces/IAlertModel';
import BaseAlertModel from '../../models/BaseAlertModel';
import PromptAlertView from './PromptAlertView';

export interface IPromptAlertPayload {
    label: any;
    initialValue: any;
    onConfirm?(value: any): void;
    inputType?: string;
    placeholder?: string;
    confirmText?: string;
    inputProps?: any;
}

@define()
@alias('Alert')
export class PromptAlertModel extends BaseAlertModel<IPromptAlertPayload> {
    public static readonly Type = AlertType.Prompt;

    @observable public value: any;

    public get View(): AlertView {
        return PromptAlertView;
    }

    constructor(payload: IPromptAlertPayload, accent?: AlertAccent, withLoader?: boolean) {
        super(payload, accent, withLoader);

        makeObservable(this);

        this.confirm = this.confirm.bind(this);

        this.value = payload.initialValue;

        payload.confirmText = payload.confirmText || 'Confirm';
        payload.inputType = payload.inputType || 'text';
    }

    public confirm() {
        this.payload.onConfirm && this.payload.onConfirm(this.value);
        this.resolve(this.value);
        this.dismiss();
    }

    public setValue(value: any) {
        this.value = value;
    }
}