import * as React from "react";
import theme from "./theme.module.scss";
import {Icon, Tooltip} from "@vidazoo/ui-kit";
import {observer} from "mobx-react";

export interface IQuickFilterProps {
    item: any;
    onClick: (item) => void;
    iconName: string;
    children: any;
}

function QuickReportAction({children, item, onClick, iconName}: IQuickFilterProps) {

    const onIconClick = () => {
        onClick(item);
    };

    return (
        <div className={theme.quickFilter}>
       		<span onClick={onIconClick} className={theme.icon}>
				<Icon iconName={iconName} size={1.5}/>
			</span>
            {children}
        </div>
    );
}

export default observer(QuickReportAction);
