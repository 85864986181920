import theme from '../reportingHeader/theme.module.scss';
import {Infocon} from '@vidazoo/ui-kit';

export function MetricsTip() {
    return (
        <div className={theme.linkedTip}>
            <div>
                Metrics are measures of quantitative<br/>
                assessment used for measurement<br/>
                or tracking performance.
            </div>
        </div>
    );
}


export default function MetricsInfo() {
    return (
        <Infocon className={theme.infocon} leaveOpenOnMouseOver={true}
                 title={<MetricsTip/>}
                 position="bottom"/>
    )
}
