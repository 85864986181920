import ILabelValuePair from '../interfaces/ILabelValuePair';

export enum TagType {
    VAST = "vast",
    IMA = "ima",
    SHE_MEDIA_DYNAMIC_VAST = "she_media_dynamic_vast",
    PREBID = "pre_bid",
    PREBID_DISPLAY = "pre_bid_display",
    OPEN_RTB = "open_rtb",
    DFP_DISPLAY = "dfp_display",
    YANDEX = "yandex",
    AMAZON = "amazon",
    OPEN_RTB_CLIENT = "open_rtb_client",
    OPEN_RTB_DISPLAY = "open_rtb_display",
    STATIC = "static",
}


export const TagTypes: ILabelValuePair[] = [
    {value: TagType.VAST, label: "Vast"},
    {value: TagType.IMA, label: "IMA"},
    {value: TagType.PREBID, label: "Header Bidding (Prebid.js)"},
    {value: TagType.DFP_DISPLAY, label: "DFP Display"},
    {value: TagType.YANDEX, label: "Yandex"},
    {value: TagType.OPEN_RTB_CLIENT, label: "Open RTB Client"},
    {value: TagType.OPEN_RTB, label: "Open RTB Server"},
];

export const TagTypeLabels: ILabelValuePair[] = [
    {value: TagType.IMA, label: "GoogleAdManager"},
    {value: TagType.SHE_MEDIA_DYNAMIC_VAST, label: "She Media"},
    {value: TagType.DFP_DISPLAY, label: "GoogleDFP"},
    {value: TagType.VAST, label: "Other"},
    {value: TagType.YANDEX, label: "Yandex"},
    {value: TagType.AMAZON, label: "Amazon"},
    {value: TagType.PREBID, label: "Pre Bid"},
    {value: TagType.PREBID_DISPLAY, label: "Pre Bid Display"},
    {value: TagType.OPEN_RTB, label: "Open RTB"},
    {value: TagType.STATIC, label: "Static"},
];
