import {define, inject, singleton} from '@injex/core';
import SocketManager from '../../../common/managers/SocketManager.mdl';
import {BaseReportsManager} from './baseReportsManager';
import UserManager from "../../../common/managers/UserManager.mdl";
import ReportingParamsManager from "./reportingParamsManager.mdl";

@define()
@singleton()
export class RegularReportsManager extends BaseReportsManager {

    @inject() private socketManager: SocketManager;
    @inject() private reportingParamsManager: ReportingParamsManager;
    @inject() private userManager: UserManager;

    public async get(): Promise<any> {
        try {
            this.reportingParamsManager.setStorageParams();

            if (this.reportingParamsManager.params.isCompare) {
                return this.getCompare();
            }

            return this.getReport();

        } catch (e) {
            await this.hooks.onReportError.call()
        }
    }

    public async getReport(): Promise<any> {
        const params = this.reportingParamsManager.preparedParams;
        return this.socketManager.bdReporting.getReporting(params);
    }

    public async getCompare() {
        const params = this.reportingParamsManager.preparedParams;
        return this.socketManager.bdReporting.getCompareReporting(params);
    }
}
