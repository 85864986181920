import {observer} from "mobx-react";
import {Chips, FormSelect} from "@vidazoo/ui-kit";
import theme from "../theme.module.scss";
import * as React from "react";
import {IChangeLogFilter} from "../../../common/interfaces/IChangeLogFilter";
import {FilterOperators} from '../../../../../common/enums/FilterOperators';
import useChangeLogsModel from '../../../common/hooks/useChangeLogsModel';
import {ReportVerticalType} from '../../../../../common/enums/ReportVerticalType';
import useAccountsMetaDataManager from '../../../../../common/hooks/useAccountsMetaDataManager';

export interface IAccountFilterProps extends React.InputHTMLAttributes<HTMLInputElement> {
    filter: IChangeLogFilter;
}

function PlatformTagsFilter({filter}: IAccountFilterProps): JSX.Element {

    const model = useChangeLogsModel();
    const accountsMetaDataManager = useAccountsMetaDataManager();

    const onChangeFilterParam = (e: { target: { name: any; value: any; }; }) => {
        const {name, value} = e.target;
        model.setFilterParam(filter, name, value);
    }

    const addFilter = (value: string) => {
        model.addFilterParamArray(filter, "value", value);
    }

    const onDeleteValue = (value: string) => {
        model.removeFilterParamArray(filter, "value", value);
    }

    const onSelectAccount = (e: { target: { value: any; }; }) => {
        const {value} = e.target;
        model.setAccount(value);
    }

    return (
        <>
            <FormSelect
                className={theme.select}
                items={[FilterOperators.isAnyOf, FilterOperators.isNotAnyOf]}
                maxItems={5}
                itemValueKey="value"
                itemLabelKey="label"
                value={filter.operator || FilterOperators.isAnyOf}
                name="operator"
                required
                hideValidationMessage
                onChange={onChangeFilterParam}
                searchable
            />
            <FormSelect
                className={theme.select}
                items={accountsMetaDataManager.accountsByVertical[ReportVerticalType.PLATFORM]}
                maxItems={5}
                itemValueKey="_id"
                itemLabelKey="username"
                value={model.account}
                name="account"
                required
                hideValidationMessage
                onChange={onSelectAccount}
                searchable
            />
            <Chips
                allowOrder={false}
                leaveOpenAfterSelect
                allowCreate={false}
                selectValueOnBlur={false}
                dataSource={model.platformTagsByAccount}
                itemLabelKey="name"
                itemValueKey="_id"
                chips={filter.value}
                maxItems={5}
                onSelect={addFilter}
                onDelete={onDeleteValue}
            />
        </>
    );
}

export default observer(PlatformTagsFilter);
