import React from "react";
import {observer} from "mobx-react";
import {Responsive, WidthProvider} from "react-grid-layout";
import theme from "../../styles/theme.module.scss";
import ChartDisplay from '../../../../../../common/components/chart-display/ChartDisplay';
import useEditChartsManager from '../../../../../../common/hooks/useEditChartsManager';
import {DashboardCreateModel} from '../../../create/model/DashboardCreateModel.mdl';
import {DashboardEditModel} from '../../../edit/model/DashboardEditModel.mdl';
import {IconButton, Surface} from "@vidazoo/ui-kit";

const ResponsiveReactGridLayout = observer(WidthProvider(Responsive));

export interface IChartsFormProps {
    isEditMode?: boolean;
    model: DashboardCreateModel | DashboardEditModel;
}

export default observer(function ChartsForm(props: IChartsFormProps) {
    const editChartsManager = useEditChartsManager();
    const {model, isEditMode = true} = props;
    const disableDrag = !isEditMode || window.innerWidth < 500;

    return (
        <ResponsiveReactGridLayout useCSSTransforms rowHeight={1} isDraggable={!disableDrag} isResizable={!disableDrag}
                                   onLayoutChange={model.onLayoutChange} className="layout">
            {model.item.data.layouts.lg.map((layout) => {
                const chartEditModel = editChartsManager.getModel(layout.i);

                const ref = React.createRef();

                return (
                    <Surface className={isEditMode ? theme.editLayout : ""} ref={ref} key={layout.i} data-grid={layout}>
                        <ChartDisplay onDeleteClick={model.removeChart} chartModel={chartEditModel}
                                      isEditMode={isEditMode}/>
                    </Surface>
                );
            })}
        </ResponsiveReactGridLayout>
    );
});
