import {Route} from 'react-router-dom';
import * as React from 'react';
import NotificationCenter from "../components/notification-center/NotificationCenter";
import UrlsService from "../../../common/router/services/urlsService.mdl";
import NotificationSettings from "../components/notification-settings/view/NotificationSettings";

const UserNotificationsRouting = (
    <React.Fragment>
        <Route path={UrlsService.notificationCenter()} element={<NotificationCenter/>}/>
        <Route path={UrlsService.notificationSettings()} element={<NotificationSettings/>}/>
    </React.Fragment>
);

export default UserNotificationsRouting
