import BaseFilter from "../BaseFilter";
import {PlatformReportingFilterType} from "../ReportingFilterType";
import {alias, define, singleton} from '@injex/core';

@define()
@singleton()
@alias('PlatformReportingFilter')
export default class DomainFilter extends BaseFilter {

	public type = PlatformReportingFilterType.Domain;

	constructor() {
		super();
	}
}
