import { useState, useEffect } from 'react';

function useAutoCompleteListWidth() {
    const [width, setWidth] = useState(getWidth(window.innerWidth));

    useEffect(() => {
        function handleResize() {
            setWidth(getWidth(window.innerWidth));
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function getWidth(innerWidth: number) {
        if (innerWidth < 1050) {
            return 150;
        } else if (innerWidth < 1200) {
            return 200;
        } else if (innerWidth < 1550) {
            return 300;
        } else {
            return 400;
        }
    }

    return width;
}

export default useAutoCompleteListWidth;
