import * as React from "react";
import theme from "../../../../../common/styles/base.module.scss";
import {observer} from "mobx-react";
import {Button, Chips, HLine, Label} from '@vidazoo/ui-kit';
import {FormMode} from '../../../../../common/enums/FormMode';
import IChartReportingParams from '../../../interfaces/IChartReportingParams';
import {ChartType} from '../../../../../common/enums/ChartType';
import {ReportingContextMenuType} from '../../../../reporting/common/enums/ReportingContextMenuType';
import IReportingFilter from '../../../../reporting/common/interfaces/IReportingFilter';
import IReportingParams from '../../../../reporting/common/interfaces/IReportingParams';
import IReportingConstraint from '../../../../reporting/common/interfaces/IReportingConstraint';
import ICustomStyle from '../../../interfaces/ICustomStyle';
import useReportingMetaDataManager from '../../../../../common/hooks/useReportingMetaDataManager';
import ReportingFilters from '../../../../reporting/components/reportingFilters/ReportingFilters';
import ReportingConstraints from '../../../../reporting/components/reportingConstraints/ReportingConstraints';
import StyleReportForm from '../../../../../common/components/style-report-form/StyleReportForm';

interface IReportFormProps {
    mode: FormMode;
    reportParams: IChartReportingParams;
    type: ChartType;
    onClearAllParams: (key: ReportingContextMenuType) => void;

    removeReportParam: (key: string, value: string) => void;
    setParam: (key: keyof IReportingParams, value: any) => void;
    addFilter: () => IReportingFilter;
    pushReportParam: (key: string, value: string, label: string) => void;

    setFilterKey: (filter: IReportingFilter, key: string) => Promise<any>;
    setFilterParam: (filter: IReportingFilter, key: string, value: any) => void;
    removeFilter: (filter: IReportingFilter) => void;
    pushFilterValue: (filter: IReportingFilter, value: string, label: string) => void;
    removeFilterValue: (filter: IReportingFilter, value: string) => void;

    setConstraintParam: (constraint: IReportingConstraint, key: string, value: any) => void;
    removeConstraint: (constraint: IReportingConstraint) => void;
    addConstraint: () => void;

    onCustomStyleChange: (value: IReportingConstraint, name: string, index: number) => void;
    removeCustomStyle: (index: number) => void;
    customStyles: ICustomStyle[];
    addCustomStyle: () => void;
}

export default observer(function ReportForm(props: IReportFormProps) {
    const reportingMetaDataManager = useReportingMetaDataManager();
    const {
        addFilter, removeFilter, addConstraint,
        onClearAllParams, removeConstraint,
        pushReportParam, removeReportParam, setParam,
        onCustomStyleChange, removeCustomStyle, customStyles, addCustomStyle,
        removeFilterValue, pushFilterValue, setConstraintParam,
        setFilterParam, setFilterKey,
        reportParams, type
    } = props;

    return (
        <div className={theme.form}>
            <div className="flex justify-between align-center" style={{marginBottom: 30}}>
                <Label spacing={1.25} size={13} monserrat weight={600}>Dimensions</Label>
                <Button type="button" iconName="icon-plus-small" green small uppercase
                        onClick={addFilter}>Add Filter</Button>
            </div>
            {type !== ChartType.SUMMARY ? (
                <Chips
                    allowOrder
                    required
                    chips={reportParams.groups}
                    maxItems={5}
                    leaveOpenAfterSelect
                    onDelete={(value: string) => removeReportParam("groups", value)}
                    onOrder={(groups: any[]) => setParam("groups", groups)}
                    onSelect={(value: string, label: string) => pushReportParam("groups", value, label)}
                    dataSource={reportingMetaDataManager.metaDataByVertical[reportParams.verticalType].groups.entries}
                    searchPlaceholder="Add Dimension"
                    emptyPlaceholder="Add Dimension"
                />
            ) : null}
            <ReportingFilters
                filters={reportParams.filters}
                onClearAllParams={onClearAllParams}
                setFilterKey={setFilterKey}
                setFilterParam={setFilterParam}
                removeFilter={removeFilter}
                pushFilterValue={pushFilterValue}
                removeFilterValue={removeFilterValue}
            />
            <HLine space={3.2}/>
            <div className="flex justify-between align-center" style={{marginBottom: 30}}>
                <Label spacing={1.25} size={13} monserrat weight={600}>Metrics</Label>
                <Button type="button" iconName="icon-plus-small" green small uppercase
                        onClick={addConstraint}>Add Constraint</Button>
            </div>
            <Chips
                dataSource={reportingMetaDataManager.metaDataByVertical[reportParams.verticalType].fields.entries}
                searchPlaceholder="Add Metric"
                emptyPlaceholder="Add Metric"
                allowOrder
                required
                chips={reportParams.fields}
                maxItems={5}
                leaveOpenAfterSelect
                onDelete={(value: string) => removeReportParam("fields", value)}
                onOrder={(groups: any[]) => setParam("fields", groups)}
                onSelect={(value: string, label: string) => pushReportParam("fields", value, label)}
            />
            <ReportingConstraints
                constraints={reportParams.constraints}
                onClearAllParams={onClearAllParams}
                setConstraintParam={setConstraintParam}
                removeConstraint={removeConstraint}
            />
            {type === ChartType.DATA_TABLE &&
                <div className="flex justify-flex-end align-center" style={{marginBottom: 30, marginTop: 30}}>
                    <Button type="button" iconName="icon-plus-small" deepPurple small uppercase
                            disabled={!(reportParams.fields && reportParams.fields.length > 0)}
                            onClick={addCustomStyle}>Add Custom Style</Button>
                </div>}
            {customStyles && customStyles.map((customStyle, index) => (
                <StyleReportForm
                    customStyles={customStyles}
                    reportParams={reportParams}
                    onCustomStyleChange={onCustomStyleChange}
                    removeCustomStyle={removeCustomStyle}
                    index={index}
                    key={customStyle.id}/>))}
        </div>
    );
});
