import * as React from "react";
import clsx from "clsx";
import theme from "./theme.module.scss";
import {FormSelect, Icon, Chips, FormInput} from "@vidazoo/ui-kit";
import {ISubGroup} from "../../common/interfaces/ISubGroup";
import {
    PlatformSubGroupsKeys,
    TargetingOperators,
    TargetingTypesSubGroups
} from "../../managers/reportingSubGroupsManager.mdl";
import useReportingSubGroupsManager from "../../common/hooks/useReportingSubGroupsManager";
import useReportingParamsManager from "../../common/hooks/useReportingParamsManager";
import {observer} from "mobx-react";
import useAutoCompleteListWidth from "../../../../common/hooks/useAutoCompleteListWidth";

export interface IReportingSubDimensionProps {
    index: number;
    subGroup: ISubGroup;
    onChange: (subGroup: ISubGroup, key: string, value: string | number | boolean) => void;
    onDelete: (subGroup: ISubGroup) => void;
    onAddValue: (subGroup: ISubGroup, value: string, label: string) => void;
    onAddTargetingType: (subGroup: ISubGroup, value: string, label: string) => void;
    onDeleteValue: (subGroup: ISubGroup, value: string) => void;
    onDeleteTargetingType: (subGroup: ISubGroup, value: string) => void;
    onAddTargetingOperator: (subGroup: ISubGroup, value: string, label: string) => void;
    onDeleteTargetingOperator: (subGroup: ISubGroup, value: string) => void;
}

function ReportingSubDimension(props: IReportingSubDimensionProps) {

    const {
        subGroup,
        onChange,
        onDelete,
        onAddValue,
        onDeleteValue,
        index,
        onDeleteTargetingOperator,
        onDeleteTargetingType,
        onAddTargetingType,
        onAddTargetingOperator
    } = props;
    const reportingSubGroupsManager = useReportingSubGroupsManager();
    const {params} = useReportingParamsManager();
    const autoCompleteListWidth = useAutoCompleteListWidth();

    const onDeleteSubGroup = () => {
        onDelete(subGroup);
    };

    const onAdd = (value: string, label: string) => {
        onAddValue(subGroup, value, label);
    };

    const onAddTargetingSubGroupType = (value: string, label: string) => {
        onAddTargetingType(subGroup, value, label);
    };

    const onAddTargetingSubGroupOperator = (value: string, label: string) => {
        onAddTargetingOperator(subGroup, value, label);
    };

    const onDeleteTargetingSubGroupOperator = (value: string) => {
        onDeleteTargetingOperator(subGroup, value);
    };

    const onDeleteSubGroupValue = (value: string) => {
        onDeleteValue(subGroup, value);
    };

    const onDeleteSubGroupTargetingType = (value: string) => {
        onDeleteTargetingType(subGroup, value);
    };

    const onChangeValue = (e: any) => {
        const {name, value} = e.target;
        onChange(subGroup, name, value);
    };

    const subGroupsEntities = subGroup.group ? reportingSubGroupsManager.subGroupsEntities[params.verticalType + subGroup.group] : {
        isLoading: true,
        data: []
    };
    return (
        <div className={theme.subGroup}>
            <div className={theme.label}>
                Sub Dimension {index + 1}
            </div>
            <div className={theme.inputs}>
                <FormSelect
                    className={clsx(theme.input, theme.groups)}
                    maxItems={5}
                    items={PlatformSubGroupsKeys}
                    itemValueKey="value"
                    itemLabelKey="label"
                    value={subGroup.group}
                    name="group"
                    required
                    hideValidationMessage
                    onChange={onChangeValue}
                    searchable
                />
                <Chips
                    allowOrder
                    autoCompleteListWidth={150}
                    leaveOpenAfterSelect
                    dataSource={subGroupsEntities ? subGroupsEntities.data : []}
                    chips={subGroup.entities}
                    maxItems={5}
                    onSelect={onAdd}
                    onDelete={onDeleteSubGroupValue}
                    isLoading={subGroupsEntities ? subGroupsEntities.isLoading : true}
                />
                <div className={theme.delete} onClick={onDeleteSubGroup}>
                    <Icon iconName="icon-delete" size={1.8}/>
                </div>
            </div>
            {subGroup.entities.includes("optionsWidget") ? (
                <FormInput style={{marginRight: 10, minWidth: 400}} className={theme.input}
                           value={subGroup.freeField} name="freeField"
                           placeholder="For example: value.checkDMode"
                           label="Free Field" onChange={onChangeValue}
                           hideValidationMessage/>
            ) : null}

            {subGroup.entities.includes("targeting") ? (
                <div className={theme.inputs} style={{paddingTop: 10}}>
                    <Chips
                        allowOrder
                        leaveOpenAfterSelect
                        dataSource={TargetingOperators}
                        chips={subGroup.targeting.operators}
                        maxItems={5}
                        onSelect={onAddTargetingSubGroupOperator}
                        onDelete={onDeleteTargetingSubGroupOperator}
                        searchPlaceholder="Add Targeting Operator"
                        emptyPlaceholder="Add Targeting Operator"
                        itemLabelKey="label"
                        itemValueKey="value"
                        autoCompleteListWidth={autoCompleteListWidth}
                    />
                    <Chips
                        allowOrder
                        leaveOpenAfterSelect
                        dataSource={TargetingTypesSubGroups}
                        chips={subGroup.targeting.types}
                        maxItems={5}
                        onSelect={onAddTargetingSubGroupType}
                        onDelete={onDeleteSubGroupTargetingType}
                        searchPlaceholder="Add Targeting Type"
                        emptyPlaceholder="Add Targeting Type"
                        autoCompleteListWidth={autoCompleteListWidth}
                        itemLabelKey="label"
                        itemValueKey="value"
                    />
                </div>
            ) : null}
        </div>
    );
}

export default observer(ReportingSubDimension);
