import * as React from 'react';
import {useMainMenuContext} from '../../main-menu/contexts/MainMenuContext';
import {observer} from 'mobx-react';
import theme from '../../../../../common/styles/_baseForm.module.scss';
import {AggregateChangeComponent, Avatar, Button, Form, FormInput, Label} from '@vidazoo/ui-kit';
import useUserManager from '../../../../../common/hooks/useUserManager';

function ProfileDetailsForm(): JSX.Element {
    const {profileModel} = useMainMenuContext();
    const {isVidazooOrganization} = useUserManager();
    const {avatarUrl, updateParam, submit, onCloseClick} = profileModel;
    const {firstName, lastName, email, phone} = profileModel.item;

    const onChange = (e) => {
        updateParam(e.target.name, e.target.value);
    }

    return (
        <Form className={theme.profile} onSubmit={submit}>
            <div className={theme.avatar}>
                <Avatar large imageUrl={avatarUrl}/>
                <div className={theme.link}>
                    <a href="https://en.gravatar.com" target="_blank" rel="noreferrer">Update image</a>
                </div>
            </div>
            <Label className={theme.label} spacing={1.25} size={13} weight={600}>User Details</Label>
            <AggregateChangeComponent onChange={onChange}>
                <div className={theme.row}>
                    <FormInput defaultValue={firstName} fill name="firstName" label="First Name" required
                               autoComplete="nope"/>
                    <FormInput defaultValue={lastName} fill name="lastName" label="Last Name" required
                               autoComplete="nope"/>
                </div>
            </AggregateChangeComponent>
            {isVidazooOrganization ?
                <div className={theme.row}>
                    <FormInput value={phone} fill name="phone" label="Phone Number"/>
                    {/*<FormPhoneNumberInput value={phone} fill name="phone" label="Phone Number" onChange={onChange} />*/}
                </div>
                : null}
            <div className={theme.row}>
                <FormInput disabled defaultValue={email} fill name="email" label="Email" required autoComplete="nope"
                           onChange={onChange}/>
            </div>
            <div className={theme.options}>
                <Button type="button" medium gray onClick={onCloseClick}>Cancel</Button>
                <Button type="submit" medium blue>Save Changes</Button>
            </div>
        </Form>
    );
}

export default observer(ProfileDetailsForm);
