import * as React from "react";
import theme from "./theme.module.scss";
import QuickReportAction from "../../quickReportAction/QuickReportAction";
import {IReportingPreparedGroup} from "../../../common/interfaces/IReportingPreparedResults";
import MarkeredText from "../../../../../common/components/markered-text/MarkeredText";
import {ColumnsManager} from "@vidazoo/ui-kit";
import {observer} from "mobx-react";

export interface IReportGroupValueCellProps {
    group: IReportingPreparedGroup;
    onQuickFilter?: (group: IReportingPreparedGroup) => void;
    model: ColumnsManager<any>;
    groupValueKey?: string;
}

function ReportGroupValueCell(props: IReportGroupValueCellProps) {
    const {group, onQuickFilter, model, groupValueKey} = props;

    const groupKey = group.key || group.type || group.name;

    const value = group.link ? (
        <a className={theme.link} href={group.link} target="_blank">
            <MarkeredText marker={model.getColumnSearchQuery(groupKey)}>
                {group.valueDisplay || "-"}
            </MarkeredText>
        </a>
    ) : (
        <MarkeredText marker={model.getColumnSearchQuery(groupKey)}>
            {groupValueKey ? (group[groupValueKey] || group.valueDisplay || "-") : group.valueDisplay || "-"}
        </MarkeredText>
    )

    if (onQuickFilter) {
        return (
            <QuickReportAction iconName="icon-filter" item={group} onClick={onQuickFilter}>
                {value}
            </QuickReportAction>
        );
    }
    return value
}

export default observer(ReportGroupValueCell);

