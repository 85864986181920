import React, {useState} from 'react';
import {AuthStatus} from '../common/enums';
import useSessionManager from './useSessionManager';

export default function useSessionStatus(): AuthStatus {
    const sessionManager = useSessionManager();
    const [status, setStatus] = useState<AuthStatus>(sessionManager.status);

    React.useEffect(() => {
        function onAuthStatusChange(status: AuthStatus) {
            setStatus(status);
        }

        sessionManager.hooks.statusChange.tap(onAuthStatusChange);

        return () => sessionManager.hooks.statusChange.untap(onAuthStatusChange);
    }, [sessionManager]);

    return status;
}