import {define, singleton} from '@injex/core';
import {createBrowserHistory, BrowserHistory, To} from 'history';

const history = createBrowserHistory();

@define()
@singleton()
export class RouterService {
    public get history(): BrowserHistory {
        return history;
    }

    public push(to: To) {
        if (typeof to === "string" && !to.startsWith('/')) {
            to = `/${to}`;
        }
        history.push(to);
    }

    public replace(to: To) {
        if (typeof to === "string" && !to.startsWith('/')) {
            to = `/${to}`;
        }
        history.replace(to);
    }
}
