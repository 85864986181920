import {observer} from 'mobx-react';
import * as React from 'react';
import {BasicAlertModel} from './basicAlertModel.mdl';

export interface IBasicAlertViewProps {
    model: BasicAlertModel;
}

function BasicAlertView({model}: IBasicAlertViewProps): JSX.Element {
    return (
        <div>{model.payload.content}</div>
    );
}

export default observer(BasicAlertView);