import * as React from 'react';
import {useMainMenuContext} from '../../main-menu/contexts/MainMenuContext';
import {observer} from 'mobx-react';
import {Tab, TabsPanel} from '@vidazoo/ui-kit';
import ProfileTwoFactorForm from './ProfileTwoFactorForm';
import ProfilePasswordForm from './ProfilePasswordForm';
import ProfileDetailsForm from './ProfileDetailsForm';
import theme from '../../../../../common/styles/_baseForm.module.scss';

function ProfileForm(): JSX.Element {
    const {profileModel} = useMainMenuContext();

    return (
        <TabsPanel animate className={theme.midContent}>
            <Tab hash="profile" label="Profile">
                <ProfileDetailsForm/>
            </Tab>
            <Tab hash="password" label="Password">
                <ProfilePasswordForm/>
            </Tab>
        </TabsPanel>
    );
}

export default observer(ProfileForm);
