import {alias, define} from '@injex/core';
import {AlertAccent, AlertType} from '../../common/enums';
import {AlertView} from '../../interfaces/IAlertModel';
import BaseAlertModel from '../../models/BaseAlertModel';
import BasicAlertView from './BasicAlertView';
import {isDefined} from '@vidazoo/ui-kit';

export interface IBasicAlertPayload {
    content: any;
    ttl?: number;
}

@define()
@alias('Alert')
export class BasicAlertModel extends BaseAlertModel<IBasicAlertPayload> {
    public static readonly Type = AlertType.Basic;

    public get View(): AlertView {
        return BasicAlertView;
    }

    constructor(payload: IBasicAlertPayload, accent?: AlertAccent, withLoader?: boolean) {
        super(payload, accent, withLoader);

        if (!isDefined(payload.ttl)) {
            payload.ttl = 4000;
        }

        if (payload.ttl) {
            setTimeout(this.dismiss, payload.ttl);
        }
    }
}
