import IDashboard from '../../interfaces/IDashboard';
import {TableColumn} from '@vidazoo/ui-kit';

export const DashboardsTableColumns: TableColumn<IDashboard>[] = [
    {
        title: 'Checkbox',
        key: 'checkbox',
    },
    {
        title: 'Name',
        key: 'name',
        defaultWidth: 300,
        options: {order: false, search: {searchKey: 'name'}, freeze: true, sort: {sortKey: 'name'}}
    }, {
        title: "Created",
        key: "created",
        defaultWidth: 200,
        options: {order: false, freeze: true, sort: {sortKey: 'created'}}
    }, {
        title: "Active",
        key: "isActive",
        defaultWidth: 200,
        options: {order: false, sort: {sortKey: 'isActive'}, filter: {filterKey: 'isActive', options: [{label: 'Active', value: true}, {label: 'Inactive', value: false}]}},
    }
];
