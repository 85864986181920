import {PlatformReportingFilterType} from "../ReportingFilterType";
import BaseFilter from "../BaseFilter";
import {ReportVerticalType} from '../../../enums/ReportVerticalType';
import {alias, define, inject, singleton, init} from '@injex/core';
import AccountsMetaDataManager from '../../../managers/AccountsMetaDataManager.mdl';

@define()
@singleton()
@alias('PlatformReportingFilter')
export default class UserFilter extends BaseFilter {

	public type = PlatformReportingFilterType.User;
    @inject() public accountsMetaDataManager: AccountsMetaDataManager;

	constructor() {
		super();

		this.allowNew = false;
		this.labelKey = "username";
		this.valueKey = "_id";
	}

	@init()
	private init() {
		this.isLoading = true;
		this.accountsMetaDataManager.hooks.accountsReady.tap(this.onAccountsReady, null, this);
	}

	private onAccountsReady() {
		this.setData();
	}

	public initialize(): Promise<void> {
		return new Promise((resolve, reject) => {
			const intervalId = setInterval(() => {
				if (!this.isLoading) {
					clearInterval(intervalId);
					resolve();
				}
			}, 1000);
		});
	}

	private setData() {
		this.items = this.accountsMetaDataManager.accountsByVertical[ReportVerticalType.PLATFORM];
		this.isLoading = false;
	}
}
