import {define, inject, singleton} from '@injex/core';
import BaseChartModel from '../../common/base/BaseChartModel';
import IChart from '../../../interfaces/IChart';
import {Promise} from 'bluebird';
import {cloneDeep} from 'lodash';
import ReportingParamsManager from '../../../../reporting/managers/reportingParamsManager.mdl';
import {RouterService} from '../../../../../common/router/services/routerService.mdl';
import UrlsService from '../../../../../common/router/services/urlsService.mdl';

@define()
@singleton()
export class ChartCreateModel extends BaseChartModel {

    @inject() private reportingParamsManager: ReportingParamsManager;
    @inject() private routerService: RouterService;

    public fetchItem(id: string): Promise<IChart> {
        return Promise.resolve(undefined);
    }

    public submit = async () => {
        try {
            const item = cloneDeep(this.item.data);

            item.preparedReportParams = this.reportingParamsManager.getReportParamsForChart(item.reportParams);

            const doc = await this.apiManager.charts.create(item);

            this.onCreateSuccess();

            this.routerService.push(UrlsService.editChart(doc._id))

        } catch (err) {
            this.onSubmitFailed(err);
        }
    }

    protected delete(): Promise<void> {
        return Promise.resolve(undefined);
    }

}
