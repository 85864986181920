import {Icon, FormInput, FormSelect} from '@vidazoo/ui-kit';
import IReportingConstraint from '../../common/interfaces/IReportingConstraint';
import theme from './theme.module.scss';
import {ConstraintsOperators} from '../../common/enums/ConstraintsOperators';
import clsx from 'clsx';
import {observer} from 'mobx-react';
import useReportingMetaDataManager from '../../../../common/hooks/useReportingMetaDataManager';
import {ReportVerticalType} from '../../../../common/enums/ReportVerticalType';

export interface IReportingConstraintRowProps {
    index: number;
    constraint: IReportingConstraint;
    setConstraintParam: (constraint: IReportingConstraint, key: string, value: any) => void;
    removeConstraint: (constraint: IReportingConstraint) => void;
}

function ReportingConstraintRow(props: IReportingConstraintRowProps) {
    const metaDataManager = useReportingMetaDataManager();
    const {fields} = metaDataManager.metaDataByVertical[ReportVerticalType.PLATFORM];
    const {removeConstraint, setConstraintParam, index, constraint} = props;

    const onChangeConstraint = (e) => {
        const {name, value} = e.target;
        setConstraintParam(constraint, name, value);
    }

    const onDeleteConstraint = () => {
        removeConstraint(constraint);
    }

    return (
        <div className={theme.constraint}>
            <div className={theme.label}>
                Constraint {index + 1}
            </div>
            <div className={theme.inputs}>
                <FormSelect
                    onChange={onChangeConstraint}
                    searchable
                    hideValidationMessage
                    required
                    placeholder="Select Metric"
                    name="name"
                    items={fields.entries}
                    maxItems={5}
                    itemValueKey="value"
                    itemLabelKey="label"
                    value={constraint.name}
                    className={theme.input}
                />
                <FormSelect
                    onChange={onChangeConstraint}
                    hideValidationMessage
                    required
                    placeholder="Select Operator"
                    name="op"
                    maxItems={6}
                    items={ConstraintsOperators}
                    value={constraint.op}
                    className={theme.input}
                />
                <FormInput
                    onChange={onChangeConstraint}
                    hideValidationMessage
                    required
                    placeholder="Type a Value"
                    type="number"
                    name="value"
                    fill
                    value={constraint.value}
                    className={clsx(theme.input, theme.value, "flex-1")}
                />
                <div className={theme.delete} onClick={onDeleteConstraint}>
                    <Icon iconName="icon-delete" size={1.8}/>
                </div>
            </div>
        </div>
    );
}

export default observer(ReportingConstraintRow);
