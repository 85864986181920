import {alias, define, singleton} from '@injex/core';
import {BaseSocket} from '../../../../common/socket/baseSocket';
import {IBiReportingParams} from "../interfaces/IReportingParams";

@define()
@singleton()
@alias('Disposable')
export class BdReportingSocket extends BaseSocket {

    public name = "bdReporting";

    public get getUri(): string {
        return this.env.biDashboardsAPI;
    }

    public getReporting(data: IBiReportingParams) {
        return this.request("getReporting", data, 10 * 60 * 1000);
    }

    public getReportByChart(chartId: string, timePreset: string, timezone: string, reportParams: {
        filters: any[],
        constraints: any[]
    }) {
        return this.request("getReportByChart", {chartId, timePreset, timezone, reportParams}, 10 * 60 * 1000);
    }

    public getCompareReporting(data: any) {
        return this.request("getCompareReporting", data, 10 * 60 * 1000);
    }

    public getAdvancedReport(data: any) {
        return this.request("getAdvancedReport", data);
    }

    public getAdStartByDate(data) {
        return this.request("getAdStartByDate", data);
    }

    public getFillRateByDate(data) {
        return this.request("getFillRateByDate", data);
    }

    public getAdStartByTag(data) {
        return this.request("getAdStartByTag", data);
    }

    public getAdStartByConnection(data) {
        return this.request("getAdStartByConnection", data);
    }

    public getAdStartByBrowser(data) {
        return this.request("getAdStartByBrowser", data);
    }

    public getAdStartByDomain(data) {
        return this.request("getAdStartByDomain", data);
    }

    public getAdStartByOs(data) {
        return this.request("getAdStartByOs", data);
    }

    public getRevenue(data) {
        return this.request("getRevenue", data);
    }

    public getRevenueByCountry(data) {
        return this.request("getRevenueByCountry", data);
    }

    public startListenToReportByChart(userId: string, chartId: string, timePreset: string, fn: (data: any) => void) {
        this.socket.on(`reportByChart${userId}${chartId}${timePreset}`, fn);
    }

    public removeListenToReportByChart(userId: string, chartId: string, timePreset: string) {
        this.socket.off(`reportByChart${userId}${chartId}${timePreset}`);
    }
}
