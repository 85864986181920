import * as React from "react";
import {observer} from "mobx-react";
import {Label, Icon, IconButton, useColorScheme} from "@vidazoo/ui-kit";
import theme from "./theme.module.scss";
import useRouterService from '../../router/hooks/useRouterService';
import UrlsService from '../../router/services/urlsService.mdl';
import {ChartType} from '../../enums/ChartType';
import {ChartEditModel} from '../../../modules/charts/item/edit/model/ChartEditModel.mdl';
import {ChartCreateModel} from '../../../modules/charts/item/create/model/ChartCreateModel.mdl';

export interface IChartHeaderProps {
    chartModel: ChartEditModel | ChartCreateModel;
    isEditMode: boolean;
    onDeleteClick?: (chartId: string) => void;
}

export default observer(function ChartHeader(props: IChartHeaderProps) {
    const {scheme} = useColorScheme();
    const {chartModel, isEditMode, onDeleteClick} = props;
    const routerService = useRouterService();
    const chart = chartModel.item.data;

    const onDelete = () => {
        onDeleteClick && onDeleteClick(chart._id);
    };

    const onEditClick = () => {
        routerService.push(UrlsService.editChart(chart._id));
    };

    const renderDownloadCsvButton = () => {
        const report = chartModel.data;
        if (!report) {
            return null;
        }
        return (chart.type === ChartType.DATA_TABLE && !isEditMode) ? (
            <IconButton iconName="icon-download" blue onClick={chartModel.downloadCSV}/>) : null;
    };

    if (!chart) {
        return null;
    }

    return (
        <div className={theme.container}>
            <Label size={18} spacing={1.25}>{chart.name}</Label>
            <div className={theme.actions}>
                {isEditMode ? (
                    <>
                        {onDeleteClick ? (
                            <div className={theme.action} onClick={onDelete}>
                                <Icon iconColor={scheme === "light" ? "black" : "white"} size={2}
                                      iconName="icon-delete"/>
                            </div>
                        ) : null}

                        <div className={theme.action} onClick={onEditClick}>
                            <Icon iconColor={scheme === "light" ? "black" : "white"} size={2} iconName="icon-edit"/>
                        </div>
                    </>
                ) : null}
            </div>
            {renderDownloadCsvButton()}
        </div>
    );
});
