import * as _ from "lodash";

export function compileFieldFormula(formula: any): (dto: any) => number {
    let template;
    if (formula && formula.includes("${")) {
        template = _.template(formula);

    } else {
        template = _.template(formula, {interpolate: /([a-zA-Z][\w]+)/g});
    }
    return (dto) => {
        try {
            const fn = new Function("return " + template(dto));
            const result = fn();
            return (Infinity === result || isNaN(result)) ? 0 : result;
        } catch (e) {
            return 0;
        }
    };
}
