import NavLink from '../NavLink';
import {useMainMenuContext} from '../../contexts/MainMenuContext';
import * as React from "react";
import clsx from 'clsx';
import NavGroup from '../nav-group/NavGroup';
import {Icon} from "@vidazoo/ui-kit";
import theme from "./theme.module.scss";

interface INavLinkGroupProps {
    children?: any
    to?: string;
    label?: string;
    iconName?: string;
    right?: boolean;
    group?: string;
}

export default function NavLinkGroup({children, to, label, iconName, right, group}: INavLinkGroupProps) {
    const {selectedGroup, setSelectedGroup} = useMainMenuContext();
    return (
        <div
            className={theme.navLinkGroup}
            onMouseEnter={() => setSelectedGroup(label ? label : group)}
            onMouseLeave={() => setSelectedGroup(null)}>
            {to ? (
                <NavLink className={theme.navLinkGroupInside} to={to}>
                    {iconName &&
                        <Icon className={clsx("text-white", {"pr-3": !!label})} iconName={iconName} iconColor="white"
                              size={2}/>}
                    {label.toUpperCase()}</NavLink>
            ) : label ? (
                <div className={theme.navLinkGroupInside}>
                    {iconName &&
                        <Icon className={clsx("text-white", {"pr-3": !!label})} iconName={iconName} iconColor="white"
                              size={2}/>}
                    {label.toUpperCase()}
                </div>
            ) : null}

            {(React.Children && (label ? label : group) === selectedGroup) ? (
                <div className={clsx("absolute bg-white dark:bg-black top-[104%] flex flex-col shadow", {
                    "left-0": !right,
                    "right-0": right
                })}>
                    <NavGroup>
                        {children}
                    </NavGroup>
                </div>
            ) : null}
        </div>
    );
}
