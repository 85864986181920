const chartConfig = {
    chart: {
        type: "spline",
        zoomType: "xy",
        spacing: [0, 0, 0, 0],
        marginTop: 18,
        backgroundColor: null,
    },
    title: {
        text: null
    },
    xAxis: [{
        type: "datetime",
        labels: {
            y: 20,
            style: {
                color: "#00051c",
                fontWeight: "600",
                fontSize: "11px",
                lineHeight: "15px",
                fontFamily: "'Nunito Sans', sans-serif"
            }
        },
        minorTickLength: 0,
        tickLength: 0,
        maxPadding: 0,
        minPadding: 0,
        lineColor: "#999ba4",
        minRange: 3600000,
        endOnTick: false,
        startOnTick: false
    }],
    yAxis: {},
    tooltip: {
        borderWidth: 0,
        backgroundColor: null,
        useHTML: true,
        shared: true,
        crosshairs: {
            color: "#e5e6e8",
            width: 1
        }
    },
    plotOptions: {
        series: {
            marker: {
                enabled: true,
                radius: 2.5
            }
        }
    },
    colors: ["#6CF", "#39F", "#06C", "#036", "#000"],
    series: []
}

export default chartConfig;
