import {define, singleton} from '@injex/core';
import EntityRequestAdapter from '../../../common/utils/EntityRequestAdapter';
import {IScheduleReport} from '../interfaces/IScheduleReport';

@define()
@singleton()
export class ScheduleReportsApi extends EntityRequestAdapter<IScheduleReport> {
    protected basePathName: string = 'schedule_reports';

    protected get apiEndpoint(): string {
        return this.env.biDashboardsAPI;
    }

    public runNow(id: string, data: any) {
        return this.requestWithContext({
            method: "post",
            url: `/api/schedule_reports/${id}/run_now`,
            data
        });
    }

}
