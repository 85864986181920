import BaseFilter from "../BaseFilter";
import {PlatformReportingFilterType} from "../ReportingFilterType";
import {AccountManagers} from '../../../enums/AccountManagers';
import {alias, define, singleton} from '@injex/core';

@define()
@singleton()
@alias('PlatformReportingFilter')
export default class AccountManagerFilter extends BaseFilter {

	public type = PlatformReportingFilterType.AccountManager;

	constructor() {
		super();

		this.allowNew = true;
		this.labelKey = "label";
		this.valueKey = "value";
		this.items = AccountManagers;
	}
}
