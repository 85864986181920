import {define, init, inject, singleton} from '@injex/core';
import {DataListQuery} from '../models/DataListQuery';
import IChart from '../../modules/charts/interfaces/IChart';
import {observable} from 'mobx';
import {AlertAccent} from '../alerts/common/enums';
import ApiManager from '../api/ApiManager.mdl';
import {AlertsManager} from '../alerts/managers/alertsManager.mdl';
import {SessionManager} from '../../modules/session/managers/sessionManager.mdl';
import {ChartTypes, ChartTypesLabels} from '../enums/ChartType';

@define()
@singleton()
export default class ChartsManager {

    @inject() private apiManager: ApiManager;
    @inject() private alertsManager: AlertsManager;
    @inject() private sessionManager: SessionManager;

    public items: DataListQuery<IChart, []>;

    constructor() {
        this.items = new DataListQuery(this.fetchCharts.bind(this), {defaultValue: [], dataObservType: observable.ref});
    }

    @init()
    protected initialize() {
        this.sessionManager.hooks.metadataLoad.tapAsync(this.getItems, null, this);
    }

    public async getItems() {
        await this.items.invalidate().fetch();
    }

    public async fetchCharts(): Promise<IChart[]> {
        try {

            const {results} = await this.apiManager.charts.getAll({pageSize: 5000, filter: {isActive: true}});
            return results.map((chart) => {
                chart.label = `${chart.name} (${ChartTypesLabels[chart.type]})`;
                return chart;
            });

        } catch (err) {
            this.alertsManager.setBasicAlert({content: `Operation Error, Failed to get charts`}, AlertAccent.Error);
        }
    }

}
