import {TableColumn} from '@vidazoo/ui-kit';
import {IChangeLog} from '../interfaces/IChangeLog';

export const ChangeLogsTableColumns: TableColumn<IChangeLog>[] = [
    {
        title: 'Scope',
        key: 'scope',
        defaultWidth: 300,
        options: {order: true, search: {searchKey: 'scope'}, freeze: true, sort: {sortKey: 'scope'}}
    },
    {
        title: 'Account',
        key: 'account',
        defaultWidth: 300,
        options: {order: true, search: {searchKey: 'account'}, freeze: true, sort: {sortKey: 'account'}}
    },
    {
        title: 'User',
        key: 'user',
        defaultWidth: 300,
        options: {order: true, search: {searchKey: 'user'}, freeze: true, sort: {sortKey: 'user'}}
    },
    {
        title: 'Action',
        key: 'action',
        defaultWidth: 300,
        options: {order: true, search: {searchKey: 'action'}, freeze: true, sort: {sortKey: 'action'}}
    },
    {
        title: 'Model Name',
        key: 'model_name',
        defaultWidth: 300,
        options: {order: true, search: {searchKey: 'model_name'}, freeze: true, sort: {sortKey: 'model_name'}}
    },
    {
        title: 'Model ID',
        key: 'model_id',
        defaultWidth: 300,
        options: {order: true, search: {searchKey: 'model_id'}, freeze: true, sort: {sortKey: 'model_id'}}
    },
    {
        title: 'Date',
        key: 'date',
        defaultWidth: 300,
        options: {order: true, search: {searchKey: 'date'}, freeze: true, sort: {sortKey: 'date'}}
    },
    {
        title: 'Diff',
        key: 'diff',
        defaultWidth: 300,
    }
];
