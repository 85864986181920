import {alias, define} from '@injex/core';
import {AlertAccent, AlertType} from '../../common/enums';
import {AlertView} from '../../interfaces/IAlertModel';
import BaseAlertModel from '../../models/BaseAlertModel';
import ConfirmAlertView from './ConfirmAlertView';

export interface IConfirmAlertPayload {
    content: any;
    onConfirm?: (...args: any[]) => void;
    onCancel?: () => void;
    confirmText?: string;
    cancelText?: string;
}

@define()
@alias('Alert')
export class ConfirmAlertModel extends BaseAlertModel<IConfirmAlertPayload> {
    public static readonly Type = AlertType.Confirm;

    private _didUserSelectOption: boolean;

    constructor(payload: IConfirmAlertPayload, accent?: AlertAccent, withLoader?: boolean) {
        payload.cancelText = payload.cancelText || 'Cancel';
        payload.confirmText = payload.confirmText || 'Confirm';

        super(payload, accent, withLoader);

        this.cancel = this.cancel.bind(this);
        this.confirm = this.confirm.bind(this);

        this.hooks.dismiss.tapOnce(this._cancelWithoutDismiss, null, this);
    }

    public get View(): AlertView {
        return ConfirmAlertView;
    }

    public confirm(...args: any[]) {
        this._didUserSelectOption = true;
        this.payload.onConfirm?.(...args);
        this.resolve(true);
        this.dismiss();
    }

    private _cancelWithoutDismiss() {
        if (this._didUserSelectOption) {
            return;
        }

        this._didUserSelectOption = true;
        this.payload.onCancel?.();
        this.resolve(false);
    }

    public cancel() {
        this._didUserSelectOption = true;
        this.payload.onCancel?.();
        this.resolve(false);
        this.dismiss();
    }
}
