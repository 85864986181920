import {define, singleton, inject} from '@injex/core';
import UserNotificationsSocket from "../../modules/user-notifications/socket/userNotificationsSocket.mdl";
import {HistoryReportsSocket} from '../../modules/reporting/common/socket/historyReportsSocket.mdl';
import {BdReportingSocket} from "../../modules/reporting/common/socket/bdReportingSocket.mdl";

@define()
@singleton()
export default class SocketManager {

    @inject(BdReportingSocket) public bdReporting: BdReportingSocket;
    @inject(HistoryReportsSocket) public historyReports: HistoryReportsSocket;
    @inject(UserNotificationsSocket) public userNotifications: UserNotificationsSocket;
}
