import {define, singleton} from '@injex/core';
import {IConfigurations} from '../interfaces/IConfigurations';
import EntityRequestAdapter from '../utils/EntityRequestAdapter';

@define()
@singleton()
export class CloudApi extends EntityRequestAdapter<IConfigurations> {
    protected basePathName: string = 'clouds';

    protected get apiEndpoint(): string {
        return this.env.biDashboardsAPI;
    }

    public getMyCloud() {
        return this.request({
            method: "get",
            url: `/api/clouds/me`,
        });
    }

    public updateMyCloud(configurations: IConfigurations) {
        return this.request({
            method: "patch",
            url: `/api/clouds/me`,
            data: {configurations}
        });
    }
}
