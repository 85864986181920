import clsx from 'clsx';
import {useMemo} from 'react';
import theme from './loader.module.scss';

export interface IBoxLoaderProps {
    width: number | string;
    height: number | string;
    radius: number | string;
    style?: React.CSSProperties;
    className?: string;
    children?: any;
}

export default function BoxLoader({width, height, radius, style, className, children}: IBoxLoaderProps) {
    const styles = useMemo(() => ({...style, width, height, borderRadius: radius}), [width, height, radius, style]);

    return (
        <div className={clsx(theme.loader, className)} style={styles}>{children}</div>
    );
}

BoxLoader.Row = ({height = '1.6rem', width = '100%', radius = height, ...others}: Partial<IBoxLoaderProps>) => <BoxLoader width={width} height={height} radius={radius} {...others} />;

BoxLoader.Cube = ({size = '2rem', radius = '.4rem', ...others}: Partial<IBoxLoaderProps> & { size: any; }) => <BoxLoader width={size} height={size} radius={radius} {...others} />;

BoxLoader.Circle = ({size = '2rem', ...others}: Partial<IBoxLoaderProps> & { size: any; }) => <BoxLoader width={size} height={size} radius={size} {...others} />;