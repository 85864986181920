import * as React from 'react';
import theme from '../../../login/view/login.module.scss';
import ProgressIndicator from '../../../../common/components/progress-indicator/ProgressIndicator';
import {CircleLoader} from '@vidazoo/ui-kit';

export default function AuthorizingLoader(): JSX.Element {
    return (
        <div className={theme.wrapper} style={{backgroundImage: 'url(/images/common/background.svg)'}}>
            <ProgressIndicator white/>
            <CircleLoader size={10} white className={theme.loader} />
        </div>
    )
}
