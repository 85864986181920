export enum ReportingDbType {
    BigQuery = "BigQuery",
    MemSqlTemp = "MemSqlTemp",
    ClickHouse = "ClickHouse",
    ClickHouseDr = "ClickHouseDr",
    MemSql = "MemSql",
    SnowFlake = "SnowFlake",
}

export const AdminReportingDbTypes = [
    {value: null, label: "No Preference"},
    {value: ReportingDbType.ClickHouse, label: ReportingDbType.ClickHouse},
    {value: ReportingDbType.ClickHouseDr, label: ReportingDbType.ClickHouseDr},
    {value: ReportingDbType.SnowFlake, label: ReportingDbType.SnowFlake},
    {value: ReportingDbType.BigQuery, label: ReportingDbType.BigQuery},
];

export const UsersReportingDbTypes = [
    {value: null, label: "No Preference"},
    {value: ReportingDbType.ClickHouse, label: ReportingDbType.ClickHouse},
    {value: ReportingDbType.SnowFlake, label: ReportingDbType.SnowFlake},
    {value: ReportingDbType.ClickHouseDr, label: ReportingDbType.ClickHouseDr},
];