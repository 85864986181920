import * as React from 'react';
import theme from './theme.module.scss';
import clsx from 'clsx';
import dayjs from 'dayjs';
import {observer} from 'mobx-react';

export interface INotificationProps {
    notification: {
        _id: string;
        userId: string;
        subject: string;
        message: string;
        app: string;
        seen: boolean;
        created: number;
        updated: number;
        type: string;
    };
    onSelectItem: (notificationId: string) => void;
    selectedItemId: string;
}

function NotificationEntity(props: INotificationProps): JSX.Element {
    const {notification, selectedItemId, onSelectItem} = props;
    const isSelected = selectedItemId === notification._id;

    const onNotificationClick = () => {
        onSelectItem(notification._id);
    };

    return (
        <div className={theme.notificationItem} onClick={onNotificationClick}>
            <div className={theme.left}>
                <div className={clsx(theme.dot, {[theme.seen]: notification.seen})}/>
                <div className={clsx(theme.text, {[theme.seen]: notification.seen})}>
                    <div className={theme.subjectContainer}>
                        <div className={theme.subject}>{notification.subject}</div>
                    </div>
                    <div className={theme.notificationType}>{notification.app || 'Vidazoo'}</div>
                    <div className={clsx(theme.date, {[theme.seen]: notification.seen})}>{dayjs(notification.created).fromNow()}</div>
                </div>
            </div>
        </div>
    );
}

export default observer(NotificationEntity);
