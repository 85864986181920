import { bootstrap, IBootstrap, inject } from '@injex/core';
import { RenderInjexProvider } from '@injex/react-plugin';
import { UIKitProvider } from "@vidazoo/ui-kit";
import { Link } from "react-router-dom";
import Root from './common/router/view/Root';
import { SessionManager } from './modules/session/managers/sessionManager.mdl';

@bootstrap()
export class Bootstrap implements IBootstrap {
    @inject() private renderInjexProvider: RenderInjexProvider;
    @inject() private sessionManager: SessionManager;

    public run() {
        this.sessionManager.initialize();
        this.renderInjexProvider(
            <UIKitProvider config={{
                linkComponent: Link
            }}>
                <Root />
            </UIKitProvider>
        );
    }
}
