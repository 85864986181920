import {Suspense} from 'react';
import {Navigate, Route, Routes, unstable_HistoryRouter as Router} from 'react-router-dom';
import useRouterService from '../../../common/router/hooks/useRouterService';
import * as React from 'react';
import ProgressBanner from '../../components/progress-banner/ProgressBanner';
import App from '../../../modules/app/view/App';
import UrlsService from '../services/urlsService.mdl';
import ReportingRouting from '../../../modules/reporting/common/routing/ReportingRouting';
import Login from '../../../modules/login/view';
import ChangeLogsRouting from '../../../modules/change-logs/common/routing/ChangeLogsRouting';
import DashboardsRouting from '../../../modules/dashboards/routing/DashboardsRouting';
import ChartsRouting from '../../../modules/charts/routing/ChartsRouting';
import ScheduleReportsRouting from '../../../modules/schedule-reports/routing/ScheduleReportsRouting';
import UserNotificationsRouting from "../../../modules/user-notifications/routing/UserNotificationsRouting";

function Loading() {
    return (
        <ProgressBanner>Loading, please wait...</ProgressBanner>
    );
}

export default function Root() {
    const routerService = useRouterService();

    return (
        <Suspense fallback={<Loading/>}>
            <Router history={routerService.history}>
                <Routes>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/" element={<App/>}>
                        <Route index element={<Navigate to={UrlsService.dashboards()}/>}/>
                        {ReportingRouting}
                        {DashboardsRouting}
                        {ChartsRouting}
                        {ScheduleReportsRouting}
                        {ChangeLogsRouting}
                        {UserNotificationsRouting}
                    </Route>
                </Routes>
            </Router>
        </Suspense>
    );
}
