import {observable, makeObservable, computed} from "mobx";
import {define, inject, singleton} from "@injex/core";
import IReportingPreset from "../common/interfaces/IReportingPreset";
import {DataListQuery} from "../../../common/models/DataListQuery";
import {ColumnsManager} from "@vidazoo/ui-kit";
import ApiManager from "../../../common/api/ApiManager.mdl";
import {ReportPresetType} from "../common/enums/ReportPresetType";
import {AlertsManager} from "../../../common/alerts/managers/alertsManager.mdl";
import {AlertAccent} from "../../../common/alerts/common/enums";
import ReportingParamsManager from "./reportingParamsManager.mdl";
import {GenerateReportUrlParamsManager} from "./generateReportUrlParamsManager.mdl";

@define()
@singleton()
export default class ReportingPresetReportsManager extends ColumnsManager<IReportingPreset> {

    @observable public activePreset: { _id: string, name: string };
    @observable public showCreatePresetModal: boolean;

    @inject() private apiManager: ApiManager;
    @inject() private alertsManager: AlertsManager;
    @inject() private reportingParamsManager: ReportingParamsManager;
    @inject() private generateReportUrlParamsManager: GenerateReportUrlParamsManager;

    public items: DataListQuery<IReportingPreset, []>;

    constructor() {
        super({
            persistKey: 'presets_reports_list_columns',
        });
        this.activePreset = null;
        this.showCreatePresetModal = false;

        this.items = new DataListQuery(this._fetchItems.bind(this), {defaultValue: [], dataObservType: observable.ref});

        makeObservable(this);

    }

    private async _fetchItems() {
        const response = await this.apiManager.presetReports.getAll({filter: {isActive: true}});
        return response.results;
    }

    @computed
    public get privateItems() {
        return this.items.data.filter((row) => (row.type === ReportPresetType.Private && !row.isHistory));
    }

    @computed
    public get publicItems() {
        return this.items.data.filter((row) => row.type === ReportPresetType.Public);
    }

    @computed
    public get historyItems() {
        return this.items.data.filter((row) => row.isHistory);
    }

    public setActivePreset = (preset: { _id: string; name: string; }) => {
        if (preset && preset._id !== this.activePreset?._id) {
            this.apiManager.presetReports.updateLastUsed(preset._id);
        }
        this.activePreset = preset;
    };

    protected get data(): IReportingPreset[] {
        return this.items.data;
    }

    public create = async (data: { name: string, type: ReportPresetType }) => {
        try {

            const params = this.getPresetReportParams(data);
            await this.apiManager.presetReports.create(params);
            this.onCreateSuccess();

        } catch (e) {
            this.onCreateError()
        }
    };

    private getPresetReportParams(params: { name: string, type: ReportPresetType }) {
        const verticalType = this.reportingParamsManager.params.verticalType
        return {
            ...params,
            vertical: verticalType,
            timespan: this.reportingParamsManager.params.time,
            timezone: this.reportingParamsManager.params.timezone,
            groups: this.reportingParamsManager.params.groups.map((g) => g.value),
            fields: this.reportingParamsManager.params.fields.map((f) => f.value),
            constraints: this.generateReportUrlParamsManager.flattenConstraints(this.reportingParamsManager.params.constraints, verticalType),
            filters: this.generateReportUrlParamsManager.flattenFilters(this.reportingParamsManager.params.filters, verticalType),
            subGroups: this.reportingParamsManager.params.subGroups
        };
    }

    private onCreateSuccess() {
        this.alertsManager.setBasicAlert({content: "Preset report created successfully"}, AlertAccent.Success);
    }

    private onCreateError() {
        this.alertsManager.setBasicAlert({content: "Failed to create preset report"}, AlertAccent.Error);
    }

    public update = async (id: string, params: IReportingPreset) => {

        try {
            await this.apiManager.presetReports.updateById(id, params);
            this.onUpdateSuccess();
        } catch (e) {
            this.onUpdateError();
        }
    };

    private onUpdateSuccess() {
        this.alertsManager.setBasicAlert({content: "Preset report updated successfully"}, AlertAccent.Success);
    }

    private onUpdateError() {
        this.alertsManager.setBasicAlert({content: "Failed to update preset report"}, AlertAccent.Error);
    }

    public onCreateItem = () => {
        this.showCreatePresetModal = true;
    }

    public onCloseCreatePresetModal = () => {
        this.showCreatePresetModal = false;
    }

    public onDeleteItem = async (preset: IReportingPreset) => {
        this.alertsManager.setPromptAlert({
            label: "Are you sure you want to delete this preset report?",
            initialValue: preset,
            onConfirm: () => this.deleteItem(preset._id),
            confirmText: "Yes, Delete",
        });
    }

    public deleteItem = async (id: string) => {

        try {
            await this.apiManager.presetReports.deleteById(id);
            this.onDeleteItemSuccess();

        } catch (e) {
            this.onDeleteItemError();
        }
    };

    private onDeleteItemSuccess() {
        this.alertsManager.setBasicAlert({content: "Preset report deleted successfully"}, AlertAccent.Success);
    }

    private onDeleteItemError() {
        this.alertsManager.setBasicAlert({content: "Failed to delete preset report"}, AlertAccent.Error);
    }
}
