import * as React from 'react';
import theme from './theme.module.scss';
import {Modal} from '@vidazoo/ui-kit';
import {useMainMenuContext} from '../../main-menu/contexts/MainMenuContext';
import {observer} from 'mobx-react';
import ProfileLoader from '../components/ProfileLoader';
import ProfileForm from '../components/ProfileForm';

function Profile(): JSX.Element {
    const {profileModel} = useMainMenuContext();

    return (
        <Modal isOpen={profileModel.isOpen} exitOnClickOutside basic windowClass={theme.modal}
               onClose={profileModel.onCloseClick}>
            {profileModel.isOpen && profileModel.isLoading ? <ProfileLoader/> : null}
            {profileModel.isOpen && !profileModel.isLoading ? <ProfileForm/> : null}
        </Modal>
    );
}

export default observer(Profile);
