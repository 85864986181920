import {Link} from 'react-router-dom';
import {VidazooHeaderLogo} from "@vidazoo/ui-kit";
import {MainMenuWithContext} from '../contexts/MainMenuContext';
import UrlsService from '../../../../../common/router/services/urlsService.mdl';
import UserMenu from '../components/user-menu/UserMenu';
import Profile from '../../profile/view/Profile';
import NotificationPanel from "../../../../user-notifications/components/notification-panel/NotificationPanel";
import useUserManager from "../../../../../common/hooks/useUserManager";
import ThemeSwitch from '../components/theme-switch/ThemeSwitch';
import theme from './theme.module.scss';
import ChangeLogsLinks from '../../../../change-logs/common/links/ChangeLogsLinks';
import ChartsLinks from '../../../../charts/links/ChartsLinks';
import DashboardsLinks from '../../../../dashboards/links/DashboardsLinks';
import ReportingLinks from '../../../../reporting/common/links/ReportsLinks';
import ScheduleReportsLinks from '../../../../schedule-reports/links/ScheduleReportsLinks';
import TimePresetsSelect from "../components/time-presets-select/TimePresetsSelect";
import {observer} from "mobx-react";
import VidazooLogo from "../../../../../common/components/vidazoo-logo/VidazooLogo";

function MainMenu(): JSX.Element {

    return (
        <MainMenuWithContext>
            <div className={theme.menuContainer}>
                <div className="flex items-center h-full">
                    <div className="pl-4">
                        <Link to={UrlsService.dashboards()}>
                            <VidazooLogo width={181} height={66} light />
                        </Link>
                    </div>
                    <DashboardsLinks/>
                    <ChartsLinks/>
                    <ReportingLinks/>
                    <ScheduleReportsLinks/>
                    <ChangeLogsLinks/>
                </div>
                <div className="flex items-center h-full">
                    <TimePresetsSelect/>
                    <NotificationPanel/>
                    <ThemeSwitch/>
                    <UserMenu/>
                </div>
            </div>
            <Profile/>
        </MainMenuWithContext>
    );
}

export default observer(MainMenu);
