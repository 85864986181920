import {CircleLoader} from '@vidazoo/ui-kit';
import clsx from 'clsx';
import * as React from 'react';
import IAlertViewProps from '../interfaces/IAlertViewProps';
import theme from '../styles/theme.module.scss';
import GenericIcon from '../../components/generic-icon/GenericIcon';
import {IconSource} from '../../enums/IconSource';

function AlertView({model}: IAlertViewProps): JSX.Element {
    return (
        <div className={theme.alert}>
            <div className={theme.inner}>
                <div className={clsx(theme.accent, theme[model.accent])}>
                    {model.withLoader ? <CircleLoader className={theme.loader} size={1.8} /> : null}
                </div>
                <div className={theme.header}>
                    <button className={theme.button} onClick={model.dismiss}>
                        <GenericIcon iconSource={IconSource.Material} iconName="close" />
                    </button>
                </div>
                <div className={theme.view}>
                    <model.View model={model} />
                </div>
            </div>
        </div>
    );
}

export default AlertView;
