import {define, init, inject, singleton} from '@injex/core';
import {SessionManager} from '../../modules/session/managers/sessionManager.mdl';
import {IAccountsByVertical, IUserMetadata} from '../../modules/session/interfaces/IUserMetadata';
import {ISession} from '../../modules/session/interfaces/IAccountsSDK';
import {makeObservable, observable} from 'mobx';
import * as _ from 'lodash';
import IUser from '../interfaces/IUser';
import Hook from '../utils/Hook';

export type AccountsMetaDataHooks = {
    accountsReady: Hook;
};

@define()
@singleton()
export default class AccountsMetaDataManager {

    @inject() private sessionManager: SessionManager;
    @observable public accountsByVertical: IAccountsByVertical;
    @observable public accounts: { username: string, _id: string }[];
    @observable public accountsById: { [index: string]: { username: string, _id: string } };

    @observable public users: IUser[];
    @observable public usersById: { [index: string]: IUser };

    public hooks: AccountsMetaDataHooks;

    constructor() {
        makeObservable(this);

        this.accountsByVertical = {
            platform: [],
            open_rtb: [],
            connection: []
        };

        this.hooks = {
            accountsReady: new Hook(),
        };
    }

    @init()
    protected initialize() {
        this.sessionManager.hooks.metadataLoad.tapAsync(this._onMetadataLoad, null, this);
    }

    private _onMetadataLoad(metadata: IUserMetadata, session: ISession) {
        this.setAccountsByVertical(metadata.accountsByVertical);
        this.setUsers(metadata.users)
        this.hooks.accountsReady.call()
    }

    public setAccountsByVertical(accountsByVertical: IAccountsByVertical) {
        this.accountsByVertical = accountsByVertical;
        this.accounts = [];
        Object.keys(accountsByVertical).forEach((vertical) => {
            accountsByVertical[vertical].forEach((account) => {
                this.accounts.push({...account, username: `${account.username} (${vertical})`});
            });
        });

        this.accountsById = _.keyBy(this.accounts, "_id");
    }

    public setUsers(users: IUser[]) {
        this.users = users;
        this.users.unshift({email: "VidazooDev@vidazoo.com", _id: "bot"} as any);
        this.usersById = _.keyBy(users, "_id");
    }

}
