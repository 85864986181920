import {observer} from "mobx-react";
import {Chips, FormSelect} from "@vidazoo/ui-kit";
import theme from "../theme.module.scss";
import * as React from "react";
import {IChangeLogFilter} from "../../../common/interfaces/IChangeLogFilter";
import {FilterOperators} from '../../../../../common/enums/FilterOperators';
import useChangeLogsModel from '../../../common/hooks/useChangeLogsModel';
import useAccountsMetaDataManager from '../../../../../common/hooks/useAccountsMetaDataManager';

export interface IUsersFilterProps extends React.InputHTMLAttributes<HTMLInputElement> {
    filter: IChangeLogFilter;
}

function UsersFilter({filter}: IUsersFilterProps): JSX.Element {
    const model = useChangeLogsModel();
    const accountsMetaDataManager = useAccountsMetaDataManager();

    const onChangeFilterParam = (e) => {
        const {name, value} = e.target;
        model.setFilterParam(filter, name, value);
    }

    const addFilter = (value: string) => {
        model.addFilterParamArray(filter, "value", value);
    }

    const onDeleteValue = (value: string, label: string) => {
        model.removeFilterParamArray(filter, "value", value);
    }

    return (
        <>
            <FormSelect
                className={theme.select}
                items={[FilterOperators.isAnyOf, FilterOperators.isNotAnyOf]}
                maxItems={5}
                itemValueKey="value"
                itemLabelKey="label"
                value={filter.operator || FilterOperators.isAnyOf}
                name="operator"
                required
                hideValidationMessage
                onChange={onChangeFilterParam}
                searchable
            />
            <Chips
                allowOrder={false}
                leaveOpenAfterSelect
                allowCreate={false}
                selectValueOnBlur={false}
                dataSource={accountsMetaDataManager.users}
                itemLabelKey="email"
                itemValueKey="_id"
                chips={filter.value}
                maxItems={5}
                onSelect={addFilter}
                onDelete={onDeleteValue}
                isLoading={(!accountsMetaDataManager.users || accountsMetaDataManager.users.length === 0)}
            />
        </>
    );
}

export default observer(UsersFilter);
