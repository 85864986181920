import React, {useState} from "react";
import theme from "../../styles/theme.module.scss";
import {observer} from "mobx-react";
import {Button, FormInput, Modal} from "@vidazoo/ui-kit";
import {normalizeEventValue} from '../../../../../../common/utils/utils';
import AddChart from '../../../../../../common/add-chart/view/AddChart';
import {DashboardCreateModel} from '../../../create/model/DashboardCreateModel.mdl';
import {DashboardEditModel} from '../../../edit/model/DashboardEditModel.mdl';

export interface IDetailsFormProps {
    model: DashboardCreateModel | DashboardEditModel;
}

export default observer(function DetailsForm(props: IDetailsFormProps) {
    const [showSelectChartModal, setShowSelectChartModal] = useState(false);
    const {model} = props;

    const onChangeDetail = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = normalizeEventValue(e);
        model.item.updateParam(e.target.name, value);
    };

    const toggleSelectChartModal = () => {
        setShowSelectChartModal((prev) => !prev);
    };


    const onAddClick = async (selectedChart: string) => {
        await model.onAddLayout(selectedChart);
        toggleSelectChartModal();
    };

    return (
        <>
            <div className={theme.header}>
                <FormInput
                    onChange={onChangeDetail}
                    className={theme.input}
                    value={model.item.data.name}
                    autoFocus
                    name="name"
                    label="Name"
                    required
                    fill
                    hideValidationMessage
                />
                <Button
                    type="button"
                    iconName="icon-plus-small"
                    medium
                    blue
                    uppercase
                    onClick={toggleSelectChartModal}
                >
                    Add Chart
                </Button>
            </div>
            <Modal isOpen={showSelectChartModal} exitOnClickOutside panel title="Add Chart"
                   onClose={toggleSelectChartModal}>
                <AddChart onAddClick={onAddClick} onCancelClick={toggleSelectChartModal}/>
            </Modal>
        </>
    );
});
