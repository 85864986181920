
export const AccountManagers = [
    {value: "6155967489c1160004d1d41d", label: "Inbar Elkouby"},
    {value: "6155965c74fe8c00046733ce", label: "Daniel Goldman"},
    {value: "61d16805802f09000441893b", label: "Shahaf Saar Sayah"},
    {value: "633582c27a7210909494a92d", label: "Matan Raz"},
    {value: "6196610d11fcdc000444ffef", label: "Tai Moscovich"},
    {value: "644f6a4d6bbff3272434163e", label: "Ronit Milikovski"},
    {value: "5a8eddff12eda300040fc082", label: "Yoav Peled"},
];
