import * as qs from "qs";

export default class URLQueryParams {

    public static parse(search: string): any {
        return qs.parse(search, { ignoreQueryPrefix: true });
    }

    public static stringify(json: any): string {
        return qs.stringify(json, { encode: true });
    }
}
