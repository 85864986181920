import {HLine, PageMessage} from '@vidazoo/ui-kit';
import * as React from 'react';
import dayjs from 'dayjs';
import theme from './theme.module.scss';
import {observer} from 'mobx-react';
import {IUserNotification} from "../../../../interfaces/IUserNotification";

export interface INotificationProps {
    selectedItem: IUserNotification;
}

function NotificationMessage(props: INotificationProps): JSX.Element {
    const {selectedItem} = props;
    const item = selectedItem as any;

    const renderError = () => {
        return (
            <div className={theme.notificationEmpty}>
                <PageMessage
                    small
                    title="No notification selected"
                    description="Please select a notification to continue..."
                    iconName="icon-warning"
                />
            </div>
        );
    };

    return (
        <>
            {item ?
                <div className={theme.notificationMessage}>
                    <div className={theme.top}>
                        <div className={theme.topText}>
                            <div>
                                <div className={theme.author}>
                                    <div>App: {item.app || 'Vidazoo'}</div>
                                </div>
                                <div className={theme.date}>Time: {dayjs(item.created).format('DD/MM/YYYY, HH:MM:SS')}</div>
                            </div>
                            <div>
                                <div className={theme.subject}>{item.subject}</div>
                            </div>
                        </div>
                    </div>
                    <HLine topSpace={1} bottomSpace={2} type="solid" style={{borderBottomColor: 'white'}} />
                    <div className={theme.bottom}>
                        <div className={theme.message} dangerouslySetInnerHTML={{__html: (item.params && item.params.htmlMessage) ? item.params.htmlMessage : item.message}} />
                    </div>
                </div>
                : renderError()}
        </>
    );
}

export default observer(NotificationMessage);
