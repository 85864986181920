const devices = [
    {
        key: "m",
        name: "mobile",
        label: "Mobile"
    },
    {
        key: "t",
        name: "tablet",
        label: "Tablet"
    },
    {
        key: "d",
        name: "desktop",
        label: "Desktop"
    },
    {
        key: "im",
        name: "inapp_mobile",
        label: "In-App Mobile"
    },
    {
        key: "it",
        name: "inapp_tablet",
        label: "In-App Tablet"
    },
    {
        key: "tv",
        name: "smarttv",
        label: "Smart TV"
    }
];

export default devices
