import {define, init} from '@injex/core';
import BaseDashboardModel from '../../common/base/BaseDashboardModel';
import IDashboard from '../../../interfaces/IDashboard';
import {AlertAccent} from '../../../../../common/alerts/common/enums';
import {Layout} from 'react-grid-layout';
import UrlsService from "../../../../../common/router/services/urlsService.mdl";

@define()
export class DashboardEditModel extends BaseDashboardModel {

    @init()
    protected initialize() {
        this.item.hooks.fetchCompleted.tapAsync(this.onFetchCompleted);
        this.reportingMetaDataManager.hooks.timePresetChange.tap(this.onFetchCompleted);
    }

    public onFetchCompleted = async () => {
        if (!this.item?.data?.layouts?.lg || this.item?.data?.layouts?.lg?.length === 0) {
            return;
        }
        await Promise.all(this.item.data.layouts.lg.map(async (layout) => {
            const chartModel = await this.editChartsManager.initChartModel(layout.i);
            await chartModel.getReport();
        }));
    }

    public fetchItem(id: string): Promise<IDashboard> {
        return this.apiManager.dashboards.getById(id, {});
    }

    public submit = async () => {
        try {
            await this.apiManager.dashboards.updateById(this.item.data._id, this.item.data);
            this.alertsManager.setBasicAlert({content: `Operation Complete, Updated successfully`}, AlertAccent.Success);

        } catch (err) {
            this.alertsManager.setBasicAlert({content: `Operation Error, Failed to update`}, AlertAccent.Error);
        }
    }

    public removeChart = (chartId: string) => {
        const idx = this.item.data.layouts.lg.findIndex((layout) => layout.i === chartId);
        if (idx > -1) {
            this.item.data.layouts.lg.splice(idx, 1);
        }
    };

    public onLayoutChange = (layouts: Layout[]) => {
        this.item.data.layouts.lg = layouts;
    };

    private delete = async () => {
        try {
            await this.apiManager.dashboards.deleteById(this.item.data._id);
            this.alertsManager.setBasicAlert({content: 'Dashboard deleted successfully'}, AlertAccent.Success);
            this.routerService.push(UrlsService.dashboards())

        } catch (e) {
            this.alertsManager.setBasicAlert({content: 'Failed to delete dashboard'}, AlertAccent.Error);
        }
    }

    public onDelete = () => {
        this.alertsManager.setConfirmAlert({
            content: 'Are you sure you want to delete this dashboard?',
            onConfirm: this.delete
        }, AlertAccent.Natural);
    };

}
