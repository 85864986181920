import BaseFilter from "../BaseFilter";
import {PlatformReportingFilterType} from "../ReportingFilterType";
import {alias, define, singleton} from '@injex/core';
import {TagTypeLabels} from "../../../enums/TagType";

@define()
@singleton()
@alias('PlatformReportingFilter')
export default class TagTypeFilter extends BaseFilter {

	public type = PlatformReportingFilterType.TagType;

	constructor() {
		super();

		this.allowNew = true;
		this.labelKey = "label";
		this.valueKey = "value";
		this.items = TagTypeLabels;
	}
}
