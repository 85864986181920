import {useChartContext} from "../../context/ChartContext";
import {Label, Checkbox, FormInput, Surface} from "@vidazoo/ui-kit";
import {useCallback} from 'react';
import {normalizeEventValue} from '../../../../../common/utils/utils';
import theme from '../../../../../common/styles/base.module.scss';
import ChartTypesSelect from '../../../../../common/components/chart-types-select/ChartTypesSelect';
import {ChartType} from '../../../../../common/enums/ChartType';
import useUserManager from '../../../../../common/hooks/useUserManager';
import {observer} from 'mobx-react';
import {FormMode} from '../../../../../common/enums/FormMode';
import ChartReportForm from '../chartReportForm/ChartReportForm';
import * as React from 'react';
import IReportingFilter from '../../../../reporting/common/interfaces/IReportingFilter';
import IReportingConstraint from '../../../../reporting/common/interfaces/IReportingConstraint';

export default observer(function ChartDetails() {
    const {model} = useChartContext();
    const userManager = useUserManager();
    const {data} = model.item;

    const onChangeDetail = useCallback((e) => {
            const value = normalizeEventValue(e);
            model.item.updateParam(e.target.name, value);
        }, [model.item]
    );

    const onRemoveFilter = (filter: IReportingFilter) => {
        model.removeFromArrayInObjByKey("reportParams", "filters", filter.id, "id");
    }

    const onPushReportParam = (key: string, value: string, label: string) => {
        model.addToArrayInObj("reportParams", key, value);
    }

    const onRemoveConstraint = (constraint: IReportingConstraint) => {
        model.removeFromArrayInObjByKey("reportParams", "constraints", constraint.id, "id");
    }

    return (
        <div className={theme.form}>
            <Surface className={theme.surface} level={1}>
                <div className={theme.row}>
                    <FormInput onChange={onChangeDetail} className={theme.input} value={data.name} autoFocus
                               name="name" label="Name"
                               required fill hideValidationMessage/>
                </div>
                <Label weight={600} spacing={1} size={12}
                       style={{color: "var(--field-label-color)", marginBottom: 10}}>Chart Type</Label>
                <ChartTypesSelect selectedType={data.type}
                                  onSelectType={(type) => model.item.updateParam("type", type)}/>
                <div className={theme.row}>
                    {userManager.isDeveloper() && data.type === ChartType.DATA_TABLE ? (
                        <Checkbox className={theme.input} name="mustFilter" label="Must Filter"
                                  defaultChecked={data.mustFilter} onChange={onChangeDetail}/>
                    ) : null}
                    {(userManager.isDeveloper() && data.type === ChartType.DATA_TABLE) ? (
                        <Checkbox className={theme.input} name="isDefault" label="Is Default"
                                  defaultChecked={data.isDefault} onChange={onChangeDetail}/>
                    ) : null}
                </div>
                <ChartReportForm
                    mode={FormMode.Edit}
                    reportParams={data.reportParams}
                    type={data.type}
                    onClearAllParams={model.onClearAllParams}
                    removeReportParam={model.removeReportParam}
                    setParam={model.setParam}
                    addFilter={model.addFilter}
                    pushReportParam={onPushReportParam}
                    setFilterKey={model.setFilterKey}
                    setFilterParam={model.setFilterParam}
                    removeFilter={onRemoveFilter}
                    pushFilterValue={model.pushFilterValue}
                    removeFilterValue={model.removeFilterValue}
                    addConstraint={model.addConstraint}
                    removeConstraint={onRemoveConstraint}
                    customStyles={model.item.data.customStyles}
                    addCustomStyle={model.addCustomStyle}
                    onCustomStyleChange={model.onCustomStyleChange}
                    removeCustomStyle={model.removeCustomStyle}
                    setConstraintParam={model.setConstraintParam}
                />
            </Surface>
        </div>
    );
});
