import {observer} from "mobx-react";
import {FormSelect, Chips} from "@vidazoo/ui-kit";
import theme from "../theme.module.scss";
import * as React from "react";
import {IChangeLogFilter} from "../../../common/interfaces/IChangeLogFilter";
import useChangeLogsModel from '../../../common/hooks/useChangeLogsModel';
import {FilterOperators} from '../../../../../common/enums/FilterOperators';
import {ChangeLogModelNames} from '../../../common/constants/changeLogModelNames';

export interface IModelIdFilterProps extends React.InputHTMLAttributes<HTMLInputElement> {
    filter: IChangeLogFilter;
}

function ModelNameFilter({filter}: IModelIdFilterProps): JSX.Element {

    const model = useChangeLogsModel();

    const onChangeFilterParam = (e) => {
        const {name, value} = e.target;
        model.setFilterParam(filter, name, value);
    }

    const addFilterValue = (value: string) => {
        model.addFilterParamArray(filter, "value", value);
    }

    const onDeleteValue = (value: string, label: string) => {
        model.removeFilterParamArray(filter, "value", value);
    }

    return (
        <>
            <FormSelect
                className={theme.select}
                items={[FilterOperators.isAnyOf, FilterOperators.isNotAnyOf]}
                maxItems={5}
                itemValueKey="value"
                itemLabelKey="label"
                value={filter.operator || FilterOperators.isAnyOf}
                name="operator"
                required
                hideValidationMessage
                onChange={onChangeFilterParam}
                searchable
            />
            <Chips
                allowOrder={true}
                leaveOpenAfterSelect
                allowCreate={true}
                selectValueOnBlur={false}
                chips={filter.value}
                dataSource={ChangeLogModelNames}
                maxItems={5}
                onSelect={addFilterValue}
                onDelete={onDeleteValue}
            />
        </>
    );
}

export default observer(ModelNameFilter);
