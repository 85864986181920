import {set} from '../utils/utils';
import DataQuery, {DataQueryFetcher, DataQueryOptions} from './DataQuery';

export class DataUpdaterQuery<T, Args extends Array<any> = any[]> extends DataQuery<T, Args> {

    constructor(fetcher: DataQueryFetcher<T, Args>, options?: DataQueryOptions<T>) {
        super(fetcher, options);
    }

    public updateObject(object: any, key: string, value: any) {
        object[key] = value;
    }

    public updateParam(key: string, value: any): void {
        if ((key as string).indexOf('.') > -1) {
            set(this.data, key as string, value);
        } else {
            this.data[key] = value;
        }
    }

    public updateDeepParam(objectKey: keyof T, key: string, value: any): void {
        this.data[objectKey][key] = value;
    }

    public addToParamArray(key: keyof T, value: any): void {
        (this.data[key] as any) = [...(this.data[key] as any), value];
    }

    public addToArr(array: any[], value: any) {
        array.push(value);
    }

    public addToArray(array: any[], value: any) {
        array.push(value);
    }

    public removeFromArrayByProp = (key: string, value: string, prop: string = "value") => {
        this.data[key] = this.data[key].filter((item) => (prop ? item[prop] : item) !== value);
    }

    public removeFromArray(array: any[], value: any) {
        const index = array.indexOf(value);

        if (index === -1) {
            return;
        }

        array.splice(index, 1);
    }

    public removeFromArrayByIndex(array: any[], index: number) {
        array.splice(index, 1);
    }

    public removeFromParamArray(key: keyof T, value: any): void {
        const arr: any = this.data[key];
        const index = arr.indexOf(value);
        if (index === -1) {
            return;
        }

        (this.data[key] as any) = (this.data[key] as any).filter((_, i) => i !== index);
    }

    public updateParamNested(keyPath: string[], finalKey: string, value: any) {

        if (keyPath.length === 0) {
            return;
        }
        let current: any = this.data;

        for (const step of keyPath) {
            current = current[step];
        }

        current[finalKey] = value;
    }
}
