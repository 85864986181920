import {observer} from 'mobx-react';
import {
    Breadcrumbs,
    Button,
    Chips,
    ConfirmPopMessage,
    Form,
    Label,
    Loader,
    Modal,
    PageContainer,
    PageMessage,
    SliderPanel,
    Spacer,
    Surface,
    Tab,
    TabsPanel
} from '@vidazoo/ui-kit';
import useManager from '../../../../../common/hooks/useManager';
import {ScheduleReportEditModel} from '../model/ScheduleReportEditModel.mdl';
import DetailsForm from '../../common/components/details-form/DetailsForm';
import ReportForm from '../../common/components/report-form/ReportForm';
import theme from "../../../../../common/styles/_baseForm.module.scss";
import * as React from "react";
import UrlsService from '../../../../../common/router/services/urlsService.mdl';
import {useNavigate, useParams} from 'react-router-dom';
import {useEffect} from 'react';
import {useModuleFactory} from "@injex/react-plugin";

export default observer(function ScheduleReportEdit() {
    const model = useModuleFactory<ScheduleReportEditModel>('scheduleReportEditModel');
    const navigate = useNavigate();
    const item = model.item.data;

    const [showRunNowMessage, setShowRunNowMessage] = React.useState(false);
    const {id} = useParams<{ id: string; }>();

    useEffect(() => {
        model.item.invalidate().fetch(id);
    }, [id]);

    const onCancelClick = () => {
        navigate(UrlsService.scheduleReports());
    };

    const renderForm = () => {
        return (
            <Form className={theme.form} onSubmit={model.submit}>
                <TabsPanel>
                    <Tab hash="details" label="Details">
                        <div className={theme.midContent}>
                            <Surface className={theme.surface} level={1} style={{marginBottom: 68}}>
                                <DetailsForm model={model}/>
                            </Surface>
                            <Surface className={theme.surface} level={1}>
                                <ReportForm model={model}/>
                            </Surface>
                        </div>
                    </Tab>
                </TabsPanel>
                <SliderPanel>
                    <div className={theme.actions}>
                        <Button type="button" medium gray uppercase onClick={onCancelClick}>Cancel</Button>
                        <Button type="button" medium green uppercase onClick={() => setShowRunNowMessage(true)}>Run
                            Now</Button>
                        <Button type="button" medium red uppercase onClick={model.onDelete}>Delete</Button>
                        <Button medium gray60 uppercase onClick={model.duplicate}>Duplicate</Button>
                        <Button type="submit" medium blue uppercase>Update</Button>
                    </div>
                </SliderPanel>
            </Form>
        );
    }

    const renderLoader = () => {
        return (
            <div className={theme.message}>
                <Loader size={1} gray className={theme.loader}/>
                <PageMessage
                    small
                    title="Loading Schedule Report"
                    description="Please wait..."
                />
            </div>
        );
    }

    const renderError = () => {
        return (
            <div className={theme.message}>
                <PageMessage
                    small
                    title="Failed to load schedule report"
                    description="We had an error loading the requested schedule report."
                    iconName="icon-rejected-status"
                />
            </div>
        );
    }

    const onAddRecipient = (value: string, label: string, isNew: boolean, name: string) => {
        model.addToRunNowRecipients(value);
    };

    const onRemoveRecipient = (value: string, label: string, name: string) => {
        model.removeFromRunNowRecipients(value, null);
    };

    const onRunNowClick = () => {
        model.runNow();
        setShowRunNowMessage(false)
    };

    const renderRunNowMessage = () => {

        return (
            <Modal external exitOnClickOutside onClose={() => setShowRunNowMessage(false)} isOpen={showRunNowMessage}>
                <ConfirmPopMessage
                    green
                    title="Run Schedule Report?"
                    text={<React.Fragment>
                        <div>Are you sure you want to run <Label weight={600}
                                                                 block={false}>{item.name}</Label>?
                        </div>
                        <div>The report will be sent in the next few minutes to your selected recipients below:</div>
                        <Spacer/>
                        <div className={theme.row}>
                            <Chips
                                name="runNowRecipients"
                                searchPlaceholder="Add Recipient Email"
                                allowCreate={true}
                                allowOrder={false}
                                onSelect={onAddRecipient}
                                onDelete={onRemoveRecipient}
                                chips={model.runNowRecipients}/>
                        </div>
                    </React.Fragment>}
                    cancelText="Cancel"
                    confirmText="OK"
                    onCancelClick={() => setShowRunNowMessage(false)}
                    onConfirmClick={onRunNowClick}
                />
            </Modal>
        );
    }

    const getPageTitle = () => {
        return ["Edit Schedule Report", getTitle()];
    }

    const getTitle = () => {
        const {isLoading, error, data} = model.item;
        return isLoading ? "Loading..." : error ? "Error" : data.name;
    }

    const getBreadcrumbs = () => {
        return [{
            label: "Schedule Reports",
            iconName: "icon-calendar",
            link: {to: UrlsService.scheduleReports()}
        }, {
            label: "update"
        }, {
            label: getTitle(),
            primary: true
        }];
    }

    return (
        <PageContainer pageTitle={getPageTitle()} inner top={1.2}>
            <div className={[theme.breadcrumbs, "flex", "justify-space-between"].join(" ")}>
                <Breadcrumbs breadcrumbs={getBreadcrumbs()}/>
            </div>
            {model.item.isLoading ? renderLoader() : null}
            {model.item.error ? renderError() : null}
            {!model.item.isLoading && !model.item.error ? renderForm() : null}
            {renderRunNowMessage()}
        </PageContainer>
    );
});
