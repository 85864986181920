import * as Papa from "papaparse";
import {isDefined} from '@vidazoo/ui-kit';

export function formatNumber(num: number, fixed?: number) {
    var value = (num || 0).toString();
    var options: Intl.NumberFormatOptions = {};
    if (!isNaN(fixed)) {
        options.maximumFractionDigits = fixed;
    }
    return parseFloat(value).toLocaleString(undefined, options);
}

export function remove<T>(arr: T[], shouldRemove: (item: T) => boolean): T[] {
    const removedItems: T[] = [];

    arr.filter((item) => {
        if (shouldRemove(item)) {
            removedItems.push(item);
            return false;
        }

        return true;
    });

    return removedItems;
}

export function compact(arr: any[]): any[] {
    if (!arr) {
        return [];
    }

    return arr.filter((item) => isDefined(item) && item !== null);
}

export function uniqueBy(arr: any[], key?: string): any[] {
    const unique = [];
    const index: any = {};
    for (let i = 0, len = arr.length; i < len; i++) {
        if ((key && !index[arr[i][key]])) {
            unique.push(arr[i]);
            index[arr[i][key]] = true;

        } else if (!key && !index[arr[i]]) {
            unique.push(arr[i]);
            index[arr[i]] = true;
        }
    }
    return unique;
}

export function isInRange(val: number, [min, max]): boolean {
    return val >= min && val <= max;
}

export function get<T = any>(predicate: any, path: string): T {
    let value = predicate;
    const parts = path.split('.');

    while (parts.length) {
        const part = parts.shift();
        value = value[part];
    }

    return value;
}

export function set(predicate: any, path: string, value: any): void {
    let current = predicate;
    const parts = path.split('.');
    const last = parts.pop();

    while (parts.length) {
        const part = parts.shift();
        current = current[part];
    }

    current[last] = value;
}

export function normalizeEventValue(e: any) {
    switch (e.target.type.toLowerCase()) {
        case "checkbox":
        case "radio":
            return e.target.checked;
        case "number": {
            const value = parseFloat(e.target.value);
            return !isNaN(value) ? value : "";
        }
        default:
            return e.target.value;
    }
}

export function percentageOf(value: number, total: number): number {
    return value / total * 100;
}

export function randomTenSteps(max: number = 10) {
    let randomNumber = Math.floor(Math.random() * max) + 1;
    return randomNumber * 10;
}

interface TemplateOptions {
    interpolate?: RegExp;
}

// We need to check if it works, because it's not tested and its from chatGpt 😅
export function template(templateString: string, options: TemplateOptions = {}): (values: Record<string, any>) => string {
    const interpolate = options.interpolate || /<%=([\s\S]+?)%>/g;

    // eslint-disable-next-line no-new-func
    return new Function('data',
        "var p=[];with(data){p.push('" +
        templateString.replace(/[\r\t\n]/g, " ")
            .replace(interpolate, "',typeof ($1) !== 'undefined' ? $1 : '', '")
            .replace(/<%/g, "');")
            .replace(/%>/g, "p.push('")
        + "');}return p.join('');") as any;
}

export function pick(obj, keys) {
    return keys.reduce((acc, key) => {
        if (obj.hasOwnProperty(key)) {
            acc[key] = obj[key];
        }
        return acc;
    }, {});
}

export function parseCsv<T>(csv: string): T[] {
    const {errors = [], data} = Papa.parse(csv, {skipEmptyLines: true});
    if (errors.length) {
        throw new Error(errors.join(","));
    }
    const headers = data[0] as string[];
    const rows = data.slice(1);
    return rows.map((row) => {
        // some windows users upload csv files with \r, and Papaparse does not remove them
        const trimmed: any = Array.isArray(row) ? row.map((value) => typeof value === "string" ? value.trim() : value) : row;
        return zipObject(headers, trimmed) as unknown as T;
    });
}

export function zipObject(keys: string[], values: any[]) {
    let result = {};
    for (let i = 0; i < keys.length; i++) {
        result[keys[i]] = values[i];
    }
    return result;
}

export const getClipboardContent = async (): Promise<string> => {
    const content = await navigator.clipboard.readText(); // while working on local ENV please change to localhost instead of vidazoo.local.com (devServer.js)
    if (!content) {

        return "";
    }
    return content;

};

export const genericStringSplit = (content: string): string[] => {
    if (content.includes(",")) {
        return content.split((","));

    } else if (content.includes("\r\n")) {
        return content.split("\r\n");

    } else if (content.includes("\n")) {
        return content.split("\n");

    } else if (content.includes("\t")) {
        return content.split(("\t"));
    }
    return [content];
};

export function isObject(value: any): boolean {
    // The typeof operator returns 'object' for both objects and null, but we want to exclude null.
    // Also, typeof returns 'function' for functions, but functions are objects in JavaScript, so we want to include those.
    return value !== null && (typeof value === 'object' || typeof value === 'function');
}

export function keyBy<T>(
    array: T[],
    iteratee: ((item: T) => string) | keyof T
): Record<string, T> {
    return array.reduce((result: Record<string, T>, value: T) => {
        let key: string;
        if (typeof iteratee === 'function') {
            key = iteratee(value);
        } else {
            key = String(value[iteratee]);
        }
        result[key] = value;
        return result;
    }, {});
}

