import * as React from 'react';
import {ButtonsGroup, GroupButton} from "@vidazoo/ui-kit";
import useReportingMetaDataManager from "../../../../../../common/hooks/useReportingMetaDataManager";
import {TimePresets} from "../../../../../../common/enums/TimePresets";
import {observer} from "mobx-react";
import {useEffect, useState} from "react";
import { useLocation } from 'react-router-dom';

function TimePresetsSelect(): JSX.Element {
    const location = useLocation();
    const {timePreset, onTimePresetChange} = useReportingMetaDataManager();
    const [show, setShow] = useState(false)

    useEffect(() => {
        setShow(location.pathname.includes("dashboard") || location.pathname.includes("chart"))
    }, [location.pathname]);

    return show ? (
        <ButtonsGroup active={timePreset} onChange={onTimePresetChange}>
            <GroupButton name={TimePresets.TwoHours}>Two Hours</GroupButton>
            <GroupButton name={TimePresets.FourHours}>Four Hours</GroupButton>
            <GroupButton name={TimePresets.Today}>Today</GroupButton>
            <GroupButton name={TimePresets.Yesterday}>Yesterday</GroupButton>
        </ButtonsGroup>
    ) : null
}

export default observer(TimePresetsSelect);
